import {Formik, Form, Field, ErrorMessage} from 'formik'
import * as Yup from 'yup'

import Swal from 'sweetalert2'
import {useState, useEffect} from 'react'
import {useIntl} from 'react-intl'
import React from 'react'
import {useLang} from '../../../_metronic/i18n/Metronici18n'
import {KTSVG} from '../../../_metronic/helpers'
import usePostApi from '../custom_hooks/usePostApi'
import {baseUrl} from '../core'
import secureLocalStorage from 'react-secure-storage'
import useApiCall from '../custom_hooks/useApiCall'
import DatePicker from 'react-datepicker'
import '../DatepickerStyles.css'
import 'react-datepicker/dist/react-datepicker.css'
import {useThemeMode} from '../../../_metronic/partials'
import {DateBasicFormat} from '../DateHelpers'

const AddAsset = ({}) => {
  const intl = useIntl()
  const lang = useLang()
  const {execute} = usePostApi()
  const {data: assettypeDetails} = useApiCall('/hrConfig/assetTypes')
  const {data: countries} = useApiCall('/country')
  const {mode} = useThemeMode()
  const {data: currencytype} = useApiCall('/currencyTypes')

  const [isSubmitting, setIsSubmitting] = useState(false)

  const validationSchema = Yup.object().shape({
    assetType: Yup.string().required('Asset Type is Required'),
    brand: Yup.string().required('Brand is Required'),
    make: Yup.string().required('Make is Required'),
    model: Yup.string().required('Model is Required'),
    serial_number: Yup.string().required('Serial Number is Required'),
    pr_no: Yup.string().required('Purchase Number is Required'),
    pr_date: Yup.string().required('Purchase Date is Required'),
    po_no: Yup.string().required('Purchase Order is Required'),
    po_date: Yup.string().required('Order Date is Required'),
    po_amount: Yup.string().required('Amount is Required'),
    unit_rate: Yup.string().required('Unit Rate is Required'),
    supplier: Yup.string().required('Supplier is Required'),
    working_status: Yup.string().required('Status is Required'),
    salvage_value: Yup.string().required('Salvage Value is Required'),
    depreciation_percentage: Yup.string().required('Depriciation is Required'),
    asset_lifespan: Yup.string().required('Asset Lifespan is Required'),
    asset_tag: Yup.string().required('Asset Tag is Required'),
    first_installation_date: Yup.string().required('Date is Required'),
    warranty_start_date: Yup.string().required('Warranty Date is Required'),
    warranty_expiry_date: Yup.string().when(
      'warranty_start_date',
      (warranty_start_date, schema) => {
        return schema
          .test(
            'warranty_expiry_date',
            'Expiry Date cannot be before Start Date',
            function (value) {
              return (
                !warranty_start_date || !value || new Date(value) >= new Date(warranty_start_date)
              )
            }
          )
          .required('Warranty Expiry Date is Required')
      }
    ),
    currency_id: Yup.string().required('Currency Id is Required'),
    country_of_manufacture: Yup.string().required('Country of Manufacture is Required'),
  })

  const initialValues = {
    assetType: '',
    currency_name: '',
    brand: '',
    make: '',
    model: '',
    country_name: '',
    serial_number: '',
    pr_no: '',
    pr_date: '',
    po_amount: '',
    unit_rate: '',
    po_no: '',
    po_date: '',
    supplier: '',
    working_status: '',
    salvage_value: '',
    depreciation_percentage: '',
    asset_lifespan: '',
    asset_tag: '',
    first_installation_date: '',
    in_stock: '',
    warranty_start_date: '',
    warranty_expiry_date: '',
    countryName: '',
    currency_id: '',
    country_of_manufacture: '',
  }

  const handleSubmit = async (values, formikHelpers) => {
    const {resetForm} = formikHelpers
    try {
      const organizationId = secureLocalStorage.getItem('organization_id')
      const url = `${baseUrl}/assetManagement/assets`
      const selectedassetType = assettypeDetails.find(
        (assetType) => assetType.asset_type_name === values.assetType
      )
      const selectedCountry = countries.find(
        (countryName) => countryName.country_name === values.countryName
      )
      const formData = {
        organization_id: organizationId,
        asset_type_id: selectedassetType.asset_type_id,
        brand: values.brand,
        make: values.make,
        model: values.model,
        country_of_manufacture: parseInt(values.country_of_manufacture),
        serial_number: values.serial_number,
        pr_no: values.pr_no,
        pr_date: DateBasicFormat(values.pr_date),
        po_amount: parseFloat(values.po_amount + '.01'),
        unit_rate: parseFloat(values.unit_rate + '.01'),
        po_no: values.po_no,
        po_date: DateBasicFormat(values.po_date),
        supplier: values.supplier,
        working_status: values.working_status,
        salvage_value: parseFloat(values.salvage_value + '.01'),
        depreciation_percentage: parseFloat(values.depreciation_percentage + '.01'),
        asset_lifespan: parseInt(values.asset_lifespan),
        asset_tag: values.asset_tag,
        first_installation_date: DateBasicFormat(values.first_installation_date),
        in_stock: 1,
        warranty_start_date: DateBasicFormat(values.warranty_start_date),
        warranty_expiry_date: DateBasicFormat(values.warranty_expiry_date),
        currency_id: parseInt(values.currency_id),
      }

      await execute(url, 'POST', formData)
      resetForm()

      Swal.fire({
        title: 'Asset Added Successfuully',
        text: 'You can now track the status of the Asset in the “All Assets” section..',
        icon: 'success',
      })
    } catch (error) {
      console.error(error)
      Swal.fire({
        icon: 'error',
        title: intl.formatMessage({id: 'LABEL.OOPS'}),
        text: intl.formatMessage({id: 'LABEL.SOMETHING_WRONG'}),
      })
    }
  }

  return (
    <div className='card shadow-none rounded-0'>
      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({values, setFieldValue}) => {
            return (
              <Form>
                <div className='card-header' id='side_activities_header'>
                  <h3 className='card-title fw-bolder text-dark'>Add Asset</h3>
                  <div className='card-toolbar '>
                    <React.Fragment>
                      <div
                        className='btn btn-icon btn-sm btn-active-danger'
                        id='side_activities_close'
                      >
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr061.svg'
                          className='svg-icon svg-icon-2x'
                        />
                      </div>
                    </React.Fragment>
                  </div>
                </div>

                <div className='card-body position-relative' id='side_activities_body'>
                  <div>
                    <div
                      id='side_activities_scroll'
                      className='position-relative scroll-y me-n5 pe-5 w-450px'
                      data-kt-scroll='true'
                      data-kt-scroll-height='auto'
                      data-kt-scroll-wrappers='#side_activities_body'
                      data-kt-scroll-dependencies='#side_activities_header, #side_activities_footer'
                      data-kt-scroll-offset='5px'
                    >
                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>Asset Type</label>
                        <div className='col-lg-8 text-start'>
                          <Field
                            as='select'
                            className='form-select form-select-solid'
                            id='floatingInput'
                            name='assetType'
                            value={values.assetType || ''}
                            onChange={(e) => setFieldValue('assetType', e.target.value)}
                          >
                            <option value=''> Select Asset type</option>

                            {assettypeDetails?.map((assetType) => (
                              <option
                                key={assetType.asset_type_id}
                                value={assetType.asset_type_name}
                              >
                                {assetType.asset_type_name}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage name='assetType' component='div' className='text-danger' />
                        </div>
                      </div>

                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>Brand</label>
                        <div className='col-lg-8 text-start'>
                          <Field
                            type='text'
                            name='brand'
                            className='form-control form-control-solid'
                            value={values.brand || ''}
                            onChange={(e) => setFieldValue('brand', e.target.value)}
                            placeholder='brand'
                          />
                          <ErrorMessage name='brand' component='div' className='text-danger' />
                        </div>
                      </div>

                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>Make</label>
                        <div className='col-lg-8 text-start'>
                          <Field
                            type='text'
                            name='make'
                            className='form-control form-control-solid'
                            value={values.make || ''}
                            placeholder='Make'
                            onChange={(e) => setFieldValue('make', e.target.value)}
                          />
                          <ErrorMessage name='make' component='div' className='text-danger' />
                        </div>
                      </div>

                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>Model</label>
                        <div className='col-lg-8 text-start'>
                          <Field
                            type='text'
                            name='model'
                            className='form-control form-control-solid'
                            value={values.model || ''}
                            placeholder='Model'
                            onChange={(e) => setFieldValue('model', e.target.value)}
                          />
                          <ErrorMessage name='model' component='div' className='text-danger' />
                        </div>
                      </div>

                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          {intl.formatMessage({id: 'LABEL.COUNTRY'})}
                        </label>
                        <div className='col-lg-8 text-start'>
                          <Field
                            as='select'
                            name='country_of_manufacture'
                            className='form-select form-select-solid'
                            value={values.country_of_manufacture || ''}
                            onChange={(e) =>
                              setFieldValue('country_of_manufacture', e.target.value)
                            }
                            placeholder='Select Country'
                          >
                            <option value=''>Select Country</option>
                            {countries?.map((countryName) => (
                              <option key={countryName.country_id} value={countryName.country_id}>
                                {countryName.country_name}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            name='country_of_manufacture'
                            component='div'
                            className='text-danger'
                          />
                        </div>
                      </div>

                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          {intl.formatMessage({id: 'CURRENCY'})}
                        </label>
                        <div className='col-lg-8 text-start'>
                          <Field
                            as='select'
                            className='form-select form-select-solid'
                            id='floatingInput'
                            name='currency_id'
                            value={values.currency_id}
                            onChange={(e) => setFieldValue('currency_id', e.target.value)}
                          >
                            <option key='' value=''>
                              {intl.formatMessage({id: 'LABEL.SELECT_CURRENCY'})}
                            </option>

                            {currencytype?.map((currencyType) => (
                              <option
                                key={currencyType.currency_id}
                                value={currencyType.currency_id}
                              >
                                {currencyType.currency_name}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            name='currency_id'
                            component='div'
                            className='text-danger'
                          />
                        </div>
                      </div>

                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          Serial Number
                        </label>
                        <div className='col-lg-8 text-start'>
                          <Field
                            type='text'
                            name='serial_number'
                            className='form-control form-control-solid'
                            value={values.serial_number || ''}
                            placeholder='Serial Number'
                            onChange={(e) => setFieldValue('serial_number', e.target.value)}
                          />
                          <ErrorMessage
                            name='serial_number'
                            component='div'
                            className='text-danger'
                          />
                        </div>
                      </div>
                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          Purchase Number
                        </label>
                        <div className='col-lg-8 text-start'>
                          <Field
                            type='text'
                            name='pr_no'
                            className='form-control form-control-solid'
                            // value={values.pr_no || ''}
                            placeholder='Purchase Number'
                            // onChange={(e) => setFieldValue('pr_no', e.target.value)}
                          />
                          <ErrorMessage name='pr_no' component='div' className='text-danger' />
                        </div>
                      </div>

                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          Purchase Date
                        </label>
                        <div className='col-lg-8 text-start'>
                          <Field name='pr_date'>
                            {({form, field}) => {
                              const {setFieldValue} = form
                              const {value} = field

                              const handleChange = (date) => {
                                setFieldValue('pr_date', date)
                              }

                              return (
                                <div
                                  className={`react-datepicker-fullwidth-wrapper test ${
                                    mode === 'dark' ? 'dark-mode' : 'light-mode'
                                  }`}
                                >
                                  <DatePicker
                                    className='form-control form-control-solid'
                                    selected={value}
                                    isClearable
                                    showYearDropdown
                                    scrollableYearDropdown
                                    dateFormat='dd/MM/yyyy'
                                    onChange={(date) => {
                                      handleChange(date)
                                    }}
                                    placeholderText='Select Date'
                                    showMonthDropdown
                                    useShortMonthInDropdown
                                  />
                                </div>
                              )
                            }}
                          </Field>
                          <ErrorMessage name='pr_date' component='div' className='text-danger' />
                        </div>
                      </div>
                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          Purchase Amount
                        </label>
                        <div className='col-lg-8 text-start'>
                          <Field
                            type='number'
                            name='po_amount'
                            className='form-control form-control-solid'
                            value={values.po_amount || ''}
                            placeholder='Select Amount'
                            onChange={(e) => setFieldValue('po_amount', e.target.value)}
                          />
                          <ErrorMessage name='po_amount' component='div' className='text-danger' />
                        </div>
                      </div>

                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>Unit Rate</label>
                        <div className='col-lg-8 text-start'>
                          <Field
                            type='number'
                            name='unit_rate'
                            className='form-control form-control-solid'
                            value={values.unit_rate || ''}
                            placeholder='Unit Rate'
                            onChange={(e) => setFieldValue('unit_rate', e.target.value)}
                          />
                          <ErrorMessage name='unit_rate' component='div' className='text-danger' />
                        </div>
                      </div>

                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          Puchase Order
                        </label>
                        <div className='col-lg-8 text-start'>
                          <Field
                            type='text'
                            name='po_no'
                            className='form-control form-control-solid'
                            value={values.po_no || ''}
                            placeholder='Purchase Order'
                            onChange={(e) => setFieldValue('po_no', e.target.value)}
                          />
                          <ErrorMessage name='po_no' component='div' className='text-danger' />
                        </div>
                      </div>

                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>Order Date</label>
                        <div className='col-lg-8 text-start'>
                          <Field name='po_date'>
                            {({form, field}) => {
                              const {setFieldValue} = form
                              const {value} = field

                              const handleChange = (date) => {
                                setFieldValue('po_date', date)
                              }

                              return (
                                <div
                                  className={`react-datepicker-fullwidth-wrapper test ${
                                    mode === 'dark' ? 'dark-mode' : 'light-mode'
                                  }`}
                                >
                                  <DatePicker
                                    className='form-control form-control-solid'
                                    selected={value}
                                    isClearable
                                    showYearDropdown
                                    scrollableYearDropdown
                                    dateFormat='dd/MM/yyyy'
                                    onChange={(date) => {
                                      handleChange(date)
                                    }}
                                    placeholderText='Select Date'
                                    showMonthDropdown
                                    useShortMonthInDropdown
                                  />
                                </div>
                              )
                            }}
                          </Field>
                          <ErrorMessage name='po_date' component='div' className='text-danger' />
                        </div>
                      </div>
                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>Supplier</label>
                        <div className='col-lg-8 text-start'>
                          <Field
                            type='text'
                            name='supplier'
                            className='form-control form-control-solid'
                            value={values.supplier || ''}
                            placeholder='Supplier'
                            onChange={(e) => setFieldValue('supplier', e.target.value)}
                          />
                          <ErrorMessage name='supplier' component='div' className='text-danger' />
                        </div>
                      </div>
                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          Working Status
                        </label>
                        <div className='col-lg-8 text-start'>
                          <Field
                            type='text'
                            name='working_status'
                            className='form-control form-control-solid'
                            value={values.working_status || ''}
                            placeholder='Status'
                            onChange={(e) => setFieldValue('working_status', e.target.value)}
                          />
                          <ErrorMessage
                            name='working_status'
                            component='div'
                            className='text-danger'
                          />
                        </div>
                      </div>
                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          Salvage Value
                        </label>
                        <div className='col-lg-8 text-start'>
                          <Field
                            type='number'
                            name='salvage_value'
                            className='form-control form-control-solid'
                            value={values.salvage_value || ''}
                            placeholder='Salvage Value'
                            onChange={(e) => setFieldValue('salvage_value', e.target.value)}
                          />
                          <ErrorMessage
                            name='salvage_value'
                            component='div'
                            className='text-danger'
                          />
                        </div>
                      </div>

                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          Depriciation Percentage
                        </label>
                        <div className='col-lg-8 text-start'>
                          <Field
                            type='number'
                            name='depreciation_percentage'
                            className='form-control form-control-solid'
                            value={values.depreciation_percentage || ''}
                            placeholder='Depreciation'
                            onChange={(e) =>
                              setFieldValue('depreciation_percentage', e.target.value)
                            }
                          />
                          <ErrorMessage
                            name='depreciation_percentage'
                            component='div'
                            className='text-danger'
                          />
                        </div>
                      </div>

                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          Asset LifeSpan(in yrs)
                        </label>
                        <div className='col-lg-8 text-start'>
                          <Field
                            type='number'
                            name='asset_lifespan'
                            className='form-control form-control-solid'
                            value={values.asset_lifespan || ''}
                            placeholder='Life Span'
                            onChange={(e) => setFieldValue('asset_lifespan', e.target.value)}
                          />
                          <ErrorMessage
                            name='asset_lifespan'
                            component='div'
                            className='text-danger'
                          />
                        </div>
                      </div>

                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>Asset Tag</label>
                        <div className='col-lg-8 text-start'>
                          <Field
                            type='text'
                            name='asset_tag'
                            className='form-control form-control-solid'
                            value={values.asset_tag || ''}
                            placeholder='Asset Tag'
                            onChange={(e) => setFieldValue('asset_tag', e.target.value)}
                          />
                          <ErrorMessage name='asset_tag' component='div' className='text-danger' />
                        </div>
                      </div>

                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          Installation Date
                        </label>
                        <div className='col-lg-8 text-start'>
                          <Field name='first_installation_date'>
                            {({form, field}) => {
                              const {setFieldValue} = form
                              const {value} = field

                              const handleChange = (date) => {
                                setFieldValue('first_installation_date', date)
                              }

                              return (
                                <div
                                  className={`react-datepicker-fullwidth-wrapper test ${
                                    mode === 'dark' ? 'dark-mode' : 'light-mode'
                                  }`}
                                >
                                  <DatePicker
                                    className='form-control form-control-solid'
                                    selected={value}
                                    isClearable
                                    showYearDropdown
                                    scrollableYearDropdown
                                    dateFormat='dd/MM/yyyy'
                                    onChange={(date) => {
                                      handleChange(date)
                                    }}
                                    placeholderText='Select Date'
                                    showMonthDropdown
                                    useShortMonthInDropdown
                                  />
                                </div>
                              )
                            }}
                          </Field>
                          <ErrorMessage
                            name='first_installation_date'
                            component='div'
                            className='text-danger'
                          />
                        </div>
                      </div>

                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          Warranty Start Date
                        </label>
                        <div className='col-lg-8 text-start'>
                          <Field name='warranty_start_date'>
                            {({form, field}) => {
                              const {setFieldValue} = form
                              const {value} = field

                              const handleChange = (date) => {
                                setFieldValue('warranty_start_date', date)
                              }

                              return (
                                <div
                                  className={`react-datepicker-fullwidth-wrapper test ${
                                    mode === 'dark' ? 'dark-mode' : 'light-mode'
                                  }`}
                                >
                                  <DatePicker
                                    className='form-control form-control-solid'
                                    selected={value}
                                    isClearable
                                    showYearDropdown
                                    scrollableYearDropdown
                                    dateFormat='dd/MM/yyyy'
                                    onChange={(date) => {
                                      handleChange(date)
                                    }}
                                    placeholderText='Select  Start Date'
                                    showMonthDropdown
                                    useShortMonthInDropdown
                                  />
                                </div>
                              )
                            }}
                          </Field>
                          <ErrorMessage
                            name='warranty_start_date'
                            component='div'
                            className='text-danger'
                          />
                        </div>
                      </div>

                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          Warranty End Date
                        </label>
                        <div className='col-lg-8 text-start'>
                          <Field name='warranty_expiry_date'>
                            {({form, field}) => {
                              const {setFieldValue} = form
                              const {value} = field

                              const handleChange = (date) => {
                                setFieldValue('warranty_expiry_date', date)
                              }

                              let minDate = null
                              if (values && values.warranty_start_date) {
                                const startDate = new Date(values.warranty_start_date)
                                minDate = startDate.setDate(startDate.getDate() + 1)
                              }

                              return (
                                <div
                                  className={`react-datepicker-fullwidth-wrapper test ${
                                    mode === 'dark' ? 'dark-mode' : 'light-mode'
                                  }`}
                                >
                                  <DatePicker
                                    className='form-control form-control-solid'
                                    selected={value}
                                    isClearable
                                    showYearDropdown
                                    scrollableYearDropdown
                                    dateFormat='dd/MM/yyyy'
                                    onChange={(date) => {
                                      handleChange(date)
                                    }}
                                    placeholderText='Select End Date'
                                    showMonthDropdown
                                    useShortMonthInDropdown
                                    minDate={minDate}
                                  />
                                </div>
                              )
                            }}
                          </Field>
                          <ErrorMessage
                            name='warranty_expiry_date'
                            component='div'
                            className='text-danger'
                          />
                        </div>
                      </div>
                    </div>

                    <div className='card-footer p-0 py-5 text-end'>
                      <button
                        type='submit'
                        className='btn btn-sm btn-light-primary ms-2'
                        onClick={() => {
                          setIsSubmitting(true)
                        }}
                      >
                        {intl.formatMessage({id: 'LABEL.ADD'})}
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            )
          }}
        </Formik>
      </div>
    </div>
  )
}

export default AddAsset
