import {useEffect, useMemo, useState} from 'react'
import {useTable, usePagination} from 'react-table'
import {Pagination} from 'react-bootstrap'
import {KTIcon} from '../../../../_metronic/helpers'
import Context from '../../../../_metronic/partials/layout/activity-drawer/context'
import {SideDrawer} from '../../../../_metronic/partials/layout/activity-drawer/Side-drawer'
import {useIntl} from 'react-intl'
import DatePicker from 'react-datepicker'
import '../../DatepickerStyles.css'
import 'react-datepicker/dist/react-datepicker.css'
import {useThemeMode} from '../../../../_metronic/partials'
import DateFormatter from '../../DateFormatter'
import {useLocation} from 'react-router-dom'
import usePageTitle from '../../custom_hooks/usePageTitle'
import secureLocalStorage from 'react-secure-storage'
import VacancyContext from '../vacancies/VacancyContext'
import useApiCall from '../../custom_hooks/useApiCall'
import {baseUrl} from '../../core'
import Swal from 'sweetalert2'
import usePostApi from '../../custom_hooks/usePostApi'

const DataTable = ({columns, data, loading}) => {
  const [inputVal, setInputVal] = useState('')
  const intl = useIntl()
  const {mode} = useThemeMode()
  const [selectedDate, setSelectedDate] = useState(null)
  usePageTitle(intl.formatMessage({id: 'MENU.RECRUITMENT.ALLCANDIDATES'}))
  // Filter data based on input value
  const filteredData = useMemo(() => {
    return data?.filter((each) => each.first_name.toLowerCase().includes(inputVal.toLowerCase()))
  }, [data, inputVal])

  // Create separate datasets for active and inactive records

  const activeData = useMemo(() => {
    return filteredData?.filter((leave) => leave.offer_status === 'Pending')
  }, [filteredData])

  const inactiveData = useMemo(() => {
    return filteredData?.filter((leave) => leave.offer_status === 'Selected')
  }, [filteredData])

  // Table instances for active and inactive dataset
  const RejectedData = useMemo(() => {
    return filteredData?.filter((leave) => leave.offer_status === 'Rejected')
  }, [filteredData])

  // Table instances for active and inactive dataset
  const activeTableInstance = useTable({columns, data: activeData}, usePagination)
  const inactiveTableInstance = useTable({columns, data: inactiveData}, usePagination)
  const RejectedDataInstance = useTable({columns, data: RejectedData}, usePagination)

  // Extract required values from table instances
  const activePage = activeTableInstance.page
  const activePageCount = activeTableInstance.pageCount
  const activePageIndex = activeTableInstance.state.pageIndex
  const activePageSize = activeTableInstance.state.pageSize

  const inactivePage = inactiveTableInstance.page
  const inactivePageCount = inactiveTableInstance.pageCount
  const inactivePageIndex = inactiveTableInstance.state.pageIndex
  const inactivePageSize = inactiveTableInstance.state.pageSize

  const RejectedPage = RejectedDataInstance.page
  const RejectedPageCount = RejectedDataInstance.pageCount
  const RejectedPageIndex = RejectedDataInstance.state.pageIndex
  const RejectedPageSize = RejectedDataInstance.state.pageSize

  const gotoActivePage = (pageIndex) => {
    activeTableInstance.gotoPage(pageIndex)
  }

  const setActivePageSize = (pageSize) => {
    activeTableInstance.setPageSize(pageSize)
  }

  // Handle pagination for inactive dataset
  const gotoInactivePage = (pageIndex) => {
    inactiveTableInstance.gotoPage(pageIndex)
  }

  const setInactivePageSize = (pageSize) => {
    inactiveTableInstance.setPageSize(pageSize)
  }

  const gotoRejectedPage = (pageIndex) => {
    RejectedDataInstance.gotoPage(pageIndex)
  }

  const setRejectedPage = (pageSize) => {
    RejectedDataInstance.setPageSize(pageSize)
  }

  const pageOptions = [5, 10, 20, 30, 40, 50]

  return (
    <VacancyContext.Consumer>
      {(value) => {
        const {VACANCYID, updateVACANCYID} = value
        return (
          <>
            {loading ? (
              <div className='card h-250px'>
                <div className='m-auto d-flex flex-column align-items-center'>
                  <div className='spinner-border spinner-primary mr-15'></div>
                </div>
              </div>
            ) : data.length > 0 ? (
              <>
                <div className='d-flex'>
                  <div className='d-flex flex-wrap flex-stack mb-6'>
                    <h3 className='fw-bolder my-2'>
                      {' '}
                      {intl.formatMessage({id: 'MENU.RECRUITMENT.ALLCANDIDATES'})}
                    </h3>
                  </div>
                </div>
                <div className='card'>
                  <>
                    <div className='card-header border-0 pt-5'>
                      <div className='d-flex flex-wrap flex-stack'>
                        <div className='fw-bolder'>
                          <ul className='nav'>
                            <li className='nav-item'>
                              <a
                                className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary active fw-bold px-4 me-1 ms-0'
                                data-bs-toggle='tab'
                                href='#kt_table_widget_4_tab_1'
                              >
                                {intl.formatMessage({id: 'LABEL.PENDING'})}
                              </a>
                            </li>
                            <li className='nav-item'>
                              <a
                                className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1'
                                data-bs-toggle='tab'
                                href='#kt_table_widget_4_tab_2'
                              >
                                {intl.formatMessage({id: 'LABEL.APPROVED'})}
                              </a>
                            </li>
                            <li className='nav-item'>
                              <a
                                className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1'
                                data-bs-toggle='tab'
                                href='#kt_table_widget_4_tab_3'
                              >
                                {intl.formatMessage({id: 'LABEL.REJECTED'})}
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className='card-toolbar'>
                        <div className='d-flex align-items-center position-relative me-3'>
                          <KTIcon iconName='magnifier' className='fs-3 position-absolute ms-3' />
                          <input
                            type='text'
                            id='kt_filter_search'
                            className='form-control form-control-white form-control-sm w-150px ps-9'
                            placeholder={intl.formatMessage({id: 'LABEL.SEARCH'})}
                            value={inputVal}
                            onChange={(e) => setInputVal(e.target.value)}
                          />
                        </div>

                        {/* <button className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                          <KTIcon iconName='calendar' className='fs-2 m-0' />
                        </button> */}
                        {/* <div
                          className={`react-datepicker-fullwidth-wrapper test ${
                            mode === 'dark' ? 'dark-mode' : 'light-mode'
                          }`}
                        >
                          <DatePicker
                            className='form-control form-control-solid'
                            selected={selectedDate}
                            isClearable
                            showYearDropdown
                            scrollableYearDropdown
                            dateFormat='dd/MM/yyyy'
                            onChange={(date) => setSelectedDate(date)}
                            placeholderText='Select Date'
                            showMonthDropdown
                            useShortMonthInDropdown
                          />
                        </div> */}
                        <a
                          href='#'
                          className='btn btn-sm btn-light-primary mx-2'
                          id='side_activities_toggle'
                          onClick={() => {
                            updateVACANCYID({
                              type: 'add_candidate',
                            })
                          }}
                        >
                          <KTIcon iconName='plus' className='fs-3' />
                          {intl.formatMessage({id: 'BTN.ADD_CANDIDATES'})}
                        </a>
                      </div>
                    </div>
                    <div className='tab-content p-2 pt-0'>
                      <div
                        className='card-body tab-pane fade show active table-responsive'
                        id='kt_table_widget_4_tab_1'
                      >
                        <table
                          className='table table-row-dashed align-middle table-row-gray-300'
                          {...activeTableInstance.getTableProps()}
                        >
                          <thead>
                            <tr className='fw-bold text-muted'>
                              <th className='min-w-150px ms-9 text-start default-cursor'>
                                {intl.formatMessage({id: 'LABEL.CANDIDATE'})}
                              </th>

                              <th className='min-w-120px text-start default-cursor'>
                                {intl.formatMessage({id: 'LABEL.EMAIL'})}
                              </th>

                              <th className='min-w-120px text-start default-cursor'>
                                {intl.formatMessage({id: 'LABEL.ROLE'})}
                              </th>

                              <th className='min-w-100px text-start default-cursor'>
                                {intl.formatMessage({id: 'LABEL.EDUCATION'})}
                              </th>

                              <th className='min-w-100px text-start default-cursor'>
                                {intl.formatMessage({id: 'LABEL.EXPERIENCE'})}
                              </th>
                              {/* <th className='min-w-100px text-start default-cursor'>
                                {intl.formatMessage({id: 'LABEL.SOURCE_OF_HIRE'})}
                              </th> */}

                              {/* <th className='min-w-100px text-start default-cursor'>
                                {intl.formatMessage({id: 'LABEL.STATUS'})}
                              </th> */}

                              <th className='min-w-100px text-center default-cursor'>
                                {intl.formatMessage({id: 'MENU.ACTIONS'})}
                              </th>
                            </tr>
                          </thead>
                          <tbody {...activeTableInstance.getTableBodyProps()}>
                            {activePage.length > 0 ? (
                              activePage.map((row) => {
                                activeTableInstance.prepareRow(row)
                                return (
                                  <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => (
                                      <td className=' ' {...cell.getCellProps()}>
                                        {cell.render('Cell')}
                                      </td>
                                    ))}
                                  </tr>
                                )
                              })
                            ) : (
                              <tr>
                                <td>
                                  <p></p>
                                </td>
                                <td className='card'>
                                  <p className='text-dark fw-bold text-hover-primary d-block fs-6 m-0 my-5'>
                                    {intl.formatMessage({id: 'NO_RESULTS_FOUND_MSG'})}
                                  </p>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>

                        {data.length > 10 && (
                          <Pagination>
                            <Pagination.First
                              onClick={() => gotoActivePage(0)}
                              disabled={!activeTableInstance.canPreviousPage}
                            />
                            <Pagination.Prev
                              onClick={activeTableInstance.previousPage}
                              disabled={!activeTableInstance.canPreviousPage}
                            />
                            {[...Array(activePageCount)].map((_, i) => (
                              <Pagination.Item
                                key={i}
                                active={i === activePageIndex}
                                onClick={() => gotoActivePage(i)}
                              >
                                {i + 1}
                              </Pagination.Item>
                            ))}
                            <Pagination.Next
                              onClick={activeTableInstance.nextPage}
                              disabled={!activeTableInstance.canNextPage}
                            />
                            <Pagination.Last
                              onClick={() => gotoActivePage(activePageCount - 1)}
                              disabled={!activeTableInstance.canNextPage}
                            />
                            <div className='d-flex align-items-center mx-5'>
                              <span className='me-2'>{intl.formatMessage({id: 'LABEL.SHOW'})}</span>
                              <select
                                className='form-select form-select-solid fw-bold w-75px me-2'
                                value={activePageSize}
                                onChange={(e) => setActivePageSize(Number(e.target.value))}
                              >
                                {pageOptions.map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </Pagination>
                        )}
                      </div>

                      <div
                        className='card-body tab-pane fade  table-responsive'
                        id='kt_table_widget_4_tab_2'
                      >
                        <table
                          className='table table-row-dashed align-middle table-row-gray-300'
                          {...inactiveTableInstance.getTableProps()}
                        >
                          <thead>
                            <tr className='fw-bold text-muted'>
                              <th className='min-w-150px ms-9 text-start default-cursor'>
                                {intl.formatMessage({id: 'LABEL.CANDIDATE'})}
                              </th>

                              <th className='min-w-120px text-start default-cursor'>
                                {intl.formatMessage({id: 'LABEL.EMAIL'})}
                              </th>

                              <th className='min-w-120px text-start default-cursor'>
                                {intl.formatMessage({id: 'LABEL.ROLE'})}
                              </th>

                              <th className='min-w-100px text-start default-cursor'>
                                {intl.formatMessage({id: 'LABEL.EDUCATION'})}
                              </th>

                              <th className='min-w-100px text-start default-cursor'>
                                {intl.formatMessage({id: 'LABEL.EXPERIENCE'})}
                              </th>
                              {/* <th className='min-w-100px text-start default-cursor'>
                                {intl.formatMessage({id: 'LABEL.SOURCE_OF_HIRE'})}
                              </th> */}

                              {/* <th className='min-w-100px text-start default-cursor'>
                                {intl.formatMessage({id: 'LABEL.STATUS'})}
                              </th> */}

                              {/* <th className='min-w-100px text-center default-cursor'>
                                {intl.formatMessage({id: 'MENU.ACTIONS'})}
                              </th> */}
                            </tr>
                          </thead>
                          <tbody {...inactiveTableInstance.getTableBodyProps()}>
                            {inactivePage.length > 0 ? (
                              inactivePage.map((row) => {
                                inactiveTableInstance.prepareRow(row)
                                return (
                                  <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => (
                                      <td className=' ' {...cell.getCellProps()}>
                                        {cell.render('Cell')}
                                      </td>
                                    ))}
                                  </tr>
                                )
                              })
                            ) : (
                              <tr>
                                <td>
                                  <p></p>
                                </td>
                                <td className='card'>
                                  <p className='text-dark fw-bold text-hover-primary d-block fs-6 m-0 my-5'>
                                    {intl.formatMessage({id: 'NO_RESULTS_FOUND_MSG'})}
                                  </p>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>

                        {data.length > 10 && (
                          <Pagination>
                            <Pagination.First
                              onClick={() => gotoInactivePage(0)}
                              disabled={!inactiveTableInstance.canPreviousPage}
                            />
                            <Pagination.Prev
                              onClick={inactiveTableInstance.previousPage}
                              disabled={!inactiveTableInstance.canPreviousPage}
                            />
                            {[...Array(inactivePageCount)].map((_, i) => (
                              <Pagination.Item
                                key={i}
                                active={i === inactivePageIndex}
                                onClick={() => gotoInactivePage(i)}
                              >
                                {i + 1}
                              </Pagination.Item>
                            ))}
                            <Pagination.Next
                              onClick={inactiveTableInstance.nextPage}
                              disabled={!inactiveTableInstance.canNextPage}
                            />
                            <Pagination.Last
                              onClick={() => gotoInactivePage(inactivePageCount - 1)}
                              disabled={!inactiveTableInstance.canNextPage}
                            />
                            <div className='d-flex align-items-center mx-5'>
                              <span className='me-2'>{intl.formatMessage({id: 'LABEL.SHOW'})}</span>
                              <select
                                className='form-select form-select-solid fw-bold w-75px me-2'
                                value={inactivePageSize}
                                onChange={(e) => setInactivePageSize(Number(e.target.value))}
                              >
                                {pageOptions.map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </Pagination>
                        )}
                      </div>

                      <div
                        className='card-body tab-pane fade  table-responsive'
                        id='kt_table_widget_4_tab_3'
                      >
                        <table
                          className='table table-row-dashed align-middle table-row-gray-300'
                          {...inactiveTableInstance.getTableProps()}
                        >
                          <thead>
                            <tr className='fw-bold text-muted'>
                              <th className='min-w-150px ms-9 text-start default-cursor'>
                                {intl.formatMessage({id: 'LABEL.CANDIDATE'})}
                              </th>

                              <th className='min-w-120px text-start default-cursor'>
                                {intl.formatMessage({id: 'LABEL.EMAIL'})}
                              </th>

                              <th className='min-w-120px text-start default-cursor'>
                                {intl.formatMessage({id: 'LABEL.ROLE'})}
                              </th>

                              <th className='min-w-100px text-start default-cursor'>
                                {intl.formatMessage({id: 'LABEL.EDUCATION'})}
                              </th>

                              <th className='min-w-100px text-start default-cursor'>
                                {intl.formatMessage({id: 'LABEL.EXPERIENCE'})}
                              </th>
                              {/* <th className='min-w-100px text-start default-cursor'>
                                {intl.formatMessage({id: 'LABEL.SOURCE_OF_HIRE'})}
                              </th> */}

                              {/* <th className='min-w-100px text-start default-cursor'>
                                {intl.formatMessage({id: 'LABEL.STATUS'})}
                              </th> */}

                              {/* <th className='min-w-100px text-center default-cursor'>
                                {intl.formatMessage({id: 'MENU.ACTIONS'})}
                              </th> */}
                            </tr>
                          </thead>

                          <tbody {...RejectedDataInstance.getTableBodyProps()}>
                            {RejectedPage.length > 0 ? (
                              RejectedPage.map((row) => {
                                RejectedDataInstance.prepareRow(row)
                                return (
                                  <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => (
                                      <td className=' ' {...cell.getCellProps()}>
                                        {cell.render('Cell')}
                                      </td>
                                    ))}
                                  </tr>
                                )
                              })
                            ) : (
                              <tr>
                                <td>
                                  <p></p>
                                </td>
                                <td className='card'>
                                  <p className='text-dark fw-bold text-hover-primary d-block fs-6 m-0 my-5'>
                                    {intl.formatMessage({id: 'NO_RESULTS_FOUND_MSG'})}
                                  </p>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>

                        {data.length > 10 && (
                          <Pagination>
                            <Pagination.First
                              onClick={() => gotoRejectedPage(0)}
                              disabled={!RejectedDataInstance.canPreviousPage}
                            />
                            <Pagination.Prev
                              onClick={RejectedDataInstance.previousPage}
                              disabled={!RejectedDataInstance.canPreviousPage}
                            />
                            {[...Array(RejectedPageCount)].map((_, i) => (
                              <Pagination.Item
                                key={i}
                                active={i === RejectedPageIndex}
                                onClick={() => gotoRejectedPage(i)}
                              >
                                {i + 1}
                              </Pagination.Item>
                            ))}
                            <Pagination.Next
                              onClick={RejectedDataInstance.nextPage}
                              disabled={!RejectedDataInstance.canNextPage}
                            />
                            <Pagination.Last
                              onClick={() => gotoRejectedPage(RejectedPageCount - 1)}
                              disabled={!RejectedDataInstance.canNextPage}
                            />
                            <div className='d-flex align-items-center mx-5'>
                              <span className='me-2'>{intl.formatMessage({id: 'LABEL.SHOW'})}</span>
                              <select
                                className='form-select form-select-solid fw-bold w-75px me-2'
                                value={RejectedPageSize}
                                onChange={(e) => setRejectedPage(Number(e.target.value))}
                              >
                                {pageOptions.map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </Pagination>
                        )}
                      </div>
                    </div>
                  </>
                </div>
              </>
            ) : (
              <div className='card h-250px'>
                <div className='m-auto d-flex flex-column align-items-center'>
                  <h1 className='mb-5'>{intl.formatMessage({id: 'LABEL.NO_CANDIDATES_FOUND'})}</h1>

                  <button
                    className='btn btn-sm btn-light-primary'
                    id='side_activities_toggle'
                    onClick={() => {
                      updateVACANCYID({
                        type: 'add_candidate',
                      })
                    }}
                  >
                    <KTIcon iconName='plus' className='fs-3' />
                    {intl.formatMessage({id: 'BTN.ADD_CANDIDATES'})}
                  </button>
                </div>
              </div>
            )}
            <SideDrawer value={VACANCYID} />
          </>
        )
      }}
    </VacancyContext.Consumer>
  )
}

const AllCandidates = () => {
  const location = useLocation()
  const intl = useIntl()
  const {execute} = usePostApi()
  const {data: filteredData, isLoading, fetchData} = useApiCall(`/recruitment/candidates`)

  useEffect(() => {
    const targetElement = document.getElementById('side_activities')
    if (targetElement && location.pathname === '/recruitment/allcandidates') {
      const body = document.querySelector('body')
      const observer = new MutationObserver(() => {
        if (!body.hasAttribute('data-kt-drawer-side-activities')) {
          fetchData()
        }
      })
      observer.observe(targetElement, {attributes: true})
      return () => {
        observer.disconnect()
      }
    }
  }, [location.pathname])

  const columns = useMemo(
    () => [
      {
        Header: 'Candidate',
        accessor: 'Candidate',
        Cell: ({row}) => {
          return (
            <div className='d-flex align-items-center'>
              <div className='text-start'>
                <p className='text-dark fw-bold text-hover-primary d-block fs-6 default-cursor'>
                  {row.original.first_name} {row.original.last_name}
                </p>
              </div>
            </div>
          )
        },
      },
      {
        Header: 'E-MAIL',
        accessor: 'E-MAIL',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark fw-bold text-hover-primary d-block fs-6 default-cursor'>
                {row.original.email}
              </p>
            </div>
          )
        },
        disableSortBy: true,
      },

      {
        Header: 'Applied Role',
        accessor: 'Applied Role',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark fw-bold text-hover-primary d-block fs-6 default-cursor'>
                {row.original.role_name}
              </p>
            </div>
          )
        },
        disableSortBy: true,
      },

      {
        Header: 'Education',
        accessor: 'Education',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark fw-bold text-hover-primary d-block fs-6 default-cursor'>
                {row.original.education}
              </p>
            </div>
          )
        },
        disableSortBy: true,
      },

      {
        Header: 'Experience ',
        accessor: 'Experience',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark fw-bold text-hover-primary d-block fs-6 default-cursor'>
                {row.original.experience_in_yrs}
              </p>
            </div>
          )
        },
        disableSortBy: true,
      },

      {
        Header: 'Actions',
        accessor: 'Actions',
        Cell: ({row}) => {
          const offerStatus = row.original.offer_status

          if (offerStatus === 'Pending') {
            const handleSubmit = async () => {
              const organizationId = secureLocalStorage.getItem('organization_id')
              const url = `${baseUrl}/recruitment/offerStatus/${row.original.id}`

              try {
                const requestData = {
                  organization_id: organizationId,
                  offer_status: 'Rejected',
                }

                await execute(url, 'PATCH', requestData)
                Swal.fire({
                  title: intl.formatMessage({id: 'LABEL.CANDIDATE_REJECTED_SUCCESSFULLY'}),
                  text: intl.formatMessage({id: 'LABEL.CHANGES_SAVED'}),
                  icon: 'success',
                  customClass: {
                    confirmButton: 'btn btn-light-primary btn-sm',
                  },
                })
                fetchData()
              } catch (error) {
                console.error(error)
                Swal.fire({
                  icon: 'error',
                  title: intl.formatMessage({id: 'LABEL.OOPS'}),
                  text: intl.formatMessage({id: 'LABEL.SOMETHING_WENT_WRONG'}),
                  customClass: {
                    confirmButton: 'btn btn-light-primary btn-sm',
                  },
                })
              }
            }

            const handleInfoIconClick = () => {
              Swal.fire({
                title: intl.formatMessage({id: 'LABEL.REJECTED_CANDIDATE'}),
                text: intl.formatMessage({id: 'PRESS_THE_CONFIRM_BUTTON_TO_REJECT_CANDIDATE'}),
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: intl.formatMessage({id: 'BTN.CONFIRM'}),
                cancelButtonText: intl.formatMessage({id: 'LABEL.CANCEL'}),
                customClass: {
                  confirmButton: 'btn btn-light-primary btn-sm',
                  cancelButton: 'btn btn-sm',
                },
              }).then((result) => {
                if (result.isConfirmed) {
                  handleSubmit()
                }
              })
            }
            return (
              <VacancyContext.Consumer>
                {(value) => {
                  const {updateVACANCYID} = value
                  const offerStatus = row.original.offer_status

                  return (
                    <div>
                      <>
                        {offerStatus === 'Pending' ? (
                          <div className='d-flex flex-row justify-content-center align-items-center'>
                            <div className='d-flex justify-content-start flex-shrink-0 me-2'>
                              <button
                                className='btn btn-light-primary btn-sm'
                                id='side_activities_toggle'
                                onClick={() => {
                                  updateVACANCYID({
                                    id: row.original.id,
                                    type: 'edit_candidates',
                                    fetchData,
                                  })
                                }}
                              >
                                <KTIcon iconName='plus' className='fs-3' />
                                {intl.formatMessage({id: 'BTN.ISSUE_OFFER'})}
                              </button>
                            </div>
                            <div className='text-center'>
                              <button
                                className='btn btn-light-primary btn-sm'
                                onClick={handleInfoIconClick}
                              >
                                <KTIcon iconName='cross-circle' className='fs-3 text-danger' />

                                {intl.formatMessage({id: 'LABEL.REJECTED_CANDIDATE'})}
                              </button>
                            </div>
                          </div>
                        ) : (
                          <div className='text-center'>
                            <KTIcon iconName='cross-circle' className='fs-1 text-danger' />
                          </div>
                        )}
                      </>
                    </div>
                  )
                }}
              </VacancyContext.Consumer>
            )
          } else {
            // If offerStatus is not 'Pending', return a placeholder or null
            return null
          }
        },
      },
    ],
    []
  )

  return <DataTable columns={columns} data={filteredData ? filteredData : []} loading={isLoading} />
}

export default AllCandidates
