import React, {useMemo, useState, useCallback} from 'react'
import {useTable, usePagination} from 'react-table'
import {KTIcon} from '../../../../../_metronic/helpers'
import {Pagination} from 'react-bootstrap'
import ExpContext from '../../ExpensesContext'
import {useIntl} from 'react-intl'
import DateFormatter from '../../../DateFormatter'
import Swal from 'sweetalert2'
import {baseUrl} from '../../../core'
import usePostApi from '../../../custom_hooks/usePostApi'
import secureLocalStorage from 'react-secure-storage'

const DataTable = ({columns, data}) => {
  const intl = useIntl()
  const [inputVal, setInputVal] = useState('')
  const [currentStatus, setCurrentStatus] = useState('Pending') // initial state is 'Pending'

  const filteredData = useMemo(() => {
    return data
      ? data.filter((each) => {
          const reqType = each.loan_type === 'LOAN' ? 'loan' : 'salary advance'
          const AdvanceCodeMatches =
            each.advance_code && each.advance_code.toLowerCase().includes(inputVal.toLowerCase())
          const advanceStatusMatches =
            each.advance_status &&
            each.advance_status.toLowerCase().includes(inputVal.toLowerCase())
          const advanceDateMatches =
            each.advance_date &&
            DateFormatter(each.advance_date).toLowerCase().includes(inputVal.toLowerCase())
          const advanceAmountMatches =
            each.advance_amount &&
            each.advance_amount.toString().toLowerCase().includes(inputVal.toLowerCase())
          const advanceReasonMatches =
            each.advance_reason &&
            each.advance_reason.toLowerCase().includes(inputVal.toLowerCase())
          const reqTypeMatches = reqType && reqType.toLowerCase().includes(inputVal.toLowerCase())
          const advStatus =
            each.advance_status !== 'Approved' &&
            each.advance_status !== 'Rejected' &&
            each.advance_status !== 'Revoked'
              ? 'Pending'
              : each.advance_status === 'Revoked'
              ? 'Revoked'
              : each.advance_status
          const currentStatusMatches =
            advStatus && advStatus.toLowerCase() === currentStatus.toLowerCase()

          return (
            (AdvanceCodeMatches ||
              advanceStatusMatches ||
              advanceReasonMatches ||
              advanceAmountMatches ||
              reqTypeMatches ||
              advanceDateMatches) &&
            currentStatusMatches
          )
        })
      : []
  }, [inputVal, data, currentStatus])

  const tableInstance = useTable({columns, data: filteredData}, usePagination)

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    state: {pageIndex, pageSize},
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageCount,
    gotoPage,
    setPageSize,
  } = tableInstance

  const pageOptions = [10, 20, 30, 40, 50]

  return (
    <>
      {data.length === 0 || data === null ? (
        <div className='card h-250px d-flex justify-content-center align-items-center'>
          <h1>{intl.formatMessage({id: 'LABEL.NO_LOANS_OR_ADV_FOUND'})}</h1>
        </div>
      ) : (
        <>
          <div className='card p-1 mt-5 mt-lg-0' style={{minHeight: '100%'}}>
            <div className='card-header border-0 pt-5'>
              <div className='d-flex flex-wrap flex-stack'>
                <div className='fw-bolder'>
                  <ul className='nav'>
                    <li className='nav-item'>
                      <a
                        className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary active fw-bold px-4 me-1 ms-0'
                        data-bs-toggle='tab'
                        onClick={() => setCurrentStatus('Pending')}
                      >
                        {intl.formatMessage({id: 'LABEL.PENDING'})}
                      </a>
                    </li>
                    <li className='nav-item'>
                      <a
                        className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1'
                        data-bs-toggle='tab'
                        onClick={() => setCurrentStatus('Approved')}
                      >
                        {intl.formatMessage({id: 'LABEL.APPROVED'})}
                      </a>
                    </li>
                    <li className='nav-item'>
                      <a
                        className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1'
                        data-bs-toggle='tab'
                        onClick={() => setCurrentStatus('Rejected')}
                      >
                        {intl.formatMessage({id: 'LABEL.REJECTED'})}
                      </a>
                    </li>
                    <li className='nav-item'>
                      <a
                        className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1'
                        data-bs-toggle='tab'
                        onClick={() => setCurrentStatus('Revoked')}
                      >
                        {intl.formatMessage({id: 'LABEL.REVOKED'})}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='card-toolbar'>
                <div className='d-flex align-items-center position-relative ms-4'>
                  <KTIcon iconName='magnifier' className='fs-3 position-absolute ms-3' />
                  <input
                    type='search'
                    className='form-control form-control-white form-control-sm w-150px ps-9'
                    placeholder={intl.formatMessage({id: 'LABEL.SEARCH'})}
                    value={inputVal}
                    onChange={(e) => setInputVal(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className=''>
              <div className='card-body  table-responsive '>
                <table
                  className='table table-row-dashed align-middle table-row-gray-300'
                  {...getTableProps()}
                >
                  <thead className='default-cursor'>
                    <tr className='fw-bold text-muted'>
                      <th className='min-w-120px'>{intl.formatMessage({id: 'LABEL.ID'})}</th>
                      <th className='min-w-120px'>
                        {intl.formatMessage({id: 'LABEL.REASON&TYPE'})}
                      </th>
                      <th className='min-w-120px'>{intl.formatMessage({id: 'TABLE.AMOUNT'})}</th>
                      <th className='min-w-120px'>
                        {intl.formatMessage({id: 'LABEL.NEED_BY_DATE'})}
                      </th>
                      <th className='min-w-120px text-center'>
                        {intl.formatMessage({id: 'MENU.ACTIONS'})}
                      </th>
                    </tr>
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {page.length > 0 ? (
                      page.map((row) => {
                        prepareRow(row)
                        return (
                          <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => (
                              <td className='text-center' {...cell.getCellProps()}>
                                {cell.render('Cell')}
                              </td>
                            ))}
                          </tr>
                        )
                      })
                    ) : (
                      <tr>
                        <td className='card'>
                          <p className='text-dark fw-bold text-hover-primary default-cursor d-block fs-6 m-0 my-5'>
                            {intl.formatMessage({id: 'NO_RESULTS_FOUND_MSG'})}
                          </p>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            {filteredData.length > 10 && (
              <Pagination>
                <Pagination.First onClick={() => gotoPage(0)} disabled={!canPreviousPage} />
                <Pagination.Prev onClick={previousPage} disabled={!canPreviousPage} />
                {[...Array(pageCount)].map((_, i) => (
                  <Pagination.Item key={i} active={i === pageIndex} onClick={() => gotoPage(i)}>
                    {i + 1}
                  </Pagination.Item>
                ))}
                <Pagination.Next onClick={nextPage} disabled={!canNextPage} />
                <Pagination.Last onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} />
                <div className='d-flex align-items-center'>
                  <span className='me-2'>{intl.formatMessage({id: 'LABEL.SHOW'})}</span>
                  <select
                    className='form-select form-select-solid fw-bold w-75px me-2'
                    value={pageSize}
                    onChange={(e) => setPageSize(Number(e.target.value))}
                  >
                    {pageOptions.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
              </Pagination>
            )}
          </div>
        </>
      )}
    </>
  )
}

const EmployeeAdvance = React.memo(({empAdvancesData: modifiedData, fetchData}) => {
  const {execute} = usePostApi()
  const intl = useIntl()
  const userInfo = secureLocalStorage.getItem('user_info')
  const {organization_id} = userInfo || {}

  const updateAdvInfoCallback = useCallback(
    (updateExpInfo, rowData, handleInfoIconClick) => {
      return (
        <div className='text-center'>
          <button
            className='btn btn-icon btn-light-primary btn-sm ms-3'
            id='teamMemberAdvanceInfoToggle'
            onClick={() => {
              updateExpInfo({...rowData})
            }}
            data-toggle='tooltip'
            title={intl.formatMessage({id: 'TITLE.VIEW_DETAILS'})}
          >
            <KTIcon iconName='eye' className='fs-3' />
          </button>
          {rowData.advance_status === 'Revoked' ||
          rowData.advance_status === 'Approved' ||
          rowData.advance_status === 'Rejected' ? null : (
            <button
              className='btn btn-icon btn-light-primary btn-sm ms-3'
              data-toggle='tooltip'
              title='Revoke Request'
              onClick={handleInfoIconClick}
            >
              <KTIcon iconName='cross' className='fs-3' />
            </button>
          )}
        </div>
      )
    },
    [intl]
  )
  const columns = useMemo(
    () => [
      {
        Header: 'Advance Code',
        accessor: 'Advance Code',
        Cell: ({row}) => {
          return (
            <p className='text-dark fw-bold text-hover-primary fs-6 m-0 default-cursor text-start'>
              {row.original.advance_code}
            </p>
          )
        },

        disableSortBy: true,
      },
      {
        Header: 'Name',
        accessor: 'Name',
        Cell: ({row}) => {
          return (
            <div className='d-flex align-items-center'>
              <div className='d-flex justify-content-start flex-column'>
                <div className='d-flex align-items-center'>
                  <div className='d-flex justify-content-start flex-column'>
                    <p
                      className='text-dark fw-bold text-hover-primary fs-6 m-0 default-cursor text-start'
                      style={{maxWidth: '220px'}}
                    >
                      {row.original.advance_reason}
                    </p>
                    <span className='text-muted fw-semibold text-muted fs-7 text-start default-cursor'>
                      {row.original.loan_type === 'LOAN'
                        ? `${intl.formatMessage({id: 'LABEL.LOAN'})}`
                        : `${intl.formatMessage({id: 'LABEL.SAL_ADV'})}`}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )
        },

        disableSortBy: true,
      },
      {
        Header: 'Amount',
        accessor: 'amount',
        Cell: ({row}) => {
          return (
            <p className='text-dark fw-bold text-hover-primary default-cursor fs-6 text-start'>
              {row.original.currency_symbol} {row.original.advance_amount}
            </p>
          )
        },

        disableSortBy: true,
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({row}) => {
          return (
            <p className='text-dark fw-bold text-hover-primary default-cursor fs-6 text-start'>
              {row.original.advance_date ? DateFormatter(row.original.advance_date) : '-'}
            </p>
          )
        },

        disableSortBy: true,
      },
      {
        Header: 'Approved',
        accessor: 'Approved',
        Cell: ({row}) => {
          const handleInfoIconClick = () => {
            Swal.fire({
              title: 'Are you sure you want to cancel this request?',
              text: 'This action cannot be reversed.',
              icon: 'info',
              showCancelButton: true,
              confirmButtonText: intl.formatMessage({id: 'BTN.CONFIRM'}),
              cancelButtonText: intl.formatMessage({id: 'LABEL.CANCEL'}),
              customClass: {
                confirmButton: 'btn btn-light-primary btn-sm',
                cancelButton: 'btn btn-sm btn-secondary',
              },
            }).then((result) => {
              if (result.isConfirmed) {
                handleSubmit()
              }
            })
          }

          const handleSubmit = async () => {
            const url = `${baseUrl}/employee/advances/${row.original.id}`
            try {
              const requestData = {
                organization_id: organization_id,
                advance_status: 'Revoked',
              }
              await execute(url, 'PATCH', requestData)
              fetchData()
              Swal.fire({
                title: 'Revoked the request successfully.',
                text: intl.formatMessage({id: 'LABEL.CHANGES_SAVED'}),
                icon: 'success',
                showConfirmButton: true,
                confirmButtonText: 'OK',
                customClass: {
                  confirmButton: 'btn btn-light-primary btn-sm',
                },
              })
            } catch (error) {
              console.error(error)
              Swal.fire({
                icon: 'error',
                title: intl.formatMessage({id: 'LABEL.OOPS'}),
                text: intl.formatMessage({id: 'LABEL.SOMETHING_WRONG'}),
                customClass: {
                  confirmButton: 'btn btn-light-primary btn-sm',
                },
              })
            }
          }

          return (
            <ExpContext.Consumer>
              {(value) => {
                const {updateExpInfo} = value
                return updateAdvInfoCallback(updateExpInfo, row.original, handleInfoIconClick)
              }}
            </ExpContext.Consumer>
          )
        },
        disableSortBy: true,
      },
    ],

    [updateAdvInfoCallback]
  )

  return (
    <>
      <DataTable columns={columns} data={modifiedData} />
    </>
  )
})

export default EmployeeAdvance
