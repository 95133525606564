/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import DepartmentBlock from './DepartmentBlock'
import {useIntl} from 'react-intl'

function Organization_department() {
  const intl = useIntl()
  return (
    <>
      <div className='d-flex flex-wrap flex-stack mb-6'>
        <h3 className='fw-bolder my-2'>
          {intl.formatMessage({id: 'LABEL.ORGANIZATION_DEPARTMENTS'})}
        </h3>
      </div>
      <div className='card mb-5 mb-xl-10'>
        <DepartmentBlock />
      </div>
    </>
  )
}

export default Organization_department
