/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {useIntl} from 'react-intl'
import {KTIcon} from '../../../../_metronic/helpers'

type Props = {
  color: string
  expensesData: any
}

const LatestExpenseCard: React.FC<Props> = ({expensesData}) => {
  const intl = useIntl()
  const approvedReq = expensesData?.filter((each: any) => each.expense_status === 'Approved')
  const rejectedReq = expensesData?.filter((each: any) => each.expense_status === 'Rejected')
  const pendingReq = expensesData?.filter(
    (each: any) =>
      each.expense_status !== 'Approved' &&
      each.expense_status !== 'Rejected' &&
      each.expense_status !== 'Revoked'
  )
  const revokedReq = expensesData?.filter((each: any) => each.expense_status === 'Revoked')

  return (
    <div className={`card me-4 default-cursor`}>
      <div className='card-body p-0'>
        <div className={`px-9 pt-7 card-rounded h-275px w-100 bg-custom-color`}>
          <div className='d-flex flex-stack'>
            <h3 className='m-0 text-white fw-bold fs-3'>
              {intl.formatMessage({id: 'LABEL.EXPENSE_SUMMARY'})}
            </h3>
          </div>
          <div className='d-flex text-center flex-column text-white pt-8'>
            <span className='fw-semibold fs-7'>{intl.formatMessage({id: 'LABEL.TOTAL_REQS'})}</span>
            <span className='fw-bold fs-2x pt-1'>{expensesData?.length}</span>
          </div>
        </div>
        <div
          className='shadow-xs card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1 bg-body min-h-250px'
          style={{marginTop: '-100px'}}
        >
          <div className='d-flex align-items-center mb-6'>
            <div className='symbol symbol-45px w-40px me-5'>
              <span className='symbol-label bg-lighten'>
                <KTIcon iconName='check' className='fs-1 text-success' />
              </span>
            </div>
            <div className='d-flex justify-content-between align-items-center flex-wrap w-100'>
              <div className='d-flex align-items-center'>
                <div className='fw-bold fs-5 text-gray-800 pe-1 text-hover-primary'>
                  {intl.formatMessage({id: 'EMP.APPROVED_REQUESTS'})}
                </div>
              </div>
              <div className='d-flex align-items-center'>
                <div className='fw-bold fs-5 text-gray-800 pe-1 text-hover-primary'>
                  {approvedReq?.length || 0}
                </div>
              </div>
            </div>
          </div>

          <div className='d-flex align-items-center mb-6'>
            <div className='symbol symbol-45px w-40px me-5'>
              <span className='symbol-label bg-lighten'>
                <KTIcon iconName='loading' className='fs-1 text-info' />
              </span>
            </div>

            <div className='d-flex justify-content-between align-items-center flex-wrap w-100'>
              <div className='d-flex align-items-center'>
                <div className='fw-bold fs-5 text-gray-800 pe-1 text-hover-primary'>
                  {intl.formatMessage({id: 'LABEL.PENDING_REQS'})}
                </div>
              </div>
              <div className='d-flex align-items-center'>
                <div className='fw-bold fs-5 text-gray-800 pe-1 text-hover-primary'>
                  {pendingReq?.length || 0}
                </div>
              </div>
            </div>
          </div>

          <div className='d-flex align-items-center mb-6'>
            <div className='symbol symbol-45px w-40px me-5'>
              <span className='symbol-label bg-lighten'>
                <KTIcon iconName='cross' className='fs-1 text-danger' />
              </span>
            </div>

            <div className='d-flex justify-content-between align-items-center flex-wrap w-100'>
              <div className='d-flex align-items-center'>
                <div className='fw-bold fs-5 text-gray-800 pe-1 text-hover-primary'>
                  {intl.formatMessage({id: 'LABEL.REJECTED_REQS'})}
                </div>
              </div>
              <div className='d-flex align-items-center'>
                <div className='fw-bold fs-5 text-gray-800 pe-1 text-hover-primary'>
                  {rejectedReq?.length || 0}
                </div>
              </div>
            </div>
          </div>

          <div className='d-flex align-items-center mb-6'>
            <div className='symbol symbol-45px w-40px me-5'>
              <span className='symbol-label bg-lighten'>
                <KTIcon iconName='cross' className='fs-1 text-danger' />
              </span>
            </div>

            <div className='d-flex justify-content-between align-items-center flex-wrap w-100'>
              <div className='d-flex align-items-center'>
                <div className='fw-bold fs-5 text-gray-800 pe-1 text-hover-primary'>
                  {/* {intl.formatMessage({id: 'LABEL.REJECTED_REQS'})} */}
                  Revoked Requests
                </div>
              </div>
              <div className='d-flex align-items-center'>
                <div className='fw-bold fs-5 text-gray-800 pe-1 text-hover-primary'>
                  {revokedReq?.length || 0}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {LatestExpenseCard}
