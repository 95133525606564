import React, {useMemo, useState, useCallback} from 'react'
import {useTable, usePagination} from 'react-table'
import {KTIcon} from '../../../../_metronic/helpers'
import {useIntl} from 'react-intl'
import {Pagination} from 'react-bootstrap'
import DateFormatter from '../../DateFormatter'
import Swal from 'sweetalert2'
import ExpContext from '../ExpensesContext'
import {baseUrl} from '../../core'
import secureLocalStorage from 'react-secure-storage'
import usePostApi from '../../custom_hooks/usePostApi'

const DataTable = ({columns, data}) => {
  const intl = useIntl()

  const [inputVal, setInputVal] = useState('')
  const [currentStatus, setCurrentStatus] = useState('Pending')

  const filteredData = useMemo(() => {
    return data
      ? data.filter((each) => {
          const billedToProject = each.is_billed_to_project === 0 ? 'Company' : 'Project'
          const expenseNameMatches =
            each.expense_name && each.expense_name.toLowerCase().includes(inputVal.toLowerCase())
          const expenseCodeMatches =
            each.expense_code && each.expense_code.toLowerCase().includes(inputVal.toLowerCase())
          const expenseDateMatches =
            each.expense_date &&
            DateFormatter(each.expense_date).toLowerCase().includes(inputVal.toLowerCase())
          const expenseAmountMatches =
            each.expense_amount &&
            each.expense_amount.toString().toLowerCase().includes(inputVal.toLowerCase())
          const expenseStatusMatches =
            each.expense_status &&
            each.expense_status.toLowerCase().includes(inputVal.toLowerCase())
          const empExpenseIdMatches =
            each.emp_expense_id &&
            each.emp_expense_id.toString().toLowerCase().includes(inputVal.toLowerCase())
          const isBilledToMatches =
            billedToProject && billedToProject.toLowerCase().includes(inputVal.toLowerCase())

          const expStatus =
            each.expense_status !== 'Approved' &&
            each.expense_status !== 'Rejected' &&
            each.expense_status !== 'Revoked'
              ? 'Pending'
              : each.expense_status === 'Revoked'
              ? 'Revoked'
              : each.expense_status
          const currentStatusMatches =
            expStatus && expStatus.toLowerCase() === currentStatus.toLowerCase()

          return (
            (expenseNameMatches ||
              expenseCodeMatches ||
              expenseDateMatches ||
              isBilledToMatches ||
              expenseAmountMatches ||
              expenseStatusMatches ||
              empExpenseIdMatches) &&
            currentStatusMatches
          )
        })
      : []
  }, [inputVal, data, currentStatus])

  const tableInstance = useTable({columns, data: filteredData}, usePagination)
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    state: {pageIndex, pageSize},
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageCount,
    gotoPage,
    setPageSize,
  } = tableInstance

  const pageOptions = [10, 20, 30, 40, 50]

  return (
    <>
      <div className='card flex-grow-1 overflow-x-scroll p-e-5'>
        <div className='card-header border-0 pt-5'>
          <div className='d-flex flex-wrap flex-stack'>
            <div className='fw-bolder'>
              <ul className='nav'>
                <li className='nav-item'>
                  <a
                    className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary active fw-bold px-4 me-1 ms-0'
                    data-bs-toggle='tab'
                    onClick={() => setCurrentStatus('Pending')}
                  >
                    {intl.formatMessage({id: 'LABEL.PENDING'})}
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1'
                    data-bs-toggle='tab'
                    onClick={() => setCurrentStatus('Approved')}
                  >
                    {intl.formatMessage({id: 'LABEL.APPROVED'})}
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1'
                    data-bs-toggle='tab'
                    onClick={() => setCurrentStatus('Rejected')}
                  >
                    {intl.formatMessage({id: 'LABEL.REJECTED'})}
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1'
                    data-bs-toggle='tab'
                    onClick={() => setCurrentStatus('Revoked')}
                  >
                    {intl.formatMessage({id: 'LABEL.REVOKED'})}
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className='card-toolbar'>
            <div className='d-flex align-items-center position-relative ms-4'>
              <KTIcon iconName='magnifier' className='fs-3 position-absolute ms-3' />
              <input
                type='search'
                className='form-control form-control-white form-control-sm w-150px ps-9'
                placeholder={intl.formatMessage({id: 'LABEL.SEARCH'})}
                value={inputVal}
                onChange={(e) => setInputVal(e.target.value)}
                autoComplete='off'
              />
            </div>
          </div>
        </div>
        <div className='mb-5 mb-xl-8 p-10 pb-0'>
          <table
            className='table table-row-dashed table-row-gray-300 align-middle'
            {...getTableProps()}
          >
            <thead className='default-cursor'>
              <tr className='fw-bold text-muted'>
                <th className='min-w-150px text-start'>{intl.formatMessage({id: 'LABEL.ID'})}</th>
                <th className='min-w-140px'>{intl.formatMessage({id: 'LABEL.CATEGORY'})}</th>
                <th className='min-w-120px text-start'>
                  {intl.formatMessage({id: 'TABLE.AMOUNT'})}
                </th>
                <th className='min-w-100px text-start'>
                  {intl.formatMessage({id: 'LABEL.EXPENSE_DATE'})}
                </th>
                <th className='min-w-100px text-center'>
                  {intl.formatMessage({id: 'MENU.ACTIONS'})}
                </th>
              </tr>
            </thead>
            <tbody {...getTableBodyProps()}>
              {page && page.length > 0 ? (
                page.map((row) => {
                  prepareRow(row)
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <td className='text-center' {...cell.getCellProps()}>
                          {cell.render('Cell')}
                        </td>
                      ))}
                    </tr>
                  )
                })
              ) : (
                <tr>
                  <td className='card'>
                    <p className='text-dark fw-bold text-hover-primary default-cursor d-block fs-6 m-0 my-5'>
                      {intl.formatMessage({id: 'NO_RESULTS_FOUND_MSG'})}
                    </p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {filteredData.length > 10 && (
            <Pagination>
              <Pagination.First onClick={() => gotoPage(0)} disabled={!canPreviousPage} />
              <Pagination.Prev onClick={previousPage} disabled={!canPreviousPage} />

              {[...Array(pageCount)].map((_, i) => (
                <Pagination.Item key={i} active={i === pageIndex} onClick={() => gotoPage(i)}>
                  {i + 1}
                </Pagination.Item>
              ))}

              <Pagination.Next onClick={nextPage} disabled={!canNextPage} />
              <Pagination.Last onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} />

              <div className='d-flex align-items-center mx-5'>
                <span className='me-2'>{intl.formatMessage({id: 'LABEL.SHOW'})}</span>
                <select
                  className='form-select form-select-solid fw-bold w-75px me-2'
                  value={pageSize}
                  onChange={(e) => setPageSize(Number(e.target.value))}
                >
                  {pageOptions.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            </Pagination>
          )}
        </div>
      </div>
    </>
  )
}

const ExpensesTable = React.memo(({data: modifiedData, fetchData}) => {
  const {execute} = usePostApi()
  const intl = useIntl()
  const userInfo = secureLocalStorage.getItem('user_info')
  const {organization_id} = userInfo || {}

  const updateExpInfoCallback = useCallback(
    (updateExpInfo, rowData, handleInfoIconClick) => {
      return (
        <div className='text-center'>
          <button
            className='btn btn-icon btn-light-primary btn-sm ms-3'
            onClick={() => {
              if (rowData.document_url) {
                window.open(rowData.document_url, '_blank')
              } else {
                alert('Not Found')
              }
            }}
            data-toggle='tooltip'
            title='View document'
          >
            <KTIcon iconName='document' className='fs-3' />
          </button>

          <button
            className='btn btn-icon btn-light-primary btn-sm ms-3'
            id='teamMemberExpInfoToggle'
            onClick={() => {
              updateExpInfo({...rowData})
            }}
            data-toggle='tooltip'
            title={intl.formatMessage({id: 'TITLE.VIEW_DETAILS'})}
          >
            <KTIcon iconName='eye' className='fs-3' />
          </button>
          {rowData.expense_status === 'Revoked' ||
          rowData.expense_status === 'Approved' ||
          rowData.expense_status === 'Rejected' ? null : (
            <button
              className='btn btn-icon btn-light-primary btn-sm ms-3'
              onClick={() => {
                handleInfoIconClick()
              }}
              disabled={
                rowData.expense_status === 'Revoked' ||
                rowData.expense_status === 'Approved' ||
                rowData.expense_status === 'Rejected'
              }
              data-toggle='tooltip'
              title='Revoke Request'
            >
              <KTIcon iconName='cross' className='fs-3' />
            </button>
          )}
        </div>
      )
    },
    [intl]
  )
  const expensesData = modifiedData
  const columns = useMemo(
    () => [
      {
        Header: 'ExpenseId',
        accessor: 'expenseId',
        Cell: ({row}) => {
          return (
            <p className='text-dark fw-bold text-hover-primary fs-6 m-0 default-cursor text-start'>
              {row.original.expense_code}
            </p>
          )
        },
        disableSortBy: true,
      },
      {
        Header: 'ExpenseName',
        accessor: 'expenseName',
        Cell: ({row}) => {
          return (
            <div className='d-flex align-items-center'>
              <div className='d-flex justify-content-start flex-column'>
                <div className='d-flex align-items-center'>
                  <div className='d-flex justify-content-start flex-column'>
                    <p className='text-dark fw-bold text-hover-primary fs-6 m-0 default-cursor text-start'>
                      {row.original.expense_name}
                    </p>
                    <span className='text-muted fw-semibold text-muted fs-7 text-start default-cursor'>
                      {row.original.is_billed_to_project == 0
                        ? intl.formatMessage({id: 'LABEL.BILLED_TO_COMPANY'})
                        : intl.formatMessage({id: 'LABEL.BILLED_TO_PROJECT'})}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )
        },
      },
      {
        Header: 'Amount',
        accessor: 'amount',
        Cell: ({row}) => {
          return (
            <p className='text-dark fw-bold text-hover-primary default-cursor d-block fs-6 text-start'>
              {row.original.currency_symbol} {row.original.expense_amount}
            </p>
          )
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({row}) => {
          return (
            <p className='text-dark fw-bold text-hover-primary default-cursor d-block fs-6 text-start'>
              {row.original.expense_date ? DateFormatter(row.original.expense_date) : '-'}
            </p>
          )
        },
      },
      {
        Header: 'View',
        accessor: 'btn',
        Cell: ({row}) => {
          const handleInfoIconClick = () => {
            Swal.fire({
              title: 'Are you sure you want to cancel this request?',
              text: 'This action cannot be reversed.',
              icon: 'info',
              showCancelButton: true,
              confirmButtonText: intl.formatMessage({id: 'BTN.CONFIRM'}),
              cancelButtonText: intl.formatMessage({id: 'LABEL.CANCEL'}),
              customClass: {
                confirmButton: 'btn btn-light-primary btn-sm',
                cancelButton: 'btn btn-sm btn-secondary',
              },
            }).then((result) => {
              if (result.isConfirmed) {
                handleSubmit()
              }
            })
          }

          const handleSubmit = async () => {
            const url = `${baseUrl}/employee/expenses/status/${row.original.emp_expense_id}`
            try {
              const requestData = {
                organization_id: organization_id,
                expense_status: 'Revoked',
              }
              await execute(url, 'PATCH', requestData)
              fetchData()
              Swal.fire({
                title: 'Revoked the request successfully.',
                text: intl.formatMessage({id: 'LABEL.CHANGES_SAVED'}),
                icon: 'success',
                showConfirmButton: true,
                confirmButtonText: 'OK',
                customClass: {
                  confirmButton: 'btn btn-light-primary btn-sm',
                },
              })
            } catch (error) {
              console.error(error)
              Swal.fire({
                icon: 'error',
                title: intl.formatMessage({id: 'LABEL.OOPS'}),
                text: intl.formatMessage({id: 'LABEL.SOMETHING_WRONG'}),
                customClass: {
                  confirmButton: 'btn btn-light-primary btn-sm',
                },
              })
            }
          }

          return (
            <ExpContext.Consumer>
              {(value) => {
                const {updateExpInfo} = value
                return updateExpInfoCallback(updateExpInfo, row.original, handleInfoIconClick)
              }}
            </ExpContext.Consumer>
          )
        },
      },
    ],
    [updateExpInfoCallback]
  )

  return <DataTable columns={columns} data={expensesData ? expensesData : []} />
})

export default ExpensesTable
