import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import useApiCall from '../custom_hooks/useApiCall'
export function GetBusinessUnitAll() {
  const {
    data: businessUnitData,
    isLoading: isBusinessLoading,
    fetchData,
  }: any | null = useApiCall(`/businessUnits/all`)
  return {businessUnitData, isBusinessLoading, fetchData}
}

export function GetDepartmentAll() {
  const {data: departmenteData, isLoading, fetchData}: any | null = useApiCall(`/departments/all`)
  return {departmenteData, isLoading, fetchData}
}

export function GetThemePreferences() {
  interface ColorDataItem {
    name: string
    code: string
    id: number
  }
  const intl = useIntl()
  const [colorData, setColorData] = useState<ColorDataItem[]>([])
  const {data} = useApiCall(`/pref/theme/1`)
  const themeColors: any | null = data && data[0]
  useEffect(() => {
    setColorData([
      {
        name: 'Primary (Light Mode)',
        code: themeColors?.brand_primary_color,
        id: 1,
      },
      {
        name: intl.formatMessage({id: 'LABEL.ORGANIZATION_Secondary Color'}),
        code: themeColors?.brand_secondary_color,
        id: 2,
      },
      {
        name: 'Primary (Dark Mode)',
        code: themeColors?.brand_accent_color,
        id: 3,
      },
      {
        name: intl.formatMessage({id: 'LABEL.ORGANIZATION_BACKGROUND_COLOR'}),
        code: themeColors?.brand_background_color,
        id: 4,
      },
      {
        name: intl.formatMessage({id: 'LABEL.ORGANIZATION_TEXT_COLOR'}),
        code: themeColors?.brand_text_color,
        id: 5,
      },
    ])
  }, [themeColors])
  return {colorData}
}

export function GetFontPreferences() {
  const {data: fontPreferences}: any | null = useApiCall(`/pref/font`)
  return {fontPreferences}
}

export function GetLogoPreferences() {
  const {data: logosData}: any | null = useApiCall(`/pref/logos/1`)
  let brandlogosData: any | null = logosData && logosData[0]
  return {brandlogosData}
}

export function GetBrandPreference() {
  const [displayName, setDisplayName] = useState('Kloudworx')
  const [dateFormat, setDateFormats] = useState('dd/MM/yyyy')
  const [welcomeMessage, setWelcomeMessage] = useState('Welcome to Kloudworx....')
  const {data}: any | null = useApiCall(`/pref/brand/1`)
  let brandPreferenceData = data && data[0]
  useEffect(() => {
    if (brandPreferenceData) {
      setDisplayName(brandPreferenceData.display_name)
      setDateFormats(brandPreferenceData.date_format)
      setWelcomeMessage(brandPreferenceData.brand_welcome_message)
    }
  }, [brandPreferenceData])
  return {dateFormat, displayName, welcomeMessage}
}

export function GetWorkPreferences() {
  const {data, isLoading} = useApiCall(`/pref/work`)
  let workPrefereceData: any | null = data && data[0]
  return {workPrefereceData, isLoading}
}
export function GetOrganizationInfo() {
  interface OrganizationItem {
    organization_name: string
    tax_id: string
    tenant_id: number
    billing_organization_id: number
    organization_address_id: number
    registration_number: string
    date_of_incorporation: string
    organization_code: string
    address_line1: string
    state_name: string
    city_name: string
    city_id: number
    state_id: number
    country_id: number
    country_name: string
    address_line2: string
    postal_code: string
    status: number
  }
  const [organizationInfo, setOrganizationInfo] = useState<OrganizationItem>()
  const {data, isLoading} = useApiCall(`/organization/1`)
  const information: any | null = data && data[0]
  useEffect(() => {
    setOrganizationInfo(information)
  }, [information])
  return {organizationInfo, isLoading}
}
