function RejectedExit({revokedDetails}) {
  const {revoked_timestamp = ''} = revokedDetails

  return (
    <div className='timeline-item'>
      <div className='timeline-line w-20px'></div>
      <div className='timeline-icon symbol symbol-circle symbol-20px me-4'>
        <div className={`symbol-label bg-danger`}></div>
      </div>
      <div className='timeline-content mb-12 mt-n1'>
        <div className='d-flex justify-content-between mt-1'>
          <p className={`mb-0 fw-bold text-danger`}>Rejected</p>
          <div className=''>
            <p className='designation-btn bg-secondary m-auto'>Employee</p>
          </div>
        </div>
        <p className='m-0 text-muted'>{revoked_timestamp && revoked_timestamp.slice(0, 19)}</p>
        <p className='m-0 text-muted'>Employee Rejected</p>
      </div>
    </div>
  )
}

export default RejectedExit
