/* eslint-disable jsx-a11y/anchor-is-valid */
import VacanciesTable from './VacanciesTable'
import {useIntl} from 'react-intl'
function SelfService() {
  const intl = useIntl()
  return (
    <>
      <div className='d-flex'>
        <div className='d-flex flex-wrap flex-stack mb-6'>
          <h3 className='fw-bolder my-2'>
            {' '}
            {intl.formatMessage({id: 'MENU.RECRUITMENT.VACANCIES'})}
          </h3>
        </div>
      </div>

      <div className='card mb-5 mb-xl-10'>
        <VacanciesTable />
      </div>
    </>
  )
}

export default SelfService
