import {useState, useEffect} from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import enLocale from '@fullcalendar/core/locales/en-gb'
import frLocale from '@fullcalendar/core/locales/fr'
import esLocale from '@fullcalendar/core/locales/es-us'
import arLocale from '@fullcalendar/core/locales/ar-sa'
import useApiCall from '../../custom_hooks/useApiCall'
import * as MetronicI18n from '../../../../_metronic/i18n/Metronici18n'
import {useIntl} from 'react-intl'

export default function HolidaysPageCalendar() {
  const [events, setEvents] = useState([])
  const [calendarLocale, setCalendarLocale] = useState(getInitLanguage())
  const intl = useIntl()
  const {data: holidayData, isLoading, fetchData} = useApiCall(`/leave/holidays`)

  useEffect(() => {
    fetchData()
  }, [])
  function getGlobalLanguage() {
    const selectedLang = MetronicI18n.getLanguage()
    return selectedLang
  }
  function getInitLanguage() {
    const selectedLanguage = getGlobalLanguage()
    let calendarLocale = enLocale
    switch (selectedLanguage) {
      case 'en':
        calendarLocale = enLocale
        break
      case 'fr':
        calendarLocale = frLocale
        break
      case 'ar':
        calendarLocale = arLocale
        break
      case 'es':
        calendarLocale = esLocale
        break
      default:
        calendarLocale = enLocale
        break
    }

    return calendarLocale
  }
  const employeeHoliday = holidayData?.map((leave) => ({
    title: leave.holiday_name,
    start: leave.holiday_date,
    end: leave.holiday_date,
  }))
  return (
    <>
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay',
        }}
        initialView='dayGridMonth'
        weekends={true}
        events={employeeHoliday}
        locale={calendarLocale}
        height={600}
      />
    </>
  )
}
