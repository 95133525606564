import {useState} from 'react'
import {useLocation} from 'react-router-dom'
import ExpenseAdvances from './ExpenseAdvancesNav'
import LoansAdvances from './loans_advances/employee_loan/LoansAdvances'
import {PageTitle} from '../../../_metronic/layout/core'
import MyExpenses from './my_expenses/MyExpenses'
import ExpContext from './ExpensesContext'
import {useIntl} from 'react-intl'

const ExpenseAdvancesMain = () => {
  const intl = useIntl()

  const [expenseInfo, setExpenseInfo] = useState(null)

  const UpdateExpInfo = (data) => {
    setExpenseInfo(data)
  }

  const {pathname} = useLocation()

  const myExpensesTitle = [
    {
      title: `${intl.formatMessage({id: 'LABEL.MYEXPENSES'})}`,
      path: '/expenses-advances/my-expense-claims',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  const checkNavigation = () => {
    switch (pathname) {
      case '/expenses-advances/loans-advances':
        return (
          <PageTitle breadcrumbs={myExpensesTitle}>
            {intl.formatMessage({id: 'MY_LOANS_AND_ADVANCES'})}
          </PageTitle>
        )
      case '/expenses-advances/my-expense-claims':
        return (
          <PageTitle breadcrumbs={myExpensesTitle}>
            {intl.formatMessage({id: 'LABEL.MYEXPENSES'})}
          </PageTitle>
        )
      default:
        return (
          <PageTitle breadcrumbs={myExpensesTitle}>
            {intl.formatMessage({id: 'LABEL.MYEXPENSES'})}
          </PageTitle>
        )
    }
  }

  const switchComponent = () => {
    switch (pathname) {
      case '/expenses-advances/loans-advances':
        return <LoansAdvances />

      case '/expenses-advances/my-expense-claims':
        return <MyExpenses />
      default:
        return <MyExpenses />
    }
  }

  return (
    <>
      <ExpContext.Provider
        value={{
          ExpInfo: expenseInfo,
          updateExpInfo: UpdateExpInfo,
        }}
      >
        {checkNavigation()}
        <ExpenseAdvances />
        {switchComponent()}
      </ExpContext.Provider>
    </>
  )
}

export default ExpenseAdvancesMain
