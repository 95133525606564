import {useIntl} from 'react-intl'
import HolidayTable2 from './HolidayTable'
import HolidaysPageCalendar from './HolidaysPageCalendar'
import usePageTitle from '../../custom_hooks/usePageTitle'
export default function HolidaysPageMain() {
  const intl = useIntl()
  usePageTitle(intl.formatMessage({id: 'MENU.HR_CONFIGURATION.HOLIDAYS'}))
  return (
    <>
      <div className='d-flex flex-wrap flex-stack mb-6'>
        <div className='fw-bolder my-2'>
          <h3 className='fw-bolder my-2 default-cursor'>
            {intl.formatMessage({id: 'MENU.LEAVE_MANAGEMENT.HOLIDAYS'})}
          </h3>
        </div>
      </div>

      <div className='row g-5 g-xl-8 d-flex justify-content-between'>
        <div className='col-lg-6 col-xl-6'>
          <HolidayTable2 />
        </div>
        <div className='col-lg-6 col-xl-6'>
          <div className='card  p-4'>
            <HolidaysPageCalendar />
          </div>
        </div>
      </div>
    </>
  )
}
