import {useState, useEffect} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {defaultProfileImage} from '../../core'
import {useIntl} from 'react-intl'
import TeamLeavesCalendar from '../team_leaves/TeamLeavesCalendar'
import useApiCall from '../../custom_hooks/useApiCall'
import LeaveHR from './LeaveHR'
import LeaveManager from './LeaveManager'
import React from 'react'
import secureLocalStorage from 'react-secure-storage'

const LeaveApprovalDrawer = (leaveinfo) => {
  let emp_id
  let user_id
  const userInfo = secureLocalStorage.getItem('user_info')
  if (userInfo) {
    ;({emp_id, user_id} = userInfo)
  }
  const intl = useIntl()
  const [activePage, setActivePage] = useState('page1')

  const [leaveCalendarKey, setLeaveCalendarKey] = useState(0)
  const handleLinkClick = (pageId) => {
    setLeaveCalendarKey((prevKey) => prevKey + 1)
    setActivePage(pageId)
  }
  const {props} = leaveinfo

  const {data, fetchData, isLoading} = useApiCall(`/leave/employees/${props?.leave_id}`)

  const {
    data: leavedata,
    fetchData: fetchLeaveData,
    isleaveLoading,
  } = useApiCall(`/leave/emp/${emp_id}`)
  let leaveData = data && data[0]

  useEffect(() => {
    fetchData()
  }, [emp_id])

  const reviewerDetails = {
    reviewer_status: leaveData?.reviewer_status || '',
    reviewer_comments: leaveData?.reviewer_comments || '',
    leavefetchData: props?.fetchData,
    fetchData: fetchData,
    leave_id: leaveData?.leave_id,
  }

  const hrDetails = {
    reviewer_status: leaveData?.reviewer_status || '',
    hr_review_comments: leaveData?.hr_review_comments || '',
    hr_reviewed_status: leaveData?.hr_reviewed_status || '',
    leave_id: leaveData?.leave_id,
    fetchData: fetchData,
    leavefetchData: props?.fetchData,
  }

  function getBadgeColorClass(status) {
    switch (status) {
      case 'HR Approved':
        return 'bg-success'
      case 'Rejected':
        return 'bg-danger'
      case 'Pending':
        return 'bg-primary'
      default:
        return ''
    }
  }

  return (
    <div className='card shadow-none rounded-0'>
      <div>
        <div className='card-header' id='side_activities_header'>
          <h3 className='card-title fw-bolder text-dark'>
            {intl.formatMessage({id: 'LABEL.TEAM_LEAVES'})}
          </h3>
          <div className='card-toolbar '>
            <React.Fragment>
              <div className='btn btn-icon btn-sm btn-active-danger' id='side_activities_close'>
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </React.Fragment>
          </div>
        </div>
        <div className='card-body position-relative' id='side_activities_body'>
          <div>
            <div
              id='side_activities_scroll'
              className='position-relative scroll-y me-n5 pe-5 w-500px'
              data-kt-scroll='true'
              data-kt-scroll-height='auto'
              data-kt-scroll-wrappers='#side_activities_body'
              data-kt-scroll-dependencies='#side_activities_header, #side_activities_footer'
              data-kt-scroll-offset='5px'
            >
              {isLoading ? (
                <div className='card h-350px'>
                  <div className='m-auto d-flex flex-column align-items-center'>
                    <div className='spinner-border spinner-primary mr-15'></div>
                  </div>
                </div>
              ) : (
                <>
                  <div className='d-flex  mb-5'>
                    <div className='d-flex'>
                      <img
                        src={
                          leaveData?.employee_profile_pic
                            ? leaveData?.employee_profile_pic
                            : defaultProfileImage
                        }
                        alt=''
                        className='activity-img rounded h-80px w-80px me-2'
                      />
                      <div className=''>
                        <a href='#' className='text-dark fw-bold text-hover-primary fs-5'>
                          {leaveData?.first_name && leaveData?.middle_name && leaveData?.last_name
                            ? `${leaveData?.first_name} ${leaveData?.middle_name} ${leaveData?.last_name}`
                            : leaveData?.first_name && leaveData?.last_name
                            ? `${leaveData?.first_name} ${leaveData?.last_name}`
                            : '-'}
                        </a>

                        <br />
                        <a href='#' className='text-muted fw-normal text-hover-primary fs-7'>
                          {leaveData?.employee_code ? leaveData?.employee_code : '-'}
                        </a>
                        <br />
                        <a href='#' className='text-muted fw-normal text-hover-primary fs-7'>
                          {leaveData?.job_title ? leaveData?.job_title : '-'}
                        </a>
                        <br />
                        <a href='#' className='text-muted fw-normal text-hover-primary fs-7'>
                          {leaveData?.department_name ? leaveData?.department_name : '-'}
                        </a>
                        <br />
                      </div>
                    </div>
                  </div>

                  <div className='d-flex  mt-0'>
                    <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                      <li className='nav-item nav-item-links'>
                        <a
                          className={`nav-link ${activePage === 'page1' ? 'active' : ''}`}
                          onClick={() => handleLinkClick('page1')}
                          role='tab'
                        >
                          {intl.formatMessage({id: 'MENU.ACTIONS'})}
                        </a>
                      </li>
                      {data &&
                        data.map((item, index) => (
                          <li key={index} className='nav-item nav-item-links'>
                            <a
                              className={`nav-link ${activePage === 'page2' ? 'active' : ''}`}
                              onClick={() => handleLinkClick('page2')}
                              role='tab'
                            >
                              {item.first_name} {intl.formatMessage({id: 'LABEL.LEAVES'})}
                            </a>
                          </li>
                        ))}
                      <li className='nav-item nav-item-links'>
                        <a
                          className={`nav-link ${activePage === 'page3' ? 'active' : ''}`}
                          onClick={() => handleLinkClick('page3')}
                          role='tab'
                        >
                          {intl.formatMessage({id: 'LABEL.TEAM_LEAVES'})}
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div id='page2' className={activePage === 'page2' ? '' : 'd-none'}>
                    <div className=' mt-0 pt-0'>
                      {leavedata &&
                        leavedata
                          .slice()
                          .sort(
                            (a, b) =>
                              new Date(b.start_date).getTime() - new Date(a.start_date).getTime()
                          )
                          .map((item, index) => {
                            return (
                              <div key={index} className='timeline-label'>
                                <div className='timeline-item'>
                                  <div className='timeline-label fw-bold text-gray-800 fs-7 my-3 default-cursor'>
                                    {item.approved_no_of_days} Days
                                  </div>
                                  <div
                                    className={`timeline-badge my-3  ${getBadgeColorClass(
                                      item.status
                                    )}`}
                                  >
                                    <i className='fa abstract-8 fs-1'></i>
                                  </div>
                                  <div className='d-flex flex-column'>
                                    <div className='d-flex flex-column fw-mormal timeline-content text-dark ps-3 my-3 default-cursor'>
                                      <div> {item.leave_type_name} </div>
                                      <span>
                                        {new Date(item.start_date).toLocaleDateString('en-US', {
                                          month: 'short',
                                          day: 'numeric',
                                          year: 'numeric',
                                        })}{' '}
                                        to{' '}
                                        {new Date(item.end_date).toLocaleDateString('en-US', {
                                          month: 'short',
                                          day: 'numeric',
                                          year: 'numeric',
                                        })}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          })}
                    </div>
                  </div>

                  <div id='page3' className={activePage === 'page3' ? '' : 'd-none'}>
                    <div className=''>
                      <TeamLeavesCalendar key={leaveCalendarKey} />
                    </div>
                  </div>
                  <div id='page1' className={activePage === 'page1' ? '' : 'd-none'}>
                    <div className=' mt-6 mb-7'>
                      <div className='row mb-4'>
                        <label className='col-lg-4 fw-bold text-muted'>
                          {intl.formatMessage({id: 'LABEL.START_DATE'})}
                        </label>
                        <div className='col-lg-8'>
                          <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                            {leaveData?.start_date.slice(0, 10)}
                          </span>
                        </div>
                      </div>

                      <div className='row mb-4'>
                        <label className='col-lg-4 fw-bold text-muted'>
                          {intl.formatMessage({id: 'LABEL.END_DATE'})}
                        </label>
                        <div className='col-lg-8'>
                          <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                            {leaveData?.end_date.slice(0, 10)}
                          </span>
                        </div>
                      </div>

                      <div className='row mb-4'>
                        <label className='col-lg-4 fw-bold text-muted'>
                          {intl.formatMessage({id: 'LABEL.NO_OF_DAYS'})}
                        </label>
                        <div className='col-lg-8'>
                          <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                            {leaveData?.counted_no_of_days}
                          </span>
                        </div>
                      </div>

                      <h5 className='mt-4 text-dark'>
                        {intl.formatMessage({id: 'LABEL.WORKFLOW'})}
                      </h5>
                      <hr className='text-dark mt-2' />
                      <div className='card-body position-relative p-0' id='kt_activities_body'>
                        <div className='timeline'>
                          <LeaveManager reviewerDetails={reviewerDetails} />
                          <LeaveHR hrDetails={hrDetails} />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LeaveApprovalDrawer
