//businessCard
import {KTIcon, KTSVG} from '../../../../_metronic/helpers'
import {useIntl} from 'react-intl'
import * as Yup from 'yup'
import {ErrorMessage, Field, Form, Formik} from 'formik'
import {FormikHelpers} from 'formik'
import Swal from 'sweetalert2'
import {baseUrl} from '../../core'
import secureLocalStorage from 'react-secure-storage'
import {useParams} from 'react-router-dom'
import usePostApi from '../../custom_hooks/usePostApi'
import useApiCall from '../../custom_hooks/useApiCall'

const AddBusinessUnit = (props: any) => {
  const intl = useIntl()
  const {execute} = usePostApi()
  const {id}: any | null = useParams()
  const userInfo: any | null = secureLocalStorage.getItem('user_info')
  const {data: headName}: any | null = useApiCall('/masterData/employees')
  const {organization_id} = userInfo || {}
  const brandLogos: any | null = secureLocalStorage.getItem('brand_logos')
  const {brand_logo_url} = brandLogos || {}
  const brandName = localStorage.getItem('displayName') || 'Kloudworx'
  const {data}: any | null = useApiCall(`/organization/1`)
  const locationName = data && data[0]
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(intl.formatMessage({id: 'LABEL.NAME_REQUIRED'})),
    description: Yup.string().required(intl.formatMessage({id: 'LABEL.DESCRIPTION_REQUIRED'})),
    buCode: Yup.string().required(intl.formatMessage({id: 'LABEL.BU_CODE_REQUIRED'})),
    erpId: Yup.string().required(intl.formatMessage({id: 'LABEL.ERP_ID_REQUIRED'})),
    status: Yup.boolean().required(intl.formatMessage({id: 'LABEL.STATUS_REQUIRED'})),
  })

  const handleSubmit = async (values: any, formikHelpers: FormikHelpers<any>) => {
    const {resetForm} = formikHelpers
    const url = `${baseUrl}/businessUnits`
    try {
      const requestData = {
        organization_id: organization_id,
        business_unit_name: values.name,
        business_unit_description: values.description,
        bu_head: parseInt(values.headName) || 0,
        bu_code: values.buCode,
        erp_id: values.erpId,
        status: values.status ? 1 : 0,
      }
      await execute(url, 'POST', requestData, id)
      props.props.fetchData()
      resetForm()
      Swal.fire({
        title: 'Business Unit Added Successfully',
        text: intl.formatMessage({id: 'LABEL.CHANGES_SAVED'}),
        icon: 'success',
        showConfirmButton: true,
        customClass: {
          confirmButton: 'btn btn-light-primary btn-sm',
        },
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          const sideDrawer = document.getElementById('side_activities_close')
          if (sideDrawer) {
            sideDrawer.click()
          }
        }
      })
    } catch (error) {
      console.error(error)
      Swal.fire({
        icon: 'error',
        title: intl.formatMessage({id: 'LABEL.OOPS'}),
        text: intl.formatMessage({id: 'LABEL.SOMETHING_WRONG'}),
      })
    }
  }
  const handleCancel = (resetForm: any) => {
    resetForm()
  }
  return (
    <div>
      <div className='card shadow-none rounded-0'>
        <Formik
          initialValues={{
            name: '',
            description: '',
            headName: '',
            buCode: '',
            erpId: '',
            status: true,
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({values, handleChange, resetForm}) => {
            return (
              <Form>
                <div className='card-header' id='side_activities_header'>
                  <h3 className='card-title fw-bolder text-dark'>
                    {intl.formatMessage({id: 'LABEL.ADD_BUSINESS_UNIT'})}
                  </h3>
                  <div className='card-toolbar'>
                    <button
                      type='button'
                      className='btn btn-sm btn-icon btn-active-light-primary me-n5'
                      id='side_activities_close'
                    >
                      <KTIcon iconName='cross' className='fs-1' />
                    </button>
                  </div>
                </div>
                <div className='card-body position-relative' id='side_activities_body'>
                  <div>
                    <div
                      id='side_activities_scroll'
                      className='position-relative scroll-y me-n5 pe-5'
                      data-kt-scroll='true'
                      data-kt-scroll-height='auto'
                      data-kt-scroll-wrappers='#side_activities_body'
                      data-kt-scroll-dependencies='#side_activities_header, #side_activities_footer'
                      data-kt-scroll-offset='5px'
                    >
                      <div className='d-flex align-items-start'>
                        <img
                          src={brand_logo_url}
                          alt='img'
                          className='activity-img rounded w-65px h-65px me-3'
                        />
                        <div className='mt-0 pt-0'>
                          <p className='text-dark fw-bold text-hover-primary fs-5 m-0'>
                            {brandName}
                          </p>
                          <p className='text-dark fw-bold text-hover-primary text-muted m-0 mt-1'>
                            <KTIcon iconName='geolocation' className='fs-7 me-1' />
                            {locationName?.country_name}
                          </p>
                          <p className='text-dark fw-bold text-hover-primary text-muted m-0'>
                            <KTIcon iconName='bank' className='fs-7 me-1' />
                            {locationName?.address_line1}
                          </p>
                        </div>
                      </div>
                      <hr className='text-dark mt-4 mb-4' />
                      <div className='timeline'>
                        <div className='timeline-item'>
                          <div className='timeline-line w-40px'></div>
                          <div className='timeline-icon symbol symbol-circle symbol-40px'>
                            <div className='symbol-label bg-light'>
                              <KTIcon iconName='home' className='fs-2 text-gray-500' />
                            </div>
                          </div>
                          <div className='timeline-content mb-4 mt-n1'>
                            <div className='overflow-auto pe-3'>
                              <div className='fw-bold mb-2 required'>Business Unit Name</div>
                              <div className='flex-fill'>
                                <Field
                                  type='text'
                                  className='form-control form-control-solid'
                                  id='floatingInput'
                                  name='name'
                                />
                                <ErrorMessage component='div' className='text-danger' name='name' />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className='timeline-item'>
                          <div className='timeline-line w-40px'></div>
                          <div className='timeline-icon symbol symbol-circle symbol-40px'>
                            <div className='symbol-label bg-light'>
                              <KTIcon iconName='user' className='fs-2 text-gray-500' />
                            </div>
                          </div>
                          <div className='timeline-content mb-8 mt-n2'>
                            <div className='overflow-auto pe-3'>
                              <div className=' fw-bold mb-2'>Business Unit Head Name</div>
                              <div className='flex-fill'>
                                <Field
                                  as='select'
                                  id='headName'
                                  name='headName'
                                  className='form-select form-select-solid'
                                  value={values.headName}
                                  onChange={handleChange}
                                >
                                  <option value=''>Select Business Unit Head</option>
                                  {headName?.map((option: any) => (
                                    <option key={option.employee_id} value={option.employee_id}>
                                      {option.employee_name}
                                    </option>
                                  ))}
                                </Field>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr className='text-dark mt-4 mb-4' />
                      <div className='fw-bold mb-2 required'>Description</div>
                      <div className='mb-4'>
                        <Field
                          as='textarea'
                          className='form-control form-control-solid'
                          id='floatingInput'
                          row={3}
                          name='description'
                        />
                        <ErrorMessage component='div' className='text-danger' name='description' />
                      </div>
                      <div className='d-flex mb-4'>
                        <div className=''>
                          <div className='fw-bold mb-2 required'>BU CODE</div>
                          <Field
                            type='text'
                            className='form-control form-control-solid'
                            id='floatingInput'
                            name='buCode'
                          />
                          <ErrorMessage component='div' className='text-danger' name='buCode' />
                        </div>
                        <div className=' ms-2'>
                          <div className='fw-bold mb-2 required'>ERP ID</div>
                          <Field
                            type='text'
                            className='form-control form-control-solid'
                            id='floatingInput'
                            name='erpId'
                          />
                          <ErrorMessage component='div' className='text-danger' name='erpId' />
                        </div>
                      </div>
                      <div className='form-floating flex-fill'>
                        <div className='form-check form-check-solid form-switch mb-0'>
                          <Field
                            className='form-check-input w-40px h-20px'
                            type='checkbox'
                            name='status'
                            id='toggle'
                          />
                          <span
                            className={`me-4 fw-bold ${
                              values.status ? 'text-success' : 'text-danger'
                            }`}
                          >
                            {values.status ? 'Active' : 'Inactive'}
                          </span>
                          <ErrorMessage component='div' className='text-danger' name='status' />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='card-footer text-end' id='side_activities_footer'>
                  <div
                    className='btn btn-sm btn-secondary m-2'
                    onClick={() => handleCancel(resetForm)}
                    id='side_activities_close'
                  >
                    {intl.formatMessage({id: 'LABEL.CANCEL'})}
                  </div>
                  <button className='btn btn-light-primary btn-sm' type='submit'>
                    <KTIcon iconName='check' className='fs-7' />
                    {intl.formatMessage({id: 'BTN.SAVE'})}
                  </button>
                </div>
              </Form>
            )
          }}
        </Formik>
      </div>
    </div>
  )
}

export {AddBusinessUnit}
