import React, {useState, useEffect} from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import enLocale from '@fullcalendar/core/locales/en-gb'
import frLocale from '@fullcalendar/core/locales/fr'
import esLocale from '@fullcalendar/core/locales/es-us'
import arLocale from '@fullcalendar/core/locales/ar-sa'
import useApiCall from '../../custom_hooks/useApiCall'
import * as MetronicI18n from '../../../../_metronic/i18n/Metronici18n'

const TeamLeavesCalendar = () => {
  const {data: employeesleaveDetails, isLoading, fetchData} = useApiCall(`/leave/employees`)
  // Replace this with your actual employee leave details data

  useEffect(() => {
    fetchData()
  }, [])

  const [events, setEvents] = useState([])
  const [calendarLocale, setCalendarLocale] = useState(getInitLanguage())

  function getGlobalLanguage() {
    const selectedLang = MetronicI18n.getLanguage()
    return selectedLang
  }

  function getInitLanguage() {
    const selectedLanguage = getGlobalLanguage()

    let calendarLocale = enLocale

    switch (selectedLanguage) {
      case 'en':
        calendarLocale = enLocale
        break
      case 'fr':
        calendarLocale = frLocale
        break
      case 'ar':
        calendarLocale = arLocale
        break
      case 'es':
        calendarLocale = esLocale
        break
      default:
        calendarLocale = enLocale
        break
    }

    return calendarLocale
  }

  function getEventStyling(leaveStatus) {
    switch (leaveStatus) {
      case 'Pending':
        return '#9FA6B2'
      case 'HR Pending':
        return '#9FA6B2'
      case 'Manager Pending':
        return '#9FA6B2'
      case 'Approved':
        return 'green'
      case 'Reviewer Approved':
        return 'green'
      case 'Rejected':
        return 'red'
      default:
        return ''
    }
  }

  function addOneDayToDate(dateString) {
    const date = new Date(dateString)
    date.setDate(date.getDate() + 1)
    return date.toISOString().split('T')[0]
  }

  useEffect(() => {
    if (employeesleaveDetails && employeesleaveDetails.length > 0) {
      // Use employeesleaveDetails instead of filteredData
      const eventList = employeesleaveDetails.map((leave) => ({
        title: leave.first_name,
        start: new Date(leave.start_date).toISOString().split('T')[0], // Convert to Date and only get the date part

        end: addOneDayToDate(leave.end_date),
        backgroundColor: getEventStyling(leave.status),
        borderColor: getEventStyling(leave.status),
      }))
      setEvents(eventList)
    }
  }, [employeesleaveDetails])

  return (
    <>
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay',
        }}
        initialView='dayGridMonth'
        weekends={true}
        events={events}
        locale={calendarLocale}
      />
    </>
  )
}

export default TeamLeavesCalendar
