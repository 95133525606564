import {useEffect, useState} from 'react'
import useApiCall from '../custom_hooks/useApiCall'
import {useContext} from 'react'
import VacancyContext from './vacancies/VacancyContext'
export function GetAllVacancies() {
  const {
    data: vacanciesData,
    isLoading: isVacancyLoading,
    fetchData,
  }: any | null = useApiCall(`/recruitment/vacancies`)

  useEffect(() => {
    fetchData()
  }, [])

  return {vacanciesData, isVacancyLoading}
}

export function GetAllEmployees() {
  const {
    data: employeesData,
    isLoading: isEmployeeLoading,
    fetchData,
  }: any | null = useApiCall('/hrConfig/employees/all')

  useEffect(() => {
    fetchData()
  }, [])

  return {employeesData, isEmployeeLoading}
}

export function GetAllCandidates() {
  const {VACANCYID} = useContext(VacancyContext)
  const getuserID = localStorage.getItem('CANDID')

  let candidateID = null

  if (VACANCYID != 0) {
    candidateID = VACANCYID
  } else if (getuserID) {
    candidateID = getuserID
  }
  const {
    data: candidatesData,
    isLoading: isEmployeeLoading,
    fetchData,
  }: any | null = useApiCall(candidateID !== null ? `/recruitment/candidates/${candidateID}` : null)

  useEffect(() => {
    fetchData()
  }, [])

  return {candidatesData, isEmployeeLoading}
}
