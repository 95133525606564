/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {useIntl} from 'react-intl'
import {baseUrl} from '../../../core'
import secureLocalStorage from 'react-secure-storage'
import {ErrorMessage, Field, Form, Formik} from 'formik'
import Swal from 'sweetalert2'
import * as Yup from 'yup'
import usePostApi from '../../../custom_hooks/usePostApi'
import {useParams} from 'react-router-dom'
import useApiCall from '../../../custom_hooks/useApiCall'
const BrandPreferences = () => {
  const intl = useIntl()
  const {execute} = usePostApi()
  const {id}: any | null = useParams()
  const userInfo: any | null = secureLocalStorage.getItem('user_info')
  const {is_admin, is_hr, organization_id} = userInfo || {}
  const {data, isLoading}: any | null = useApiCall(`/pref/brand/1`)
  let brandPreferenceData = data && data[0]
  const [isEditMode, setIsEditMode] = useState(false)
  const [displayName, setDisplayName] = useState<string>('KLOUDWORX')
  const [tagline, setTagline] = useState('Think outside the box & only think that way!')
  const [messageName, setMessage] = useState('Kloudworx')
  const [timeFormat, setTimeFormats] = useState('HH:mm:ss')
  const [dateFormat, setDateFormat] = useState('dd/MM/yyyy')
  const [timeZone, setTimezone] = useState('Asia/Kolkata')
  interface DateFormatMapping {
    [format: string]: string
  }
  interface TimeFormatMapping {
    [format: string]: string
  }
  interface TimezoneFormatMapping {
    [format: string]: string
  }

  const DateFormats: DateFormatMapping = {
    'dd/MM/yyyy': 'DD/MM/YYYY',
    'MM/dd/yyyy': 'MM/DD/YYYY',
    'yyyy/MM/dd': 'YYYY/MM/DD',
    'dd-MM-yyyy': 'DD-MM-YYYY',
    'MM dd, yyyy': 'MM DD, YYYY',
    'dd MMMM yyyy': 'DD Month YYYY',
    'MM dd yyyy': 'MM DD YYYY',
    'dd-MM-yy': 'DD-MM-YY',
    'yy/MM/dd': 'YY/MM/DD',
  }
  const TimeFormats: TimeFormatMapping = {
    'HH:mm:ss': '24-hour format with seconds: HH:mm:ss',
    'HH:mm': '24-hour format without seconds: HH:mm',
    'AM/PM: hh:mm:ss A': '12-hour format with seconds and AM/PM: hh:mm:ss A',
    'AM/PM: hh:mm A': '12-hour format without seconds and AM/PM: hh:mm A',
  }

  const TimeZoneFormats: TimezoneFormatMapping = {
    'Etc/GMT+12': '(GMT-12:00) International Date Line West',
    'Pacific/Midway': '(GMT-11:00) Midway Island, Samoa',
    'Pacific/Honolulu': '(GMT-10:00) Hawaii',
    'US/Alaska': '(GMT-09:00) Alaska',
    'Asia/Kolkata': '(GMT+05:30) India Standard Time (IST)',
    'Asia/Dubai': '(GMT+04:00) United Arab Emirates Standard Time (UAE)',
  }
  const handleEditClick = () => {
    setIsEditMode(true)
  }
  const handleCancelClick = (resetForm: any) => {
    setIsEditMode(false)
    resetForm()
  }
  useEffect(() => {
    if (brandPreferenceData) {
      setDisplayName(brandPreferenceData.display_name)
      setMessage(brandPreferenceData.brand_welcome_message)
      setTagline(brandPreferenceData.brand_tagline)
      setTimezone(brandPreferenceData.timezone)
      setTimeFormats(brandPreferenceData.time_format)
      setDateFormat(brandPreferenceData.date_format)
    }
  }, [brandPreferenceData])
  const handleSaveClick = async (values: any) => {
    const url = `${baseUrl}/pref/brand/1`
    setIsEditMode(false)
    try {
      const result = await Swal.fire({
        title: intl.formatMessage({id: 'LABEL.UPDATING_BRAND_PREFERENCES'}),
        text: intl.formatMessage({id: 'LABEL.CONFIRM_UPDATE_BRAND_PREFERENCES'}),
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: intl.formatMessage({id: 'BTN.CONFIRM'}),
        cancelButtonText: intl.formatMessage({id: 'LABEL.CANCEL'}),
        customClass: {
          confirmButton: 'btn btn-light-primary btn-sm',
          cancelButton: 'btn btn-sm btn-secondary',
        },
      })

      if (result.isConfirmed) {
        const brandpreferences = {
          organization_id: organization_id,
          display_name: values.displayName,
          brand_welcome_message: values.messageName,
          brand_tagline: values.tagline,
          timezone: values.timeZone,
          time_format: values.timeFormat,
          date_format: values.dateFormat,
        }
        await execute(url, 'PATCH', brandpreferences, id)
        setIsEditMode(false)
        Swal.fire({
          title: intl.formatMessage({id: 'LABEL.BRAND_PREFERENCES_EDITED'}),
          text: intl.formatMessage({id: 'LABEL.CHANGES_SAVED'}),
          icon: 'success',
          showConfirmButton: true,
          confirmButtonText: 'OK',
          customClass: {
            confirmButton: 'btn btn-light-primary btn-sm',
          },
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload()
          }
        })
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: intl.formatMessage({id: 'LABEL.OOPS'}),
        text: intl.formatMessage({id: 'LABEL.SOMETHING_WRONG'}),
      })
      console.error('Error updating data:', error)
    }
  }
  const initialValues = {
    dateFormat: dateFormat || '',
    displayName: displayName || '',
    timeFormat: timeFormat || '',
    timeZone: timeZone || '',
    messageName: messageName || '',
    tagline: tagline || '',
  }
  const validationSchema = Yup.object().shape({
    displayName: Yup.string().required(intl.formatMessage({id: 'LABEL.NAME_REQUIRED'})),
    messageName: Yup.string().required(intl.formatMessage({id: 'LABEL.WELCOME_MESSAGE'})),
    tagline: Yup.string().required(intl.formatMessage({id: 'LABEL.TAGLINE'})),
  })

  return (
    <div className={`card card-xl-stretch`}>
      {isLoading ? (
        <div className='card card-xl-stretch h-250px'>
          <div className='m-auto d-flex flex-column align-items-center'>
            <div className='spinner-border spinner-primary mr-15'></div>
          </div>
        </div>
      ) : (
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={handleSaveClick}
        >
          {({values, handleChange, resetForm}) => {
            return (
              <Form>
                <div className='card-header'>
                  <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-5 mb-1'>
                      {intl.formatMessage({id: 'LABEL.ORGANIZATION_BRAND_PREFERENCES'})}
                    </span>
                    <span className='text-muted fw-bold fs-7'>
                      {intl.formatMessage({id: 'LABEL.ORGANIZATION_RESONANCE_TIMEZONE_LOCAL'})}
                    </span>
                  </h3>
                  {is_admin === 1 && (
                    <div className='card-toolbar'>
                      {!isEditMode && (
                        <button
                          type='button'
                          className='btn btn-sm btn-light-primary btn-ripple'
                          onClick={handleEditClick}
                        >
                          <KTIcon iconName='pencil' className='fs-7' />
                          {intl.formatMessage({id: 'BTN.EDIT'})}
                        </button>
                      )}
                      {isEditMode && (
                        <React.Fragment>
                          <button className='btn btn-light-primary btn-sm' type='submit'>
                            <KTIcon iconName='check' className='fs-7' />
                            {intl.formatMessage({id: 'BTN.SAVE'})}
                          </button>

                          <div
                            className='btn btn-icon btn-sm btn-active-light-danger ms-2'
                            onClick={() => handleCancelClick(resetForm)}
                          >
                            <KTIcon iconName='cross' className='fs-2' />
                          </div>
                        </React.Fragment>
                      )}
                    </div>
                  )}
                </div>
                <div className='card-body'>
                  <div className='row'>
                    <div className='col-md-6 col-sm-12 mb-2'>
                      <div className='row'>
                        <div className='col-3 d-flex'>
                          <div className='symbol symbol-50px me-2'>
                            <span className='symbol-label'>
                              <img
                                src={toAbsoluteUrl('/media/svg/brand-logos/webpack.svg')}
                                className='h-50 align-self-center'
                                alt=''
                              />
                            </span>
                          </div>
                        </div>
                        <div className='col-9 d-flex'>
                          {isEditMode ? (
                            <div className='form-floating flex-fill'>
                              <Field
                                type='text'
                                className='form-control'
                                id='displayName'
                                name='displayName'
                                value={!isEditMode ? displayName : values.displayName}
                                disabled={!isEditMode}
                                onChange={handleChange}
                              />

                              <label htmlFor='displayName'>
                                {intl.formatMessage({id: 'LABEL.ORGANIZATION_DISPLAY_NAME'})}
                              </label>
                              <ErrorMessage
                                component='div'
                                className='text-danger'
                                name='displayName'
                              />
                            </div>
                          ) : (
                            <>
                              <div className='form-floating'>
                                <p
                                  className='text-dark fw-bold text-hover-primary mb-1 fs-6 default-cursor'
                                  style={{
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    width: '140px',
                                  }}
                                  title={displayName}
                                >
                                  {displayName}
                                </p>
                                <span className='text-muted fw-bold d-block fs-7'>
                                  {intl.formatMessage({id: 'LABEL.ORGANIZATION_DISPLAY_NAME'})}
                                </span>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className='col-md-6 col-sm-12 mb-2'>
                      <div className='row'>
                        <div className='col-3 d-flex'>
                          <div className='symbol symbol-50px me-2'>
                            <span className='symbol-label'>
                              <KTIcon iconName='time' className='fs-2x' />
                            </span>
                          </div>
                        </div>
                        <div className='col-9 d-flex'>
                          {isEditMode ? (
                            <div className='form-floating flex-fill'>
                              <Field
                                as='select'
                                id='timeFormat'
                                name='timeFormat'
                                className='form-select'
                                value={!isEditMode ? timeFormat : values.timeFormat}
                                onChange={handleChange}
                                disabled={!isEditMode}
                              >
                                <option value='HH:mm:ss'>
                                  24-hour format with seconds: HH:mm:ss
                                </option>
                                <option value='HH:mm'>24-hour format without seconds: HH:mm</option>
                                <option value='AM/PM: hh:mm:ss A'>
                                  12-hour format with seconds and AM/PM: hh:mm:ss A
                                </option>
                                <option value='AM/PM: hh:mm A'>
                                  12-hour format without seconds and AM/PM: hh:mm A
                                </option>
                              </Field>
                              <label htmlFor='timeFormat'>
                                {intl.formatMessage({id: 'LABEL.ORGANIZATION_TIME_FORMATS'})}
                              </label>
                              <ErrorMessage
                                component='div'
                                className='text-danger'
                                name='timeFormat'
                              />
                            </div>
                          ) : (
                            <>
                              <div className='form-floating'>
                                <p
                                  className='text-dark fw-bold text-hover-primary mb-1 fs-6 default-cursor'
                                  style={{
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    width: '140px',
                                  }}
                                  title={TimeFormats[timeFormat]}
                                >
                                  {TimeFormats[timeFormat]}
                                </p>
                                <span className='text-muted fw-bold d-block fs-7'>
                                  {intl.formatMessage({id: 'LABEL.ORGANIZATION_TIME_FORMATS'})}
                                </span>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className='col-md-6 col-sm-12 my-2'>
                      <div className='row'>
                        <div className='col-3 d-flex'>
                          <div className='symbol symbol-50px me-2'>
                            <span className='symbol-label'>
                              <img
                                src={toAbsoluteUrl('/media/svg/brand-logos/webpack.svg')}
                                className='h-50 align-self-center'
                                alt=''
                              />
                            </span>
                          </div>
                        </div>
                        <div className='col-9 d-flex'>
                          {isEditMode ? (
                            <div className='form-floating flex-fill'>
                              <Field
                                type='text'
                                className='form-control'
                                id='messageName'
                                name='messageName'
                                value={!isEditMode ? messageName : values.messageName}
                                disabled={!isEditMode}
                                onChange={handleChange}
                              />

                              <label htmlFor='messageName'>
                                {intl.formatMessage({id: 'LABEL.ORGANIZATION_WELCOME_MESSAGE'})}
                              </label>
                              <ErrorMessage
                                component='div'
                                className='text-danger'
                                name='messageName'
                              />
                            </div>
                          ) : (
                            <>
                              <div className='form-floating'>
                                <p
                                  className='text-dark fw-bold text-hover-primary mb-1 fs-6 default-cursor'
                                  style={{
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    width: '140px',
                                  }}
                                  title={messageName}
                                >
                                  {messageName}
                                </p>
                                <span className='text-muted fw-bold d-block fs-7'>
                                  {intl.formatMessage({id: 'LABEL.ORGANIZATION_WELCOME_MESSAGE'})}
                                </span>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className='col-md-6 col-sm-12 my-2'>
                      <div className='row'>
                        <div className='col-3 d-flex'>
                          <div className='symbol symbol-50px me-2'>
                            <span className='symbol-label'>
                              <KTIcon iconName='time' className='fs-2x' />
                            </span>
                          </div>
                        </div>
                        <div className='col-9 d-flex'>
                          {isEditMode ? (
                            <div className='form-floating flex-fill'>
                              <Field
                                as='select'
                                id='dateFormat'
                                name='dateFormat'
                                className='form-select'
                                value={!isEditMode ? dateFormat : values.dateFormat}
                                onChange={handleChange}
                                disabled={!isEditMode}
                              >
                                <option value='dd/MM/yyyy'>DD/MM/YYYY</option>
                                <option value='MM/dd/yyyy'>MM/DD/YYYY</option>
                                <option value='yyyy/MM/dd'>YYYY/MM/DD</option>
                                <option value='dd-MM-yyyy'>DD-MM-YYYY</option>
                                <option value='MM dd, yyyy'>MM DD, YYYY</option>
                                <option value='dd MMMM yyyy'>DD Month YYYY</option>
                                <option value='MM dd yyyy'>MM DD YYYY</option>
                                <option value='dd-MM-yy'>DD-MM-YY</option>
                                <option value='yy/MM/dd'>YY/MM/DD</option>
                              </Field>
                              <label htmlFor='dateFormat'>
                                {intl.formatMessage({id: 'LABEL.ORGANIZATION_DATE_FORMATS'})}
                              </label>
                              <ErrorMessage
                                component='div'
                                className='text-danger'
                                name='dateFormat'
                              />
                            </div>
                          ) : (
                            <>
                              <div className='form-floating'>
                                <p className='text-dark fw-bold text-hover-primary mb-1 fs-6 default-cursor'>
                                  {DateFormats[dateFormat]}
                                </p>
                                <span className='text-muted fw-bold d-block fs-7'>
                                  {intl.formatMessage({id: 'LABEL.ORGANIZATION_DATE_FORMATS'})}
                                </span>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className='col-md-6 col-sm-12 my-2'>
                      <div className='row'>
                        <div className='col-3 d-flex'>
                          <div className='symbol symbol-50px me-2'>
                            <span className='symbol-label'>
                              <img
                                src={toAbsoluteUrl('/media/svg/brand-logos/webpack.svg')}
                                className='h-50 align-self-center'
                                alt=''
                              />
                            </span>
                          </div>
                        </div>
                        <div className='col-9 d-flex'>
                          {isEditMode ? (
                            <div className='form-floating flex-fill'>
                              <Field
                                type='text'
                                className='form-control'
                                id='tagline'
                                name='tagline'
                                value={!isEditMode ? tagline : values.tagline}
                                disabled={!isEditMode}
                                onChange={handleChange}
                              />

                              <label htmlFor='tagline'>
                                {intl.formatMessage({id: 'LABEL.ORGANIZATION_TAGLINE'})}
                              </label>
                              <ErrorMessage
                                component='div'
                                className='text-danger'
                                name='tagline'
                              />
                            </div>
                          ) : (
                            <>
                              <div className='form-floating'>
                                <p
                                  className='text-dark fw-bold text-hover-primary mb-1 fs-6 default-cursor'
                                  style={{
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    width: '140px',
                                  }}
                                  title={tagline}
                                >
                                  {tagline}
                                </p>
                                <span className='text-muted fw-bold d-block fs-7'>
                                  {intl.formatMessage({id: 'LABEL.ORGANIZATION_TAGLINE'})}
                                </span>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className='col-md-6 col-sm-12 my-2'>
                      <div className='row'>
                        <div className='col-3 d-flex'>
                          <div className='symbol symbol-50px me-2'>
                            <span className='symbol-label'>
                              <KTIcon iconName='time' className='fs-2x' />
                            </span>
                          </div>
                        </div>
                        <div className='col-9 d-flex'>
                          {isEditMode ? (
                            <div className='form-floating  flex-fill'>
                              <Field
                                as='select'
                                id='timeZone'
                                name='timeZone'
                                className='form-select'
                                value={!isEditMode ? timeZone : values.timeZone}
                                onChange={handleChange}
                                disabled={!isEditMode}
                              >
                                <option value='Etc/GMT+12'>
                                  (GMT-12:00) International Date Line West
                                </option>
                                <option value='Pacific/Midway'>
                                  (GMT-11:00) Midway Island, Samoa
                                </option>
                                <option value='Pacific/Honolulu'>(GMT-10:00) Hawaii</option>
                                <option value='US/Alaska'>(GMT-09:00) Alaska</option>
                                <option value='Asia/Kolkata'>
                                  (GMT+05:30) India Standard Time (IST)
                                </option>
                                <option value='Asia/Dubai'>
                                  (GMT+04:00) United Arab Emirates Standard Time (UAE)
                                </option>
                                {/* Add more options */}
                              </Field>
                              <label htmlFor='timeZone'>
                                {intl.formatMessage({id: 'LABEL.ORGANIZATION_TIMEZONE'})}
                              </label>
                              <ErrorMessage
                                component='div'
                                className='text-danger'
                                name='timeZone'
                              />
                            </div>
                          ) : (
                            <>
                              <div className='form-floating'>
                                <p
                                  className='text-dark fw-bold text-hover-primary my-1 fs-6 default-cursor'
                                  style={{
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    width: '140px',
                                  }}
                                  title={TimeZoneFormats[timeZone]}
                                >
                                  {TimeZoneFormats[timeZone]}
                                </p>
                                <span className='text-muted fw-bold d-block fs-7'>
                                  {intl.formatMessage({id: 'LABEL.ORGANIZATION_TIMEZONE'})}
                                </span>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )
          }}
        </Formik>
      )}
    </div>
  )
}

export {BrandPreferences}
