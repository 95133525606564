import {Formik, Form, Field, ErrorMessage} from 'formik'
import * as Yup from 'yup'

import Swal from 'sweetalert2'
import {useState, useEffect} from 'react'
import {useIntl} from 'react-intl'
import React from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import useApiCall from '../../custom_hooks/useApiCall'
import Multiselect from 'multiselect-react-dropdown'
import secureLocalStorage from 'react-secure-storage'
import usePostApi from '../../custom_hooks/usePostApi'
import {baseUrl} from '../../core'
const AddVacancy = () => {
  const {execute} = usePostApi()
  const {data: departmenttypes} = useApiCall('/masterData/departments')
  const {data: employmenttype} = useApiCall('/masterData/employment_types')
  const {data: jobroles} = useApiCall('/hrConfig/jobRole')
  const {data: jobtitles} = useApiCall('/hrConfig/jobTitle')
  const {data: currencytype} = useApiCall('/currencyTypes')
  const {data: skillset} = useApiCall('/masterData/skillsets')
  const {data: countries} = useApiCall('/country')
  const intl = useIntl()
  const [countryId, setCountryId] = useState(null)
  const [stateId, setStateId] = useState(null)
  const [vacancydepartmentid, setvacancydepartment] = useState(null)
  const [selectedDepartmentId, setSelectedDepartmentId] = useState(null)
  const [selectedskills, setSelectedskills] = useState([])
  const handleSelect = (selectedList, selectedItem) => {
    setSelectedskills(selectedList.map((item) => item.value))
  }

  const handleRemove = (selectedList, removedItem) => {
    setSelectedskills(selectedList.map((item) => item.value))
  }

  const {data: hiringManager, fetchData: fetchManager} = useApiCall(
    vacancydepartmentid ? `/masterData/managers/${vacancydepartmentid}` : null
  )

  const {
    data: states,
    isLoading,
    fetchData: fetchStates,
  } = useApiCall(countryId ? `/state/${countryId}` : null)
  const {
    data: citiesData,
    isLoading: citiesLoading,
    fetchData: fetchCities,
  } = useApiCall(stateId ? `/city/${stateId}` : null)
  let cities = citiesData ? citiesData : null

  useEffect(() => {
    fetchStates()
    cities = []
  }, [countryId])
  useEffect(() => {
    fetchCities()
  }, [stateId])
  useEffect(() => {
    fetchManager()
  }, [vacancydepartmentid])

  const clearFunc = () => {
    setSelectedskills([])
  }

  const selectedVals =
    selectedskills.map((skillSet) => ({
      value: skillSet,
      key: skillSet,
    })) || []

  const validationSchema = Yup.object().shape({
    job_role_id: Yup.string().required(
      `${intl.formatMessage({id: 'ERR.MSG.POSITION_IS_REQUIRED'})}`
    ),
    job_title_id: Yup.string().required(
      `${intl.formatMessage({id: 'ERR.MSG.JOBTITLE_IS_REQUIRED'})}`
    ),
    qualifications: Yup.string().required(
      `${intl.formatMessage({id: 'ERR.MSG.QUALIFICATION_IS_REQUIRED'})}`
    ),
    experience: Yup.number()
      .moreThan(
        -1,
        `${intl.formatMessage({id: 'ERR.MSG.EXPERIENCE_MUST_BE_A_POSITIVE_NUMBER_OR_ZERO'})}`
      )
      .required(`${intl.formatMessage({id: 'ERR.MSG.EXPERIENCE_IS_REQUIRED'})}`),
    number_of_vacancies: Yup.string().required(
      `${intl.formatMessage({id: 'ERR.MSG.NUMBER_OF_VACANCIES_IS_REQUIRED'})}`
    ),
    salary_start: Yup.string().required(
      `${intl.formatMessage({id: 'ERR.MSG.SALARY_IS_REQUIRED'})}`
    ),
    salary_end: Yup.string().required(`${intl.formatMessage({id: 'ERR.MSG.SALARY_IS_REQUIRED'})}`),
    currency_name: Yup.string().required(
      `${intl.formatMessage({id: 'ERR.MSG.CURRENCY_IS_REQUIRED'})}`
    ),
    service_agreement: Yup.string().required(
      `${intl.formatMessage({id: 'ERR.MSG.SERVICE_AGREEMENT_IS_REQUIRED'})}`
    ),
    // skills: Yup.string().required(`${intl.formatMessage({id: 'ERR.MSG.SKILLS_IS_REQUIRED'})}`),
    description: Yup.string().required(
      `${intl.formatMessage({id: 'ERR.MSG.DESCRIPTION_IS_REQUIRED'})}`
    ),
    department_id: Yup.string().required(
      `${intl.formatMessage({id: 'ERR.MSG.DEPARTMENT_IS_REQUIRED'})}`
    ),
    city_name: Yup.string().required(`${intl.formatMessage({id: 'ERR.MSG.CITY_IS_REQUIRED'})}`),
    state_name: Yup.string().required(`${intl.formatMessage({id: 'ERR.MSG.STATE_IS_REQUIRED'})}`),
    country_name: Yup.string().required(
      `${intl.formatMessage({id: 'ERR.MSG.COUNTRY_IS_REQUIRED'})}`
    ),
    employment_type_name: Yup.string().required(
      `${intl.formatMessage({id: 'ERR.MSG.EMPLOYMENTTYPE_IS_REQUIRED'})}`
    ),
    hiring_manager_name: Yup.string().required(
      `${intl.formatMessage({id: 'ERR.MSG.HIRING_MANAGER_IS_REQUIRED'})}`
    ),
  })

  const initialValues = {
    job_role_id: '',
    job_title_id: '',
    qualifications: '',
    experience: '',
    number_of_vacancies: '',
    salary_start: '',
    salary_end: '',
    currency_name: '',
    service_agreement: '',
    skills: '',
    description: '',
    department_name: '',
    city_name: '',
    state_name: '',
    country_name: '',
    employment_type_name: '',

    hiring_manager_name: '',
    vacancy_status: '',
  }
  const [isSubmitting, setIsSubmitting] = useState(false)
  const handleSubmit = async (values, formikHelpers) => {
    const {resetForm} = formikHelpers
    try {
      const organizationId = secureLocalStorage.getItem('organization_id')
      const url = `${baseUrl}/recruitment/vacancies`
      const formData = {
        job_role_id: parseInt(values.job_role_id),
        job_title_id: parseInt(values.job_title_id),
        organization_id: organizationId,

        department_id: vacancydepartmentid,
        hiring_manager_id: parseInt(values.hiring_manager_name),
        number_of_vacancies: values.number_of_vacancies,
        job_description: values.description,
        city_id: parseInt(values.city_name),
        state_id: parseInt(values.state_name),
        country_id: parseInt(values.country_name),
        salary_start: values.salary_start,
        salary_end: values.salary_end,

        salary_currency_id: parseInt(values.currency_name),
        qualifications: values.qualifications,
        skills: selectedskills,
        experience: values.experience,
        vacancy_status: 'Open',
        service_agreement: values.service_agreement,
        employment_type: values.employment_type_name,
      }

      await execute(url, 'POST', formData)
      resetForm()

      Swal.fire({
        title: intl.formatMessage({id: 'LABEL.VACANCY_CREATED_SUCCESSFULLY'}),
        text: 'You can now track the status of the Vacancy in the “Vacancies” section..',
        icon: 'success',
      })
    } catch (error) {
      console.error(error)
      Swal.fire({
        icon: 'error',
        title: intl.formatMessage({id: 'LABEL.OOPS'}),
        text: intl.formatMessage({id: 'LABEL.SOMETHING_WENT_WRONG'}),
      })
    }
  }

  return (
    <div className='card shadow-none rounded-0'>
      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({values, setFieldValue, handleChange}) => {
            return (
              <Form>
                <div className='card-header' id='side_activities_header'>
                  <h3 className='card-title fw-bolder text-dark'>
                    {intl.formatMessage({id: 'BTN.ADD_VACANCY'})}
                  </h3>
                  <div className='card-toolbar '>
                    <React.Fragment>
                      <div
                        className='btn btn-icon btn-sm btn-active-danger'
                        id='side_activities_close'
                      >
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr061.svg'
                          className='svg-icon svg-icon-2x'
                        />
                      </div>
                    </React.Fragment>
                  </div>
                </div>

                <div className='card-body position-relative' id='side_activities_body'>
                  <div>
                    <div
                      id='side_activities_scroll'
                      className='position-relative scroll-y me-n5 pe-5 w-450px'
                      data-kt-scroll='true'
                      data-kt-scroll-height='auto'
                      data-kt-scroll-wrappers='#side_activities_body'
                      data-kt-scroll-dependencies='#side_activities_header, #side_activities_footer'
                      data-kt-scroll-offset='5px'
                    >
                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          {intl.formatMessage({id: 'LABEL.POSITION'})}
                        </label>
                        <div className='col-lg-8 text-start'>
                          <Field
                            as='select'
                            name='job_role_id'
                            className='form-select form-select-solid'
                            placeholder={intl.formatMessage({id: 'LABEL.SELECT_JOB_ROLE'})}
                            value={values.job_role_id}
                            onChange={(e) => {
                              const selectedJobRoleId = e.target.value
                              // Fetch the corresponding department ID based on the selected job role
                              const correspondingDepartmentId = jobroles.find(
                                (jobrole) => jobrole.job_role_id === parseInt(selectedJobRoleId)
                              )?.department_id

                              // Set the selected values in the form
                              setFieldValue('job_role_id', selectedJobRoleId)
                              setFieldValue('department_id', correspondingDepartmentId)
                              setvacancydepartment(correspondingDepartmentId)
                              handleChange(e)
                            }}
                          >
                            <option value=''>
                              {intl.formatMessage({id: 'LABEL.SELECT_JOB_ROLE'})}
                            </option>

                            {jobroles?.map((jobrole) => (
                              <option key={jobrole.job_role_id} value={jobrole.job_role_id}>
                                {jobrole.role_name}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            name='job_role_id'
                            component='div'
                            className='text-danger'
                          />
                        </div>
                      </div>
                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>Job Title</label>
                        <div className='col-lg-8 text-start'>
                          <Field
                            as='select'
                            name='job_title_id'
                            className='form-select form-select-solid'
                            placeholder={intl.formatMessage({id: 'LABEL.SELECT_JOB_TITLE'})}
                            value={values.job_title_id}
                          >
                            <option value=''>
                              {' '}
                              {intl.formatMessage({id: 'LABEL.SELECT_JOB_TITLE'})}
                            </option>

                            {jobtitles?.map((jobrole) => (
                              <option key={jobrole.job_title_id} value={jobrole.job_title_id}>
                                {jobrole.job_title}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            name='job_title_id'
                            component='div'
                            className='text-danger'
                          />
                        </div>
                      </div>
                      {/* <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          {intl.formatMessage({id: 'DEPARTMENT'})}
                        </label>
                        <div className='col-lg-8 text-start'>
                          <Field
                            as='select'
                            id='floatingInput'
                            className='form-select'
                            name='department_name'
                            onChange={(e) => {
                              const selectedId = e.target.value
                              setSelectedDepartmentId(selectedId)

                              handleChange(e)
                            }}
                          >
                            <option value=''>Select Department</option>

                            {departmenttypes?.map((departmentType) => (
                              <option
                                key={departmentType.department_id}
                                value={departmentType.department_id} // Change this to use the department_id
                              >
                                {departmentType.department_name}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            name='department_name'
                            component='div'
                            className='text-danger'
                          />
                        </div>
                      </div> */}

                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          {intl.formatMessage({id: 'LABEL.DESCRIPTION'})}
                        </label>
                        <div className='col-lg-8 text-start'>
                          <Field
                            tupe='text'
                            name='description'
                            className='form-control form-control-solid'
                            value={values.description}
                            placeholder={intl.formatMessage({id: 'LABEL.DESCRIPTION'})}
                            onChange={(e) => setFieldValue('description', e.target.value)}
                          />
                          <ErrorMessage
                            name='description'
                            component='div'
                            className='text-danger'
                          />
                        </div>
                      </div>

                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          {intl.formatMessage({id: 'LABEL.QUALIFICATION'})}
                        </label>
                        <div className='col-lg-8 text-start'>
                          <Field
                            type='text'
                            name='qualifications'
                            className='form-control form-control-solid'
                            value={values.qualifications}
                            placeholder={intl.formatMessage({id: 'LABEL.QUALIFICATION'})}
                            onChange={handleChange}
                          />
                          <ErrorMessage
                            name='qualifications'
                            component='div'
                            className='text-danger'
                          />
                        </div>
                      </div>

                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          {' '}
                          {intl.formatMessage({id: 'LABEL.SKILLS'})}
                        </label>
                        <div className='col-lg-8 text-start'>
                          <Multiselect
                            className='multiselectContainer searchWrapper form-select form-select-solid'
                            displayValue='key'
                            onKeyPressFn={function noRefCheck() {}}
                            onRemove={handleRemove}
                            onSearch={function noRefCheck2() {}}
                            onSelect={handleSelect}
                            options={[
                              // Use spread operator to flatten the array returned by skillset?.map
                              ...(skillset?.map((skillSet) => ({
                                value: skillSet?.skillset_name,
                                key: skillSet?.skillset_name,
                              })) || []), // Use empty array if skillset is undefined or null
                            ]}
                            placeholder='Select Skills'
                            showCheckbox
                            selectedValues={selectedVals}
                          >
                            {/* <option key='' value=''>
                              {intl.formatMessage({id: 'MENU.HR_CONFIGURATION.SKILLSETS'})}
                            </option>

                            {skillset?.map((skillSet, index) => (
                              <option key={skillSet.skillset_id} value={skillSet.skillset_name}>
                                {skillSet.skillset_name}
                              </option>
                            ))} */}
                          </Multiselect>
                          <ErrorMessage name='skills' component='div' className='text-danger' />
                        </div>
                      </div>

                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          {intl.formatMessage({id: 'LABEL.HIRING_MANAGER'})}
                        </label>
                        <div className='col-lg-8 text-start'>
                          <Field
                            as='select'
                            className='form-select form-select-solid'
                            id='floatingInput'
                            name='hiring_manager_name'
                            value={values.hiring_manager_name}
                            onChange={handleChange}
                          >
                            <option key='' value=''>
                              {intl.formatMessage({id: 'LABEL.SELECT_HIRING_MANAGER'})}
                            </option>

                            {hiringManager?.map((hiringmanager) => (
                              <option
                                key={hiringmanager.employee_id}
                                value={hiringmanager.employee_id}
                              >
                                {hiringmanager.employee_name}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            name='hiring_manager_name'
                            component='div'
                            className='text-danger'
                          />
                        </div>
                      </div>

                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          {intl.formatMessage({id: 'LABEL.NO_OF_VACANCIES'})}
                        </label>
                        <div className='col-lg-8 text-start'>
                          <Field
                            type='number'
                            name='number_of_vacancies'
                            className='form-control form-control-solid'
                            value={values.number_of_vacancies}
                            onChange={handleChange}
                            placeholder={intl.formatMessage({id: 'LABEL.NO_OF_VACANCIES'})}
                          />
                          <ErrorMessage
                            name='number_of_vacancies'
                            component='div'
                            className='text-danger'
                          />
                        </div>
                      </div>

                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          {intl.formatMessage({id: 'LABEL.EXPERIENCE'})}
                        </label>
                        <div className='col-lg-8 text-start'>
                          <Field
                            type='number'
                            name='experience'
                            className='form-control form-control-solid'
                            value={values.experience}
                            onChange={handleChange}
                            placeholder={intl.formatMessage({id: 'LABEL.EXPERIENCE'})}
                          />
                          <ErrorMessage name='experience' component='div' className='text-danger' />
                        </div>
                      </div>

                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          {intl.formatMessage({id: 'LABEL.COUNTRY'})}
                        </label>
                        <div className='col-lg-8 text-start'>
                          <Field
                            as='select'
                            name='country_name'
                            className='form-select form-select-solid'
                            onChange={(e) => {
                              setCountryId(e.target.value)
                              handleChange(e)
                            }}
                          >
                            <option value=''>
                              {' '}
                              {intl.formatMessage({id: 'LABEL.SELECT_COUNTRY'})}
                            </option>
                            {countries?.map((each) => (
                              <option key={each.country_id} value={each.country_id}>
                                {each.country_name}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            name='country_name'
                            component='div'
                            className='text-danger'
                          />
                        </div>
                      </div>

                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          {intl.formatMessage({id: 'LABEL.STATE'})}
                        </label>
                        <div className='col-lg-8 text-start'>
                          <Field
                            as='select'
                            name='state_name'
                            className='form-select form-select-solid'
                            onChange={(e) => {
                              setStateId(e.target.value)
                              handleChange(e)
                            }}
                          >
                            <option value=''>
                              {' '}
                              {intl.formatMessage({id: 'LABEL.SELECT_STATE'})}
                            </option>
                            {states?.map((each) => (
                              <option key={each.state_id} value={each.state_id}>
                                {each.state_name}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage name='state_name' component='div' className='text-danger' />
                        </div>
                      </div>

                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          {intl.formatMessage({id: 'LABEL.CITY'})}
                        </label>
                        <div className='col-lg-8 text-start'>
                          <Field
                            as='select'
                            name='city_name'
                            className='form-select form-select-solid'
                          >
                            <option value=''>
                              {' '}
                              {intl.formatMessage({id: 'LABEL.SELECT_CITY'})}
                            </option>
                            {cities?.map((each) => (
                              <option key={each.city_id} value={each.city_id}>
                                {each.city_name}
                              </option>
                            ))}
                          </Field>

                          <ErrorMessage name='city_name' component='div' className='text-danger' />
                        </div>
                      </div>
                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          {intl.formatMessage({id: 'LABEL.SERVICE_AGREEMENT'})}
                        </label>
                        <div className='col-lg-8 text-start'>
                          <Field
                            type='number'
                            name='service_agreement'
                            className='form-control form-control-solid'
                            value={values.service_agreement}
                            placeholder={intl.formatMessage({id: 'LABEL.SERVICE_AGREEMENT'})}
                            onChange={handleChange}
                          />
                          <ErrorMessage
                            name='service_agreement'
                            component='div'
                            className='text-danger'
                          />
                        </div>
                      </div>
                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          {intl.formatMessage({id: 'LABEL.CURRENCY'})}
                        </label>{' '}
                        <div className='col-lg-8 text-start'>
                          <Field
                            as='select'
                            className='form-select form-select-solid'
                            id='floatingInput'
                            name='currency_name'
                            value={values.currency_name}
                            onChange={handleChange}
                          >
                            <option key='' value=''>
                              {intl.formatMessage({id: 'LABEL.SELECT_CURRENCY'})}
                            </option>

                            {currencytype?.map((currencyType) => (
                              <option
                                key={currencyType.currency_id}
                                value={currencyType.currency_id}
                              >
                                {currencyType.currency_name}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            name='currency_name'
                            component='div'
                            className='text-danger'
                          />
                        </div>
                      </div>
                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          {' '}
                          {intl.formatMessage({id: 'LABEL.MIN_SALARY'})}
                        </label>
                        <div className='col-lg-8 text-start'>
                          <Field
                            type='number'
                            name='salary_start'
                            className='form-control form-control-solid'
                            value={values.salary_start}
                            onChange={handleChange}
                            placeholder={intl.formatMessage({id: 'LABEL.MIN_SALARY'})}
                          />
                          <ErrorMessage
                            name='salary_start'
                            component='div'
                            className='text-danger'
                          />
                        </div>
                      </div>

                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          {' '}
                          {intl.formatMessage({id: 'LABEL.MAX_SALARY'})}
                        </label>
                        <div className='col-lg-8 text-start'>
                          <Field
                            type='number'
                            name='salary_end'
                            className='form-control form-control-solid'
                            value={values.salary_end}
                            onChange={handleChange}
                            placeholder={intl.formatMessage({id: 'LABEL.MAX_SALARY'})}
                          />
                          <ErrorMessage name='salary_end' component='div' className='text-danger' />
                        </div>
                      </div>

                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          Employment Type
                        </label>
                        <div className='col-lg-8 text-start'>
                          <Field
                            as='select'
                            className='form-select form-select-solid'
                            id='floatingInput'
                            name='employment_type_name'
                            value={values.employment_type_name}
                            onChange={handleChange}
                          >
                            <option key='' value=''>
                              {intl.formatMessage({id: 'LABEL.SELECT_EMPLOYMENT_TYPE'})}
                            </option>

                            {employmenttype?.map((employmentType) => (
                              <option
                                key={employmentType.employment_type_id}
                                value={employmentType.employment_type_name}
                              >
                                {employmentType.employment_type_name}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            name='employment_type_name'
                            component='div'
                            className='text-danger'
                          />
                        </div>
                      </div>

                      {/* <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>Status</label>
                        <div className='col-lg-8 text-start'>
                          <Field
                            type='text'
                            name='vacancy_status'
                            className='form-control form-control-solid'
                            value={values.vacancy_status}
                            onChange={handleChange}
                            placeholder='Status'
                          />
                          <ErrorMessage
                            name='vacancy_status'
                            component='div'
                            className='text-danger'
                          />
                        </div>
                      </div> */}

                      <div className='card-footer p-0 py-5 text-end'>
                        <button
                          type='submit'
                          className='btn btn-sm btn-light-primary ms-2'
                          onClick={() => {
                            setIsSubmitting(true)
                            setSelectedskills([])
                          }}
                        >
                          {intl.formatMessage({id: 'LABEL.ADD'})}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )
          }}
        </Formik>
      </div>
    </div>
  )
}
export default AddVacancy
