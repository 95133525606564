import DatePicker from 'react-datepicker'
import {useIntl} from 'react-intl'
import * as Yup from 'yup'
import {ErrorMessage, Field, Form, Formik} from 'formik'
import {FormikHelpers} from 'formik'
import Swal from 'sweetalert2'
import secureLocalStorage from 'react-secure-storage'
import {useParams} from 'react-router-dom'
import usePostApi from '../custom_hooks/usePostApi'
import useApiCall from '../custom_hooks/useApiCall'
import {baseUrl, defaultProfileImage} from '../core'
import {KTIcon} from '../../../_metronic/helpers'
import {useThemeMode} from '../../../_metronic/partials'
import {DateBasicFormat} from '../DateHelpers'
interface userDetails {
  first_name: string
  last_name: string
  designation_name: string
  location_name: string
  email: string
  employee_profile_pic: string
  hire_date: string
}
const RaiseRequest = (props: any) => {
  const intl = useIntl()
  const {execute} = usePostApi()
  const {mode} = useThemeMode()
  const {id}: any | null = useParams()
  const userInfo: any | null = secureLocalStorage.getItem('user_info')
  const {organization_id, emp_id} = userInfo || {}
  const {data, isLoading} = useApiCall('/employee/details', emp_id)
  const userData: userDetails | null = data ? (data[0] as userDetails) : null
  const validationSchema = Yup.object().shape({
    exit_reason: Yup.string().required('Exit Reason is Required'),
    exit_date: Yup.string().required('Request Exit Date is Required'),
    exit_comments: Yup.string().required('Exit Comments is Required'),
  })

  const handleSubmit = async (values: any, {resetForm}: FormikHelpers<any>) => {
    const url = `${baseUrl}/exit/details`
    try {
      const requestData = {
        organization_id: organization_id,
        employee_id: emp_id,
        exit_date: DateBasicFormat(values.exit_date),
        exit_type: 'voluntary',
        exit_reason: values.exit_reason,
        exit_comments: values.exit_comments,
      }
      await execute(url, 'POST', requestData, id)
      resetForm()
      props.props.fetchData()
      Swal.fire({
        title: 'Request Success',
        text: 'Your exit request has been submitted.',
        icon: 'success',
        showConfirmButton: true,
        customClass: {
          confirmButton: 'btn btn-light-primary btn-sm',
        },
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          const sideDrawer = document.getElementById('side_activities_close')
          if (sideDrawer) {
            sideDrawer.click()
          }
        }
      })
    } catch (error) {
      console.error(error)
      Swal.fire({
        icon: 'error',
        title: intl.formatMessage({id: 'LABEL.OOPS'}),
        text: intl.formatMessage({id: 'LABEL.SOMETHING_WRONG'}),
      })
    }
  }
  const handleCancel = (resetForm: any) => {
    resetForm()
  }
  return (
    <div>
      <div className='card shadow-none rounded-0'>
        <Formik
          initialValues={{
            exit_date: '',
            exit_comments: '',
            exit_reason: '',
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({values, handleChange, resetForm}) => {
            return (
              <Form>
                <div className='card-header' id='side_activities_header'>
                  <h3 className='card-title fw-bolder text-dark'>
                    {intl.formatMessage({id: 'LABEL.RAISE_REQUEST'})}
                  </h3>
                  <div className='card-toolbar'>
                    <button
                      type='button'
                      className='btn btn-sm btn-icon btn-active-light-primary me-n5'
                      id='side_activities_close'
                    >
                      <KTIcon iconName='cross' className='fs-1' />
                    </button>
                  </div>
                </div>
                <div className='card-body position-relative' id='side_activities_body'>
                  <div>
                    <div
                      id='side_activities_scroll'
                      className='position-relative me-n5 pe-5 w-400px'
                      data-kt-scroll='true'
                      data-kt-scroll-height='auto'
                      data-kt-scroll-wrappers='#side_activities_body'
                      data-kt-scroll-dependencies='#side_activities_header, #side_activities_footer'
                      data-kt-scroll-offset='5px'
                    >
                      {isLoading ? (
                        <div className='card card-xl-stretch'>
                          <div className='m-auto d-flex flex-column align-items-center'>
                            <div className='spinner-border spinner-primary mr-15'></div>
                          </div>
                        </div>
                      ) : (
                        <div className='d-flex align-items-start'>
                          <img
                            src={
                              userData?.employee_profile_pic
                                ? userData?.employee_profile_pic
                                : defaultProfileImage
                            }
                            alt='img'
                            className='activity-img rounded w-65px h-65px me-3'
                          />
                          <div className='mt-0 pt-0'>
                            <p className='text-dark fw-bold text-hover-primary m-0'>
                              {userData?.first_name} {userData?.last_name}
                            </p>
                            <p className='text-dark fw-bold text-hover-primary text-muted m-0 mt-1'>
                              <KTIcon iconName='profile-circle' className='fs-7 me-1' />
                              {userData?.designation_name}
                            </p>
                            <p className='text-dark fw-bold text-hover-primary  text-muted m-0'>
                              <KTIcon iconName='geolocation' className='fs-7 me-1' />
                              {userData?.location_name}
                            </p>
                          </div>
                        </div>
                      )}
                      <hr className='text-dark mt-4 mb-4' />
                      <div className='timeline'>
                        <div className='timeline-item'>
                          <div className='timeline-line w-40px'></div>
                          <div className='timeline-icon symbol symbol-circle symbol-40px'>
                            <div className='symbol-label bg-light'>
                              <KTIcon iconName='delivery-door' className='fs-2 text-gray-500' />
                            </div>
                          </div>
                          <div className='timeline-content mb-4 mt-n1'>
                            <div className='overflow-auto pe-3'>
                              <div className='fw-bold mb-2 required'>Exit Reason</div>
                              <div className='flex-fill'>
                                <Field
                                  as='select'
                                  name='exit_reason'
                                  className='form-select form-select-solid'
                                  value={values.exit_reason}
                                  onChange={handleChange}
                                >
                                  <option value=''>Select Exit Reason</option>
                                  <option value='Career Change'>Career Change</option>
                                  <option value='Relocation'>Relocation</option>
                                  <option value='Better Opportunity'>Better Opportunity</option>
                                  <option value='Work-Life Balance'>Work-Life Balance</option>
                                </Field>
                                <ErrorMessage
                                  component='div'
                                  className='text-danger'
                                  name='exit_reason'
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='timeline-item'>
                          <div className='timeline-line w-40px'></div>
                          <div className='timeline-icon symbol symbol-circle symbol-40px'>
                            <div className='symbol-label bg-light'>
                              <KTIcon iconName='calendar' className='fs-2 text-gray-500' />
                            </div>
                          </div>
                          <div className='timeline-content mb-8 mt-n2'>
                            <div className='overflow-auto pe-3'>
                              <div className='fw-bold mb-2 required'>Req. Last Working Date</div>
                              <div className='flex-fill'>
                                <Field name='exit_date'>
                                  {({form, field}: any) => {
                                    const {setFieldValue} = form
                                    const {value} = field
                                    const handleChange = (date: any) => {
                                      setFieldValue('exit_date', date)
                                    }
                                    return (
                                      <div
                                        className={`react-datepicker-fullwidth-wrapper test ${
                                          mode === 'dark' ? 'dark-mode' : 'light-mode'
                                        }`}
                                      >
                                        <DatePicker
                                          className='form-control form-control-solid'
                                          selected={value}
                                          isClearable={value !== null && value !== ''}
                                          showYearDropdown
                                          scrollableYearDropdown
                                          dateFormat='dd/MM/yyyy'
                                          onChange={(date) => {
                                            handleChange(date)
                                          }}
                                          placeholderText='Request Exit Date'
                                          showMonthDropdown
                                          useShortMonthInDropdown
                                          minDate={new Date()}
                                        />
                                      </div>
                                    )
                                  }}
                                </Field>
                                <ErrorMessage
                                  component='div'
                                  className='text-danger'
                                  name='exit_date'
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr className='text-dark mt-4 mb-4' />
                      <div className='fw-bold mb-2 required'>Exit Comments</div>
                      <div className='mb-4'>
                        <Field
                          as='textarea'
                          className='form-control form-control-solid'
                          id='floatingInput'
                          row={3}
                          name='exit_comments'
                        />
                        <ErrorMessage
                          component='div'
                          className='text-danger'
                          name='exit_comments'
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='card-footer text-end' id='side_activities_footer'>
                  <div
                    className='btn btn-sm btn-secondary m-2'
                    onClick={() => handleCancel(resetForm)}
                    id='side_activities_close'
                  >
                    {intl.formatMessage({id: 'LABEL.CANCEL'})}
                  </div>
                  <button className='btn btn-light-primary btn-sm' type='submit'>
                    <KTIcon iconName='check' className='fs-7' />
                    {intl.formatMessage({id: 'BTN.SAVE'})}
                  </button>
                </div>
              </Form>
            )
          }}
        </Formik>
      </div>
    </div>
  )
}

export {RaiseRequest}
