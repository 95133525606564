import {useEffect, useRef} from 'react'
import usePageTitle from '../../custom_hooks/usePageTitle'
import {useThemeMode} from '../../../../_metronic/partials'
import {getCSSVariableValue} from '../../../../_metronic/assets/ts/_utils'
import ApexCharts, {ApexOptions} from 'apexcharts'
function SalaryStructure() {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()

  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }
    const chart = new ApexCharts(chartRef.current, chartOptions('success', '200px'))
    if (chart) {
      chart.render()
    }
    return chart
  }
  useEffect(() => {
    const chart = refreshChart()
    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode])
  usePageTitle('Salary Structure')
  return (
    <div>
      <div className='d-flex flex-wrap flex-stack mb-6'>
        <h3 className='fw-bolder my-2'>Salary Structure</h3>
      </div>
      <div className='col-xl-8'>
        <div className='card mb-5 mb-xl-10'>
          <div className='flex-grow-1'>
            <div className='col-md-12 col-sm-12 mb-3'>
              <div className='row'>
                <div className='col-3 d-flex'>
                  <div ref={chartRef} className='mixed-widget-4-chart'></div>
                </div>
                <div className='col-6'>
                  <div className='card-header border-0'>
                    <div>
                      <div className='card-title align-items-start flex-column pt-4'>
                        <div className='column col-12'>
                          <label className='fw-bold text-muted col-5'>Monthly CTC</label>
                          <span className='fw-bolder text-dark text-hover-primary default-cursor col-7'>
                            AED 20,000
                          </span>
                        </div>
                        <div className='column mb-4 col-12'>
                          <label className='fw-bold text-muted col-5'>Yearly CTC</label>
                          <span className='fw-bolder text-dark text-hover-primary default-cursor col-7'>
                            AED 2,10,000
                          </span>
                        </div>
                      </div>
                      <div className='d-flex flex-row'>
                        <div className='d-flex'>
                          <span className='bullet bullet-vertical h-50px bg-success'></span>
                          <div className='flex-grow-1 ms-4'>
                            <p className='text-gray-800 text-hover-primary fw-bold'>Earnings</p>
                            <span className='text-muted fw-semibold d-block'> 20,000.00</span>
                          </div>
                        </div>
                        <div className='d-flex ms-6'>
                          <span className='bullet bullet-vertical h-50px bg-info'></span>
                          <div className='flex-grow-1 ms-4'>
                            <p className='text-gray-800 text-hover-primary fw-bold'>
                              Reimbursements
                            </p>
                            <span className='text-muted fw-semibold d-block'>0.00</span>
                          </div>
                        </div>
                        <div className='d-flex ms-6'>
                          <span className='bullet bullet-vertical h-50px bg-danger'></span>
                          <div className='flex-grow-1 ms-4'>
                            <p className='text-gray-800 text-hover-primary fw-bold'>Deductions</p>
                            <span className='text-muted fw-semibold d-block'>1,550.00</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='p-8 pt-0'>
            <table className='table table-bordered mb-8'>
              <thead>
                <tr>
                  <th colSpan={2} className='fw-bold text-muted'>
                    Earnings
                  </th>
                  <th colSpan={2} className='fw-bold text-muted'>
                    Deductions
                  </th>
                </tr>
                <tr>
                  <th className='text-center'>Component</th>
                  <th className='text-center'>Amount (AED)</th>
                  <th className='text-center'>Component</th>
                  <th className='text-center'>Amount (AED)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Basic Salary</td>
                  <td className='text-end'>15000</td>
                </tr>
                <tr>
                  <td>House Rent Allowance (HRA)</td>
                  <td className='text-end'>500</td>
                </tr>
                <tr>
                  <td>
                    <p></p>
                  </td>
                  <td>
                    <p></p>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th>Total Earnings (TE)</th>
                  <td className='text-end'>20,000</td>
                </tr>
                <tr>
                  <th>Total Reimbursements (TR)</th>
                  <td className='text-end'>0</td>
                  <th>Total Deductions (TD)</th>
                  <td className='text-end'>1550</td>
                </tr>
                <tr>
                  <th>Total Credit Adjustments (TCA)</th>
                  <td className='text-end'>0</td>
                  <th>Total Debit Adjustments (TDA)</th>
                  <td className='text-end'>0</td>
                </tr>
              </tfoot>
            </table>
            <table className='table table-bordered'>
              <tbody>
                <tr>
                  <td>Net Pay (TE - TD + TRA)</td>
                  <td>AED 18,450</td>
                </tr>
                <tr>
                  <td>Net Pay (in words)</td>
                  <td>eighteen thousand four hundred fifty United Arab Emirates Dirhams only.</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SalaryStructure
const chartOptions = (chartColor: string, chartHeight: string): ApexOptions => {
  const baseColor = getCSSVariableValue('--bs-' + chartColor)
  const lightColor = getCSSVariableValue('--bs-' + chartColor + '-light')
  const labelColor = getCSSVariableValue('--bs-gray-700')
  return {
    series: [99],
    chart: {
      fontFamily: 'inherit',
      height: chartHeight,
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 1,
          size: '60%',
        },
        dataLabels: {
          name: {
            show: false,
            fontWeight: '800',
          },
          value: {
            color: labelColor,
            fontSize: '15px',
            fontWeight: '700',
            offsetY: 10,
            show: true,
            formatter: function (val: any) {
              return 'Salary Breakup'
            },
          },
        },
        track: {
          background: lightColor,
          strokeWidth: '100%',
        },
      },
    },
    colors: [baseColor],
    stroke: {
      lineCap: 'round',
    },
    labels: ['Progress'],
  }
}
