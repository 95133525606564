/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC, useEffect} from 'react'
import {toAbsoluteUrl} from '../../../helpers'
import {useLang, setLanguage} from '../../../i18n/Metronici18n'
import {useThemeMode} from '../theme-mode/ThemeModeProvider'
import secureLocalStorage from 'react-secure-storage'
import {baseUrl} from '../../../../app/modules/core'
import usePostApi from '../../../../app/modules/custom_hooks/usePostApi'
import {useParams} from 'react-router-dom'
const languages = [
  {
    lang: 'en',
    name: 'English',
    flag: toAbsoluteUrl('/media/ISOcodeFlags/en.svg'),
  },
  {
    lang: 'ar',
    name: 'عربي',
    dir: 'rtl',
    flag: toAbsoluteUrl('/media/ISOcodeFlags/ar.svg'),
  },
  {
    lang: 'es',
    name: 'Spanish',
    flag: toAbsoluteUrl('/media/ISOcodeFlags/es.svg'),
  },
  {
    lang: 'fr',
    name: 'French',
    flag: toAbsoluteUrl('/media/ISOcodeFlags/fr.svg'),
  },
]

const Languages: FC = () => {
  const lang = useLang()
  const {mode} = useThemeMode()
  const {execute} = usePostApi()
  const {id}: any | null = useParams()
  const userInfo: any | null = secureLocalStorage.getItem('user_info')
  const {organization_id, user_id} = userInfo || {}
  const currentLanguage = languages.find((x) => x.lang === lang)
  useEffect(() => {
    document.body.dir = currentLanguage?.dir || 'ltr'
    document.body.setAttribute('dir', currentLanguage?.dir || 'ltr')
  }, [currentLanguage])
  const updateLanguage = async (lang: string, dir: string) => {
    const url = `${baseUrl}/pref/user/${user_id}`
    try {
      const requestData = {
        dir: dir,
        lang: lang,
        theme_mode: mode,
        organization_id: organization_id,
      }
      await execute(url, 'PATCH', requestData, id)
    } catch (error) {
      console.error(error)
    }
  }
  return (
    <div
      className='menu-item px-5'
      data-kt-menu-trigger='hover'
      data-kt-menu-placement='left-start'
      data-kt-menu-flip='bottom'
    >
      <a href='#' className='menu-link px-5'>
        <span className='menu-title position-relative'>
          Language
          <span className='fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0'>
            {currentLanguage?.name}{' '}
            <img
              className='w-15px h-15px rounded-1 ms-2'
              src={currentLanguage?.flag}
              alt='metronic'
            />
          </span>
        </span>
      </a>

      <div className='menu-sub menu-sub-dropdown w-175px py-4'>
        {languages.map((l) => (
          <div
            className='menu-item px-3'
            key={l.lang}
            onClick={() => {
              setLanguage(l.lang, l.dir == null ? 'ltr' : l.dir)
              updateLanguage(l.lang, l.dir == null ? 'ltr' : l.dir)
            }}
          >
            <a
              href='#'
              className={clsx('menu-link d-flex px-5', {active: l.lang === currentLanguage?.lang})}
            >
              <span className='symbol symbol-20px me-4'>
                <img className='rounded-1' src={l.flag} alt='metronic' />
              </span>
              {l.name}
            </a>
          </div>
        ))}
      </div>
    </div>
  )
}

export {Languages}
