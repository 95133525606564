import {useIntl} from 'react-intl'
import useApiCall from '../../../custom_hooks/useApiCall'
import EmployeeID from './EmployeeID'
const IdGeneration = () => {
  const intl = useIntl()
  const {data, isLoading} = useApiCall(`/pref/idGeneration`)
  return (
    <>
      {isLoading ? (
        <div className='card card-xl-stretch h-250px'>
          <div className='m-auto d-flex flex-column align-items-center'>
            <div className='spinner-border spinner-primary mr-15'></div>
          </div>
        </div>
      ) : (
        <div className='card card-xl-stretch'>
          <div className='card-header'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold fs-5 mb-1'>
                {intl.formatMessage({id: 'LABEL.ID_GENERATION_PREFERENCES'})}
              </span>
              <span className='text-muted fw-bold fs-7'>
                {intl.formatMessage({id: 'LABEL.AUTOMATICALLY_GENERATE_IDENTIFIERS'})}
              </span>
            </h3>
          </div>
          <div className='card-body pt-4 table-responsive'>
            <table className='table table-row-dashed table-row-gray-300 align-middle'>
              <thead>
                <tr className='fw-bold text-muted'>
                  <th className='min-w-120px'>{intl.formatMessage({id: 'LABEL.NAME'})}</th>
                  <th className='min-w-120px'>Start (Prefix)</th>
                  <th className='min-w-120px'>Middle</th>
                  <th className='min-w-120px'>End (Auto)</th>
                  <th className='min-w-120px'>Divider</th>
                  <th className='min-w-120px'>Preview</th>
                  <th className='min-w-120px'>Action</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((employeeID) => (
                  <EmployeeID
                    key={employeeID.igp_id}
                    value={{employeeID, name: employeeID.id_type}}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  )
}

export default IdGeneration
