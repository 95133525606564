import OffersTable from './OffersTable.jsx'

function Offers() {
  return (
    <>
      <div className='d-flex'>
        <div className='d-flex flex-wrap flex-stack mb-6'>
          <h3 className='fw-bolder my-2'>Offers</h3>
        </div>
      </div>

      <div className='card mb-5 mb-xl-10'>
        <OffersTable />
      </div>
    </>
  )
}

export default Offers
