import {useIntl} from 'react-intl'
import DateFormatter from '../../../DateFormatter'

function Finance({financerDetails}) {
  const intl = useIntl()
  const {
    manager_reviewed_status = null,
    hr_reviewed_status = null,
    finance_reviewed_status = null,
    finance_reviewed_amount = 0,
    finance_review_comments = null,
    finance_reviewed_timestamp = '',
    currency_code = null,
    itemStatus = null,
  } = financerDetails

  const getFinancerView = (itemStatus, financerStatus) => {
    if (itemStatus === 'Finance Pending') {
      return null
    } else if (financerStatus === 'Approved' || financerStatus === 'Rejected') {
      return (
        <>
          <p className='m-0 text-muted default-cursor'>
            {finance_reviewed_timestamp ? (
              <>
                {finance_reviewed_timestamp.length >= 10 &&
                  DateFormatter(finance_reviewed_timestamp.slice(0, 10))}{' '}
                {finance_reviewed_timestamp.length >= 19 &&
                  finance_reviewed_timestamp.slice(10, 19)}
              </>
            ) : (
              '-'
            )}
          </p>
          <p className='m-0 text-muted default-cursor'>{finance_review_comments}</p>
        </>
      )
    } else {
      return null
    }
  }

  const getStatusMsg = (itemStatus, financerStatus) => {
    if (itemStatus === 'Manager Pending') {
      return 'LABLE.AWAITING_MANAGERS_ACTION'
    } else if (itemStatus === 'HR Pending') {
      return 'LABEL.AWAITING_HRS_ACTION'
    } else if (
      (itemStatus === 'Rejected' && financerStatus === null) ||
      (financerStatus === null && itemStatus === 'Revoked')
    ) {
      return 'LABLE.NO_ACTION'
    } else if (financerStatus === 'Rejected') {
      return 'LABEL.REJECTED'
    } else if (financerStatus === 'Approved') {
      return 'LABEL.APPROVED'
    } else {
      return 'LABEL.PENDING'
    }
  }

  const getStatusColor = (itemStatus, financerStatus) => {
    if (
      (itemStatus === 'Rejected' && financerStatus === null) ||
      (financerStatus === null && itemStatus === 'Revoked')
    ) {
      return 'secondary'
    } else if (
      itemStatus === 'Manager Pending' ||
      itemStatus === 'HR Pending' ||
      itemStatus === 'Finance Pending'
    ) {
      return 'muted'
    } else if (financerStatus === 'Approved') {
      return 'success'
    } else {
      return 'danger'
    }
  }

  const getApprovedAmount = () => {
    if (finance_reviewed_status === 'Approved') {
      return ` (${currency_code}: ${finance_reviewed_amount})`
    }
  }

  return (
    <div className='timeline-item'>
      <div className='timeline-line w-20px'></div>

      <div className='timeline-icon symbol symbol-circle symbol-20px'>
        <div
          className={`symbol-label bg-${getStatusColor(
            itemStatus,
            finance_reviewed_status
          )} icon-dotted-border text-${getStatusColor(itemStatus, finance_reviewed_status)}`}
        ></div>
      </div>

      <div className='timeline-content mb-12 mt-n1'>
        <div className='d-flex justify-content-between mt-1'>
          <p
            className={`mb-0 fw-bold text-${getStatusColor(
              itemStatus,
              finance_reviewed_status
            )} default-cursor`}
          >
            {intl.formatMessage({
              id: getStatusMsg(itemStatus, finance_reviewed_status),
            })}
            <span className='text-muted fw-normal'>{getApprovedAmount()}</span>
          </p>
          <div className=''>
            <p className='designation-btn bg-secondary fs-7 m-auto default-cursor'>
              {intl.formatMessage({id: 'LABEL.FINANCE'})}
            </p>
          </div>
        </div>
        {getFinancerView(itemStatus, finance_reviewed_status)}
      </div>
    </div>
  )
}

export default Finance
