/* eslint-disable jsx-a11y/anchor-is-valid */
import BusinessBlock from './BusinessBlock'
import {useIntl} from 'react-intl'
function Organization_business() {
  const intl = useIntl()
  return (
    <>
      <div className='d-flex flex-wrap flex-stack mb-6'>
        <h3 className='fw-bolder my-2'>
          {intl.formatMessage({id: 'LABEL.ORGANIZATION_BUSINESS_UNITS'})}
        </h3>
      </div>
      <div className='card mb-5 mb-xl-10'>
        <BusinessBlock />
      </div>
    </>
  )
}

export default Organization_business
