import React, {useEffect, useState} from 'react'
import {KTIcon, KTSVG} from '../../../../_metronic/helpers'
import {useIntl} from 'react-intl'
import * as Yup from 'yup'
import {ErrorMessage, Field, Form, Formik} from 'formik'
import Swal from 'sweetalert2'
import {baseUrl} from '../../core'
import secureLocalStorage from 'react-secure-storage'
import usePostApi from '../../custom_hooks/usePostApi'
import {useParams} from 'react-router-dom'
import useApiCall from '../../custom_hooks/useApiCall'
const EditLocation = (props: any) => {
  const intl = useIntl()
  const {execute} = usePostApi()
  const {id}: any | null = useParams()
  const {data: countriesData}: any | null = useApiCall(`/country`)
  const {data, isLoading, fetchData}: any | null = useApiCall(
    `/organization/locations/${props.props.id}`
  )
  const brandLogos: any | null = secureLocalStorage.getItem('brand_logos')
  const {brand_logo_url} = brandLogos || {}
  const brandName = localStorage.getItem('displayName') || 'Kloudworx'
  const {data: organizationAddress}: any | null = useApiCall(`/organization/1`)
  const orgLocationName = organizationAddress && organizationAddress[0]
  let locationData = data && data[0]
  const userInfo: any | null = secureLocalStorage.getItem('user_info')
  const {is_admin, is_hr, organization_id} = userInfo || {}
  const [isEditMode, setIsEditMode] = useState(false)
  const [locationName, setLocationName] = useState<string>('')
  const [locationCode, setLocationCode] = useState<string>('')
  const [locationTaxId, setLocationTaxId] = useState<string>('')
  const [addressLine1, setAddressLine1] = useState<string>('')
  const [addressLine2, setAddressLine2] = useState<string>('')
  const [postalCode, setPostalCode] = useState<string>('')
  const [isChecked, setIsChecked] = useState<boolean>(true)
  const [countryId, setCountryId]: any | null = useState(null)
  const [stateId, setStateId]: any | null = useState(null)
  const [cityId, setCityId]: any | null = useState(null)
  const {data: states, fetchData: fetchStates}: any | null = useApiCall(
    countryId
      ? `/state/${countryId}`
      : locationData?.state_id
      ? `/state/${locationData?.country_id}`
      : null
  )
  const {data: citiesData, fetchData: fetchCities}: any | null = useApiCall(
    stateId ? `/city/${stateId}` : locationData?.city_id ? `/city/${locationData?.state_id}` : null
  )
  let cities: any | null = citiesData ? citiesData : null
  useEffect(() => {
    fetchStates()
    cities = []
  }, [countryId])
  useEffect(() => {
    fetchCities()
  }, [stateId])
  useEffect(() => {
    if (locationData) {
      setLocationName(locationData?.location_name)
      setLocationCode(locationData?.location_code)
      setLocationTaxId(locationData?.location_tax_id)
      setAddressLine1(locationData?.address_line1)
      setAddressLine2(locationData?.address_line2)
      setPostalCode(locationData?.postal_code)
      setIsChecked(locationData?.status === 1)
    }
  }, [locationData])

  const validationSchema = Yup.object().shape({
    location_name: Yup.string().required(intl.formatMessage({id: 'LABEL.LOCATION_NAME_REQUIRED'})),
    location_code: Yup.string().required(intl.formatMessage({id: 'LABEL.LOCATION_CODE_REQUIRED'})),
    location_tax_id: Yup.string().required(
      intl.formatMessage({id: 'LABEL.LOCATION_TAXID_REQUIRED'})
    ),
    address_line1: Yup.string().required(intl.formatMessage({id: 'LABEL.ADDRESS_LINE1_REQUIRED'})),
    address_line2: Yup.string().required(intl.formatMessage({id: 'LABEL.ADDRESS_LINE2_REQUIRED'})),
    country_name: Yup.string().required(intl.formatMessage({id: 'LABEL.COUNTRY_NAME_REQUIRED'})),
    postal_code: Yup.string().required(intl.formatMessage({id: 'LABEL.POSTAL_CODE_REQUIRED'})),
    status: Yup.boolean().required(intl.formatMessage({id: 'LABEL.STATUS_REQUIRED'})),
  })
  const initialValues = {
    location_name: locationName || '',
    location_code: locationCode || '',
    location_tax_id: locationTaxId || '',
    address_line1: addressLine1 || '',
    address_line2: addressLine2 || '',
    state_name: stateId || '',
    city_name: cityId || '',
    country_name: countryId || '',
    postal_code: postalCode || '',
    status: isChecked || false,
  }
  const handleEditSubmit = async (values: any) => {
    const url = `${baseUrl}/organization/locations/${props.props.id}`
    try {
      const requestData = {
        organization_id: organization_id,
        address_id: locationData?.address_id,
        location_name: values.location_name,
        location_code: values.location_code,
        location_tax_id: values.location_tax_id,
        address_line1: values.address_line1,
        address_line2: values.address_line2,
        state_id: parseInt(stateId) || 0,
        city_id: parseInt(cityId) || 0,
        country_id: parseInt(countryId),
        postal_code: values.postal_code,
        status: values.status ? 1 : 0,
      }
      await execute(url, 'PATCH', requestData, id)
      setIsEditMode(false)
      fetchData()
      props.props.fetchData()
      Swal.fire({
        title: 'Location Edited Successfully',
        text: intl.formatMessage({id: 'LABEL.CHANGES_SAVED'}),
        icon: 'success',
        showConfirmButton: true,
        customClass: {
          confirmButton: 'btn btn-light-primary btn-sm',
        },
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          const sideDrawer = document.getElementById('side_activities_close')
          if (sideDrawer) {
            sideDrawer.click()
          }
        }
      })
    } catch (error) {
      console.error(error)
      Swal.fire({
        icon: 'error',
        title: intl.formatMessage({id: 'LABEL.OOPS'}),
        text: intl.formatMessage({id: 'LABEL.SOMETHING_WRONG'}),
      })
    }
  }

  useEffect(() => {
    fetchData()
  }, [props.props.id])
  useEffect(() => {
    if (locationData) {
      setIsChecked(locationData?.status === 1)
      setStateId(locationData?.state_id)
      setCountryId(locationData?.country_id)
      setCityId(locationData?.city_id)
    }
  }, [locationData?.country_id])
  const handleChangeMethod = (selectedValue: any) => {
    setCountryId(selectedValue)
    setStateId(null)
    setCityId(null)
  }
  const handleChangeState = (selectedValue: any) => {
    setStateId(selectedValue)
    setCityId(null)
  }
  const handleChangeCity = (selectedValue: any) => {
    setCityId(selectedValue)
  }

  const handleEditClick = () => {
    setIsEditMode(true)
  }

  const handleSaveClick = () => {
    setIsEditMode(false)
    // Add your save logic here
  }
  return (
    <div>
      <div className='card shadow-none rounded-0'>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={handleEditSubmit}
        >
          {({values, handleChange}) => {
            return (
              <Form>
                <div className='card-header' id='side_activities_header'>
                  <h3 className='card-title fw-bolder text-dark'>
                    {is_admin === 1
                      ? intl.formatMessage({id: 'LABEL.EDIT_LOCATION'})
                      : 'Location Information'}
                  </h3>
                  {is_admin === 1 && (
                    <div className='card-toolbar '>
                      {!isEditMode ? (
                        <button
                          type='button'
                          className='btn btn-sm btn-light-primary px-4 me-3'
                          data-kt-menu-trigger='click'
                          data-kt-menu-placement='bottom-start'
                          data-kt-menu-flip='top-end'
                          onClick={handleEditClick}
                        >
                          <KTIcon iconName='pencil' className='fs-3' />
                          {intl.formatMessage({id: 'BTN.EDIT'})}
                        </button>
                      ) : (
                        <React.Fragment>
                          <div
                            className='btn btn-icon btn-sm btn-active-danger'
                            onClick={handleSaveClick}
                            id='side_activities_close'
                          >
                            <KTSVG
                              path='/media/icons/duotune/arrows/arr061.svg'
                              className='svg-icon svg-icon-2x'
                            />
                          </div>
                        </React.Fragment>
                      )}
                    </div>
                  )}
                </div>
                <div className='card-body position-relative' id='side_activities_body'>
                  <div>
                    <div
                      id='side_activities_scroll'
                      className='position-relative scroll-y me-n5 pe-5 w-400px'
                      data-kt-scroll='true'
                      data-kt-scroll-height='auto'
                      data-kt-scroll-wrappers='#side_activities_body'
                      data-kt-scroll-dependencies='#side_activities_header, #side_activities_footer'
                      data-kt-scroll-offset='5px'
                    >
                      {isEditMode ? (
                        <>
                          <div className='d-flex align-items-start'>
                            <img
                              src={brand_logo_url}
                              alt='img'
                              className='activity-img rounded w-65px h-65px me-3'
                            />
                            <div className='mt-0 pt-0'>
                              <p className='text-dark fw-bold text-hover-primary fs-5 m-0'>
                                {brandName}
                              </p>
                              <p className='text-dark fw-bold text-hover-primary text-muted m-0 mt-1'>
                                <KTIcon iconName='geolocation' className='fs-7 me-1' />
                                {orgLocationName?.country_name}
                              </p>
                              <p className='text-dark fw-bold text-hover-primary text-muted m-0'>
                                <KTIcon iconName='bank' className='fs-7 me-1' />
                                {orgLocationName?.address_line1}
                              </p>
                            </div>
                          </div>
                          <hr className='text-dark mt-4 mb-4' />
                          <div className='timeline'>
                            <div className='timeline-item'>
                              <div className='timeline-line w-40px'></div>
                              <div className='timeline-icon symbol symbol-circle symbol-40px'>
                                <div className='symbol-label bg-light'>
                                  <KTIcon
                                    iconName='geolocation-home'
                                    className='fs-2 text-gray-500'
                                  />
                                </div>
                              </div>
                              <div className='timeline-content mb-6 mt-n1'>
                                <div className='overflow-auto pe-3'>
                                  <div className='fw-bold mb-2 required'>Location Name</div>
                                  <div className='flex-fill'>
                                    <Field
                                      type='text'
                                      className='form-control form-control-solid'
                                      id='floatingInput'
                                      name='location_name'
                                      value={values.location_name}
                                      onChange={handleChange}
                                    />
                                    <ErrorMessage
                                      component='div'
                                      className='text-danger'
                                      name='location_name'
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='timeline-item'>
                              <div className='timeline-line w-40px'></div>
                              <div className='timeline-icon symbol symbol-circle symbol-40px'>
                                <div className='symbol-label bg-light'>
                                  <KTIcon
                                    iconName='two-credit-cart'
                                    className='fs-2 text-gray-500'
                                  />
                                </div>
                              </div>
                              <div className='timeline-content mb-6 mt-n2'>
                                <div className='overflow-auto pe-3'>
                                  <div className='fw-bold mb-2 required'>Location Tax ID</div>
                                  <div className='flex-fill'>
                                    <Field
                                      type='text'
                                      className='form-control form-control-solid'
                                      id='floatingInput'
                                      placeholder='type...'
                                      name='location_tax_id'
                                      value={values.location_tax_id}
                                      onChange={handleChange}
                                    />
                                    <ErrorMessage
                                      component='div'
                                      className='text-danger'
                                      name='location_tax_id'
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='timeline-item'>
                              <div className='timeline-line w-40px'></div>
                              <div className='timeline-icon symbol symbol-circle symbol-40px'>
                                <div className='symbol-label bg-light'>
                                  <KTIcon iconName='barcode' className='fs-2 text-gray-500' />
                                </div>
                              </div>
                              <div className='timeline-content mb-4 mt-n3'>
                                <div className='overflow-auto pe-3'>
                                  <div className='fw-bold mb-2 required'>Location CODE</div>
                                  <div className='flex-fill'>
                                    <Field
                                      type='text'
                                      className='form-control form-control-solid'
                                      id='floatingInput'
                                      placeholder='type...'
                                      name='location_code'
                                      value={values.location_code}
                                      onChange={handleChange}
                                    />
                                    <ErrorMessage
                                      component='div'
                                      className='text-danger'
                                      name='location_code'
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <hr className='text-dark mt-4 mb-4' />
                          <div className='d-flex mb-4'>
                            <div className=''>
                              <div className='fw-bold mb-2 required'>
                                {intl.formatMessage({id: 'LABEL.ADDRESS_LINE-1'})}
                              </div>
                              <Field
                                type='text'
                                className='form-control form-control-solid'
                                id='floatingInput'
                                name='address_line1'
                                value={values.address_line1}
                                onChange={handleChange}
                              />
                              <ErrorMessage
                                component='div'
                                className='text-danger'
                                name='address_line1'
                              />
                            </div>
                            <div className='ms-2 flex-fill'>
                              <div className='fw-bold mb-2 required'>
                                {intl.formatMessage({id: 'LABEL.ADDRESS_LINE-2'})}
                              </div>
                              <Field
                                type='text'
                                className='form-control form-control-solid'
                                id='floatingInput'
                                name='address_line2'
                                value={values.address_line2}
                                onChange={handleChange}
                              />
                              <ErrorMessage
                                component='div'
                                className='text-danger'
                                name='address_line2'
                              />
                            </div>
                          </div>
                          <div className='my-2'>
                            <div className='fw-bold mb-2 required'>
                              {intl.formatMessage({id: 'LABEL.COUNTRY'})}
                            </div>
                            <Field
                              as='select'
                              id='floatingInput'
                              className='form-select form-select-solid'
                              name='country_name'
                              value={countryId ? countryId : ''}
                              onChange={(e: any) => handleChangeMethod(e.target.value)}
                            >
                              <option value=''>
                                {intl.formatMessage({id: 'LABEL.SELECT_COUNTRY'})}
                              </option>
                              {countriesData?.map((option: any) => (
                                <option key={option.country_id} value={option.country_id}>
                                  {option.country_name}
                                </option>
                              ))}
                            </Field>
                            <ErrorMessage
                              component='div'
                              className='text-danger'
                              name='country_name'
                            />
                          </div>
                          {(states?.length > 0 || locationData?.state_id) && (
                            <div className='my-2'>
                              <div className='fw-bold mb-2'>
                                {intl.formatMessage({id: 'LABEL.STATE'})}
                              </div>
                              <Field
                                as='select'
                                id='floatingInput'
                                className='form-select form-select-solid'
                                name='state_name'
                                value={stateId ? stateId : ''}
                                onChange={(e: any) => handleChangeState(e.target.value)}
                              >
                                <option value=''>
                                  {intl.formatMessage({id: 'LABEL.SELECT_STATE'})}
                                </option>
                                {states?.map((option: any) => (
                                  <option key={option.state_id} value={option.state_id}>
                                    {option.state_name}
                                  </option>
                                ))}
                              </Field>
                              <ErrorMessage
                                component='div'
                                className='text-danger'
                                name='state_name'
                              />
                            </div>
                          )}
                          {cities?.length > 0 && stateId && (
                            <div className='my-2'>
                              <div className='fw-bold mb-2'>
                                {intl.formatMessage({id: 'LABEL.CITY'})}
                              </div>
                              <Field
                                as='select'
                                id='floatingInput'
                                className='form-select form-select-solid'
                                name='city_name'
                                value={cityId ? cityId : ''}
                                onChange={(e: any) => handleChangeCity(e.target.value)}
                              >
                                <option value=''>
                                  {intl.formatMessage({id: 'LABEL.SELECT_CITY'})}
                                </option>
                                {cities?.map((option: any) => (
                                  <option key={option.city_id} value={option.city_id}>
                                    {option.city_name}
                                  </option>
                                ))}
                              </Field>
                              <ErrorMessage
                                component='div'
                                className='text-danger'
                                name='city_name'
                              />
                            </div>
                          )}
                          <div className='my-2'>
                            <div className='fw-bold mb-2 required'>
                              {intl.formatMessage({id: 'LABEL.POSTAL_CODE'})}
                            </div>
                            <Field
                              type='text'
                              className='form-control form-control-solid'
                              id='floatingInput'
                              name='postal_code'
                              value={values.postal_code}
                              onChange={handleChange}
                            />
                            <ErrorMessage
                              component='div'
                              className='text-danger'
                              name='postal_code'
                            />
                          </div>
                          <div className='form-floating my-2'>
                            <div className='form-check form-check-solid form-switch'>
                              <Field
                                className='form-check-input w-40px h-20px'
                                type='checkbox'
                                id='toggle'
                                name='status'
                                checked={values.status}
                                onChange={handleChange}
                              />
                              <span
                                className={`me-4 fw-bold ${
                                  values.status ? 'text-success' : 'text-danger'
                                }`}
                              >
                                {values.status ? 'Active' : 'Inactive'}
                              </span>
                            </div>

                            <ErrorMessage component='div' className='text-danger' name='status' />
                          </div>
                        </>
                      ) : (
                        <div>
                          {isLoading ? (
                            <div className='card h-250px'>
                              <div className='m-auto d-flex flex-column align-items-center'>
                                <div className='spinner-border spinner-primary mr-15'></div>
                              </div>
                            </div>
                          ) : (
                            <div className='card-body ms-0 ps-0 mt-0 pt-0'>
                              <div className='d-flex align-items-start'>
                                <img
                                  src={brand_logo_url}
                                  alt='img'
                                  className='activity-img rounded w-65px h-65px me-3'
                                />
                                <div className='mt-0 pt-0'>
                                  <p className='text-dark fw-bold text-hover-primary m-0 fs-5'>
                                    {brandName}
                                  </p>
                                  <p className='text-dark fw-bold text-hover-primary text-muted m-0 mt-1'>
                                    <KTIcon iconName='geolocation' className='fs-7 me-1' />
                                    {orgLocationName?.country_name}
                                  </p>
                                  <p className='text-dark fw-bold text-hover-primary text-muted m-0'>
                                    <KTIcon iconName='bank' className='fs-7 me-1' />
                                    {orgLocationName?.address_line1}
                                  </p>
                                </div>
                              </div>
                              <hr className='text-dark mt-4 mb-4' />
                              <div className='timeline'>
                                <div className='timeline-item'>
                                  <div className='timeline-line w-40px'></div>
                                  <div className='timeline-icon symbol symbol-circle symbol-40px'>
                                    <div className='symbol-label bg-light'>
                                      <KTIcon
                                        iconName='geolocation-home'
                                        className='fs-2 text-gray-500'
                                      />
                                    </div>
                                  </div>
                                  <div className='timeline-content mb-6 mt-n1'>
                                    <div className='overflow-auto pe-3'>
                                      <div className='fw-bold text-muted'>Location Name</div>
                                      <span className='fw-bolder text-dark'>{locationName}</span>
                                    </div>
                                  </div>
                                </div>
                                <div className='timeline-item'>
                                  <div className='timeline-line w-40px'></div>
                                  <div className='timeline-icon symbol symbol-circle symbol-40px'>
                                    <div className='symbol-label bg-light'>
                                      <KTIcon
                                        iconName='two-credit-cart'
                                        className='fs-2 text-gray-500'
                                      />
                                    </div>
                                  </div>
                                  <div className='timeline-content mb-6 mt-n2'>
                                    <div className='overflow-auto pe-3'>
                                      <div className='fw-bold text-muted'>Location Tax ID</div>
                                      <span className='fw-bolder text-dark'>{locationTaxId}</span>
                                    </div>
                                  </div>
                                </div>
                                <div className='timeline-item'>
                                  <div className='timeline-line w-40px'></div>
                                  <div className='timeline-icon symbol symbol-circle symbol-40px'>
                                    <div className='symbol-label bg-light'>
                                      <KTIcon iconName='barcode' className='fs-2 text-gray-500' />
                                    </div>
                                  </div>
                                  <div className='timeline-content mb-4 mt-n3'>
                                    <div className='overflow-auto pe-3'>
                                      <div className='fw-bold text-muted'>Location CODE</div>
                                      <span className='fw-bolder text-dark'>{locationCode}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <hr className='text-dark mt-4 mb-4' />
                              <div className='row mb-4'>
                                <label className='col-lg-5 fw-bold text-muted'>
                                  {intl.formatMessage({id: 'LABEL.ADDRESS_LINE-1'})}
                                </label>
                                <div className='col-lg-7 d-flex align-items-center'>
                                  <span className='fw-bolder me-2'>{addressLine1}</span>
                                </div>
                              </div>
                              <div className='row mb-4'>
                                <label className='col-lg-5 fw-bold text-muted'>
                                  {intl.formatMessage({id: 'LABEL.ADDRESS_LINE-2'})}
                                </label>
                                <div className='col-lg-7 d-flex align-items-center'>
                                  <span className='fw-bolder me-2'>{addressLine2}</span>
                                </div>
                              </div>
                              <div className='row mb-4'>
                                <label className='col-lg-5 fw-bold text-muted'>
                                  {intl.formatMessage({id: 'LABEL.SELECT_COUNTRY'})}
                                </label>
                                <div className='col-lg-7 d-flex align-items-center'>
                                  <span className='fw-bolder me-2'>
                                    {locationData?.country_name}
                                  </span>
                                </div>
                              </div>
                              {locationData?.state_name && (
                                <div className='row mb-4'>
                                  <label className='col-lg-5 fw-bold text-muted'>
                                    {intl.formatMessage({id: 'LABEL.SELECT_STATE'})}
                                  </label>
                                  <div className='col-lg-7 d-flex align-items-center'>
                                    <span className='fw-bolder me-2'>
                                      {locationData?.state_name}
                                    </span>
                                  </div>
                                </div>
                              )}
                              {locationData?.city_name && (
                                <div className='row mb-4'>
                                  <label className='col-lg-5 fw-bold text-muted'>
                                    {intl.formatMessage({id: 'LABEL.SELECT_CITY'})}
                                  </label>
                                  <div className='col-lg-7 d-flex align-items-center'>
                                    <span className='fw-bolder me-2'>
                                      {locationData?.city_name}
                                    </span>
                                  </div>
                                </div>
                              )}
                              <div className='row mb-4'>
                                <label className='col-lg-5 fw-bold text-muted'>
                                  {intl.formatMessage({id: 'LABEL.POSTAL_CODE'})}
                                </label>
                                <div className='col-lg-7 d-flex align-items-center'>
                                  <span className='fw-bolder me-2'>{postalCode}</span>
                                </div>
                              </div>
                              <div className='row mb-4'>
                                <label className='col-lg-5 fw-bold text-muted'>
                                  {intl.formatMessage({id: 'LABEL.STATUS'})}
                                </label>
                                <div className='col-lg-7 d-flex align-items-center'>
                                  <span className='fw-bolder me-2'>
                                    {locationData?.status === 1 ? 'Active' : 'Inactive'}
                                  </span>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className='card-footer text-end' id='side_activities_footer'>
                  {isEditMode ? (
                    <>
                      {!isEditMode ? (
                        <button
                          className='btn btn-sm btn-secondary m-2'
                          type='button'
                          id='side_activities_close'
                          onClick={handleSaveClick}
                        >
                          {intl.formatMessage({id: 'BTN.CLOSE'})}
                        </button>
                      ) : (
                        <div className='btn btn-sm btn-secondary m-2' onClick={handleSaveClick}>
                          {intl.formatMessage({id: 'LABEL.CANCEL'})}
                        </div>
                      )}
                      <button
                        className='btn  btn-sm btn-primary'
                        type='submit'
                        disabled={!isEditMode}
                      >
                        {intl.formatMessage({id: 'BTN.SAVE'})}
                      </button>
                    </>
                  ) : (
                    <button
                      className='btn btn-sm btn-secondary m-2 mt-0'
                      type='button'
                      id='side_activities_close'
                      onClick={handleSaveClick}
                    >
                      {intl.formatMessage({id: 'BTN.CLOSE'})}
                    </button>
                  )}
                </div>
              </Form>
            )
          }}
        </Formik>
      </div>
    </div>
  )
}

export {EditLocation}
