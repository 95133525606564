import {useEffect, useState} from 'react'
import Swal from 'sweetalert2'
import {useIntl} from 'react-intl'
import secureLocalStorage from 'react-secure-storage'
// Make sure to import axios
import {baseUrl} from '../../../core'
import usePostApi from '../../../custom_hooks/usePostApi'
function PerformanceManager({reviewerDetails}) {
  const {execute} = usePostApi()
  const {
    reviewer_status = null,
    reviewer_comments = '',
    employee_performance_id = '',
    reviewsData,
    fetchData = () => {},
  } = reviewerDetails

  // Define managerComment state
  const [managerComment, setmanagerComment] = useState('')
  const [comError, setComError] = useState(false)
  const [comLenErr, setComLenErr] = useState(false)

  let emp_id
  let organization_id
  let role
  let is_manager
  const userInfo = secureLocalStorage.getItem('user_info')
  if (userInfo) {
    ;({emp_id, organization_id, role, is_manager} = userInfo)
  }

  const intl = useIntl()

  useEffect(() => {
    if (managerComment !== '') {
      setComError(false)
    }
    if (managerComment.length >= 3) {
      setComLenErr(false)
    }
  }, [managerComment])

  const approveAndRejectBtns = async (event) => {
    if (managerComment === '') {
      setComError(true)
      return
    } else if (managerComment.length < 3) {
      setComLenErr(true)
      return
    }
    try {
      const url = `${baseUrl}/performance/epi/reviewer/${employee_performance_id}`

      const formData = {
        organization_id: organization_id,
        reviewed_by: emp_id,
        reviewer_comments: managerComment,
        reviewer_status: event.target.name,
        performance_input_status:
          event.target.name === 'Approved' ? 'HR Pending' : event.target.name,
      }

      await execute(url, 'PATCH', formData)

      fetchData()
      reviewsData()
      const successMessage =
        event.target.name === 'Approved'
          ? intl.formatMessage({id: 'MSG.PERFORMANCE_SHEET_APPROVED_SUCCESSFULLY'})
          : intl.formatMessage({id: 'MSG.PERFORMANCE_SHEET_REJECTED_SUCCESSFULLY'})

      await Swal.fire({
        title: successMessage,
        text: intl.formatMessage({id: 'LABEL.CHANGES_SAVED'}),
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-light-primary btn-sm',
        },
      })
    } catch (error) {
      console.error('Error:', error)
      Swal.fire({
        icon: 'error',
        title: intl.formatMessage({id: 'LABEL.OOPS'}),
        text: intl.formatMessage({id: 'LABEL.SOMETHING_WRONG'}),
        customClass: {
          confirmButton: 'btn btn-light-primary btn-sm',
        },
      })
    }
  }

  useEffect(() => {
    setmanagerComment('')

    setComError(false)
    setComLenErr(false)
  }, [])

  const getManagerView = (status) => {
    if (status === 'Pending' && is_manager === 1) {
      return (
        <div className='min-h-26px'>
          <label>{intl.formatMessage({id: 'LABEL.COMMENTS'})}</label>
          <br />
          <textarea
            placeholder='Comments'
            className='form-control mb-2 approve-amount-input'
            value={managerComment}
            onChange={(e) => setmanagerComment(e.target.value)} // Update the local state managerComment
          />
          {comError && <p className='text-danger'>Comments is Required</p>}
          {comLenErr && !comError && (
            <p className='text-danger'>Comments must be at least 3 characters</p>
          )}
          <div>
            <button
              className='btn btn-light-success btn-sm py-2'
              name='Approved'
              onClick={approveAndRejectBtns}
              type='submit'
            >
              {intl.formatMessage({id: 'LABEL.APPROVE'})}
            </button>
            <button
              className='btn btn-light-danger btn-sm ms-2 py-2'
              name='Rejected'
              onClick={approveAndRejectBtns}
              type='submit'
            >
              {intl.formatMessage({id: 'LABEL.REJECT'})}
            </button>
          </div>
        </div>
      )
    } else if (status === 'Approved' || status === 'Rejected') {
      return (
        <>
          <p className='m-0 text-muted'>{reviewer_comments}</p>
        </>
      )
    } else {
      return null
    }
  }

  const getStatusMsg = (status) => {
    if (status === 'Pending') {
      return 'Pending'
    } else if (status === 'Approved') {
      return status
    } else if (status === 'Rejected') {
      return status
    } else {
      return status
    }
  }

  const getStatusColor = (status) => {
    if (status === 'Pending') {
      return 'muted'
    } else if (status === 'Approved') {
      return 'success'
    } else {
      return 'danger'
    }
  }

  const getIconColor = (status) => {
    if (status === null) {
      return 'secondary'
    } else if (status === 'Approved') {
      return 'success'
    } else if (status === 'Rejected') {
      return 'danger'
    } else {
      return 'muted'
    }
  }

  return (
    <div className='timeline-item'>
      <div className='timeline-line w-20px'></div>

      <div className='timeline-icon symbol symbol-circle symbol-20px me-4'>
        <div className={`symbol-label bg-${getIconColor(reviewer_status)}`}></div>
      </div>

      <div className='timeline-content mb-12 mt-n1'>
        <div className='d-flex justify-content-between mt-1'>
          <p className={`mb-0 fw-bold text-${getStatusColor(reviewer_status)}`}>
            {getStatusMsg(reviewer_status)}
          </p>
          <div className=''>
            <p className='designation-btn bg-secondary fs-7 m-auto'>
              {intl.formatMessage({id: 'LABEL.MANAGER'})}
            </p>
          </div>
        </div>
        {getManagerView(reviewer_status)}
      </div>
    </div>
  )
}

export default PerformanceManager
