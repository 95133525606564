import {Formik, Form, Field, ErrorMessage} from 'formik'
import * as Yup from 'yup'

import Swal from 'sweetalert2'
import {useState, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {useLang} from '../../../../_metronic/i18n/Metronici18n'
import secureLocalStorage from 'react-secure-storage'
import {baseUrl} from '../../core'
import React from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

import {useThemeMode} from '../../../../_metronic/partials'
import classnames from 'classnames'
import '../../leave_management/team_leaves/teamleavestable.css'
import {format} from 'date-fns'
import usePostApi from '../../custom_hooks/usePostApi'
import useApiCall from '../../custom_hooks/useApiCall'
import DatePicker from 'react-datepicker'
import '../../DatepickerStyles.css'
import 'react-datepicker/dist/react-datepicker.css'

import {DateBasicFormat} from '../../DateHelpers'

const RaiseTravelRequest = () => {
  const {execute} = usePostApi()
  const {data: projectDetails} = useApiCall('/projects')
  const {data: currencytype} = useApiCall('/currencyTypes')
  const {data: departmenttypes} = useApiCall('/masterData/departments')
  let emp_id
  let user_id
  const userInfo = secureLocalStorage.getItem('user_info')
  if (userInfo) {
    ;({emp_id, user_id} = userInfo)
  }
  const intl = useIntl()

  const [locale, setLocale] = React.useState('en')
  const [startDateHolder, setstartDateHolder] = useState('')

  const {mode} = useThemeMode()
  const [darkMode, setDarkMode] = useState(false)

  useEffect(() => {
    setDarkMode(mode !== 'light')
  })

  useEffect(() => {
    if (lang === 'ar') {
      setLocale('arSA')
    } else {
      setLocale(lang)
    }
  })
  const calendarClasses = classnames('date-range-picker', {
    dark: darkMode,
    light: !darkMode,
  })

  const lang = useLang()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const validationSchemaEdit = Yup.object().shape({
    place_of_visit: Yup.string().required(
      `${intl.formatMessage({id: 'ERR.MSG.PLACE_OF_VISIT_REQUIRED'})}`
    ),
    purpose_of_visit: Yup.string().required(
      `${intl.formatMessage({id: 'ERR.MSG.PURPOSE_OF_VISIT_REQUIRED'})}`
    ),
    mode_of_transport: Yup.string().required(
      `${intl.formatMessage({id: 'ERR.MSG.MODE_OF_TRANSPORT_REQUIRED'})}`
    ),
    departmentType: Yup.string().required(
      `${intl.formatMessage({id: 'ERR.MSG.DEPARTMENT_REQUIRED'})}`
    ),
    expected_date_of_departure: Yup.string().required(
      `${intl.formatMessage({id: 'ERR.MSG.DEPARTURE_DATE_REQUIRED'})}`
    ),
    expected_date_of_arrival: Yup.string().when(
      'expected_date_of_departure',
      (expected_date_of_departure, schema) => {
        return schema
          .test(
            'expected_date_of_arrival',
            `${intl.formatMessage({
              id: 'ERR.MSG.ARRIVAL_DATE_GREATER_THAN_DEPARTURE_DATE_REQUIRED',
            })}`,
            function (value) {
              return (
                !expected_date_of_departure ||
                !value ||
                new Date(value) >= new Date(expected_date_of_departure)
              )
            }
          )
          .required(`${intl.formatMessage({id: 'ERR.MSG.ARRIVAL_DATE_REQUIRED'})}`)
      }
    ),
    travel_advance_currency: Yup.string().when('requires_travel_advance', {
      is: true,
      then: Yup.string().required(`${intl.formatMessage({id: 'ERR.MSG.CURRENCY_REQUIRED'})}`),
    }),
    travel_advance_amount: Yup.string().when('requires_travel_advance', {
      is: true,
      then: Yup.string().required(`${intl.formatMessage({id: 'ERR.MSG.AMOUNT_REQUIRED'})}`),
    }),
    projectType: Yup.string().required(
      `${intl.formatMessage({id: 'ERR.MSG.PROJECT_NAME_IS_REQUIRED'})}`
    ),
    // expected_duration_in_days: Yup.string().required(`No. of Days is Required`),
  })
  const initialValues = {
    status: '',
    mode_of_transport: '',
    place_of_visit: '',
    purpose_of_visit: '',
    expected_date_of_departure: null,
    expected_date_of_arrival: null,
    expected_duration_in_days: '',
    requires_accommodation: '',
    requires_travel_advance: '',
    travel_advance_currency: '',
    travel_advance_amount: '',
    project_id: '',
    department_id: '',
    projectType: '',
    departmentType: '',
  }

  const ErrorMessageHandling = () => {
    if (!startDateHolder && isSubmitting) {
      return <div className='text-danger'> {intl.formatMessage({id: 'DATEISREQUIRED'})}</div>
    }

    return null
  }

  const handleSubmit = async (values, formikHelpers) => {
    const {resetForm} = formikHelpers

    const selectedprojectType = projectDetails.find(
      (projectType) => projectType.project_name === values.projectType
    )

    const selectedDepartment = departmenttypes.find(
      (departmentType) => departmentType.department_name === values.departmentType
    )
    const selectedCurrency = currencytype.find(
      (currencyType) => currencyType.currency_code === values.travel_advance_currency
    )
    const formattedStartDate = values.expected_date_of_departure
      ? format(values.expected_date_of_departure, 'yyyy-MM-dd')
      : ''
    const formattedEndDate = values.expected_date_of_arrival
      ? format(values.expected_date_of_arrival, 'yyyy-MM-dd')
      : ''

    try {
      const url = `${baseUrl}/travel/request`
      const organizationId = secureLocalStorage.getItem('organization_id')
      const formData = {
        organization_id: organizationId,
        status: 'Manager Pending',

        mode_of_transport: values.mode_of_transport,
        place_of_visit: values.place_of_visit,
        purpose_of_visit: values.purpose_of_visit,
        expected_date_of_departure: DateBasicFormat(values.expected_date_of_departure),
        expected_date_of_arrival: DateBasicFormat(values.expected_date_of_arrival),
        expected_duration_in_days: values.expected_duration_in_days,
        requires_accommodation: values.requires_accommodation ? 1 : 0,
        requires_travel_advance: values.requires_travel_advance ? 1 : 0,
        travel_advance_currency:
          values.requires_travel_advance === 1 ? selectedCurrency.currency_id : 0,

        travel_advance_amount:
          values.requires_travel_advance == 1
            ? parseFloat(values.travel_advance_amount + '.01')
            : 0,
        project_id: selectedprojectType.project_id,
        department_id: selectedDepartment.department_id,
        manager_reviewed_status: 'Pending',
        hr_reviewed_status: 'Pending',
        traveldesk_reviewed_status: 'Pending',
        finance_reviewed_status: 'Pending',
      }

      await execute(url, 'POST', formData)

      resetForm()

      Swal.fire({
        title: `${intl.formatMessage({id: 'MSG.TRAVEL_REQUEST_CREATED_SUCCESSFULLY'})}`,
        text: 'You can now track the status of the Request in the “My Trips” section..',
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-light-primary btn-sm',
        },
      })
    } catch (error) {
      console.error(error)
      Swal.fire({
        icon: 'error',
        title: intl.formatMessage({id: 'LABEL.OOPS'}),
        text: intl.formatMessage({id: 'LABEL.SOMETHING_WRONG'}),
        customClass: {
          confirmButton: 'btn btn-light-primary btn-sm',
        },
      })
    }
  }

  const calculateNoOfDays = (startDate, endDate) => {
    if (startDate && endDate) {
      const timeDifference = endDate.getTime() - startDate.getTime()
      const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24)) + 1

      return daysDifference
    } else {
      return 0
    }
  }

  return (
    <div className='card shadow-none rounded-0'>
      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchemaEdit}
          onSubmit={handleSubmit}
        >
          {({values, errors, touched, setFieldValue, handleChange}) => {
            return (
              <Form>
                <div className='card-header' id='side_activities_header'>
                  <h3 className='card-title fw-bolder text-dark'>
                    {intl.formatMessage({id: 'LABEL.TRAVEL_REQUEST'})}
                  </h3>
                  <div className='card-toolbar '>
                    <React.Fragment>
                      <div
                        className='btn btn-icon btn-sm btn-active-danger'
                        id='side_activities_close'
                      >
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr061.svg'
                          className='svg-icon svg-icon-2x'
                        />
                      </div>
                    </React.Fragment>
                  </div>
                </div>

                <div className='card-body position-relative' id='side_activities_body'>
                  <div>
                    <div
                      id='side_activities_scroll'
                      className='position-relative scroll-y me-n5 pe-5 w-400px'
                      data-kt-scroll='true'
                      data-kt-scroll-height='auto'
                      data-kt-scroll-wrappers='#side_activities_body'
                      data-kt-scroll-dependencies='#side_activities_header, #side_activities_footer'
                      data-kt-scroll-offset='5px'
                    >
                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          {intl.formatMessage({id: 'LABEL.PLACE_OF_VISIT'})}
                        </label>
                        <div className='col-lg-8 text-start'>
                          <Field
                            type='text'
                            className='form-control form-control-solid'
                            id='floatingInput'
                            name='place_of_visit'
                            value={values.place_of_visit}
                            onChange={handleChange}
                            placeholder={intl.formatMessage({id: 'LABEL.PLACE_OF_VISIT'})}
                          ></Field>
                          <ErrorMessage
                            name='place_of_visit'
                            component='div'
                            className='text-danger'
                          />
                        </div>
                      </div>
                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          {intl.formatMessage({id: 'LABEL.MODE_OF_TRANSPORT'})}
                        </label>
                        <div className='col-lg-8 text-start'>
                          <Field
                            as='select'
                            className='form-select form-select-solid'
                            id='floatingInput'
                            name='mode_of_transport'
                            value={values.mode_of_transport}
                            onChange={handleChange}
                          >
                            <option value=' '>Select Mode of Transport</option>
                            <option value='Flight'>Flight</option>
                            <option value='Rail'>Rail</option>
                            <option value='Road'>Road</option>
                            <option value='Sea'>Sea</option>
                          </Field>
                          <ErrorMessage
                            name='mode_of_transport'
                            component='div'
                            className='text-danger'
                          />
                        </div>
                      </div>
                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          {intl.formatMessage({id: 'LABEL.DEPARTMENT_NAME'})}
                        </label>
                        <div className='col-lg-8 text-start text-muted'>
                          <Field
                            as='select'
                            className='form-select form-select-solid'
                            id='floatingInput'
                            name='departmentType'
                            value={values.departmentType || ''}
                            placeholder={intl.formatMessage({id: 'LABEL.SELECT_DEPARTMENT_NAME'})}
                            onChange={(e) => setFieldValue('departmentType', e.target.value)}
                          >
                            <option value=''>
                              {intl.formatMessage({id: 'LABEL.SELECT_DEPARTMENT_NAME'})}
                            </option>
                            {departmenttypes?.map((departmentType) => (
                              <option
                                key={departmentType.department_id}
                                value={departmentType.department_name}
                              >
                                {departmentType.department_name}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            name='departmentType'
                            component='div'
                            className='text-danger'
                          />
                        </div>
                      </div>
                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          {intl.formatMessage({id: 'LABEL.PROJECT_NAME'})}
                        </label>
                        <div className='col-lg-8 text-start'>
                          <Field
                            as='select'
                            className='form-select form-select-solid'
                            id='floatingInput'
                            name='projectType'
                            value={values.projectType || ''}
                            placeholder={intl.formatMessage({id: 'LABEL.SELECT_PROJECT_NAME'})}
                            onChange={(e) => setFieldValue('projectType', e.target.value)}
                          >
                            <option value=''>
                              {intl.formatMessage({id: 'LABEL.SELECT_PROJECT_NAME'})}
                            </option>
                            {projectDetails?.map((projectType) => (
                              <option key={projectType.project_id} value={projectType.project_name}>
                                {projectType.project_name}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            name='projectType'
                            component='div'
                            className='text-danger'
                          />
                        </div>
                      </div>

                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          {intl.formatMessage({id: 'LABEL.PURPOSE_OF_VISIT'})}
                        </label>
                        <div className='col-lg-8 text-start'>
                          <Field
                            type='text'
                            className='form-control form-control-solid'
                            id='floatingInput'
                            name='purpose_of_visit'
                            value={values.purpose_of_visit}
                            onChange={handleChange}
                            placeholder={intl.formatMessage({id: 'LABEL.PURPOSE_OF_VISIT'})}
                          ></Field>
                          <ErrorMessage
                            name='purpose_of_visit'
                            component='div'
                            className='text-danger'
                          />
                        </div>
                      </div>

                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          {' '}
                          {intl.formatMessage({id: 'LABEL.DEPARTURE_DATE'})}
                        </label>

                        <div className='col-lg-8 text-start'>
                          <Field name='expected_date_of_departure'>
                            {({form, field}) => {
                              const {setFieldValue} = form
                              const {value} = field

                              const handleChange = (date) => {
                                setFieldValue('expected_date_of_departure', date)
                                setFieldValue(
                                  'expected_duration_in_days',
                                  calculateNoOfDays(date, values.expected_date_of_arrival)
                                )
                              }

                              return (
                                <div
                                  className={`react-datepicker-fullwidth-wrapper test ${
                                    mode === 'dark' ? 'dark-mode' : 'light-mode'
                                  }`}
                                >
                                  <DatePicker
                                    selected={value}
                                    isClearable
                                    showYearDropdown
                                    scrollableYearDropdown
                                    onChange={(date) => {
                                      handleChange(date)
                                    }}
                                    placeholderText={intl.formatMessage({
                                      id: 'LABEL.SELECT_DEPARTURE_DATE',
                                    })}
                                    minDate={new Date()}
                                    showMonthDropdown
                                    useShortMonthInDropdown
                                    dateFormat='dd/MM/yyyy'
                                    className='form-control form-control-solid'
                                    popperPlacement='bottom'
                                  />
                                </div>
                              )
                            }}
                          </Field>
                          <ErrorMessage
                            name='expected_date_of_departure'
                            component='div'
                            className='text-danger'
                          />
                        </div>
                      </div>

                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          {intl.formatMessage({id: 'LABEL.ARRIVAL_DATE'})}
                        </label>
                        <div className='col-lg-8 text-start'>
                          <Field name='expected_date_of_arrival'>
                            {({form, field}) => {
                              const {setFieldValue} = form
                              const {value} = field

                              const handleChange = (date) => {
                                setFieldValue('expected_date_of_arrival', date)
                                setFieldValue(
                                  'expected_duration_in_days',
                                  calculateNoOfDays(values.expected_date_of_departure, date)
                                )
                              }

                              let minDate = null
                              if (values && values.expected_date_of_departure) {
                                const departureDate = new Date(values.expected_date_of_departure)
                                minDate = departureDate.setDate(departureDate.getDate() + 1)
                              }

                              return (
                                <div
                                  className={`react-datepicker-fullwidth-wrapper test ${
                                    mode === 'dark' ? 'dark-mode' : 'light-mode'
                                  }`}
                                >
                                  <DatePicker
                                    selected={value}
                                    isClearable
                                    showYearDropdown
                                    scrollableYearDropdown
                                    onChange={(date) => {
                                      handleChange(date)
                                    }}
                                    placeholderText={intl.formatMessage({
                                      id: 'LABEL.SELECT_ARRIVAL_DATE',
                                    })}
                                    showMonthDropdown
                                    useShortMonthInDropdown
                                    dateFormat='dd/MM/yyyy'
                                    className='form-control form-control-solid'
                                    popperPlacement='bottom'
                                    minDate={minDate}
                                  />
                                </div>
                              )
                            }}
                          </Field>
                          <ErrorMessage
                            name='expected_date_of_arrival'
                            component='div'
                            className='text-danger'
                          />
                        </div>
                      </div>

                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          {intl.formatMessage({id: 'LABEL.NO_OF_DAYS'})}
                        </label>
                        <div className='col-lg-8 text-start'>
                          <Field
                            type='number'
                            name='expected_duration_in_days'
                            className='form-control form-control-solid'
                            value={values.expected_duration_in_days}
                            placeholder={intl.formatMessage({id: 'LABEL.NO_OF_DAYS'})}
                            onChange={(e) =>
                              setFieldValue('expected_duration_in_days', e.target.value)
                            }
                          />
                          <ErrorMessage
                            name='expected_duration_in_days'
                            component='div'
                            className='text-danger'
                          />
                        </div>
                      </div>

                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          {intl.formatMessage({id: 'LABEL.REQUIRE_HOTEL_ACCOMOATION'})}
                        </label>
                        <div className='col-lg-8'>
                          <div className='form-check form-check-solid form-switch'>
                            <Field
                              className='form-check-input w-40px h-20px'
                              type='checkbox'
                              name='requires_accommodation'
                              id='toggle'
                            />
                            <span
                              className={`me-4 fw-bold ${
                                values.requires_accommodation ? 'text-success' : 'text-danger'
                              }`}
                            >
                              {values.requires_accommodation ? 'Yes' : 'No'}
                            </span>
                            <ErrorMessage
                              component='div'
                              className='text-danger'
                              name='requires_accommodation'
                            />
                          </div>
                        </div>
                      </div>

                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          {intl.formatMessage({id: 'LABEL.REQUIRE_TRAVEL_ALLOWANCE'})}
                        </label>
                        <div className='col-lg-8'>
                          <div className='form-check form-check-solid form-switch'>
                            <Field
                              className='form-check-input w-40px h-20px'
                              type='checkbox'
                              name='requires_travel_advance'
                              id='toggle'
                            />
                            <span
                              className={`me-4 fw-bold ${
                                values.requires_travel_advance ? 'text-success' : 'text-danger'
                              }`}
                            >
                              {values.requires_travel_advance ? 'Yes' : 'No'}
                            </span>
                            <ErrorMessage
                              component='div'
                              className='text-danger'
                              name='requires_travel_advance'
                            />
                          </div>
                        </div>
                      </div>
                      {values.requires_travel_advance && (
                        <>
                          <div className='row mb-5 d-flex align-items-center'>
                            <label className='col-lg-4 fw-bold text-muted text-start'>
                              {intl.formatMessage({id: 'LABEL.ADVANCE_AMOUNT'})}
                            </label>
                            <div className='col-lg-8 text-start'>
                              <Field
                                type='number'
                                name='travel_advance_amount'
                                placeholder='Advance Amount'
                                value={
                                  values.requires_travel_advance ? values.travel_advance_amount : ''
                                }
                                className='form-control form-control-solid'
                              />
                              <ErrorMessage
                                name='travel_advance_amount'
                                component='div'
                                className='text-danger'
                              />
                            </div>
                          </div>
                          <div className='row mb-5 d-flex align-items-center'>
                            <label className='col-lg-4 fw-bold text-muted text-start'>
                              {intl.formatMessage({id: 'LABEL.SELECT_CURRENCY'})}
                            </label>
                            <div className='col-lg-8 text-start'>
                              <Field
                                as='select'
                                className='form-select form-select-solid text-muted'
                                id='floatingInput'
                                name='travel_advance_currency'
                                placeholder={intl.formatMessage({id: 'LABEL.SELECT_CURRENCY'})}
                                value={
                                  values.requires_travel_advance
                                    ? values.travel_advance_currency
                                    : 0
                                }
                                onChange={(e) =>
                                  setFieldValue('travel_advance_currency', e.target.value)
                                }
                              >
                                <option value=''>
                                  {' '}
                                  {intl.formatMessage({id: 'LABEL.SELECT_CURRENCY'})}
                                </option>
                                {currencytype?.map((currencyType) => (
                                  <option
                                    key={currencyType.currency_id}
                                    value={currencyType.currency_code}
                                  >
                                    {currencyType.currency_code}
                                  </option>
                                ))}
                              </Field>
                              <ErrorMessage
                                name='travel_advance_currency'
                                component='div'
                                className='text-danger'
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>

                  <div className='card-footer p-0 py-5 text-end'>
                    <button
                      type='submit'
                      className='btn btn-sm btn-light-primary ms-2'
                      onClick={() => {
                        setIsSubmitting(true)
                        ErrorMessageHandling()
                      }}
                    >
                      {intl.formatMessage({id: 'BTN.ADD'})}
                    </button>
                  </div>
                </div>
              </Form>
            )
          }}
        </Formik>
      </div>
    </div>
  )
}
export default RaiseTravelRequest
