import {useState} from 'react'
import {useIntl} from 'react-intl'
import {KTIcon} from '../../../../../_metronic/helpers'
import usePostApi from '../../../custom_hooks/usePostApi'
import {useParams} from 'react-router-dom'
import secureLocalStorage from 'react-secure-storage'
import Swal from 'sweetalert2'
import {baseUrl} from '../../../core'
const EmployeeID = ({value}: any) => {
  const {employeeID, name} = value
  const NameFormats: any = {
    EID: 'Employee ID',
    EXP: 'Expense Requests',
    TRV: 'Travel Requests',
    ADV: 'Loans/Advances',
    SSR: 'Self Service Request',
  }
  const intl = useIntl()
  const userInfo: any | null = secureLocalStorage.getItem('user_info')
  const {organization_id} = userInfo || {}
  const {execute} = usePostApi()
  const {id}: any | null = useParams()
  const {igp_id, id_prefix, id_mid, divider, is_locked} = employeeID
  let currentYear = new Date().getFullYear()
  let currentMonth = new Date().getMonth() + 1
  let yearPreview: any = currentYear.toString().padStart(2, '0')
  let curentYearMonth = `${yearPreview}${currentMonth}`
  const [isEditMode, setIsEditMode] = useState(false)
  const [employeeDivider, setEmployeeDivider] = useState(divider)
  const [startPrefix, setStartPrefix] = useState(() => {
    if (
      id_prefix === 'YY' ||
      id_prefix === 'YYYY' ||
      id_prefix === 'YYYYMM' ||
      id_prefix === 'YYMM' ||
      id_prefix === '' ||
      id_prefix === null
    ) {
      return `${id_prefix}`
    } else {
      return '4'
    }
  })
  const [middlePrefix, setMiddlePrefix] = useState(() => {
    if (
      id_mid === 'YY' ||
      id_mid === 'YYYY' ||
      id_mid === 'YYYYMM' ||
      id_mid === 'YYMM' ||
      id_mid === '' ||
      id_mid === null
    ) {
      return `${id_mid}`
    } else {
      return '4'
    }
  })
  const [staticTextValue, SetStaticTextValue] = useState(() => {
    if (
      id_prefix === 'YY' ||
      id_prefix === 'YYYY' ||
      id_prefix === 'YYYYMM' ||
      id_prefix === 'YYMM' ||
      id_prefix === '' ||
      id_prefix === null
    ) {
      return ''
    } else {
      return `${id_prefix}`
    }
  })
  const [staticTextmiddleValue, SetStaticTextMiddleValue] = useState(() => {
    if (
      id_mid === 'YY' ||
      id_mid === 'YYYY' ||
      id_mid === 'YYYYMM' ||
      id_mid === 'YYMM' ||
      id_mid === '' ||
      id_mid === null
    ) {
      return ''
    } else {
      return `${id_mid}`
    }
  })
  const middleOption = middlePrefix === '4' || middlePrefix === 'null' || middlePrefix === ''
  const staticOption = startPrefix === '4' || startPrefix === 'null' || startPrefix === ''
  const dataFormatedMethod = (value: any) => {
    switch (value) {
      case 'YYYY':
        return currentYear
      case 'YYYYMM':
        return curentYearMonth
      case 'YY':
        return yearPreview % 100
      case 'YYMM':
        return `${yearPreview % 100}${currentMonth}`
      default:
        return value
    }
  }
  const dataPreview =
    (dataFormatedMethod(id_prefix) || '') +
    (id_prefix && divider ? divider : '') +
    (dataFormatedMethod(id_mid) || '') +
    (id_mid && divider ? divider : '') +
    '001'
  const startPreview =
    startPrefix === '4' ? staticTextValue : startPrefix === 'null' ? '' : startPrefix
  const middlePreview =
    middlePrefix === '4' ? staticTextmiddleValue : middlePrefix === 'null' ? '' : middlePrefix
  const valuePreview =
    dataFormatedMethod(startPreview) +
    (startPreview ? employeeDivider : '') +
    dataFormatedMethod(middlePreview) +
    (middlePreview ? employeeDivider : '') +
    '001'
  const handleEditClick = () => {
    setIsEditMode(true)
  }
  const handleCancelClick = () => {
    setIsEditMode(false)
  }
  const handleChangeDivider = (e: any) => {
    setEmployeeDivider(e)
  }
  const handleChangeStartPrefix = (e: any) => {
    setStartPrefix(e)
  }
  const handleChangeMiddlePrefix = (e: any) => {
    setMiddlePrefix(e)
  }
  const handleChangeStartValue = (e: any) => {
    SetStaticTextValue(e)
  }
  const handleChangeMiddleValue = (e: any) => {
    SetStaticTextMiddleValue(e)
  }
  const handleSaveClick = async () => {
    try {
      const url = `${baseUrl}/pref/idGeneration/${igp_id}`
      const result = await Swal.fire({
        title: intl.formatMessage({id: 'LABEL.UPDATING_IDGENERATION_PREFERENCES'}),
        text: intl.formatMessage({id: 'LABEL.CONFIRM_ID_GENERATION_BUTTON'}),
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: intl.formatMessage({id: 'BTN.CONFIRM'}),
        cancelButtonText: intl.formatMessage({id: 'LABEL.CANCEL'}),
        customClass: {
          confirmButton: 'btn btn-light-primary btn-sm',
          cancelButton: 'btn btn-sm btn-secondary',
        },
      })
      if (result.isConfirmed) {
        const idGenerationdata = {
          organization_id: organization_id,
          id_length: 12,
          id_prefix: startPreview,
          id_mid: middlePreview,
          divider: employeeDivider,
        }
        await execute(url, 'PATCH', idGenerationdata, id)
        Swal.fire({
          title: intl.formatMessage({id: 'LABEL.ID_GENERATION_EDITED'}),
          text: intl.formatMessage({id: 'LABEL.CHANGES_SAVED'}),
          icon: 'success',
          showConfirmButton: true,
          confirmButtonText: 'OK',
          customClass: {
            confirmButton: 'btn btn-light-primary btn-sm',
          },
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload()
          }
        })
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: intl.formatMessage({id: 'LABEL.OOPS'}),
        text: intl.formatMessage({id: 'LABEL.SOMETHING_WRONG'}),
      })
      console.error('Error updating data:', error)
    }
  }
  return (
    <>
      <tr>
        <td>
          <div className='d-flex justify-content-start flex-column'>
            <p className='text-dark fw-bold text-hover-primary fs-6 default-cursor'>
              {NameFormats[name]}
            </p>
          </div>
        </td>
        <td>
          {isEditMode ? (
            <div className='text-start'>
              <select
                className='form-select form-select-solid w-150px'
                onChange={(e: any) => handleChangeStartPrefix(e.target.value)}
                value={startPrefix}
              >
                {middleOption ? (
                  <>
                    <option value='null'>Empty</option>
                    <option value='YYYY'>Long Year ({currentYear})</option>
                    <option value='YYYYMM'>Long Year & Month ({curentYearMonth})</option>
                    <option value='YY'>Short Year ({`${yearPreview % 100}`})</option>
                    <option value='YYMM'>
                      Short Year & Month ({`${yearPreview % 100}${currentMonth}`})
                    </option>
                    <option value='4'>Static Text (Max. 3 characters)</option>
                  </>
                ) : (
                  <>
                    <option value='null'>Empty</option>
                    <option value='4'>Static Text(Max. 3 Characters)</option>
                  </>
                )}
              </select>
              {startPrefix === '4' && (
                <div className='input-group w-150px mt-2'>
                  <input
                    type='text'
                    className='form-control form-control-solid'
                    maxLength={3}
                    value={staticTextValue}
                    onChange={(e: any) => handleChangeStartValue(e.target.value)}
                  />
                  <div className='input-group-append'>
                    <i className='fa fa-unlock-alt fs-2 text-success form-control form-control-solid p-2px'></i>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className='d-flex justify-content-start flex-column'>
              <p className='text-dark fw-bold text-hover-primary fs-6 default-cursor'>
                {id_prefix ? dataFormatedMethod(id_prefix) : 'Empty'}
              </p>
            </div>
          )}
        </td>
        <td>
          {isEditMode ? (
            <div className='text-start'>
              <select
                className='form-select form-select-solid w-150px'
                onChange={(e: any) => handleChangeMiddlePrefix(e.target.value)}
                value={middlePrefix}
              >
                {staticOption ? (
                  <>
                    <option value='null'>Empty</option>
                    <option value='YYYY'>Long Year ({currentYear})</option>
                    <option value='YYYYMM'>Long Year & Month ({curentYearMonth})</option>
                    <option value='YY'>Short Year ({`${yearPreview % 100}`})</option>
                    <option value='YYMM'>
                      Short Year & Month ({`${yearPreview % 100}${currentMonth}`})
                    </option>
                    <option value='4'>Static Text (Max. 3 characters)</option>
                  </>
                ) : (
                  <>
                    <option value='null'>Empty</option>
                    <option value='4'>Static Text(Max. 3 Characters)</option>
                  </>
                )}
              </select>
              {middlePrefix === '4' && (
                <div className='input-group w-150px mt-2'>
                  <input
                    type='text'
                    className='form-control form-control-solid'
                    maxLength={3}
                    value={staticTextmiddleValue}
                    onChange={(e: any) => handleChangeMiddleValue(e.target.value)}
                  />
                  <div className='input-group-append'>
                    <i className='fa fa-unlock-alt fs-2 text-success form-control form-control-solid p-2px'></i>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className='d-flex justify-content-start flex-column'>
              <p className='text-dark fw-bold text-hover-primary fs-6 default-cursor'>
                {id_mid ? dataFormatedMethod(id_mid) : 'Empty'}
              </p>
            </div>
          )}
        </td>
        <td>
          {isEditMode ? (
            <div className='text-start'>
              <input
                type='text'
                className='form-control form-control-solid w-90px'
                value='SEQ. NO.'
                readOnly
              />
            </div>
          ) : (
            <div className='d-flex align-items-center'>
              <div className='d-flex justify-content-start flex-column'>
                <p className='text-dark fw-bold text-hover-primary fs-6 default-cursor'>001</p>
              </div>
            </div>
          )}
        </td>
        <td>
          {isEditMode ? (
            <div className='text-start'>
              <select
                className='form-select form-select-solid w-80px'
                onChange={(e: any) => handleChangeDivider(e.target.value)}
                value={employeeDivider}
              >
                <option value=''>Empty</option>
                <option value='.'>.</option>
                <option value='-'>-</option>
                <option value='/'>/</option>
              </select>
            </div>
          ) : (
            <div className='d-flex justify-content-start flex-column'>
              <p className='text-dark fw-bold text-hover-primary fs-6 default-cursor'>
                {divider ? divider : 'Empty'}
              </p>
            </div>
          )}
        </td>
        <td>
          {isEditMode ? (
            <div className='text-start'>
              <input
                type='text'
                className='form-control form-control-solid fw-bold w-200px'
                value={valuePreview}
                readOnly
              />
            </div>
          ) : (
            <div className='d-flex justify-content-start flex-column'>
              <p className='text-dark fw-bold text-hover-primary fs-6 default-cursor'>
                {dataPreview}
              </p>
            </div>
          )}
        </td>
        <td>
          <>
            {is_locked === 1 ? (
              <div className='text-start'>
                <button className='btn btn-secondary btn-sm fw-bold' disabled>
                  <KTIcon iconName='lock' className='fs-3' />
                  Locked. Cannot Edit.
                </button>
              </div>
            ) : (
              <div className='btn-toolbar' role='toolbar' aria-label='...'>
                <div className='btn-group mr-2' role='group' aria-label='...'>
                  <button
                    type='button'
                    className={`btn btn-active-color-primary btn-icon border`}
                    onClick={handleEditClick}
                  >
                    <i className='fs-1 la la-edit'></i>
                  </button>
                  {isEditMode && (
                    <>
                      <button
                        type='button'
                        className={`btn btn-active-color-primary btn-icon border`}
                        onClick={handleSaveClick}
                      >
                        <i className='fs-1 la la-floppy-o'></i>
                      </button>
                      <button
                        type='button'
                        className={`btn btn-active-color-primary btn-icon border`}
                        onClick={handleCancelClick}
                      >
                        <i className='fs-1 la la-times'></i>
                      </button>
                    </>
                  )}
                </div>
              </div>
            )}
          </>
        </td>
      </tr>
    </>
  )
}

export default EmployeeID
