import {useState} from 'react'
import {useLocation} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import Context from '../../../_metronic/partials/layout/activity-drawer/context'
import AssetManagementNavBar from './AssetManagementNavBar'
import MyAssets from './my_assets/MyAssets'
import IssuanceTable from './issuance/IssuanceTable'
import ReturnsTable from './returns/ReturnsTable'
import {useIntl} from 'react-intl'
import AllAssets from '../all_assets/AllAssets'

import TeamAssets from './issuance/TeamAssets'
import MyAssetsTable from './my_assets/MyAssetsTable'
const AssetManagementRoutes = () => {
  const AssetManagementBreadCrumbs = [
    {
      title: 'Asset Management',
      path: '/asset-management/my-assets',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]
  const {pathname} = useLocation()
  const intl = useIntl()
  const [SideDrawerId, updateId] = useState('0')
  const switchMethod = () => {
    switch (pathname) {
      case '/asset-management/all-assets':
        return (
          <>
            <PageTitle breadcrumbs={AssetManagementBreadCrumbs}>
              {intl.formatMessage({id: 'MENU.ASSET_MANAGEMENT.MY_ASSETS'})}
            </PageTitle>
            <AllAssets />
          </>
        )
      case '/asset-management/my-assets':
        return (
          <>
            <PageTitle breadcrumbs={AssetManagementBreadCrumbs}>
              {intl.formatMessage({id: 'MENU.ASSET_MANAGEMENT.MY_ASSETS'})}
            </PageTitle>
            <MyAssetsTable />
          </>
        )
      case '/asset-management/issuance-and-returns':
        return (
          <>
            <PageTitle breadcrumbs={AssetManagementBreadCrumbs}>
              {intl.formatMessage({id: 'MENU.ASSET_MANAGEMENT.ISSUANCE'})}
            </PageTitle>
            <IssuanceTable />
          </>
        )

      case '/asset-management/allassets':
        return (
          <>
            <PageTitle breadcrumbs={AssetManagementBreadCrumbs}>
              {intl.formatMessage({id: 'LABEL.ALL_ASSETS'})}
            </PageTitle>
            <AllAssets />
          </>
        )

      default:
        return (
          <>
            <PageTitle breadcrumbs={AssetManagementBreadCrumbs}>
              {intl.formatMessage({id: 'MENU.ASSET_MANAGEMENT.MY_ASSETS'})}
            </PageTitle>
            <AllAssets />
          </>
        )
    }
  }
  const UpdatingId = (id) => {
    updateId(id)
  }

  return (
    <>
      <Context.Provider value={{id: SideDrawerId, updateId: UpdatingId}}>
        <AssetManagementNavBar />
        {switchMethod()}
      </Context.Provider>
    </>
  )
}

export default AssetManagementRoutes
