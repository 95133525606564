import {ErrorMessage, Field, Form, Formik} from 'formik'
import Swal from 'sweetalert2'
import * as Yup from 'yup'
import {useIntl} from 'react-intl'
import usePostApi from '../../../custom_hooks/usePostApi'
import {useParams} from 'react-router-dom'
import secureLocalStorage from 'react-secure-storage'
import {baseUrl} from '../../../core'
import DatePicker from 'react-datepicker'
import {useThemeMode} from '../../../../../_metronic/partials'
import DateFormatter from '../../../DateFormatter'
import {DateBasicFormat} from '../../../DateHelpers'

function HR({hrDetails}) {
  const {
    manager_reviewed_status = null,
    hr_reviewed_status = null,
    hr_review_comments = '',
    hr_reviewed_timestamp = '',
    exit_id = '',
    exit_date = '',
    fetchData,
    exitFetchData,
  } = hrDetails
  const intl = useIntl()
  const {mode} = useThemeMode()
  const {execute} = usePostApi()
  const {id} = useParams()
  const dateFormat = localStorage.getItem('dateFormat') || 'yyyy-MM-dd'
  const userInfo = secureLocalStorage.getItem('user_info')
  const {organization_id} = userInfo || {}
  const validationSchema = Yup.object().shape({
    exit_comments: Yup.string().required('Interview Comments is Required'),
  })

  const handleSubmit = async (values, exitStatus, resetForm) => {
    const url = `${baseUrl}/exit/details/hr/${exit_id}`
    try {
      const requestData = {
        organization_id: organization_id,
        hr_reviewed_by: 1,
        hr_reviewed_comments: values.exit_comments,
        exit_status: exitStatus,
        exit_date: values.exit_date
          ? DateBasicFormat(values.exit_date)
          : DateBasicFormat(exit_date),
      }
      await execute(url, 'PATCH', requestData, id)
      fetchData()
      exitFetchData()
      resetForm()
      Swal.fire({
        title: 'Request Updated',
        text: 'Your comments have been updated.',
        icon: 'success',
        showConfirmButton: true,
        confirmButtonText: 'OK',
        customClass: {
          confirmButton: 'btn btn-light-primary btn-sm',
        },
      })
    } catch (error) {
      console.error(error)
      Swal.fire({
        icon: 'error',
        title: intl.formatMessage({id: 'LABEL.OOPS'}),
        text: intl.formatMessage({id: 'LABEL.SOMETHING_WRONG'}),
      })
    }
  }
  const getHrView = (ManagerStatus, hrStatus) => {
    if (ManagerStatus && hrStatus === null) {
      return (
        <>
          <Formik
            initialValues={{
              exit_comments: '',
              exit_date: '',
            }}
            validationSchema={validationSchema}
          >
            {({values, resetForm}) => {
              return (
                <Form>
                  <div className='fw-bold mb-2 required'>Interview Comments</div>
                  <div className='mb-4'>
                    <Field
                      as='textarea'
                      className='form-control form-control-solid'
                      id='floatingInput'
                      row={3}
                      name='exit_comments'
                    />
                    <ErrorMessage component='div' className='text-danger' name='exit_comments' />
                  </div>
                  <div className='fw-bold mb-2'>Employee Last Working Date</div>
                  <div className='flex-fill'>
                    <Field name='exit_date'>
                      {({form, field}) => {
                        const {setFieldValue} = form
                        const {value} = field
                        const handleChange = (date) => {
                          setFieldValue('exit_date', date)
                        }
                        return (
                          <div
                            className={`react-datepicker-fullwidth-wrapper test ${
                              mode === 'dark' ? 'dark-mode' : 'light-mode'
                            }`}
                          >
                            <DatePicker
                              className='form-control form-control-solid'
                              selected={value}
                              isClearable={value !== null && value !== ''}
                              showYearDropdown
                              scrollableYearDropdown
                              dateFormat={dateFormat}
                              onChange={(date) => {
                                handleChange(date)
                              }}
                              placeholderText={DateFormatter(exit_date)}
                              showMonthDropdown
                              useShortMonthInDropdown
                              minDate={new Date()}
                            />
                          </div>
                        )
                      }}
                    </Field>
                    <ErrorMessage component='div' className='text-danger' name='exit_date' />
                  </div>
                  <div className='d-flex flex-row justify-content-end mt-4'>
                    <button
                      className='btn btn-light-success btn-sm py-2'
                      onClick={() => handleSubmit(values, 'Exit Accepted', resetForm)}
                      type='button'
                    >
                      UPDATE
                    </button>
                    <button
                      className='btn btn-light-danger btn-sm py-2 ms-2'
                      onClick={() => handleSubmit(values, 'Request Exit Reversal', resetForm)}
                      type='button'
                    >
                      Req. Exit Reversal
                    </button>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </>
      )
    } else if (hrStatus) {
      return (
        <>
          <p className='m-0 text-muted'>
            {hr_reviewed_timestamp && hr_reviewed_timestamp.slice(0, 19)}
          </p>
          {hr_reviewed_status === 'Rejected' ? (
            <p className='m-0 text-muted'>{`${hr_review_comments}`}</p>
          ) : (
            <p className='m-0 text-muted'>{`${hr_review_comments}`}</p>
          )}
        </>
      )
    } else {
      return null
    }
  }

  const getStatusMsg = (ManagerStatus, hrStatus) => {
    if (ManagerStatus === null) {
      return "Awaiting Manager's Action"
    } else if (ManagerStatus && hrStatus == null) {
      return 'Pending'
    } else if (hrStatus) {
      return 'Updated'
    }
  }

  const getStatusColor = (status) => {
    if (status) {
      return 'success'
    } else {
      return 'muted'
    }
  }

  return (
    <div className='timeline-item'>
      <div className='timeline-line w-20px'></div>
      <div className='timeline-icon symbol symbol-circle symbol-20px me-4'>
        <div
          className={`symbol-label bg-${getStatusColor(
            hr_reviewed_status
          )} icon-dotted-border text-${getStatusColor(hr_reviewed_status)}`}
        ></div>
      </div>
      <div className='timeline-content mb-12 mt-n1'>
        <div className='d-flex justify-content-between mt-1'>
          <p className={`mb-0 fw-bold text-${getStatusColor(hr_reviewed_status)}`}>
            {getStatusMsg(manager_reviewed_status, hr_reviewed_status)}
          </p>
          <div className=''>
            <p className='designation-btn bg-secondary m-auto'>HR</p>
          </div>
        </div>
        {getHrView(manager_reviewed_status, hr_reviewed_status)}
      </div>
    </div>
  )
}

export default HR
