import clsx from 'clsx'
import {Link, useLocation} from 'react-router-dom'
import {KTIcon, WithChildren, checkIsActive} from '../../../_metronic/helpers'
import {FC} from 'react'
import {useIntl} from 'react-intl'

const HrConfiguration: FC<WithChildren> = ({children}) => {
  const {pathname} = useLocation()
  const location = useLocation()
  const intl = useIntl()
  return (
    <>
      <div className={`card mb-4 p-2`}>
        <div className='menu-item mt-4'>
          <Link
            className={clsx(
              'menu-link without-sub btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1',
              {
                active: checkIsActive(pathname, '/hr-configuration/asset-types'),
              }
            )}
            to='/hr-configuration/asset-types'
          >
            <span className='menu-icon'>
              <KTIcon iconName='delivery-door' className='fs-2' />
            </span>
            <span className='menu-title fw-bold'>
              {intl.formatMessage({id: 'MENU.HR_CONFIGURATION.ASSET_TYPES'})}
            </span>
          </Link>
        </div>
        <div className='menu-item'>
          <Link
            className={clsx(
              'menu-link without-sub btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1',
              {
                active: checkIsActive(pathname, '/hr-configuration/designations'),
              }
            )}
            to='/hr-configuration/designations'
          >
            <span className='menu-icon'>
              <KTIcon iconName='teacher' className='fs-2' />
            </span>
            <span className='menu-title fw-bold'>
              {intl.formatMessage({id: 'MENU.HR_CONFIGURATION.DESIGNATIONS'})}
            </span>
          </Link>
        </div>
        <div className='menu-item'>
          <Link
            className={clsx(
              'menu-link without-sub btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1',
              {
                active: checkIsActive(pathname, '/hr-configuration/employment-types'),
              }
            )}
            to='/hr-configuration/employment-types'
          >
            <span className='menu-icon'>
              <KTIcon iconName='badge' className='fs-2' />
            </span>
            <span className='menu-title fw-bold'>
              {intl.formatMessage({id: 'MENU.HR_CONFIGURATION.EMPLOYMENT_TYPES'})}
            </span>
          </Link>
        </div>
        <div className='menu-item'>
          <Link
            className={clsx(
              'menu-link without-sub btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1',
              {
                active: checkIsActive(pathname, '/hr-configuration/expense-types'),
              }
            )}
            to='/hr-configuration/expense-types'
          >
            <span className='menu-icon'>
              <KTIcon iconName='delivery-3' className='fs-2' />
            </span>
            <span className='menu-title fw-bold'>
              {intl.formatMessage({id: 'MENU.HR_CONFIGURATION.EXPENSE_TYPES'})}
            </span>
          </Link>
        </div>
        <div className='menu-item'>
          <Link
            className={clsx(
              'menu-link without-sub btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1',
              {
                active: checkIsActive(pathname, '/hr-configuration/holidays'),
              }
            )}
            to='/hr-configuration/holidays'
          >
            <span className='menu-icon'>
              <KTIcon iconName='calendar-2' className='fs-2' />
            </span>
            <span className='menu-title fw-bold'>
              {intl.formatMessage({id: 'MENU.HR_CONFIGURATION.HOLIDAYS'})}
            </span>
          </Link>
        </div>
        <div className='menu-item'>
          <Link
            className={clsx(
              'menu-link without-sub btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1',
              {
                active: checkIsActive(pathname, '/hr-configuration/job-roles'),
              }
            )}
            to='/hr-configuration/job-roles'
          >
            <span className='menu-icon'>
              <KTIcon iconName='user-square' className='fs-2' />
            </span>
            <span className='menu-title fw-bold'>
              {intl.formatMessage({id: 'MENU.HR_CONFIGURATION.JOB_ROLES'})}
            </span>
          </Link>
        </div>
        <div className='menu-item'>
          <Link
            className={clsx(
              'menu-link without-sub btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1',
              {
                active: checkIsActive(pathname, '/hr-configuration/job-titles'),
              }
            )}
            to='/hr-configuration/job-titles'
          >
            <span className='menu-icon'>
              <KTIcon iconName='subtitle' className='fs-2' />
            </span>
            <span className='menu-title fw-bold'>
              {intl.formatMessage({id: 'MENU.HR_CONFIGURATION.JOB_TITLES'})}
            </span>
          </Link>
        </div>
        <div className='menu-item'>
          <Link
            className={clsx(
              'menu-link without-sub btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1',
              {
                active: checkIsActive(pathname, '/hr-configuration/checklists'),
              }
            )}
            to='/hr-configuration/checklists'
          >
            <span className='menu-icon'>
              <KTIcon iconName='questionnaire-tablet' className='fs-2' />
            </span>
            <span className='menu-title fw-bold'>
              {intl.formatMessage({id: 'MENU.HR_CONFIGURATION.CHECKLISTS'})}
            </span>
          </Link>
        </div>
        <div className='menu-item'>
          <Link
            className={clsx(
              'menu-link without-sub btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1',
              {
                active: checkIsActive(pathname, '/hr-configuration/skillsets'),
              }
            )}
            to='/hr-configuration/skillsets'
          >
            <span className='menu-icon'>
              <KTIcon iconName='teacher' className='fs-2' />
            </span>
            <span className='menu-title fw-bold'>
              {intl.formatMessage({id: 'MENU.HR_CONFIGURATION.SKILLSETS'})}
            </span>
          </Link>
        </div>
        <div className='menu-item'>
          <Link
            className={clsx(
              'menu-link without-sub btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1',
              {
                active: checkIsActive(pathname, '/hr-configuration/interview-types'),
              }
            )}
            to='/hr-configuration/interview-types'
          >
            <span className='menu-icon'>
              <KTIcon iconName='people' className='fs-2' />
            </span>
            <span className='menu-title fw-bold'>
              {intl.formatMessage({id: 'MENU.HR_CONFIGURATION.INTERVIEW_TYPE'})}
            </span>
          </Link>
        </div>
        <div className='menu-item mb-4'>
          <Link
            className={clsx(
              'menu-link without-sub btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1',
              {
                active: checkIsActive(pathname, '/hr-configuration/projects'),
              }
            )}
            to='/hr-configuration/projects'
          >
            <span className='menu-icon'>
              <KTIcon iconName='message-programming' className='fs-2' />
            </span>
            <span className='menu-title fw-bold'>Projects</span>
          </Link>
        </div>
      </div>
    </>
  )
}

export default HrConfiguration
