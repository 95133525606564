/* eslint-disable jsx-a11y/anchor-is-valid */

import {useIntl} from 'react-intl'
import TDWFTravelRequestsTable from './TDWFTravelRequestsTable'
function TDWFTravelRequests() {
  const intl = useIntl()
  return (
    <>
      <div className='d-flex'>
        <div className='d-flex flex-wrap flex-stack mb-6'>
          <h3 className='fw-bolder my-2'>Travel Requests</h3>
        </div>
      </div>

      <div className='card mb-5 mb-xl-10'>
        <TDWFTravelRequestsTable />
      </div>
    </>
  )
}

export default TDWFTravelRequests
