import {useState, useEffect} from 'react'
import secureLocalStorage from 'react-secure-storage'
import {baseUrl} from '../../core'

import Swal from 'sweetalert2'
import {useIntl} from 'react-intl'

import usePostApi from '../../custom_hooks/usePostApi'

function TravelDesk({traveldeskDetails}) {
  const {execute} = usePostApi()
  const {
    manager_reviewed_status = traveldeskDetails.manager_reviewed_status,
    hr_reviewed_status = traveldeskDetails.hr_reviewed_status,

    fetchData = () => {},
    traveldesk_reviewed_status = null,
    traveldesk_review_comments = '',
    request_id = '',
    travelfetchdata,
  } = traveldeskDetails

  let emp_id
  let organization_id
  const userInfo = secureLocalStorage.getItem('user_info')
  if (userInfo) {
    ;({emp_id, organization_id} = userInfo)
  }

  const intl = useIntl()

  const [TravelDeskComment, settravelDeskComment] = useState('')
  const [comError, setComError] = useState(false)
  const [comLenErr, setComLenErr] = useState(false)

  useEffect(() => {
    settravelDeskComment('')

    setComError(false)
    setComLenErr(false)
  }, [])

  useEffect(() => {
    if (TravelDeskComment !== '') {
      setComError(false)
    }
    if (TravelDeskComment.length >= 3) {
      setComLenErr(false)
    }
  }, [TravelDeskComment])

  const approveAndRejectBtns = async (event) => {
    if (TravelDeskComment === '') {
      setComError(true)
      return
    } else if (TravelDeskComment.length < 3) {
      setComLenErr(true)
      return
    }

    try {
      const url = `${baseUrl}/travel/traveldesk/${request_id}`

      const formData = {
        organization_id: organization_id,
        traveldesk_reviewed_by: emp_id,
        traveldesk_review_comments: TravelDeskComment,
        traveldesk_reviewed_status: event.target.name,
        status: event.target.name, // Update the status based on 'Approved' or 'Rejected'
      }

      await execute(url, 'PATCH', formData)
      fetchData()
      travelfetchdata()
      const successMessage =
        event.target.name === 'Approved'
          ? intl.formatMessage({id: 'MSG.TRAVEL_REQUEST_APPROVED_SUCCESSFULLY'})
          : intl.formatMessage({id: 'MSG.TRAVEL_REQUEST_REJECTED_SUCCESSFULLY'})

      await Swal.fire({
        title: successMessage,
        text: intl.formatMessage({id: 'LABEL.CHANGES_SAVED'}),
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-light-primary btn-sm',
        },
      })
    } catch (error) {
      console.error('Error:', error)
      Swal.fire({
        icon: 'error',
        title: intl.formatMessage({id: 'LABEL.OOPS'}),
        text: intl.formatMessage({id: 'LABEL.SOMETHING_WRONG'}),
        customClass: {
          confirmButton: 'btn btn-light-primary btn-sm',
        },
      })
    }
  }

  const getFinancerView = (ManagerStatus, hrStatus, traveldeskStatus) => {
    if (ManagerStatus === 'Approved' && hrStatus === 'Approved' && traveldeskStatus === 'Pending') {
      return (
        <div className='min-h-26px'>
          <label> {intl.formatMessage({id: 'LABEL.COMMENTS'})}</label>
          <br />
          <textarea
            placeholder='Comments'
            className='form-control mb-3 form-control-solid'
            value={TravelDeskComment}
            onChange={(e) => settravelDeskComment(e.target.value)} // Update the local state managerComment
          />
          {comError && <p className='text-danger'>Comments is Required</p>}
          {comLenErr && !comError && (
            <p className='text-danger'>Comments must be at least 3 characters</p>
          )}
          <div>
            <button
              className='btn btn-light-success btn-sm py-2'
              name='Approved'
              onClick={approveAndRejectBtns}
              type='submit'
              // id='side_activities_close'
            >
              {intl.formatMessage({id: 'LABEL.APPROVE'})}
            </button>
            <button
              className='btn btn-light-danger btn-sm ms-2 py-2'
              name='Rejected'
              onClick={approveAndRejectBtns}
              type='submit'
              // id='side_activities_close'
            >
              {intl.formatMessage({id: 'LABEL.REJECT'})}
            </button>
          </div>
        </div>
      )
    } else if (
      (ManagerStatus === 'Approved' &&
        hrStatus === 'Approved' &&
        traveldeskStatus === 'Approved') ||
      (ManagerStatus === 'Approved' && hrStatus === 'Approved' && traveldeskStatus === 'Rejected')
    ) {
      return (
        <>
          {/* <p className='m-0 text-muted'>{traveldesk_reviewed_status}</p> */}
          <p className='m-0 text-muted'>{traveldesk_review_comments}</p>
        </>
      )
    } else {
      return null
    }
  }

  const getStatusMsg = (ManagerStatus, hrStatus, traveldeskStatus) => {
    if (ManagerStatus === 'Pending') {
      return "Awaiting Manager's Action"
    } else if (ManagerStatus === 'Approved' && hrStatus === 'Pending') {
      return "Awaiting HR's Action"
    } else if (ManagerStatus === 'Rejected' || hrStatus === 'Rejected') {
      return 'No Action'
    } else if (
      ManagerStatus === 'Approved' &&
      hrStatus === 'Approved' &&
      traveldeskStatus === 'Pending'
    ) {
      return 'Pending'
    } else if (traveldeskStatus === 'Rejected') {
      return 'Rejected'
    } else if (
      ManagerStatus === 'Approved' &&
      hrStatus === 'Approved' &&
      traveldeskStatus === 'Approved'
    ) {
      return `${traveldeskStatus} ${traveldesk_reviewed_status}`
    } else {
      return traveldeskStatus
    }
  }
  const getStatusColor = (ManagerStatus, hrStatus, traveldeskStatus) => {
    if (ManagerStatus === 'Rejected' || hrStatus === 'Rejected') {
      return 'secondary'
    } else if (
      ManagerStatus === 'Pending' ||
      (ManagerStatus === 'Approved' && hrStatus === 'Pending') ||
      (ManagerStatus === 'Approved' && hrStatus === 'Approved' && traveldeskStatus === 'Pending')
    ) {
      return 'muted'
    } else if (
      ManagerStatus === 'Approved' &&
      hrStatus === 'Approved' &&
      traveldeskStatus === 'Approved'
    ) {
      return 'success'
    } else {
      return 'danger'
    }
  }

  return (
    <div className='timeline-item'>
      <div className='timeline-line w-20px'></div>

      <div className='timeline-icon symbol symbol-circle symbol-20px'>
        <div
          className={`symbol-label bg-${getStatusColor(
            manager_reviewed_status,
            hr_reviewed_status,

            traveldesk_reviewed_status
          )} icon-dotted-border text-${getStatusColor(
            manager_reviewed_status,
            hr_reviewed_status,

            traveldesk_reviewed_status
          )}`}
        ></div>
      </div>

      <div className='timeline-content mb-12 mt-n1'>
        <div className='d-flex justify-content-between mt-1'>
          <p
            className={`mb-0 fw-bold text-${getStatusColor(
              manager_reviewed_status,
              hr_reviewed_status,

              traveldesk_reviewed_status
            )}`}
          >
            {getStatusMsg(
              manager_reviewed_status,
              hr_reviewed_status,

              traveldesk_reviewed_status
            )}
          </p>
          <div className=''>
            <p className='designation-btn bg-secondary fs-7 m-auto'>Travel Desk</p>
          </div>
        </div>
        {getFinancerView(
          manager_reviewed_status,
          hr_reviewed_status,

          traveldesk_reviewed_status
        )}
      </div>
    </div>
  )
}

export default TravelDesk
