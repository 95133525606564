import {useState} from 'react'
import Organization from './Organization'
import OrganizationInfo from './organization_information/OrganizationInfo'
import OrganizationBusiness from './business_Units/OrganizationBusiness'
import {useLocation} from 'react-router-dom'
import OrganizationDepartments from './organization_departments/OrganizationDepartments'
import OrganizationPreferences from './organization_preferences/OrganizationPreferences'
import OrganizationCharts from './organization_charts/OrganizationCharts'
import Context from '../../../_metronic/partials/layout/activity-drawer/context'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import OrganizationLocations from './organization_locations/OrganizationLocations'
import Countries from './geography/Countries'
import States from './geography/states/States'
const OrganizationRoutes = () => {
  const intl = useIntl()
  const OrganizationTitle = [
    {
      title: intl.formatMessage({id: 'MENU.ORGANIZATION_CONFIGURATION.ORGANIZATION'}),
      path: '/organization-configuration/organization',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  const {pathname} = useLocation()
  const [SideDrawerId, updateId] = useState('0')
  const switchMethod = () => {
    switch (pathname) {
      case '/organization-configuration/business-units':
        return (
          <>
            <PageTitle breadcrumbs={OrganizationTitle}>
              {intl.formatMessage({id: 'MENU.ORGANIZATION_CONFIGURATION.BUSINESS_UNITS'})}
            </PageTitle>
            <OrganizationBusiness />
          </>
        )
      case '/organization-configuration/departments':
        return (
          <>
            <PageTitle breadcrumbs={OrganizationTitle}>
              {intl.formatMessage({id: 'MENU.ORGANIZATION_CONFIGURATION.DEPARTMENTS'})}
            </PageTitle>
            <OrganizationDepartments />
          </>
        )
      case '/organization-configuration/preferences':
        return (
          <>
            <PageTitle breadcrumbs={OrganizationTitle}>
              {intl.formatMessage({id: 'MENU.LABEL.PREFERENCES'})}
            </PageTitle>
            <OrganizationPreferences />
          </>
        )
      case '/organization-configuration/charts':
        return (
          <>
            <PageTitle breadcrumbs={OrganizationTitle}>
              {intl.formatMessage({id: 'MENU.LABEL.ORGANOGRAM'})}
            </PageTitle>
            <OrganizationCharts />
          </>
        )
      case '/organization-configuration/locations':
        return (
          <>
            <PageTitle breadcrumbs={OrganizationTitle}>
              {intl.formatMessage({id: 'MENU.ORGANIZATION_CONFIGURATION.LOCATIONS'})}
            </PageTitle>
            <OrganizationLocations />
          </>
        )
      case '/organization-configuration/states':
        return (
          <>
            <Context.Consumer>
              {(value) => {
                const {id} = value
                return (
                  <>
                    <PageTitle breadcrumbs={OrganizationTitle}>
                      {intl.formatMessage({id: 'LABEL.STATES'})}
                    </PageTitle>
                    <States props={id} />
                  </>
                )
              }}
            </Context.Consumer>
          </>
        )
      case '/organization-configuration/countries':
        return (
          <>
            <PageTitle breadcrumbs={OrganizationTitle}>
              {intl.formatMessage({id: 'MENU.ADMINISTRATION.COUNTRIES'})}
            </PageTitle>
            <Countries />
          </>
        )
      default:
        return (
          <>
            <PageTitle breadcrumbs={OrganizationTitle}>
              {intl.formatMessage({id: 'MENU.LABEL.INFORMATION'})}
            </PageTitle>
            <OrganizationInfo />
          </>
        )
    }
  }
  const UpdatingId = (id) => {
    updateId(id)
  }
  return (
    <>
      <Context.Provider value={{id: SideDrawerId, updateId: UpdatingId}}>
        <Organization />
        {switchMethod()}
      </Context.Provider>
    </>
  )
}

export default OrganizationRoutes
