import {useEffect, useState} from 'react'
import useApiCall from '../custom_hooks/useApiCall'
import secureLocalStorage from 'react-secure-storage'
export function GetAllTrips() {
  let emp_id
  let user_id
  const userInfo = secureLocalStorage.getItem('user_info')
  if (userInfo) {
    ;({emp_id, user_id} = userInfo)
  }
  const {data: tripsData, isLoading: istripsLoading, fetchData} = useApiCall(`/travel/${emp_id}`)

  useEffect(() => {
    fetchData()
  }, [])

  return {tripsData, istripsLoading}
}

export function GetAllApprovedTrips() {
  let emp_id
  let user_id
  const userInfo = secureLocalStorage.getItem('user_info')
  if (userInfo) {
    ;({emp_id, user_id} = userInfo)
  }
  const {
    data: approvedtripsData,
    isLoading: isapprovedLoading,
    fetchData,
  } = useApiCall(`/travel/${emp_id}`)

  useEffect(() => {
    fetchData()
  }, [])

  // Filter and return only approved leaves
  const approvedtrips = approvedtripsData?.filter((leave) => leave.status === 'Approved')

  return {approvedtrips, isapprovedLoading}
}

export function GetAllEmptrips() {
  let emp_id
  let user_id
  const userInfo = secureLocalStorage.getItem('user_info')
  if (userInfo) {
    ;({emp_id, user_id} = userInfo)
  }
  const {
    data: alltripsData,
    isLoading: isalltripsLoading,
    fetchData,
  } = useApiCall(emp_id ? `/travel/${emp_id}` : null)

  useEffect(() => {
    fetchData()
  }, [])

  return {alltripsData, isalltripsLoading}
}

export function GetAllEmpApprovedtrips() {
  let emp_id
  let user_id
  const userInfo = secureLocalStorage.getItem('user_info')
  if (userInfo) {
    ;({emp_id, user_id} = userInfo)
  }

  const {
    data: getallapprovedtripsData,
    isLoading: isallapprovedLoading,
    fetchData,
  } = useApiCall(emp_id ? `/travel/${emp_id}` : null)

  useEffect(() => {
    fetchData()
  }, [])

  // Filter and return only approved leaves
  const allapprovedtrips = getallapprovedtripsData?.filter(
    (request) => request.status === 'Approved'
  )

  return {allapprovedtrips, isallapprovedLoading}
}
