import {BrandPreferences} from './preferences_cards/BrandPreferences'
import {LogosBanners} from './preferences_cards/LogosBanners'
import {WorkPreferences} from './preferences_cards/WorkPreferences'
import {PasswordPreferences} from './preferences_cards/PasswordPreferences'
import {ThemePreferences} from './preferences_cards/ThemePreferences'
import {useIntl} from 'react-intl'
import FontPreferences from './preferences_cards/font_preferences/FontPreferences'
import usePageTitle from '../../custom_hooks/usePageTitle'
import secureLocalStorage from 'react-secure-storage'
import IdGeneration from './id_generation/IdGeneration'
function Organization_preferences() {
  const intl = useIntl()
  const userInfo = secureLocalStorage.getItem('user_info')
  const {is_admin} = userInfo || {}
  usePageTitle(intl.formatMessage({id: 'MENU.LABEL.PREFERENCES'}))
  return (
    <div className='d-flex flex-column flex-grow-1'>
      <div className='d-flex flex-wrap flex-stack mb-6'>
        <h3 className='fw-bolder my-2'>
          {intl.formatMessage({id: 'LABEL.ORGANIZATION_PREFERENCES'})}
        </h3>
      </div>
      <div className='row flex-grow-1'>
        <div className='col-xl-6  mb-5'>
          <BrandPreferences />
        </div>
        <div className='col-xl-6  mb-5'>
          <WorkPreferences />
        </div>
      </div>
      {is_admin === 1 && (
        <div className='row flex-grow-1 mb-5'>
          <div className='col-xl-12'>
            <IdGeneration />
          </div>
        </div>
      )}
      <div className='row flex-grow-1'>
        <div className='col-xl-5 d-flex mb-5'>
          <LogosBanners />
        </div>
        <div className='col-xl-7 d-flex mb-5'>
          <ThemePreferences />
        </div>
      </div>
      <div className='row flex-grow-1'>
        <div className='col-xl-4 mb-5'>
          <PasswordPreferences />
        </div>
        <div className='col-xl-8 mb-5'>
          <FontPreferences />
        </div>
      </div>
    </div>
  )
}

export default Organization_preferences
