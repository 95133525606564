import {useEffect, useRef} from 'react'
import {useThemeMode} from '../../../../_metronic/partials'
import {getCSSVariableValue} from '../../../../_metronic/assets/ts/_utils'
import ApexCharts, {ApexOptions} from 'apexcharts'

interface DateChartProps {
  value: any
  data: any
}

const DateChart: React.FC<DateChartProps> = ({value, data}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()

  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const chart = new ApexCharts(chartRef.current, chartOptions('success', '220px', value, data))
    if (chart) {
      chart.render()
    }
    return chart
  }

  useEffect(() => {
    const chart = refreshChart()
    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode, value, data])

  return (
    <div className='custom-chart-wrapper'>
      <div ref={chartRef} className='mixed-widget-4-chart'></div>
    </div>
  )
}

export default DateChart

const chartOptions = (
  chartColor: string,
  chartHeight: string,
  value: any,
  data: any
): ApexOptions => {
  const baseColor = getCSSVariableValue('--bs-' + chartColor)
  const lightColor = getCSSVariableValue('--bs-' + chartColor + '-light')
  const labelColor = getCSSVariableValue('--bs-gray-700')

  // Calculate the series value based on gross_pay and deductions
  const seriesValue = calculateSeriesValue(data)

  return {
    series: [seriesValue],
    chart: {
      fontFamily: 'inherit',
      height: chartHeight,
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: '55%',
        },
        dataLabels: {
          name: {
            show: false,
            fontWeight: '700',
          },
          value: {
            color: labelColor,
            fontSize: '12px',
            fontWeight: '700',
            offsetY: 12,
            show: true,
            formatter: function () {
              return value
            },
          },
        },
        track: {
          background: lightColor,
          strokeWidth: '100%',
        },
      },
    },
    colors: [baseColor],
    stroke: {
      lineCap: 'round',
    },
    labels: ['Progress'],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            height: 200,
          },
          plotOptions: {
            radialBar: {
              size: '40%',
            },
          },
          labels: ['Progress'],
        },
      },
    ],
  }
}

const calculateSeriesValue = (data: any): number => {
  // Use gross_pay and deductions to calculate the series value
  const grossPay = parseFloat(data?.gross_pay.replace('₹', '').replace(/,/g, ''))
  const deductions = parseFloat(data?.deductions.replace('₹', '').replace(/,/g, ''))
  let seriesValue = grossPay - deductions
  seriesValue = (seriesValue / 45000) * 100
  seriesValue = Math.min(seriesValue, 100)

  return seriesValue
}
