import React, {useState, useEffect, useRef} from 'react'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import * as Yup from 'yup'

import Swal from 'sweetalert2'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../_metronic/helpers'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import {baseUrl} from '../../core'
import secureLocalStorage from 'react-secure-storage'
import DatePicker from 'react-datepicker'
import '../../DatepickerStyles.css'
import 'react-datepicker/dist/react-datepicker.css'
import {DateBasicFormat} from '../../DateHelpers'
import {useThemeMode} from '../../../../_metronic/partials'
import useApiCall from '../../custom_hooks/useApiCall'
import usePostApi from '../../custom_hooks/usePostApi'
import {parseInt} from 'lodash'

const AddInputs = ({}) => {
  let job_role_id

  const userInfo = secureLocalStorage.getItem('user_info')
  if (userInfo) {
    ;({job_role_id} = userInfo)
  }

  const {mode} = useThemeMode()
  const {execute} = usePostApi()
  const {data: jobroles} = useApiCall('/hrConfig/jobRole')
  const intl = useIntl()

  const {
    data: kpiname,
    isLoading,
    fetchData: fetchkpi,
  } = useApiCall(job_role_id ? `/masterData/kpi/${job_role_id}` : null)

  useEffect(() => {
    fetchkpi()
  }, [job_role_id])

  const validationSchema = Yup.object().shape({
    emp_start_date: Yup.string().required(
      intl.formatMessage({id: 'ERR.MSG.START_DATE_IS_REQUIRED'})
    ),
    emp_end_date: Yup.string().when('emp_start_date', (emp_start_date, schema) => {
      return schema
        .test(
          'emp_end_date',
          intl.formatMessage({id: 'ERR.MSG.END_DATE_GREATER_THAN_REQUIRED'}),
          function (value) {
            return !emp_start_date || !value || new Date(value) >= new Date(emp_start_date)
          }
        )
        .required(intl.formatMessage({id: 'ERR.MSG.END_DATE_IS_REQUIRED'}))
    }),
    activity: Yup.string().required(intl.formatMessage({id: 'ERR.MSG.ACTIVITY_IS_REQUIRED'})),
    kpi_id: Yup.string().required('Kpi is Required'),
  })
  const [Issubmitting, setIsSubmitting] = useState(false)
  const initialValues = {
    kpi_id: '',
    emp_start_date: '',
    emp_end_date: '',
    activity: '',
    kpi_name: '',
    job_role_id: '',
    roleType: '',
  }

  const handleSubmit = async (values, formikHelpers) => {
    const {resetForm} = formikHelpers
    try {
      const url = `${baseUrl}/performance/epi`
      const organizationId = secureLocalStorage.getItem('organization_id')

      const formData = {
        organization_id: organizationId,
        kpi_id: parseInt(values.kpi_id),
        job_role_id: job_role_id,
        activity: values.activity,
        emp_start_date: DateBasicFormat(values.emp_start_date),
        emp_end_date: DateBasicFormat(values.emp_end_date),
        reviewer_status: 'Pending',
        hr_reviewed_status: 'Pending',
        performance_input_status: 'Manager Pending',
      }

      await execute(url, 'POST', formData)

      resetForm()
      Swal.fire({
        title: intl.formatMessage({id: 'MSG.PERFORMANCE_ADDED_SUCCESSFULLY'}),
        text: 'You can now track the status of the Input in the “My Inputs” section..',
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-light-primary btn-sm',
        },
      })
    } catch (error) {
      console.error(error)
      Swal.fire({
        icon: 'error',
        title: intl.formatMessage({id: 'LABEL.OOPS'}),
        text: intl.formatMessage({id: 'LABEL.SOMETHING_WRONG'}),
        customClass: {
          confirmButton: 'btn btn-light-primary btn-sm',
        },
      })
    }
  }

  return (
    <div className='card shadow-none rounded-0'>
      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({values, setFieldValue, handleChange}) => (
            <Form>
              <div className='card-header' id='side_activities_header'>
                <h3 className='card-title fw-bolder text-dark'>Add EPI</h3>
                <div className='card-toolbar '>
                  <React.Fragment>
                    <div
                      className='btn btn-icon btn-sm btn-active-danger'
                      id='side_activities_close'
                    >
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr061.svg'
                        className='svg-icon svg-icon-2x'
                      />
                    </div>
                  </React.Fragment>
                </div>
              </div>

              <div className='card-body position-relative' id='side_activities_body'>
                <div>
                  <div
                    id='side_activities_scroll'
                    className='position-relative scroll-y me-n5 pe-5 w-400px h-500px'
                    data-kt-scroll='true'
                    data-kt-scroll-height='auto'
                    data-kt-scroll-wrappers='#side_activities_body'
                    data-kt-scroll-dependencies='#side_activities_header, #side_activities_footer'
                    data-kt-scroll-offset='5px'
                  >
                    {/* <div className='row mb-5 d-flex align-items-center'>
                      <label className='col-lg-4 fw-bold text-muted text-start'>Select Role</label>
                      <div className='col-lg-8 text-start'>
                        <Field
                          as='select'
                          className='form-select form-select-solid'
                          id='floatingInput'
                          name='job_role_id'
                          value={values.job_role_id || ''}
                          onChange={(e) => {
                            const selectedId = e.target.value
                            setroleId(selectedId)
                            handleChange(e)
                          }}
                          placeholder='Select Role'
                        >
                          <option value=''>Job Roles</option>
                          {jobroles?.map((roleType) => (
                            <option key={roleType.job_role_id} value={roleType.job_role_id}>
                              {roleType.role_name}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage name='job_role_id' component='div' className='text-danger' />
                      </div>
                    </div> */}

                    <div className='row mb-5 d-flex align-items-center'>
                      <label className='col-lg-4 fw-bold text-muted text-start'>
                        {intl.formatMessage({id: 'LABEL.SELECT_KPI'})}
                      </label>
                      <div className='col-lg-8 text-start'>
                        <Field
                          as='select'
                          className='form-select form-select-solid'
                          id='floatingInput'
                          name='kpi_id'
                          value={values.kpi_id}
                          onChange={handleChange}
                        >
                          <option key='' value=''>
                            {intl.formatMessage({id: 'LABEL.SELECT_KPI'})}
                          </option>
                          {kpiname?.map((kpi) => (
                            <option key={kpi.kpi_id} value={kpi.kpi_id}>
                              {kpi.kpi_name}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage name='kpi_id' component='div' className='text-danger' />
                      </div>
                    </div>

                    <div className='row mb-5 d-flex align-items-center'>
                      <label className='col-lg-4 fw-bold text-muted text-start'>
                        {intl.formatMessage({id: 'LABEL.ACTIVITY'})}
                      </label>
                      <div className='col-lg-8 text-start'>
                        <Field
                          type='text'
                          name='activity'
                          className='form-control form-control-solid'
                          value={values.activity || ''}
                          onChange={(e) => setFieldValue('activity', e.target.value)}
                          placeholder={intl.formatMessage({id: 'LABEL.ACTIVITY'})}
                        />
                        <ErrorMessage name='activity' component='div' className='text-danger' />
                      </div>
                    </div>

                    <div className='row mb-5 d-flex align-items-center'>
                      <label className='col-lg-4 fw-bold text-muted text-start'>
                        {intl.formatMessage({id: 'LABEL.START_DATE'})}
                      </label>

                      <div className='col-lg-8 text-start'>
                        <Field name='emp_start_date'>
                          {({form, field}) => {
                            const {setFieldValue} = form
                            const {value} = field

                            const handleChange = (date) => {
                              setFieldValue('emp_start_date', date)
                            }

                            return (
                              <div
                                className={`react-datepicker-fullwidth-wrapper test ${
                                  mode === 'dark' ? 'dark-mode' : 'light-mode'
                                }`}
                              >
                                <DatePicker
                                  selected={value}
                                  isClearable
                                  showYearDropdown
                                  scrollableYearDropdown
                                  onChange={(date) => {
                                    handleChange(date)
                                  }}
                                  placeholderText={intl.formatMessage({id: 'LABEL.START_DATE'})}
                                  showMonthDropdown
                                  useShortMonthInDropdown
                                  dateFormat='dd/MM/yyyy'
                                  className='form-control form-control-solid'
                                  popperPlacement='bottom'
                                />
                              </div>
                            )
                          }}
                        </Field>
                        <ErrorMessage
                          name='emp_start_date'
                          component='div'
                          className='text-danger'
                        />
                      </div>
                    </div>

                    <div className='row mb-5 d-flex align-items-center'>
                      <label className='col-lg-4 fw-bold text-muted text-start'>
                        {intl.formatMessage({id: 'LABEL.END_DATE'})}
                      </label>
                      <div className='col-lg-8 text-start'>
                        <Field name='emp_end_date'>
                          {({form, field}) => {
                            const {setFieldValue} = form
                            const {value} = field

                            const handleChange = (date) => {
                              setFieldValue('emp_end_date', date)
                            }

                            let minDate = null
                            if (values && values.emp_start_date) {
                              const startDate = new Date(values.emp_start_date)
                              minDate = startDate.setDate(startDate.getDate() + 1)
                            }

                            return (
                              <div
                                className={`react-datepicker-fullwidth-wrapper test ${
                                  mode === 'dark' ? 'dark-mode' : 'light-mode'
                                }`}
                              >
                                <DatePicker
                                  selected={value}
                                  isClearable
                                  showYearDropdown
                                  scrollableYearDropdown
                                  onChange={(date) => {
                                    handleChange(date)
                                  }}
                                  placeholderText={intl.formatMessage({id: 'LABEL.END_DATE'})}
                                  showMonthDropdown
                                  useShortMonthInDropdown
                                  dateFormat='dd/MM/yyyy'
                                  className='form-control form-control-solid'
                                  popperPlacement='bottom'
                                  minDate={minDate}
                                />
                              </div>
                            )
                          }}
                        </Field>
                        <ErrorMessage name='emp_end_date' component='div' className='text-danger' />
                      </div>
                    </div>

                    <div className='card-footer p-0 py-5 text-end'>
                      <button
                        type='submit'
                        className='btn btn-sm btn-light-primary ms-2'
                        onClick={() => {
                          setIsSubmitting(true)
                        }}
                      >
                        {intl.formatMessage({id: 'BTN.ADD'})}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export default AddInputs
