import React from 'react'

const MyContext = React.createContext({
  id: {},
  id2: {},
  id3: {},
  updateId: () => {},
  updateId2: () => {},
  updateId3: () => {},
})

export default MyContext
