import {KTIcon} from '../../../../../_metronic/helpers'
import {useIntl} from 'react-intl'
import Manager from '../../TM_expenses/reviewers_flow/Manager'
import HR from '../../TM_expenses/reviewers_flow/HR'
import Finance from '../../TM_expenses/reviewers_flow/Finance'
import DateFormatter from '../../../DateFormatter'
import '../../index.css'

const EmpAdvanceInfoSD = ({details, fetchData}) => {
  const intl = useIntl()
  const managerDetails = {
    manager_reviewed_status: details?.manager_reviewed_status,
    manager_reviewed_amount: details?.manager_reviewed_amount,
    manager_review_comments: details?.manager_review_comments,
    advance_amount: details?.advance_amount,
    id: details?.id,
    manager_reviewed_timestamp: details?.manager_reviewed_timestamp,
    fetchData: fetchData,
    currency_code: details?.currency_code,
    itemStatus: details?.advance_status,
    employee_id: details?.employee_id,
  }
  const hrDetails = {
    manager_reviewed_status: details?.manager_reviewed_status,
    hr_reviewed_status: details?.hr_reviewed_status,
    hr_reviewed_amount: details?.hr_reviewed_amount,
    hr_review_comments: details?.hr_review_comments,
    advance_amount: details?.advance_amount,
    id: details?.id,
    hr_reviewed_timestamp: details?.hr_reviewed_timestamp,
    fetchData: fetchData,
    currency_code: details?.currency_code,
    itemStatus: details?.advance_status,
  }
  const financerDetails = {
    manager_reviewed_status: details?.manager_reviewed_status,
    hr_reviewed_status: details?.hr_reviewed_status,
    finance_reviewed_status: details?.finance_reviewed_status,
    finance_reviewed_amount: details?.finance_reviewed_amount,
    finance_review_comments: details?.finance_review_comments,
    advance_amount: details?.advance_amount,
    id: details?.id,
    finance_reviewed_timestamp: details?.finance_reviewed_timestamp,
    fetchData: fetchData,
    currency_code: details?.currency_code,
    itemStatus: details?.advance_status,
  }

  const getCurrMonth = () => {
    const currentDate = new Date()
    const month = currentDate.toLocaleString('default', {month: 'long'})
    return month
  }

  const getCurrYear = () => {
    const currentDate = new Date()
    const year = currentDate.getFullYear()
    return year
  }

  const getStatusColor = (status) => {
    switch (status) {
      case 'Approved':
        return 'success'
      case 'Rejected':
        return 'danger'
      default:
        return 'muted'
    }
  }

  return (
    <div className='card shadow-none rounded-0'>
      <div
        id='teamMemberAdvanceInfo'
        className='bg-body'
        data-kt-drawer='true'
        data-kt-drawer-name='activities'
        data-kt-drawer-activate='true'
        data-kt-drawer-overlay='true'
        data-kt-drawer-width="{default:'60%', 'md':'45%', 'lg': '400px'}"
        data-kt-drawer-direction='end'
        data-kt-drawer-toggle={`#teamMemberAdvanceInfoToggle`}
        data-kt-drawer-close={`#teamMemberAdvanceInfoDrawerClose`}
      >
        <div className='w-100'>
          <div className='card-header'>
            <h3 className='card-title fw-bolder text-dark default-cursor'>
              {details?.loan_type === 'LOAN'
                ? intl.formatMessage({id: 'LABEL.LOAN_INFORMATION'})
                : intl.formatMessage({id: 'LABEL.ADV_INFORMATION'})}
            </h3>

            <div className='card-toolbar'>
              <button
                type='button'
                className='btn btn-sm btn-icon btn-active-light-primary me-n5'
                id='teamMemberAdvanceInfoDrawerClose'
              >
                <KTIcon iconName='cross' className='fs-1' />
              </button>
            </div>
          </div>

          {details ? (
            <div className='' id='side_activities_body'>
              <div className='m-8 mt-6 mb-7'>
                <div className='row mb-2 default-cursor'>
                  <label className='col-lg-4 fw-bold text-muted text-start'>
                    {intl.formatMessage({id: 'LABEL.REASON'})}
                  </label>

                  <div className='col-lg-8 text-start'>
                    <span className='fw-bolder fs-6 text-dark text-hover-primary'>
                      {details.advance_reason}
                    </span>
                  </div>
                </div>

                <div className='row mb-2 default-cursor'>
                  <label className='col-lg-4 fw-bold text-muted text-start'>
                    {intl.formatMessage({id: 'LABEL.STATUS'})}
                  </label>

                  <div className='col-lg-8 text-start'>
                    <span
                      className={`text-${getStatusColor(
                        details?.advance_status
                      )} fw-bolder fs-6 text-hover-primary default-cursor`}
                    >
                      {details?.advance_status}
                    </span>
                  </div>
                </div>

                <div className='row mb-2 default-cursor'>
                  <label className='col-lg-4 fw-bold text-muted text-start'>
                    {intl.formatMessage({id: 'LABEL.INSTALLMENTS'})}
                  </label>

                  <div className='col-lg-8 text-start'>
                    <span className='fw-bolder fs-6 text-dark text-hover-primary'>
                      {details.no_of_installments}
                    </span>
                  </div>
                </div>

                {details?.loan_type === 'LOAN' && details?.advance_status === 'Approved' && (
                  <div className='row mb-2 default-cursor'>
                    <label className='col-lg-4 fw-bold text-muted text-start'>Loan Deduction</label>

                    <div className='col-lg-8 text-start'>
                      <span className='fw-bolder fs-6 text-dark text-hover-primary'>
                        {details?.currency_code}{' '}
                        {(details?.finance_reviewed_amount / details?.no_of_installments).toFixed(
                          2
                        )}
                      </span>
                    </div>
                  </div>
                )}

                <div className='row mb-2 default-cursor'>
                  <label className='col-lg-4 fw-bold text-muted text-start'>
                    {intl.formatMessage({id: 'LABEL.NEED_BY_DATE'})}
                  </label>

                  <div className='col-lg-8 text-start'>
                    <span className='fw-bolder fs-6 text-dark text-hover-primary'>
                      {details?.advance_date ? DateFormatter(details.advance_date) : '-'}
                    </span>
                  </div>
                </div>

                {/* {details?.loan_type === 'LOAN' && (
                  <div className='row mb-2 default-cursor'>
                    <label className='col-lg-4 fw-bold text-muted text-start'>
                      {intl.formatMessage({id: 'LABEL.DUE_DATE'})}
                    </label>

                    <div className='col-lg-8 text-start'>
                      <span className='fw-bolder fs-6 text-dark text-hover-primary'>
                        {details?.pay_by_date ? DateFormatter(details.pay_by_date) : '-'}
                      </span>
                    </div>
                  </div>
                )} */}

                <div className='row mb-2 default-cursor'>
                  <label className='col-lg-4 fw-bold text-muted text-start'>
                    {intl.formatMessage({id: 'EMP.REQUESTED_DATE'})}
                  </label>

                  <div className='col-lg-8 text-start'>
                    <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                      {details.created_timestamp ? DateFormatter(details.created_timestamp) : '-'}
                    </span>
                  </div>
                </div>

                <h5 className='mt-4 text-dark default-cursor'>
                  {details?.loan_type === 'LOAN'
                    ? intl.formatMessage({id: 'LABEL.LOAN_DETAILS'})
                    : intl.formatMessage({id: 'LABEL.ADV_DETAILS'})}
                </h5>
                <hr className='text-dark mt-2' />
                <div className='d-flex justify-content-between align-items-center default-cursor'>
                  <p className='d-flex align-items-center'>
                    <KTIcon iconName='cheque' className='fs-1' />
                    <span className='fw-bold text-muted ms-3'>
                      {intl.formatMessage({id: 'LABEL.REQUESTED_AMOUNT'})}
                    </span>
                  </p>
                  <p className='fw-bold text-dark text-hover-primary'>
                    {details?.currency_code} {details?.advance_amount}
                  </p>
                </div>
                {details?.finance_reviewed_status === 'Approved' && (
                  <div className='d-flex justify-content-between align-items-center default-cursor'>
                    <p className='d-flex align-items-center'>
                      <KTIcon iconName='check-square' className='fs-1' />
                      <span className='fw-bold text-success bg-opacity-75 ms-3'>
                        {intl.formatMessage({id: 'LABEL.APPROVED_AMOUNT'})}
                      </span>
                    </p>
                    <p className='fw-bold text-success text-hover-primary'>
                      {details?.currency_code} {details?.finance_reviewed_amount}
                    </p>
                  </div>
                )}
                {details?.finance_reviewed_status === 'Rejected' ||
                details?.hr_reviewed_status === 'Rejected' ||
                details?.manager_reviewed_status === 'Rejected' ? (
                  <div className='d-flex justify-content-between align-items-center default-cursor'>
                    <p className='d-flex align-items-center'>
                      <KTIcon iconName='cross' className='fs-1' />
                      <span className='fw-bold text-danger bg-opacity-75 ms-3'>
                        {intl.formatMessage({id: 'LABEL.REJ_AMOUNT'})}
                      </span>
                    </p>
                    <p className='fw-bold text-danger text-hover-primary'>
                      {details?.currency_code} {details?.advance_amount}
                    </p>
                  </div>
                ) : null}

                {details?.finance_reviewed_status === 'Approved' && (
                  <p className='bg-light-secondary p-3 rounded default-cursor'>
                    The request has been approved and is expected to be paid out as part of the{' '}
                    {getCurrMonth()} {getCurrYear()} payroll cycle.
                  </p>
                )}

                <h5 className='mt-4 text-dark default-cursor'>
                  {intl.formatMessage({id: 'LABEL.WORKFLOW'})}
                </h5>
                <hr className='text-dark mt-2' />
                <div className='card-body position-relative p-0' id='kt_activities_body'>
                  <div className='timeline'>
                    {/* item 1 */}
                    <Manager managerDetails={managerDetails} />

                    {/* item2  */}
                    <HR hrDetails={hrDetails} />

                    {/* item 3 */}
                    <Finance financerDetails={financerDetails} />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <p>{intl.formatMessage({id: 'NO_DATA_FOUND'})}</p>
          )}
        </div>
      </div>
    </div>
  )
}

export {EmpAdvanceInfoSD}
