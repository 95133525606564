import {useLocation} from 'react-router-dom'

import TimeSheetMain from './my_timesheet/TimeSheetMain'
import {PageTitle} from '../../../_metronic/layout/core'
import TimeNavBar from './TimeNavBar'

import MyTimesheets from './my_timesheet/MyTimesheets'
import TeamTimeSheetTable from './team_timesheet/team_timetable/TeamTimeSheetTable'
import {useState} from 'react'
import Context from '../../../_metronic/partials/layout/activity-drawer/context'
import {useIntl} from 'react-intl'
import MyTimeSheetTable from './my_timesheet/MyTimeSheetTable'

const TimeRoutes = () => {
  const intl = useIntl()
  const TimeBreadCrumbs = [
    {
      title: intl.formatMessage({id: 'MENU.MY_TIMESHEET'}),
      path: '/time-and-attendance/my-timesheets',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  const {pathname} = useLocation()
  const [SideDrawerId, updateId] = useState('0')

  const switchMethod = () => {
    switch (pathname) {
      case '/time-and-attendance/my-timesheets':
        return (
          <>
            <PageTitle breadcrumbs={TimeBreadCrumbs}>
              {intl.formatMessage({id: 'MENU.MY_TIMESHEET'})}
            </PageTitle>
            <MyTimeSheetTable />
          </>
        )

      case '/time-and-attendance/team-timesheet':
        return (
          <>
            <PageTitle breadcrumbs={TimeBreadCrumbs}>
              {intl.formatMessage({id: 'MENU.TIME_AND_ATTENDANCE.TEAM_TIMESHEET'})}
            </PageTitle>
            <TeamTimeSheetTable />
          </>
        )

      default:
        return <MyTimeSheetTable />
    }
  }
  const UpdatingId = (id) => {
    updateId(id)
  }
  return (
    <>
      <Context.Provider value={{id: SideDrawerId, updateId: UpdatingId}}>
        <TimeNavBar />
        {switchMethod()}
      </Context.Provider>
    </>
  )
}

export default TimeRoutes
