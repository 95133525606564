import {useState, useEffect} from 'react'
import secureLocalStorage from 'react-secure-storage'
import {baseUrl} from '../../core'
import usePostApi from '../../custom_hooks/usePostApi'
import Swal from 'sweetalert2'
import {useIntl} from 'react-intl'

function RequestHr({hrDetails}) {
  const {execute} = usePostApi()
  const {
    request_status = null,
    fetchData = () => {},
    hr_review_comments = '',
    hr_reviewed_timestamp = '',
    requestFetchData,
    request_id = '',
  } = hrDetails

  let emp_id
  let organization_id
  let role
  let is_hr
  const userInfo = secureLocalStorage.getItem('user_info')
  if (userInfo) {
    ;({emp_id, organization_id, role, is_hr} = userInfo)
  }
  const roles = role ? JSON.parse(role) : []

  const intl = useIntl()

  const [hrComment, setHrComment] = useState('')

  const [comError, setComError] = useState(false)
  const [comLenErr, setComLenErr] = useState(false)

  useEffect(() => {
    setHrComment('')

    setComError(false)
    setComLenErr(false)
  }, [])

  useEffect(() => {
    if (hrComment !== '') {
      setComError(false)
    }
    if (hrComment.length >= 3) {
      setComLenErr(false)
    }
  }, [hrComment])

  const approveAndRejectBtns = async (event) => {
    if (hrComment === '') {
      setComError(true)
      return
    } else if (hrComment.length < 3) {
      setComLenErr(true)
      return
    }

    try {
      const url = `${baseUrl}/selfService/request/${request_id}`

      const formData = {
        organization_id: organization_id,
        hr_reviewed_by: emp_id,
        hr_review_comments: hrComment,
        hr_reviewed_status: event.target.name,
        request_status: event.target.name,
      }

      await execute(url, 'PATCH', formData)

      fetchData()
      setHrComment('')
      requestFetchData()

      // Determine the success message based on the request_status
      const successMessage =
        event.target.name === 'Approved'
          ? intl.formatMessage({id: 'MSG.REQUEST_APPROVED_SUCCESSFULLY'})
          : intl.formatMessage({id: 'MSG.REQUEST_REJECTED_SUCCESSFULLY'})

      await Swal.fire({
        title: successMessage, // Use the successMessage variable
        text: intl.formatMessage({id: 'LABEL.CHANGES_SAVED'}),
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-light-primary btn-sm',
        },
      })
    } catch (error) {
      console.error('Error:', error)
      Swal.fire({
        icon: 'error',
        title: intl.formatMessage({id: 'LABEL.OOPS'}),
        text: intl.formatMessage({id: 'LABEL.SOMETHING_WRONG'}),
        customClass: {
          confirmButton: 'btn btn-light-primary btn-sm',
        },
      })
    }
  }
  const getHrView = (hrStatus) => {
    if (hrStatus === 'HR Pending' && is_hr === 1) {
      return (
        <div className='min-h-26px'>
          <label>{intl.formatMessage({id: 'LABEL.COMMENTS'})}</label>
          <br />
          <textarea
            placeholder={intl.formatMessage({id: 'LABEL.COMMENTS'})}
            className='form-control mb-3 form-control-solid'
            value={hrComment}
            onChange={(e) => setHrComment(e.target.value)}
          />
          {comError && <p className='text-danger'>Comments is Required</p>}
          {comLenErr && !comError && (
            <p className='text-danger'>Comments must be at least 3 characters</p>
          )}
          <div>
            <button
              className='btn btn-light-success btn-sm py-2'
              name='Approved'
              onClick={approveAndRejectBtns}
              type='submit'
              // id='side_activities_close'
            >
              {intl.formatMessage({id: 'LABEL.APPROVE'})}
            </button>
            <button
              className='btn btn-light-danger btn-sm ms-2 py-2'
              name='Rejected'
              onClick={approveAndRejectBtns}
              type='submit'
              // id='side_activities_close'
            >
              {intl.formatMessage({id: 'LABEL.REJECT'})}
            </button>
          </div>
        </div>
      )
    } else if (hrStatus === 'Approved' || hrStatus === 'Rejected') {
      return (
        <>
          {/* <p className='m-0 text-muted'>{request_status}</p> */}
          <p className='m-0 text-muted'>{hr_review_comments}</p>
          <p className='m-0 text-muted'>{hr_reviewed_timestamp.slice(0, 10)}</p>
        </>
      )
    } else {
      return null
    }
  }

  const getStatusMsg = (hrStatus) => {
    if (hrStatus === 'Pending') {
      return 'Pending'
    } else if (hrStatus === 'Rejected') {
      return 'Rejected'
    } else if (hrStatus === 'Approved') {
      return request_status
    } else {
      return hrStatus
    }
  }

  const getStatusColor = (hrStatus) => {
    if (hrStatus === 'Pending') {
      return 'muted'
    } else if (hrStatus === 'Approved') {
      return 'success'
    } else {
      return 'danger'
    }
  }

  return (
    <div className='timeline-item'>
      <div className='timeline-line w-20px'></div>

      <div className='timeline-icon symbol symbol-circle symbol-20px'>
        <div
          className={`symbol-label bg-${getStatusColor(
            request_status
          )} icon-dotted-border text-${getStatusColor(request_status)}`}
        ></div>
      </div>

      <div className='timeline-content mb-12 mt-n1'>
        <div className='d-flex justify-content-between mt-1'>
          <p className={`mb-0 fw-bold text-${getStatusColor(request_status)}`}>
            {getStatusMsg(request_status)}
          </p>
          <div className=''>
            <p className='designation-btn bg-secondary fs-7 m-auto'>
              {intl.formatMessage({id: 'LABEL.HR'})}
            </p>
          </div>
        </div>
        {getHrView(request_status)}
      </div>
    </div>
  )
}

export default RequestHr
