import React from 'react'
import {Link, useLocation} from 'react-router-dom'
import {KTIcon} from '../../../_metronic/helpers'
import {useIntl} from 'react-intl'
import {GetBusinessUnitAll, GetDepartmentAll} from './OrganizationAPI'
import useApiCall from '../custom_hooks/useApiCall'
import secureLocalStorage from 'react-secure-storage'
import {defaultProfileImage} from '../core'
const Organization: React.FC = () => {
  const location = useLocation()
  const {data, isLoading}: any | null = useApiCall(`/organization/1`)
  const locationName = data && data[0]
  const brandLogos: any | null = secureLocalStorage.getItem('brand_logos')
  const {brand_logo_url} = brandLogos || {}
  const brandName = localStorage.getItem('displayName') || 'Kloudworx'
  const {businessUnitData} = GetBusinessUnitAll()
  const {departmenteData} = GetDepartmentAll()
  const intl = useIntl()
  return (
    <div className='card mb-4 mb-xl-6'>
      <div className='card-body pt-8 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap'>
          <div className='me-4 mb-3'>
            <div className='symbol symbol-100px symbol-lg-150px symbol-fixed position-relative'>
              <img
                src={brand_logo_url ? `${brand_logo_url}?v=${Date.now()}` : defaultProfileImage}
                alt='Image'
              />
            </div>
          </div>
          {isLoading ? (
            <div className='card h-100px'>
              <div className='m-auto d-flex flex-column align-items-center'>
                <div className='spinner-border spinner-primary mr-15'></div>
              </div>
            </div>
          ) : (
            <div className='flex-grow-1'>
              <div className='d-flex justify-content-between align-items-start flex-wrap'>
                <div className='d-flex flex-column'>
                  <div className='d-flex align-items-center mb-2'>
                    <p className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1 default-cursor mb-0'>
                      {brandName}
                    </p>
                  </div>
                  <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                    <p className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2 default-cursor'>
                      <KTIcon iconName='bank' className='fs-4 me-1' />
                      {locationName?.address_line1}
                    </p>
                    <p className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2 default-cursor'>
                      <KTIcon iconName='geolocation' className='fs-4 me-1' />
                      {locationName?.country_name}
                    </p>
                  </div>
                </div>
              </div>
              <div className='d-flex flex-wrap flex-stack'>
                <div className='d-flex flex-column flex-grow-1 pe-8'>
                  <div className='d-flex flex-wrap'>
                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-4'>
                      <div className='d-flex align-items-center'>
                        <div className='fs-2 fw-bolder'>
                          {businessUnitData?.length > 0 ? businessUnitData?.length : 0}
                        </div>
                      </div>
                      <div className='fw-bold fs-6 text-gray-400'>
                        {intl.formatMessage({id: 'MENU.ORGANIZATION_CONFIGURATION.BUSINESS_UNITS'})}
                      </div>
                    </div>
                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-4'>
                      <div className='d-flex align-items-center'>
                        <div className='fs-2 fw-bolder'>
                          {departmenteData?.length > 0 ? departmenteData?.length : 0}
                        </div>
                      </div>

                      <div className='fw-bold fs-6 text-gray-400'>
                        {intl.formatMessage({id: 'MENU.ORGANIZATION_CONFIGURATION.DEPARTMENTS'})}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className='d-flex overflow-auto h-55px'>
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === '/organization-configuration/organization' && 'active')
                }
                to='/organization-configuration/organization'
              >
                {intl.formatMessage({id: 'MENU.LABEL.INFORMATION'})}
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === '/organization-configuration/business-units' && 'active')
                }
                to='/organization-configuration/business-units'
              >
                {intl.formatMessage({id: 'MENU.ORGANIZATION_CONFIGURATION.BUSINESS_UNITS'})}
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === '/organization-configuration/departments' && 'active')
                }
                to='/organization-configuration/departments'
              >
                {intl.formatMessage({id: 'MENU.ORGANIZATION_CONFIGURATION.DEPARTMENTS'})}
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === '/organization-configuration/preferences' && 'active')
                }
                to='/organization-configuration/preferences'
              >
                {intl.formatMessage({id: 'MENU.LABEL.PREFERENCES'})}
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === '/organization-configuration/locations' && 'active')
                }
                to='/organization-configuration/locations'
              >
                {intl.formatMessage({id: 'MENU.ORGANIZATION_CONFIGURATION.LOCATIONS'})}
              </Link>
            </li>
            <li className='nav-item nav-item-links'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === '/organization-configuration/countries' && 'active')
                }
                to='/organization-configuration/countries'
              >
                {intl.formatMessage({id: 'LABEL.GEOGRAPHY'})}
              </Link>
            </li>
            <li className='nav-item nav-item-links'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === '/organization-configuration/charts' && 'active')
                }
                to='/organization-configuration/charts'
              >
                {intl.formatMessage({id: 'MENU.LABEL.ORGANOGRAM'})}
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Organization
