import React, {useEffect, useState} from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import {useIntl} from 'react-intl'
import * as Yup from 'yup'
import {ErrorMessage, Field, Form, Formik} from 'formik'
import Swal from 'sweetalert2'
import {baseUrl} from '../../core'
import secureLocalStorage from 'react-secure-storage'
import usePostApi from '../../custom_hooks/usePostApi'
import {useParams} from 'react-router-dom'
import useApiCall from '../../custom_hooks/useApiCall'
const EditExpenseType = (props: any) => {
  const {id: expenseTypeId, refreshData} = props.id
  const intl = useIntl()
  const {execute} = usePostApi()
  const {id}: any | null = useParams()
  const userInfo: any | null = secureLocalStorage.getItem('user_info')
  const {data, fetchData, isLoading}: any | null = useApiCall(
    `/hrConfig/expenseTypes/${expenseTypeId}`
  )
  let expenseTypeData = data && data[0]
  const {organization_id} = userInfo || {}
  const [name, setName] = useState<string | null>('')
  const [description, setDescription] = useState<string | null>('')
  const [expenseCode, setExpenseCode] = useState<string | null>('')
  const [coaCode, setCoaCode] = useState<string | null>('')
  const [isChecked, setIsChecked] = useState<boolean>(true)

  useEffect(() => {
    if (expenseTypeData) {
      setName(expenseTypeData.expense_type_name)
      setDescription(expenseTypeData.description)
      setExpenseCode(expenseTypeData.expense_code)
      setCoaCode(expenseTypeData.coa_code)
      setIsChecked(expenseTypeData.status === 1)
    }
  }, [expenseTypeData])
  const initialValues = {
    expense_type_name: name || '',
    expense_code: expenseCode || '',
    coa_code: coaCode || '',
    status: isChecked || false,
    description: description || '',
  }
  const validationSchema = Yup.object().shape({
    expense_type_name: Yup.string().required(intl.formatMessage({id: 'LABEL.NAME_REQUIRED'})),
    expense_code: Yup.string().required(intl.formatMessage({id: 'LABEL.CODE_REQUIRED'})),
    coa_code: Yup.string().required(intl.formatMessage({id: 'LABEL.COA_ID_REQUIRED'})),
    description: Yup.string().required(intl.formatMessage({id: 'LABEL.DESCRIPTION_REQUIRED'})),
    status: Yup.boolean().required(intl.formatMessage({id: 'LABEL.STATUS_REQUIRED'})),
  })

  const handleEditSubmit = async (values: any) => {
    const url = `${baseUrl}/hrConfig/expenseTypes/${expenseTypeId}`
    try {
      const requestData = {
        organization_id: organization_id,
        expense_type_name: values.expense_type_name,
        expense_code: values.expense_code,
        coa_code: values.coa_code,
        description: values.description,
        status: values.status ? 1 : 0,
      }
      await execute(url, 'PATCH', requestData, id)
      refreshData()
      const closeBtn = document.querySelector(
        '#kt_modal_edit_expenseType [data-bs-dismiss="modal"]'
      ) as HTMLElement
      if (closeBtn) {
        closeBtn.click()
      }
      Swal.fire({
        title: 'Expense Type Edited Successfully',
        text: intl.formatMessage({id: 'LABEL.CHANGES_SAVED'}),
        icon: 'success',
        showConfirmButton: true,
        confirmButtonText: 'OK',
        customClass: {
          confirmButton: 'btn btn-light-primary btn-sm',
        },
      })
    } catch (error) {
      console.error(error)
      Swal.fire({
        icon: 'error',
        title: intl.formatMessage({id: 'LABEL.OOPS'}),
        text: intl.formatMessage({id: 'LABEL.SOMETHING_WRONG'}),
      })
    }
  }
  //
  useEffect(() => {
    fetchData()
  }, [expenseTypeId])

  return (
    <div>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={handleEditSubmit}
      >
        {({values, handleChange}) => {
          return (
            <Form>
              <div className='modal-dialog'>
                <div className='modal-content'>
                  <div className='modal-header'>
                    <h5 className='modal-title'>
                      {intl.formatMessage({id: 'LABEL.EDIT_EXPENSE_TYPE'})}
                    </h5>
                    <div className='modal-toolbar'>
                      <div
                        className='btn btn-icon btn-sm btn-active-light-danger'
                        data-bs-dismiss='modal'
                        aria-label='Close'
                      >
                        <KTIcon iconName='cross' className='fs-2' />
                      </div>
                    </div>
                  </div>
                  <div className='modal-body position-relative'>
                    <div>
                      {isLoading ? (
                        <div className='card card-xl-stretch'>
                          <div className='m-auto d-flex flex-column align-items-center'>
                            <div className='spinner-border spinner-primary mr-15'></div>
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className='form-floating mb-4 flex-fill'>
                            <Field
                              type='text'
                              className='form-control'
                              id='expense_type_name'
                              placeholder='type...'
                              name='expense_type_name'
                              value={values.expense_type_name}
                              onChange={handleChange}
                            />

                            <label htmlFor='expense_type_name'>
                              {intl.formatMessage({id: 'LABEL.NAME'})}
                            </label>
                            <ErrorMessage
                              component='div'
                              className='text-danger'
                              name='expense_type_name'
                            />
                          </div>
                          <div className='mb-4 d-flex'>
                            <div className='form-floating flex-fill'>
                              <Field
                                type='text'
                                className='form-control'
                                id='expense_code'
                                placeholder='type...'
                                name='expense_code'
                              />
                              <label htmlFor='expense_code'>
                                {intl.formatMessage({id: 'LABEL.CODE'})}
                              </label>
                              <ErrorMessage
                                component='div'
                                className='text-danger'
                                name='expense_code'
                              />
                            </div>
                            <div className='form-floating flex-fill ms-2'>
                              <Field
                                type='text'
                                className='form-control'
                                id='coa_code'
                                placeholder='type...'
                                name='coa_code'
                              />
                              <label htmlFor='coa_code'>
                                {intl.formatMessage({id: 'LABEL.COA_ID'})}
                              </label>
                              <ErrorMessage
                                component='div'
                                className='text-danger'
                                name='coa_code'
                              />
                            </div>
                          </div>
                          <div className='form-floating flex-fill mb-4'>
                            <Field
                              type='text'
                              className='form-control'
                              id='description'
                              placeholder='Type..'
                              rows={3}
                              name='description'
                              value={values.description}
                              onChange={handleChange}
                            />
                            <label htmlFor='description'>
                              {intl.formatMessage({id: 'LABEL.DESCRIPTION'})}
                            </label>
                            <ErrorMessage
                              component='div'
                              className='text-danger'
                              name='description'
                            />
                          </div>
                          <div className='form-floating flex-fill'>
                            <div className='form-check form-check-solid form-switch mb-0'>
                              <Field
                                className='form-check-input w-40px h-20px'
                                type='checkbox'
                                name='status'
                                id='toggle'
                                checked={values.status}
                              />
                              <span
                                className={`me-4 fw-bold ${
                                  values.status ? 'text-success' : 'text-danger'
                                }`}
                              >
                                {values.status ? 'Active' : 'Inactive'}
                              </span>
                              <ErrorMessage component='div' className='text-danger' name='status' />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className='modal-footer text-end'>
                    <div
                      className='btn btn-sm btn-secondary m-2'
                      data-bs-dismiss='modal'
                      aria-label='Close'
                    >
                      {intl.formatMessage({id: 'LABEL.CANCEL'})}
                    </div>
                    <button className='btn btn-light-primary btn-sm' type='submit'>
                      <KTIcon iconName='check' className='fs-7' />
                      {intl.formatMessage({id: 'BTN.SAVE'})}
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export {EditExpenseType}
