import {useEffect} from 'react'
import {LoanCard} from './LoanCard'
import EmployeeAdvance from './EmployeeAdvance'
import {EmployeeLoanChart} from './EmployeeLoanChart'
import {EmpAdvanceInfoSD} from './EmpAdvanceDetailsSD'
import ExpContext from '../../ExpensesContext'
import {useIntl} from 'react-intl'
import {KTIcon} from '../../../../../_metronic/helpers'
import AddAdvanceSideDrawer from './AddNewLoanSD'
import useApiCall from '../../../custom_hooks/useApiCall'
import usePageTitle from '../../../custom_hooks/usePageTitle'

function LoansAdvances() {
  const intl = useIntl()
  usePageTitle(intl.formatMessage({id: 'MY_LOANS_AND_ADVANCES'}))
  const {data: empAdvanceDetails, isLoading, fetchData} = useApiCall('/employee/advances')

  const getUpdatedData = () => {
    fetchData()
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <ExpContext.Consumer>
      {(value) => {
        const {ExpInfo} = value
        return (
          <>
            <div className='g-5 g-xxl-8'>
              {isLoading ? (
                <div className='card h-250px'>
                  <div className='m-auto d-flex flex-column align-items-center'>
                    <div className='spinner-border spinner-primary mr-15'></div>
                  </div>
                </div>
              ) : empAdvanceDetails && empAdvanceDetails.length > 0 ? (
                <>
                  <div className='d-flex flex-wrap flex-stack mb-6'>
                    <h3 className='fw-bolder my-2 mx-2 default-cursor'>
                      {intl.formatMessage({id: 'MY_LOANS_AND_ADVANCES'})}
                    </h3>

                    <div className='d-flex flex-wrap my-2'>
                      <a
                        href='#'
                        className='btn btn-light-primary btn-sm btn-ripple'
                        id='addNewAdvanceToggle'
                      >
                        <KTIcon iconName='plus' className='fs-3' />
                        {intl.formatMessage({id: 'BTN.APPLY_LOAN'})}
                      </a>
                    </div>
                  </div>
                  <div className='d-flex row mb-5'>
                    <div className='col-xl-4'>
                      <div className='flex-grow-1'>
                        <LoanCard advancesData={empAdvanceDetails} />
                      </div>
                    </div>
                    <div className='col-xl-8 mt-sm-5 mt-lg-0'>
                      <div className='flex-grow-1'>
                        <EmployeeAdvance
                          empAdvancesData={empAdvanceDetails}
                          fetchData={fetchData}
                        />
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div className='card h-250px mb-5'>
                  <div className='m-auto d-flex flex-column align-items-center'>
                    <h1 className='mb-5'>
                      {intl.formatMessage({id: 'LABEL.NO_LOANS_OR_ADV_FOUND'})}
                    </h1>
                    <button className='btn btn-sm btn-light-primary' id='addNewAdvanceToggle'>
                      <KTIcon iconName='plus' className='fs-3' />
                      {intl.formatMessage({id: 'BTN.APPLY_LOAN'})}
                    </button>
                  </div>
                </div>
              )}
              {/* <div>
                <EmployeeLoanChart
                  className='card-xl-stretch mb-xl-8'
                  chartColor='info'
                  chartHeight='200px'
                />
              </div> */}
            </div>
            <EmpAdvanceInfoSD details={ExpInfo} />
            <AddAdvanceSideDrawer updateExpensesFunc={getUpdatedData} />
          </>
        )
      }}
    </ExpContext.Consumer>
  )
}

export default LoansAdvances
