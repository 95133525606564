import {useIntl} from 'react-intl'
import {useMemo, useState} from 'react'
import {useTable, usePagination} from 'react-table'
import {Pagination} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import usePageTitle from '../../../custom_hooks/usePageTitle'
import {KTIcon} from '../../../../../_metronic/helpers'
import {AddState} from './AddState'
import {EditState} from './EditState'
import useApiCall from '../../../custom_hooks/useApiCall'
import Cities from '../cities/Cities'
import secureLocalStorage from 'react-secure-storage'
const DataTable = ({columns, data, loading, name, id, showCities, stateId, fetchData}) => {
  const [inputVal, setInputVal] = useState('')
  const [functionCall, updateId] = useState('')
  const userInfo = secureLocalStorage.getItem('user_info')
  const {is_admin} = userInfo || {}
  const intl = useIntl()
  usePageTitle(intl.formatMessage({id: 'LABEL.STATES'}))
  const filteredData = useMemo(() => {
    const filteredStates = data.filter((each) =>
      each.state_name.toLowerCase().includes(inputVal.toLowerCase())
    )
    const sortedStates = filteredStates.sort((a, b) => a.state_name.localeCompare(b.state_name))

    return sortedStates
  }, [data, inputVal])

  const activeData = useMemo(() => {
    return filteredData.filter((record) => record.status === 1)
  }, [filteredData])

  const inactiveData = useMemo(() => {
    return filteredData.filter((record) => record.status === 0)
  }, [filteredData])

  // Table instances for active and inactive datasets
  const activeTableInstance = useTable({columns, data: activeData}, usePagination)
  const inactiveTableInstance = useTable({columns, data: inactiveData}, usePagination)

  // Extract required values from table instances
  const activePage = activeTableInstance.page
  const activePageCount = activeTableInstance.pageCount
  const activePageIndex = activeTableInstance.state.pageIndex
  const activePageSize = activeTableInstance.state.pageSize

  const inactivePage = inactiveTableInstance.page
  const inactivePageCount = inactiveTableInstance.pageCount
  const inactivePageIndex = inactiveTableInstance.state.pageIndex
  const inactivePageSize = inactiveTableInstance.state.pageSize

  // Handle pagination for active dataset
  const gotoActivePage = (pageIndex) => {
    activeTableInstance.gotoPage(pageIndex)
  }
  const setActivePageSize = (pageSize) => {
    activeTableInstance.setPageSize(pageSize)
  }
  // Handle pagination for inactive dataset
  const gotoInactivePage = (pageIndex) => {
    inactiveTableInstance.gotoPage(pageIndex)
  }

  const setInactivePageSize = (pageSize) => {
    inactiveTableInstance.setPageSize(pageSize)
  }
  const pageOptions = [5, 10, 20, 30, 40, 50]
  return (
    <>
      <div className='d-flex flex-wrap flex-stack mb-6'>
        <h3 className='fw-bolder my-2 mx-2'>{intl.formatMessage({id: 'LABEL.STATES_CITIES'})}</h3>
        <div className='d-flex flex-wrap my-2'>
          <Link className='btn btn-secondary btn-sm' to='/organization-configuration/countries'>
            <KTIcon iconName='black-left' className='fs-4' />
            {intl.formatMessage({id: 'AUTH.GENERAL.BACK_BUTTON'})}
          </Link>
        </div>
      </div>
      <div className='row g-5 g-xxl-8'>
        <div className='col-xl-6'>
          {loading ? (
            <div className='card h-250px'>
              <div className='m-auto d-flex flex-column align-items-center'>
                <div className='spinner-border spinner-primary mr-15'></div>
              </div>
            </div>
          ) : data.length > 0 ? (
            <div className='card'>
              <div className='card-header border-0 pt-5'>
                <div className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bold fs-4 mb-3'>
                    <span className='col-lg-4 fw-bold text-muted'>Country Name : </span>
                    {name}
                  </span>
                  <ul className='nav fs-7'>
                    <li className='nav-item'>
                      <a
                        className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary active fw-bold px-4 me-1 ms-0'
                        data-bs-toggle='tab'
                        href='#kt_table_widget_4_tab_1'
                      >
                        {intl.formatMessage({id: 'LABEL.ACTIVE'})}
                      </a>
                    </li>
                    {is_admin === 1 && (
                      <li className='nav-item'>
                        <a
                          className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1'
                          data-bs-toggle='tab'
                          href='#kt_table_widget_4_tab_2'
                        >
                          {intl.formatMessage({id: 'LABEL.INACTIVE'})}
                        </a>
                      </li>
                    )}
                  </ul>
                </div>
                <div className='card-toolbar'>
                  <div className='d-flex align-items-center position-relative me-4'>
                    <KTIcon iconName='magnifier' className='fs-3 position-absolute ms-3' />
                    <input
                      type='text'
                      id='kt_filter_search'
                      className='form-control form-control-white form-control-sm w-150px ps-9'
                      placeholder={intl.formatMessage({id: 'LABEL.SEARCH'})}
                      value={inputVal}
                      onChange={(e) => setInputVal(e.target.value)}
                      autoComplete='off'
                    />
                  </div>
                  {is_admin === 1 && (
                    <a
                      href='#'
                      className='btn btn-sm btn-light-primary mx-2'
                      data-bs-toggle='modal'
                      data-bs-target='#kt_modal_add_state'
                      onClick={() => {
                        updateId({refreshData: fetchData})
                      }}
                    >
                      <KTIcon iconName='plus' className='fs-3' />
                      STATE
                    </a>
                  )}
                </div>
              </div>
              <div>
                <div className='tab-content px-2'>
                  <div
                    className='card-body tab-pane fade show active table-responsive pt-2'
                    id='kt_table_widget_4_tab_1'
                  >
                    <table
                      className='table table-row-dashed align-middle table-row-gray-300'
                      {...activeTableInstance.getTableProps()}
                    >
                      <thead>
                        <tr className='fw-bold text-muted'>
                          <th className='min-w-140px'>State Name</th>
                          <th className='min-w-140px'>
                            {intl.formatMessage({id: 'LABEL.STATUS'})}
                          </th>
                          <th className='min-w-120px text-center'>
                            {intl.formatMessage({id: 'LABEL.ACTION'})}
                          </th>
                        </tr>
                      </thead>
                      <tbody {...activeTableInstance.getTableBodyProps()}>
                        {activePage.length > 0 ? (
                          activePage.map((row) => {
                            activeTableInstance.prepareRow(row)
                            return (
                              <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => (
                                  <td className=' ' {...cell.getCellProps()}>
                                    {cell.render('Cell')}
                                  </td>
                                ))}
                              </tr>
                            )
                          })
                        ) : (
                          <tr>
                            <td className='card'>
                              <p className='text-dark fw-bold text-hover-primary d-block fs-6 m-0 my-5'>
                                {intl.formatMessage({id: 'NO_RESULTS_FOUND_MSG'})}
                              </p>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    {data.length > 10 && (
                      <Pagination>
                        <Pagination.First
                          onClick={() => gotoActivePage(0)}
                          disabled={!activeTableInstance.canPreviousPage}
                        />
                        <Pagination.Prev
                          onClick={activeTableInstance.previousPage}
                          disabled={!activeTableInstance.canPreviousPage}
                        />
                        {[...Array(activePageCount)].map((_, i) => (
                          <Pagination.Item
                            key={i}
                            active={i === activePageIndex}
                            onClick={() => gotoActivePage(i)}
                          >
                            {i + 1}
                          </Pagination.Item>
                        ))}
                        <Pagination.Next
                          onClick={activeTableInstance.nextPage}
                          disabled={!activeTableInstance.canNextPage}
                        />
                        <Pagination.Last
                          onClick={() => gotoActivePage(activePageCount - 1)}
                          disabled={!activeTableInstance.canNextPage}
                        />
                        <div className='d-flex align-items-center mx-5'>
                          <span className='me-2'>{intl.formatMessage({id: 'LABEL.SHOW'})}</span>
                          <select
                            className='form-select form-select-solid fw-bold w-75px me-2'
                            value={activePageSize}
                            onChange={(e) => setActivePageSize(Number(e.target.value))}
                          >
                            {pageOptions.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>
                        </div>
                      </Pagination>
                    )}
                  </div>

                  {/* Inactive Tab */}
                  <div
                    className='card-body tab-pane fade table-responsive pt-2'
                    id='kt_table_widget_4_tab_2'
                  >
                    <table
                      className='table table-row-dashed align-middle table-row-gray-300'
                      {...inactiveTableInstance.getTableProps()}
                    >
                      <thead>
                        <tr className='fw-bold text-muted'>
                          <th className='min-w-140px'>State Name</th>
                          <th className='min-w-140px'>
                            {intl.formatMessage({id: 'LABEL.STATUS'})}
                          </th>
                          <th className='min-w-120px text-center'>
                            {intl.formatMessage({id: 'LABEL.ACTION'})}
                          </th>
                        </tr>
                      </thead>
                      <tbody {...inactiveTableInstance.getTableBodyProps()}>
                        {inactivePage.length > 0 ? (
                          inactivePage.map((row) => {
                            inactiveTableInstance.prepareRow(row)
                            return (
                              <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => (
                                  <td className=' ' {...cell.getCellProps()}>
                                    {cell.render('Cell')}
                                  </td>
                                ))}
                              </tr>
                            )
                          })
                        ) : (
                          <tr>
                            <td className='card'>
                              <p className='text-dark fw-bold text-hover-primary d-block fs-6 m-0 my-5'>
                                {intl.formatMessage({id: 'NO_RESULTS_FOUND_MSG'})}
                              </p>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    {data.length > 10 && (
                      <Pagination>
                        <Pagination.First
                          onClick={() => gotoInactivePage(0)}
                          disabled={!inactiveTableInstance.canPreviousPage}
                        />
                        <Pagination.Prev
                          onClick={inactiveTableInstance.previousPage}
                          disabled={!inactiveTableInstance.canPreviousPage}
                        />
                        {[...Array(inactivePageCount)].map((_, i) => (
                          <Pagination.Item
                            key={i}
                            active={i === inactivePageIndex}
                            onClick={() => gotoInactivePage(i)}
                          >
                            {i + 1}
                          </Pagination.Item>
                        ))}
                        <Pagination.Next
                          onClick={inactiveTableInstance.nextPage}
                          disabled={!inactiveTableInstance.canNextPage}
                        />
                        <Pagination.Last
                          onClick={() => gotoInactivePage(inactivePageCount - 1)}
                          disabled={!inactiveTableInstance.canNextPage}
                        />
                        <div className='d-flex align-items-center mx-5'>
                          <span className='me-2'>{intl.formatMessage({id: 'LABEL.SHOW'})}</span>
                          <select
                            className='form-select form-select-solid fw-bold w-75px me-2'
                            value={inactivePageSize}
                            onChange={(e) => setInactivePageSize(Number(e.target.value))}
                          >
                            {pageOptions.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>
                        </div>
                      </Pagination>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className='card h-250px'>
              <div className='m-auto d-flex flex-column align-items-center'>
                <h1 className='mb-5'>No States Found</h1>
                {is_admin === 1 && (
                  <button
                    className='btn btn-sm btn-light-primary'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_add_state'
                    onClick={() => {
                      updateId({refreshData: fetchData})
                    }}
                  >
                    <KTIcon iconName='plus' className='fs-3' />
                    STATE
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
        <div className='col-xl-6'>{showCities && <Cities value={stateId} />}</div>
      </div>
      <div className='modal fade' tabIndex={-1} id='kt_modal_add_state'>
        <AddState id={{id, functionCall}} />
      </div>
      <div className='modal fade' tabIndex={-1} id='kt_modal_edit_state'>
        <EditState value={stateId} />
      </div>
    </>
  )
}

const States = (value) => {
  const {props} = value
  const {data: TableData, isLoading, fetchData} = useApiCall(`/state/${props.id}`)
  const userInfo = secureLocalStorage.getItem('user_info')
  const {is_admin} = userInfo || {}
  const [stateId, updateStateId] = useState('11')
  const [showCities, setShowCities] = useState(false)
  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'state_name',
        Cell: ({row}) => {
          return (
            <div className='d-flex align-items-center'>
              <div className='d-flex justify-content-start flex-column'>
                <p className='text-dark fw-bold text-hover-primary fs-6 default-cursor'>
                  {row.original.state_name}
                </p>
              </div>
            </div>
          )
        },
        disableSortBy: true,
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({row}) => {
          const statusColor = row.original.status === 1 ? 'success' : 'danger'
          return (
            <p
              className={`text-${statusColor} fw-bold text-hover-primary d-block fs-6 default-cursor`}
            >
              {row.original.status === 1 ? 'Active' : 'Inactive'}
            </p>
          )
        },
      },
      {
        Header: 'Actions',
        accessor: 'Actions',
        Cell: ({row}) => {
          return (
            <div className='d-flex justify-content-center align-items-center'>
              {is_admin === 1 && (
                <div
                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                  data-bs-toggle='modal'
                  data-bs-target='#kt_modal_edit_state'
                  onClick={() => {
                    updateStateId({id: row.original.state_id, refreshData: fetchData})
                  }}
                >
                  <KTIcon iconName='pencil' className='fs-3' />
                </div>
              )}
              <div
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm ms-2'
                onClick={() => {
                  updateStateId({
                    id: row.original.state_id,
                    name: row.original.state_name,
                  })
                  setShowCities(true)
                }}
              >
                <KTIcon iconName='black-right' className='fs-2x' />
              </div>
            </div>
          )
        },
      },
    ],
    []
  )

  return (
    <DataTable
      columns={columns}
      data={TableData ? TableData : []}
      loading={isLoading}
      name={props.name ? props.name : ''}
      id={props.id ? props.id : ''}
      showCities={showCities}
      stateId={stateId}
      fetchData={fetchData}
    />
  )
}

export default States
