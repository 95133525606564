import {useEffect, useState} from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import {useIntl} from 'react-intl'
import * as Yup from 'yup'
import {ErrorMessage, Field, Form, Formik} from 'formik'
import Swal from 'sweetalert2'
import {baseUrl} from '../../core'
import secureLocalStorage from 'react-secure-storage'
import usePostApi from '../../custom_hooks/usePostApi'
import useApiCall from '../../custom_hooks/useApiCall'
import {useParams} from 'react-router-dom'
const EditDepartment = (props: any) => {
  const intl = useIntl()
  const {execute} = usePostApi()
  const {id}: any | null = useParams()
  const userInfo: any | null = secureLocalStorage.getItem('user_info')
  const {is_admin, is_hr, organization_id} = userInfo || {}
  const {data, fetchData, isLoading}: any | null = useApiCall(`/departments/${props.props.id}`)
  const {data: buName}: any | null = useApiCall(`/masterData/business_units`)
  const {data: headName}: any | null = useApiCall('/masterData/employees')
  let departmentData = data && data[0]
  const brandLogos: any | null = secureLocalStorage.getItem('brand_logos')
  const {brand_logo_url} = brandLogos || {}
  const brandName = localStorage.getItem('displayName') || 'Kloudworx'
  const {data: organizationAddress}: any | null = useApiCall(`/organization/1`)
  const locationName = organizationAddress && organizationAddress[0]
  const [isEditMode, setIsEditMode] = useState(false)
  const [depName, setDepName] = useState<string | null>('')
  const [depDescription, setDepDescription] = useState<string | null>('')
  const [depCode, setDepCode] = useState<string | null>('')
  const [depHead, setDepHead] = useState<string | null>('')
  const [bunitName, setBunitName] = useState(1)
  const [depErpId, setDepErpId] = useState<string | null>('')
  const [depIsChecked, setDepIsChecked] = useState<boolean>(true)

  useEffect(() => {
    if (departmentData) {
      setDepName(departmentData.department_name)
      setDepDescription(departmentData.department_description)
      setDepCode(departmentData.dep_code)
      setDepHead(departmentData.dep_head)
      setBunitName(departmentData.business_unit_id)
      setDepErpId(departmentData.erp_id)
      setDepIsChecked(departmentData.status === 1)
    }
  }, [departmentData])
  const initialValues = {
    name: depName || '',
    status: depIsChecked || false,
    description: depDescription || '',
    depCode: depCode || '',
    headName: depHead || '',
    buName: bunitName || '',
    erpId: depErpId || '',
  }
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Department Name is Required'),
    description: Yup.string().required(intl.formatMessage({id: 'LABEL.DESCRIPTION_REQUIRED'})),
    depCode: Yup.string().required(intl.formatMessage({id: 'LABEL.DEP_CODE_REQUIRED'})),
    buName: Yup.string().required(intl.formatMessage({id: 'LABEL.BU_NAME_REQUIRED'})),
    erpId: Yup.string().required(intl.formatMessage({id: 'LABEL.ERP_ID_REQUIRED'})),
    status: Yup.boolean().required(intl.formatMessage({id: 'LABEL.STATUS_REQUIRED'})),
  })

  const handleEditSubmit = async (values: any) => {
    const url = `${baseUrl}/departments/${props.props.id}`
    try {
      const requestData = {
        organization_id: organization_id,
        business_unit_id: parseInt(values.buName),
        department_name: values.name,
        department_description: values.description,
        dep_head: parseInt(values.headName) || 0,
        dep_code: values.depCode,
        erp_id: values.erpId,
        status: values.status ? 1 : 0,
      }
      await execute(url, 'PATCH', requestData, id)
      setIsEditMode(false)
      fetchData()
      props.props.fetchData()
      Swal.fire({
        title: 'Department Edited Successfully',
        text: intl.formatMessage({id: 'LABEL.CHANGES_SAVED'}),
        icon: 'success',
        showConfirmButton: true,
        customClass: {
          confirmButton: 'btn btn-light-primary btn-sm',
        },
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          const sideDrawer = document.getElementById('side_activities_close')
          if (sideDrawer) {
            sideDrawer.click()
          }
        }
      })
    } catch (error) {
      console.error(error)
      Swal.fire({
        icon: 'error',
        title: intl.formatMessage({id: 'LABEL.OOPS'}),
        text: intl.formatMessage({id: 'LABEL.SOMETHING_WRONG'}),
      })
    }
  }

  useEffect(() => {
    fetchData()
  }, [props.props.id])

  const handleEditClick = () => {
    setIsEditMode(true)
  }
  const handleSaveClick = () => {
    setIsEditMode(false)
  }

  return (
    <div>
      <div className='card shadow-none rounded-0'>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={handleEditSubmit}
        >
          {({values, handleChange}) => {
            return (
              <Form>
                <div className='card-header' id='side_activities_header'>
                  <h3 className='card-title fw-bolder text-dark'>
                    {is_admin === 1
                      ? intl.formatMessage({id: 'LABEL.EDIT_DEPARTMENT'})
                      : 'Department Information'}
                  </h3>
                  {is_admin === 1 && (
                    <div className='card-toolbar'>
                      {!isEditMode ? (
                        <button
                          type='button'
                          className='btn btn-sm btn-light-primary btn-ripple'
                          data-kt-menu-trigger='click'
                          data-kt-menu-placement='bottom-start'
                          data-kt-menu-flip='top-end'
                          onClick={handleEditClick}
                        >
                          <KTIcon iconName='pencil' className='fs-7' />
                          {intl.formatMessage({id: 'BTN.EDIT'})}
                        </button>
                      ) : (
                        <div
                          className='btn btn-icon btn-sm btn-active-light-danger'
                          onClick={handleSaveClick}
                          data-bs-dismiss='modal'
                          aria-label='Close'
                        >
                          <KTIcon iconName='cross' className='fs-2' />
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div className='card-body position-relative' id='side_activities_body'>
                  <div>
                    <div
                      id='side_activities_scroll'
                      className='position-relative scroll-y me-n5 pe-5 w-400px'
                      data-kt-scroll='true'
                      data-kt-scroll-height='auto'
                      data-kt-scroll-wrappers='#side_activities_body'
                      data-kt-scroll-dependencies='#side_activities_header, #side_activities_footer'
                      data-kt-scroll-offset='5px'
                    >
                      {isEditMode ? (
                        <>
                          <div className='d-flex align-items-start'>
                            <img
                              src={brand_logo_url}
                              alt='img'
                              className='activity-img rounded w-65px h-65px me-3'
                            />
                            <div className='mt-0 pt-0'>
                              <p className='text-dark fw-bold text-hover-primary fs-5 m-0'>
                                {brandName}
                              </p>
                              <p className='text-dark fw-bold text-hover-primary text-muted m-0 mt-1'>
                                <KTIcon iconName='geolocation' className='fs-7 me-1' />
                                {locationName?.country_name}
                              </p>
                              <p className='text-dark fw-bold text-hover-primary text-muted m-0'>
                                <KTIcon iconName='bank' className='fs-7 me-1' />
                                {locationName?.address_line1}
                              </p>
                            </div>
                          </div>
                          <hr className='text-dark mt-4 mb-4' />
                          <div className='timeline'>
                            <div className='timeline-item'>
                              <div className='timeline-line w-40px'></div>
                              <div className='timeline-icon symbol symbol-circle symbol-40px'>
                                <div className='symbol-label bg-light'>
                                  <KTIcon iconName='home' className='fs-2 text-gray-500' />
                                </div>
                              </div>
                              <div className='timeline-content mb-4 mt-n2'>
                                <div className='overflow-auto pe-3'>
                                  <div className='fw-bold mb-2 required'>Department Name</div>
                                  <div className='flex-fill'>
                                    <Field
                                      type='text'
                                      className='form-control form-control-solid'
                                      id='floatingInput'
                                      name='name'
                                      value={values.name}
                                      onChange={handleChange}
                                    />
                                    <ErrorMessage
                                      component='div'
                                      className='text-danger'
                                      name='name'
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className='timeline-item'>
                              <div className='timeline-line w-40px'></div>
                              <div className='timeline-icon symbol symbol-circle symbol-40px'>
                                <div className='symbol-label bg-light'>
                                  <KTIcon iconName='user' className='fs-2 text-gray-500' />
                                </div>
                              </div>
                              <div className='timeline-content mb-8 mt-n2'>
                                <div className='overflow-auto pe-3'>
                                  <div className='fw-bold mb-2'>Department Head Name</div>
                                  <div className='flex-fill'>
                                    <Field
                                      as='select'
                                      id='headName'
                                      name='headName'
                                      className='form-select form-select-solid'
                                      value={values.headName}
                                      onChange={handleChange}
                                    >
                                      <option value=''>Select Department Head</option>
                                      {headName?.map((option: any) => (
                                        <option key={option.employee_id} value={option.employee_id}>
                                          {option.employee_name}
                                        </option>
                                      ))}
                                    </Field>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='timeline-item'>
                              <div className='timeline-line w-40px'></div>
                              <div className='timeline-icon symbol symbol-circle symbol-40px me-4'>
                                <div className='symbol-label bg-light'>
                                  <KTIcon iconName='bank' className='fs-2 text-gray-500' />
                                </div>
                              </div>
                              <div className='timeline-content mb-8 mt-n1'>
                                <div className='pe-3 overflow-auto '>
                                  <div className='fw-bold mb-2 required'>Business Unit Name</div>
                                  <div className='flex-fill'>
                                    <Field
                                      as='select'
                                      id='buName'
                                      name='buName'
                                      className='form-select form-select-solid'
                                      value={values.buName}
                                      onChange={handleChange}
                                    >
                                      <option value=''>
                                        {intl.formatMessage({id: 'LABEL.SELECT_BU_NAME'})}
                                      </option>
                                      {buName?.map((option: any) => (
                                        <option
                                          key={option.business_unit_id}
                                          value={option.business_unit_id}
                                        >
                                          {option.business_unit_name}
                                        </option>
                                      ))}
                                    </Field>
                                    <ErrorMessage
                                      component='div'
                                      className='text-danger'
                                      name='buName'
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <hr className='text-dark mt-4 mb-4' />
                          <div className='fs-5 fw-bold mb-2 required'>Description</div>
                          <div className='mb-4 flex-fill'>
                            <Field
                              as='textarea'
                              className='form-control form-control-solid'
                              id='floatingInput'
                              row={3}
                              name='description'
                              value={values.description}
                              onChange={handleChange}
                            />
                            <ErrorMessage
                              component='div'
                              className='text-danger'
                              name='description'
                            />
                          </div>
                          <div className='d-flex mb-4'>
                            <div className=''>
                              <div className='fw-bold mb-2 required'>Department Code</div>
                              <Field
                                type='text'
                                className='form-control form-control-solid'
                                id='floatingInput'
                                name='depCode'
                                value={values.depCode}
                                onChange={handleChange}
                              />
                              <ErrorMessage
                                component='div'
                                className='text-danger'
                                name='depCode'
                              />
                            </div>
                            <div className='ms-2 flex-fill'>
                              <div className='fw-bold mb-2 required'>ERP ID</div>
                              <Field
                                type='text'
                                className='form-control form-control-solid '
                                id='floatingInput'
                                name='erpId'
                                value={values.erpId}
                                onChange={handleChange}
                              />
                              <ErrorMessage component='div' className='text-danger' name='erpId' />
                            </div>
                          </div>
                          <div className='form-floating flex-fill'>
                            <div className='form-check form-check-solid form-switch mb-0'>
                              <Field
                                className='form-check-input w-40px h-20px'
                                type='checkbox'
                                name='status'
                                id='toggle'
                                checked={values.status}
                                onChange={handleChange}
                              />
                              <span
                                className={`me-4 fw-bold ${
                                  values.status ? 'text-success' : 'text-danger'
                                }`}
                              >
                                {values.status ? 'Active' : 'Inactive'}
                              </span>
                              <ErrorMessage component='div' className='text-danger' name='status' />
                            </div>
                          </div>
                        </>
                      ) : (
                        <div>
                          {isLoading ? (
                            <div className='card card-xl-stretch'>
                              <div className='m-auto d-flex flex-column align-items-center'>
                                <div className='spinner-border spinner-primary mr-15'></div>
                              </div>
                            </div>
                          ) : (
                            <>
                              <div className='d-flex align-items-start'>
                                <img
                                  src={brand_logo_url}
                                  alt='img'
                                  className='activity-img rounded w-65px h-65px me-3'
                                />
                                <div className='mt-0 pt-0'>
                                  <p className='text-dark fw-bold text-hover-primary m-0'>
                                    {brandName}
                                  </p>
                                  <p className='text-dark fw-bold text-hover-primary text-muted m-0 mt-1'>
                                    <KTIcon iconName='geolocation' className='fs-7 me-1' />
                                    {locationName?.country_name}
                                  </p>
                                  <p className='text-dark fw-bold text-hover-primary text-muted m-0'>
                                    <KTIcon iconName='bank' className='fs-7 me-1' />
                                    {locationName?.address_line1}
                                  </p>
                                </div>
                              </div>
                              <hr className='text-dark mt-4 mb-4' />
                              <div className='timeline'>
                                <div className='timeline-item'>
                                  <div className='timeline-line w-40px'></div>
                                  <div className='timeline-icon symbol symbol-circle symbol-40px'>
                                    <div className='symbol-label bg-light'>
                                      <KTIcon iconName='home' className='fs-2 text-gray-500' />
                                    </div>
                                  </div>
                                  <div className='timeline-content mb-8 mt-n1'>
                                    <div className='overflow-auto pe-3'>
                                      <div className='fw-bold text-muted'>Department Name</div>
                                      <span className='fw-bolder text-dark'>{depName}</span>
                                    </div>
                                  </div>
                                </div>

                                <div className='timeline-item'>
                                  <div className='timeline-line w-40px'></div>
                                  <div className='timeline-icon symbol symbol-circle symbol-40px'>
                                    <div className='symbol-label bg-light'>
                                      <KTIcon iconName='user' className='fs-2 text-gray-500' />
                                    </div>
                                  </div>
                                  <div className='timeline-content mb-8 mt-n2'>
                                    <div className='overflow-auto pe-3'>
                                      <div className='fw-bold text-muted mt-1'>
                                        Department Head Name
                                      </div>
                                      <span className='fw-bolder text-dark'>
                                        {departmentData?.dep_head_name
                                          ? departmentData?.dep_head_name
                                          : '--'}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className='timeline-item'>
                                  <div className='timeline-line w-40px'></div>
                                  <div className='timeline-icon symbol symbol-circle symbol-40px me-4'>
                                    <div className='symbol-label bg-light'>
                                      <KTIcon iconName='bank' className='fs-2 text-gray-500' />
                                    </div>
                                  </div>
                                  <div className='timeline-content mb-4 mt-n3'>
                                    <div className='pe-3 overflow-auto '>
                                      <div className='fw-bold text-muted mt-2'>
                                        Business Unit Name
                                      </div>
                                      <span className='fw-bolder text-dark'>
                                        {departmentData?.business_unit_name}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <hr className='text-dark mt-4 mb-4' />
                              <div className='row mb-4'>
                                <label className='col-lg-4 fw-bold text-muted'>
                                  {intl.formatMessage({id: 'LABEL.DESCRIPTION'})}
                                </label>

                                <div className='col-lg-8 fv-row'>
                                  <span className='fw-bold'>{depDescription}</span>
                                </div>
                              </div>
                              <div className='row mb-4'>
                                <label className='col-lg-4 fw-bold text-muted'>
                                  Department Code
                                </label>
                                <div className='col-lg-8 d-flex align-items-center'>
                                  <span className='fw-bolder me-2'>{depCode}</span>
                                </div>
                              </div>
                              <div className='row mb-4'>
                                <label className='col-lg-4 fw-bold text-muted'>
                                  {intl.formatMessage({id: 'LABEL.ERP_ID'})}
                                </label>
                                <div className='col-lg-8 d-flex align-items-center'>
                                  <span className='fw-bolder me-2'>{depErpId}</span>
                                </div>
                              </div>
                              <div className='row'>
                                <label className='col-lg-4 fw-bold text-muted'>
                                  {intl.formatMessage({id: 'LABEL.STATUS'})}
                                </label>
                                <div className='col-lg-8 d-flex align-items-center'>
                                  <span className='fw-bolder me-2'>
                                    {departmentData?.status === 1 ? 'Active' : 'Inactive'}
                                  </span>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className='card-footer text-end' id='side_activities_footer'>
                  {isEditMode ? (
                    <>
                      <div className='btn btn-sm btn-secondary m-2' onClick={handleSaveClick}>
                        {intl.formatMessage({id: 'LABEL.CANCEL'})}
                      </div>
                      <button className='btn btn-light-primary btn-sm' type='submit'>
                        <KTIcon iconName='check' className='fs-7' />
                        {intl.formatMessage({id: 'BTN.SAVE'})}
                      </button>
                    </>
                  ) : (
                    <button
                      className='btn btn-sm btn-secondary m-2 mt-0'
                      type='button'
                      id='side_activities_close'
                      onClick={handleSaveClick}
                    >
                      {intl.formatMessage({id: 'BTN.CLOSE'})}
                    </button>
                  )}
                </div>
              </Form>
            )
          }}
        </Formik>
      </div>
    </div>
  )
}

export {EditDepartment}
