import {useEffect, useState} from 'react'
import Swal from 'sweetalert2'
import {useIntl} from 'react-intl'
import secureLocalStorage from 'react-secure-storage'

import {baseUrl} from '../../core'
import usePostApi from '../../custom_hooks/usePostApi'

function TravelManager({managerDetails}) {
  const {execute} = usePostApi()
  const {
    manager_reviewed_status = null,
    manager_reviewer_comments = '',
    fetchData = () => {},
    request_id = '',
    travelfetchdata,
  } = managerDetails

  // Define managerComment state

  let emp_id
  let organization_id
  let role
  let is_manager
  const userInfo = secureLocalStorage.getItem('user_info')
  if (userInfo) {
    ;({emp_id, organization_id, role, is_manager} = userInfo)
  }

  const intl = useIntl()
  const [managerComment, setManagerComment] = useState('')
  const [comError, setComError] = useState(false)
  const [comLenErr, setComLenErr] = useState(false)

  useEffect(() => {
    if (managerComment !== '') {
      setComError(false)
    }
    if (managerComment.length >= 3) {
      setComLenErr(false)
    }
  }, [managerComment])

  const approveAndRejectBtns = async (event) => {
    if (managerComment === '') {
      setComError(true)
      return
    } else if (managerComment.length < 3) {
      setComLenErr(true)
      return
    }

    try {
      const url = `${baseUrl}/travel/manager/${request_id}`
      const organizationId = secureLocalStorage.getItem('organization_id')
      const formData = {
        organization_id: organizationId,
        manager_reviewed_by: emp_id,
        manager_reviewer_comments: managerComment, // Use the local state managerComment
        manager_reviewed_status: event.target.name,
        status: event.target.name == 'Approved' ? 'HR Pending' : event.target.name, // Update the status based on 'Approved' or 'Rejected'
      }

      await execute(url, 'PATCH', formData)
      fetchData()
      travelfetchdata()
      const successMessage =
        event.target.name === 'Approved'
          ? intl.formatMessage({id: 'MSG.TRAVEL_REQUEST_APPROVED_SUCCESSFULLY'})
          : intl.formatMessage({id: 'MSG.TRAVEL_REQUEST_REJECTED_SUCCESSFULLY'})

      await Swal.fire({
        title: successMessage,
        text: intl.formatMessage({id: 'LABEL.CHANGES_SAVED'}),
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-light-primary btn-sm',
        },
      })
    } catch (error) {
      console.error('Error:', error)
      Swal.fire({
        icon: 'error',
        title: intl.formatMessage({id: 'LABEL.OOPS'}),
        text: intl.formatMessage({id: 'LABEL.SOMETHING_WRONG'}),
        customClass: {
          confirmButton: 'btn btn-light-primary btn-sm',
        },
      })
    }
  }

  useEffect(() => {
    setManagerComment('')

    setComError(false)
    setComLenErr(false)
  }, [])

  const getManagerView = (status) => {
    if (status === 'Pending' && is_manager === 1) {
      return (
        <div className='min-h-26px'>
          <label> {intl.formatMessage({id: 'LABEL.COMMENTS'})}</label>
          <br />
          <textarea
            placeholder='Comments'
            className='form-control mb-3 form-control-solid'
            value={managerComment}
            onChange={(e) => setManagerComment(e.target.value)}
          />
          {comError && <p className='text-danger'>Comments is Required</p>}
          {comLenErr && !comError && (
            <p className='text-danger'>Comments must be at least 3 characters</p>
          )}
          <div>
            <button
              className='btn btn-light-success btn-sm py-2'
              name='Approved'
              onClick={approveAndRejectBtns}
              type='submit'
              // id='side_activities_close'
            >
              {intl.formatMessage({id: 'LABEL.APPROVE'})}
            </button>
            <button
              className='btn btn-light-danger btn-sm ms-2 py-2'
              name='Rejected'
              onClick={approveAndRejectBtns}
              type='submit'
              // id='side_activities_close'
            >
              {intl.formatMessage({id: 'LABEL.REJECT'})}
            </button>
          </div>
        </div>
      )
    } else if (status === 'Approved' || status === 'Rejected') {
      return (
        <>
          {/* <p className='m-0 text-muted'>{status}</p> */}
          <p className='m-0 text-muted'>{manager_reviewer_comments}</p>
        </>
      )
    } else {
      return null
    }
  }

  const getStatusMsg = (status) => {
    if (status === 'Pending') {
      return 'Pending'
    } else if (status === 'Approved') {
      return status
    } else if (status === 'Rejected') {
      return status
    } else {
      return status
    }
  }

  const getStatusColor = (status) => {
    if (status === 'Pending') {
      return 'muted'
    } else if (status === 'Approved') {
      return 'success'
    } else {
      return 'danger'
    }
  }

  const getIconColor = (status) => {
    if (status === null) {
      return 'secondary'
    } else if (status === 'Approved') {
      return 'success'
    } else if (status === 'Rejected') {
      return 'danger'
    } else {
      return 'muted'
    }
  }

  return (
    <div className='timeline-item'>
      <div className='timeline-line w-20px'></div>

      <div className='timeline-icon symbol symbol-circle symbol-20px me-4'>
        <div className={`symbol-label bg-${getIconColor(manager_reviewed_status)}`}></div>
      </div>

      <div className='timeline-content mb-12 mt-n1'>
        <div className='d-flex justify-content-between mt-1'>
          <p className={`mb-0 fw-bold text-${getStatusColor(manager_reviewed_status)}`}>
            {getStatusMsg(manager_reviewed_status)}
          </p>
          <div className=''>
            <p className='designation-btn bg-secondary fs-7 m-auto'>Manager</p>
          </div>
        </div>
        {getManagerView(manager_reviewed_status)}
      </div>
    </div>
  )
}

export default TravelManager
