import Locations from './Locations'
import {useIntl} from 'react-intl'
function OrganizationLocations(props) {
  const intl = useIntl()
  return (
    <>
      <div className='d-flex flex-wrap flex-stack mb-6'>
        <h3 className='fw-bolder my-2'>
          {intl.formatMessage({id: 'MENU.ORGANIZATION_CONFIGURATION.LOCATIONS'})}
        </h3>
      </div>
      <div className='row g-5 g-xxl-8'>
        <div className='col-xl-12'>
          <Locations />
        </div>
      </div>
    </>
  )
}

export default OrganizationLocations
