import {useIntl} from 'react-intl'
import {KTIcon} from '../../../../_metronic/helpers'
import {LatestExpenseCard} from './LatestExpenseCard'
import ExpensesTable from './ExpensesTable'
import '../index.css'
import AddExpenseSideDrawer from './AddNewExpenseSideDrawer'
import ExpContext from '../ExpensesContext'
import {TeamMemberExpInfoSD} from '../TM_expenses/TMExpInfo'
import useApiCall from '../../custom_hooks/useApiCall'
import usePageTitle from '../../custom_hooks/usePageTitle'

function MyExpenses() {
  const intl = useIntl()
  usePageTitle(intl.formatMessage({id: 'LABEL.MYEXPENSES'}))

  const {data: empExpenseDetails, isLoading, fetchData} = useApiCall('/employee/expenses')

  return (
    <ExpContext.Consumer>
      {(value) => {
        const {ExpInfo} = value
        return (
          <>
            <div className=''>
              {isLoading ? (
                <div className='card h-250px'>
                  <div className='m-auto d-flex flex-column align-items-center'>
                    <div className='spinner-border spinner-primary mr-15'></div>
                  </div>
                </div>
              ) : empExpenseDetails && empExpenseDetails.length > 0 ? (
                <>
                  <div className='d-flex flex-wrap flex-stack mb-6 default-cursor'>
                    <h3 className='fw-bolder my-2'>
                      {intl.formatMessage({id: 'LABEL.MYEXPENSES'})}
                    </h3>

                    <div className='d-flex flex-wrap my-2'>
                      <a
                        href='#'
                        className='btn btn-light-primary btn-sm btn-ripple'
                        id='addNewExpenseToggle'
                      >
                        <KTIcon iconName='plus' className='fs-3' />
                        {intl.formatMessage({id: 'BTN.NEW_EXPENSE'})}
                      </a>
                    </div>
                  </div>

                  <div className='d-flex row ps-3'>
                    <div className='col-xl-4 p-0 mb-md-5'>
                      <LatestExpenseCard expensesData={empExpenseDetails} />
                    </div>
                    <div className='col-xl-8'>
                      <ExpensesTable data={empExpenseDetails} fetchData={fetchData} />
                    </div>
                  </div>
                </>
              ) : (
                <div className='card h-250px'>
                  <div className='m-auto d-flex flex-column align-items-center'>
                    <h1 className='mb-5'>{intl.formatMessage({id: 'MSG.NO_EXPS_FOUND'})}</h1>
                    <a
                      href='#'
                      className='btn btn-light-primary btn-sm btn-ripple'
                      id='addNewExpenseToggle'
                    >
                      <KTIcon iconName='plus' className='fs-3' />
                      {intl.formatMessage({id: 'BTN.NEW_EXPENSE'})}
                    </a>
                  </div>
                </div>
              )}
            </div>
            <AddExpenseSideDrawer updateExpensesFunc={fetchData} />
            <TeamMemberExpInfoSD details={ExpInfo ? ExpInfo : null} />
          </>
        )
      }}
    </ExpContext.Consumer>
  )
}

export default MyExpenses
