import {useMemo, useState} from 'react'
import {useTable, usePagination} from 'react-table'
import Context from '../../../../../../_metronic/partials/layout/activity-drawer/context'
import {KTIcon, toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {SideDrawer} from '../../../../../../_metronic/partials/layout/activity-drawer/Side-drawer'
import {useIntl} from 'react-intl'
import Swal from 'sweetalert2'
import * as MetronicI18n from '../../../../../../_metronic/i18n/Metronici18n'
import {baseUrl} from '../../../../core'
import secureLocalStorage from 'react-secure-storage'
import {useParams} from 'react-router-dom'
import usePostApi from '../../../../custom_hooks/usePostApi'
import useApiCall from '../../../../custom_hooks/useApiCall'
const DataTable = ({columns, data, isLoading}) => {
  const [inputVal] = useState('')
  const intl = useIntl()
  const tableData = useMemo(() => {
    return data.filter((each) =>
      each.locale_name.toLocaleLowerCase().includes(inputVal.toLocaleLowerCase())
    )
  }, [data])
  const tableInstance = useTable({columns, data: tableData}, usePagination)
  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    page,
    state: {pageIndex, pageSize},
  } = tableInstance

  return (
    <Context.Consumer>
      {(value) => {
        const {id} = value
        return (
          <>
            {isLoading ? (
              <div className='card card-xl-stretch h-250px'>
                <div className='m-auto d-flex flex-column align-items-center'>
                  <div className='spinner-border spinner-primary mr-15'></div>
                </div>
              </div>
            ) : (
              <div className='card card-xl-stretch pb-20'>
                <div className='card-header'>
                  <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-5 mb-1'>
                      {intl.formatMessage({id: 'LABEL.ORGANIZATION_FONT_PREFERENCES'})}
                    </span>
                    <span className='text-muted fw-bold fs-7'>
                      {intl.formatMessage({id: 'LABEL.ORGANIZATION_CONFIG_FONT_PREFERENCES'})}
                    </span>
                  </h3>
                </div>
                <div className='card-body table-responsive pt-4'>
                  <table
                    className='table table-row-dashed align-middle table-row-gray-300'
                    {...getTableProps()}
                  >
                    <thead className=''>
                      <tr className='fw-bold text-muted'>
                        <th className='min-w-120px'>{intl.formatMessage({id: 'LABEL.NAME'})}</th>
                        <th className='min-w-120px'>
                          {intl.formatMessage({id: 'LABEL.CURRENT_FONT'})}
                        </th>
                        <th className='min-w-120px'>
                          {intl.formatMessage({id: 'LABEL.CHOOSE_FONT'})}
                        </th>
                        <th className='min-w-120px text-center'>
                          {intl.formatMessage({id: 'LABEL.ACTION'})}
                        </th>
                      </tr>
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {page.map((row) => {
                        prepareRow(row)
                        return (
                          <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => (
                              <td className=' ' {...cell.getCellProps()}>
                                {cell.render('Cell')}
                              </td>
                            ))}
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
                <SideDrawer value={id} />
              </div>
            )}
          </>
        )
      }}
    </Context.Consumer>
  )
}

const FontPreferences = () => {
  const {execute} = usePostApi()
  const {id} = useParams()
  const {data: fontPreferences, fetchData, isLoading} = useApiCall(`/pref/font`)
  const [selectedFont, setSelectedFont] = useState({})
  const userInfo = secureLocalStorage.getItem('user_info')
  const {is_admin, is_hr, organization_id} = userInfo || {}
  const handleChange = (e, row) => {
    setSelectedFont((prevSelectedFonts) => {
      return {
        ...prevSelectedFonts,
        [row.original.locale_id]: e.target.value,
      }
    })
  }
  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'locale_name',
        Cell: ({row}) => {
          return (
            <div className='d-flex align-items-center'>
              <div className='symbol symbol-45px me-5'>
                <img src={toAbsoluteUrl(`/media/ISOcodeFlags/${row.original.locale_code}.svg`)} />
              </div>
              <div className='d-flex justify-content-start flex-column'>
                <div className='text-dark fw-bold text-hover-primary fs-6 default-cursor'>
                  {row.original.locale_name}
                </div>
                <span className='text-muted fw-semibold text-muted d-block fs-7 text-start'>
                  {row.original.locale_code}
                </span>
              </div>
            </div>
          )
        },
        disableSortBy: true,
      },
      {
        Header: 'Current Font',
        accessor: 'default_font',
        Cell: ({row}) => {
          return (
            <div className='d-flex align-items-center'>
              <div className='d-flex justify-content-start flex-column'>
                <p className='text-dark fw-bold text-hover-primary fs-6 default-cursor'>
                  {row.original.default_font}
                </p>
              </div>
            </div>
          )
        },
        disableSortBy: true,
      },
      {
        Header: 'Choose Font',
        accessor: 'choose Font',
        Cell: ({row}) => {
          const selectedFontValue = selectedFont[row.original.locale_id] || ''
          return (
            <div className='d-flex align-items-center'>
              <div className='d-flex justify-content-start flex-column'>
                <a className='text-dark fw-bold text-hover-primary fs-6'>
                  <select
                    name='fontFamily'
                    data-control='select2'
                    data-hide-search='true'
                    className='form-select form-select-white form-select-sm w-125px'
                    value={selectedFontValue} // Set the value attribute to reflect the selected font
                    onChange={(e) => handleChange(e, row)}
                    disabled={!(is_admin === 1)}
                  >
                    <option value=''>Select Font</option>
                    <option value='DM Sans'>DM Sans</option>
                    <option value='Bebas Neue'>Bebas Neue</option>
                    <option value='Playfair'>Playfair</option>
                    <option value='Arial'>Arial</option>
                    <option value='Inter'>Inter</option>
                    <option value='Flow Circular'>Flow Circular</option>
                    <option value='Montserrat'>Montserrat</option>
                    <option value='Roboto'>Roboto</option>
                    <option value='Poppins'>Poppins</option>
                    <option value='Cairo'>Cairo</option>
                    <option value='DynaPuff'>DynaPuff</option>
                  </select>
                </a>
              </div>
            </div>
          )
        },
        disableSortBy: true,
      },
      {
        Header: 'Preview',
        accessor: 'Preview',
        Cell: ({row}) => {
          const selectedFontValue = selectedFont[row.original.locale_id] || ''
          const selectedLang = MetronicI18n.getLanguage()
          const intl = useIntl()
          const handleInfoIconClick = () => {
            Swal.fire({
              title: intl.formatMessage({id: 'LABEL.UPDATING_FONT_PREFERENCES'}),
              text: intl.formatMessage({id: 'LABEL.CONFIRM_UPDATE_FONT_PREFERENCES'}),
              icon: 'info',
              showCancelButton: true,
              confirmButtonText: intl.formatMessage({id: 'BTN.CONFIRM'}),
              cancelButtonText: intl.formatMessage({id: 'LABEL.CANCEL'}),
              customClass: {
                confirmButton: 'btn btn-light-primary btn-sm',
                cancelButton: 'btn btn-sm btn-secondary',
              },
            }).then((result) => {
              if (result.isConfirmed) {
                handleSubmit()
              }
            })
          }
          const handleSubmit = async () => {
            const url = `${baseUrl}/pref/font/${row.original.locale_id}`
            try {
              const requestData = {
                organization_id: organization_id,
                default_font: selectedFontValue,
              }
              await execute(url, 'PATCH', requestData, id)
              if (selectedLang === row.original.locale_code) {
                localStorage.setItem('themeFont', selectedFontValue)
                Swal.fire({
                  title: 'Font Preferences Edited Successfully',
                  text: intl.formatMessage({id: 'LABEL.CHANGES_SAVED'}),
                  icon: 'success',
                  showConfirmButton: true,
                  confirmButtonText: 'OK',
                  customClass: {
                    confirmButton: 'btn btn-light-primary btn-sm',
                  },
                  allowOutsideClick: false,
                }).then((result) => {
                  if (result.isConfirmed) {
                    window.location.reload()
                  }
                })
              } else {
                Swal.fire({
                  title: 'Font Preferences Edited Successfully',
                  text: intl.formatMessage({id: 'LABEL.CHANGES_SAVED'}),
                  icon: 'success',
                  showConfirmButton: true,
                  confirmButtonText: 'OK',
                  customClass: {
                    confirmButton: 'btn btn-light-primary btn-sm',
                  },
                })
                fetchData()
              }
            } catch (error) {
              console.error(error)
              Swal.fire({
                icon: 'error',
                title: intl.formatMessage({id: 'LABEL.OOPS'}),
                text: intl.formatMessage({id: 'LABEL.SOMETHING_WRONG'}),
              })
            }
          }

          return (
            <Context.Consumer>
              {(value) => {
                const {updateId} = value
                const selectedFontValue = selectedFont[row.original.locale_id] || ''
                const isFontSelected = selectedFontValue !== ''
                return (
                  <div className='d-flex justify-content-center align-items-center'>
                    <div title={isFontSelected ? '' : 'Please choose Font'}>
                      <button
                        className={`btn btn-icon btn-bg-light btn-active-color-primary btn-sm ${
                          isFontSelected ? '' : 'disabled'
                        }`}
                        id='side_activities_toggle'
                        onClick={() => {
                          if (isFontSelected) {
                            updateId({
                              id: row.original.locale_id,
                              name: row.original.locale_name,
                              locale: row.original.locale_code,
                              selectedFont: selectedFontValue,
                              type: 'fontpreview',
                            })
                          }
                        }}
                        disabled={!isFontSelected}
                      >
                        <KTIcon iconName='eye' className='fs-2' />
                      </button>
                    </div>
                    <div title={isFontSelected ? '' : 'Please choose Font'}>
                      <button
                        className={`btn btn-icon btn-bg-light btn-active-color-primary btn-sm ms-2 ${
                          isFontSelected ? '' : 'disabled'
                        }`}
                        onClick={handleInfoIconClick}
                        disabled={!isFontSelected}
                      >
                        <KTIcon iconName='check-circle' className='fs-1' />
                      </button>
                    </div>
                  </div>
                )
              }}
            </Context.Consumer>
          )
        },
      },
    ],
    [fontPreferences, selectedFont]
  )

  return (
    <DataTable
      columns={columns}
      data={fontPreferences ? fontPreferences.slice(0, 1) : []}
      isLoading={isLoading}
    />
  )
}

export default FontPreferences
