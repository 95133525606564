import {FC, useState, useEffect, createContext, useContext, useRef} from 'react'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import {AuthModel, UserModel} from './_models'
import * as authHelper from './AuthHelpers'
import {getUserByToken} from './_requests'
import {WithChildren} from '../../../../_metronic/helpers'
import secureLocalStorage from 'react-secure-storage'
type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: AuthModel | undefined
  setCurrentUser: (auth: AuthModel | undefined) => void
  logout: () => void
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: () => {},
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({children}) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<AuthModel | undefined>()
  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = () => {
    localStorage.removeItem('auth_token')
    localStorage.removeItem('i18nConfig')
    localStorage.setItem('themeFont', 'Inter')
    localStorage.setItem('themeColor', '#009ef7')
    localStorage.removeItem('kt_theme_mode_menu')
    localStorage.removeItem('kt_theme_mode_value')
    localStorage.removeItem('displayName')
    secureLocalStorage.removeItem('user_info')
    secureLocalStorage.removeItem('auth_token')
    saveAuth(undefined)
    setCurrentUser(undefined)
  }

  return (
    <AuthContext.Provider value={{auth, saveAuth, currentUser, setCurrentUser, logout}}>
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({children}) => {
  const {auth, logout, setCurrentUser} = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)

  useEffect(() => {
    const requestUser = async () => {
      try {
        if (!didRequest.current) {
          const userInfo: any | null = secureLocalStorage.getItem('user_info')
          const response = await getUserByToken(userInfo?.auth_token)
          if (response) {
            setCurrentUser(response)
            const userInfo = {
              user_id: response.user_id,
              emp_id: response.employee_id,
              role: response.role,
              auth_token: response.api_token,
              organization_id: response.organization_id,
              job_role_id: response.job_role_id,
              is_admin: response.is_admin,
              is_finance: response.is_finance,
              is_hr: response.is_hr,
              is_itadmin: response.is_itadmin,
              is_manager: response.is_manager,
            }

            secureLocalStorage.setItem('user_info', userInfo)
          }
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (auth) {
      requestUser()
    } else {
      logout()
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthProvider, AuthInit, useAuth}
