import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import * as Yup from 'yup'
import {ErrorMessage, Field, Form, Formik} from 'formik'
import Swal from 'sweetalert2'
import secureLocalStorage from 'react-secure-storage'
import {useParams} from 'react-router-dom'
import usePostApi from '../../../custom_hooks/usePostApi'
import useApiCall from '../../../custom_hooks/useApiCall'
import {KTIcon} from '../../../../../_metronic/helpers'
import {baseUrl} from '../../../core'

const EditState = (props: any) => {
  const {value} = props
  const intl = useIntl()
  const {execute} = usePostApi()
  const {id}: any | null = useParams()
  const userInfo: any | null = secureLocalStorage.getItem('user_info')
  const {data, fetchData, isLoading}: any | null = useApiCall(
    value.id ? `/state/state/${value.id}` : null
  )
  let stateData = data && data[0]
  const {organization_id, is_admin} = userInfo || {}
  const [isEditMode, setIsEditMode] = useState(false)
  const [stateName, setStateName] = useState<string>('')
  const [isChecked, setIsChecked] = useState<boolean>(true)
  useEffect(() => {
    if (stateData) {
      setStateName(stateData.state_name)
      setIsChecked(stateData.status === 1)
    }
  }, [stateData])

  const validationSchemaEdit = Yup.object().shape({
    state_name: Yup.string().required(intl.formatMessage({id: 'LABEL.NAME_REQUIRED'})),
    status: Yup.string().required(intl.formatMessage({id: 'LABEL.STATUS_REQUIRED'})),
  })

  const initialValues = {
    state_name: stateName || '',
    status: isChecked || '',
  }

  const handleEditSubmit = async (values: any) => {
    const url = `${baseUrl}/state/${value.id}`
    try {
      const requestData = {
        organization_id: organization_id,
        state_name: values.state_name,
        country_id: stateData?.country_id,
        status: values.status ? 1 : 0,
      }
      await execute(url, 'PATCH', requestData, id)
      value.refreshData()
      setIsEditMode(false)
      Swal.fire({
        title: 'State Edited Successfully',
        text: intl.formatMessage({id: 'LABEL.CHANGES_SAVED'}),
        icon: 'success',
        showConfirmButton: true,
        confirmButtonText: 'OK',
        customClass: {
          confirmButton: 'btn btn-light-primary btn-sm',
        },
      })
    } catch (error) {
      console.error(error)
      Swal.fire({
        icon: 'error',
        title: intl.formatMessage({id: 'LABEL.OOPS'}),
        text: intl.formatMessage({id: 'LABEL.SOMETHING_WRONG'}),
      })
    }
  }

  useEffect(() => {
    fetchData()
  }, [props.id])

  const handleEditClick = () => {
    setIsEditMode(true)
  }

  const handleSaveClick = () => {
    setIsEditMode(false)
    // Add your save logic here
  }
  return (
    <div>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationSchemaEdit}
        onSubmit={handleEditSubmit}
      >
        {({values, handleChange}) => {
          return (
            <Form>
              <div className='modal-dialog'>
                <div className='modal-content'>
                  <div className='modal-header'>
                    <h3 className='modal-title fw-bolder text-dark'>Edit State Name</h3>
                    {is_admin === 1 && (
                      <div className='modal-toolbar'>
                        {!isEditMode ? (
                          <button
                            type='button'
                            className='btn btn-sm btn-light-primary btn-ripple'
                            data-kt-menu-trigger='click'
                            data-kt-menu-placement='bottom-start'
                            data-kt-menu-flip='top-end'
                            onClick={handleEditClick}
                          >
                            <KTIcon iconName='pencil' className='fs-7' />
                            {intl.formatMessage({id: 'BTN.EDIT'})}
                          </button>
                        ) : (
                          <React.Fragment>
                            <div
                              className='btn btn-icon btn-sm btn-active-light-danger'
                              onClick={handleSaveClick}
                              data-bs-dismiss='modal'
                              aria-label='Close'
                            >
                              <KTIcon iconName='cross' className='fs-2' />
                            </div>
                          </React.Fragment>
                        )}
                      </div>
                    )}
                  </div>
                  <div className='modal-body position-relative'>
                    <div>
                      {isEditMode ? (
                        <>
                          <div className='form-floating mb-4'>
                            <Field
                              type='text'
                              className='form-control'
                              id='floatingInput'
                              placeholder='type...'
                              name='state_name'
                              value={!isEditMode ? stateName : values.state_name}
                              disabled={!isEditMode}
                              onChange={handleChange}
                            />
                            <label htmlFor='floatingInput'>
                              {intl.formatMessage({id: 'LABEL.NAME'})}
                            </label>
                            <ErrorMessage
                              component='div'
                              className='text-danger'
                              name='state_name'
                            />
                          </div>
                          <div className='form-floating mb-0'>
                            <div className='form-check form-check-solid form-switch'>
                              <Field
                                className='form-check-input w-40px h-20px'
                                type='checkbox'
                                id='toggle'
                                name='status'
                                checked={!isEditMode ? isChecked : values.status}
                                disabled={!isEditMode}
                                onChange={handleChange}
                              />
                              {!isEditMode ? (
                                <span
                                  className={`me-4 fw-bold ${
                                    isChecked ? 'text-success' : 'text-danger'
                                  }`}
                                >
                                  {isChecked ? 'Active' : 'Inactive'}
                                </span>
                              ) : (
                                <span
                                  className={`me-4 fw-bold ${
                                    values.status ? 'text-success' : 'text-danger'
                                  }`}
                                >
                                  {values.status ? 'Active' : 'Inactive'}
                                </span>
                              )}
                            </div>
                            <ErrorMessage component='div' className='text-danger' name='status' />
                          </div>
                        </>
                      ) : (
                        <div>
                          {isLoading ? (
                            <div className='card card-xl-stretch'>
                              <div className='m-auto d-flex flex-column align-items-center'>
                                <div className='spinner-border spinner-primary mr-15'></div>
                              </div>
                            </div>
                          ) : (
                            <div className='card-body ms-0 ps-0 mt-0 pt-0 mb-0 pb-0 '>
                              <div className='row mb-4 mt-0'>
                                <label className='col-lg-4 fw-bold text-muted'>
                                  {intl.formatMessage({id: 'LABEL.NAME'})}
                                </label>
                                <div className='col-lg-8'>
                                  <span className='fw-bolder fs-6 text-dark'>{stateName}</span>
                                </div>
                              </div>
                              <div className='row mb-0'>
                                <label className='col-lg-4 fw-bold text-muted'>
                                  {intl.formatMessage({id: 'LABEL.STATUS'})}
                                </label>
                                <div className='col-lg-8 d-flex align-items-center'>
                                  <span className='fw-bolder fs-6 me-2'>
                                    {stateData?.status === 1 ? 'Active' : 'Inactive'}
                                  </span>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='modal-footer text-end'>
                    {isEditMode ? (
                      <>
                        <div className='btn btn-sm btn-secondary m-2' onClick={handleSaveClick}>
                          {intl.formatMessage({id: 'LABEL.CANCEL'})}
                        </div>
                        <button className='btn btn-light-primary btn-sm' type='submit'>
                          <KTIcon iconName='check' className='fs-7' />
                          {intl.formatMessage({id: 'BTN.SAVE'})}
                        </button>
                      </>
                    ) : (
                      <button
                        className='btn btn-sm btn-secondary m-2 mt-0'
                        type='button'
                        data-bs-dismiss='modal'
                        aria-label='Close'
                        onClick={handleSaveClick}
                      >
                        {intl.formatMessage({id: 'BTN.CLOSE'})}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export {EditState}
