import React, {useMemo, useState} from 'react'
import {useTable, usePagination} from 'react-table'
import {useIntl} from 'react-intl'

import {Table, Pagination} from 'react-bootstrap'
import {KTIcon, toAbsoluteUrl} from '../../../../_metronic/helpers'
import usePageTitle from '../../custom_hooks/usePageTitle'
import DateFormatter from '../../DateFormatter'

const DataTable = ({columns, data}) => {
  const intl = useIntl()
  usePageTitle(intl.formatMessage({id: 'MENU.LEAVE_MANAGEMENT.LEAVE_TYPES'}))
  const [inputVal, setInputVal] = useState('')
  const tableData = useMemo(() => {
    return data.filter((each) =>
      each.leave_type.toLocaleLowerCase().includes(inputVal.toLocaleLowerCase())
    )
  }, [inputVal])

  const tableInstance = useTable({columns, data: tableData}, usePagination)
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    state: {pageIndex, pageSize},
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageCount,
    gotoPage,
    setPageSize,
  } = tableInstance

  const pageOptions = [1, 2, 5, 10, 20, 30, 40, 50]

  return (
    <>
      <div className='d-flex flex-wrap flex-stack mb-6'>
        <h3 className='fw-bolder my-2 default-cursor'>
          {intl.formatMessage({id: 'MENU.LEAVE_MANAGEMENT.LEAVE_TYPES'})}
        </h3>

        <div className='d-flex my-2'>
          <div className='d-flex align-items-center position-relative me-4'>
            <KTIcon iconName='magnifier' className='fs-3 position-absolute ms-3' />
            <input
              type='search'
              id='kt_filter_search'
              className='form-control form-control-white form-control-sm w-150px ps-9'
              placeholder={intl.formatMessage({id: 'LABEL.SEARCH'})}
              value={inputVal}
              onChange={(e) => setInputVal(e.target.value)}
            />
          </div>
        </div>
      </div>

      <div className='card mb-5 mb-xl-8 p-10 overflow-auto'>
        <table
          className='table table-row-dashed table-row-gray-300 align-middle'
          {...getTableProps()}
        >
          <thead>
            <tr className='fw-bold text-muted'>
              <th className='min-w-150px default-cursor'>
                {intl.formatMessage({id: 'LABEL.LEAVE_TYPE'})}
              </th>
              <th className='min-w-150px text-center default-cursor'>
                {intl.formatMessage({id: 'LABEL.CATEGORY'})}
              </th>
              <th className='min-w-150px text-center default-cursor'>
                {intl.formatMessage({id: 'LABEL.CARRY_FORWARD'})}
              </th>

              <th className='min-w-120px text-center default-cursor'>
                {intl.formatMessage({id: 'LABEL.MAX_ALLOWED_BALANCE'})}
              </th>
            </tr>
          </thead>
          <tbody {...getTableBodyProps()}>
            {page && page.length > 0 ? (
              page.map((row) => {
                prepareRow(row)
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td className='text-center' {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    ))}
                  </tr>
                )
              })
            ) : (
              <tr>
                <td className='card'>
                  <p className='text-dark fw-bold text-hover-primary default-cursor d-block fs-6 m-0 my-5'>
                    {intl.formatMessage({id: 'NO_RESULTS_FOUND_MSG'})}
                  </p>
                </td>
                <td>
                  <p></p>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {data.length > 10 && (
        <Pagination>
          <Pagination.First onClick={() => gotoPage(0)} disabled={!canPreviousPage} />
          <Pagination.Prev onClick={previousPage} disabled={!canPreviousPage} />

          {[...Array(pageCount)].map((_, i) => (
            <Pagination.Item key={i} active={i === pageIndex} onClick={() => gotoPage(i)}>
              {i + 1}
            </Pagination.Item>
          ))}

          <Pagination.Next onClick={nextPage} disabled={!canNextPage} />
          <Pagination.Last onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} />

          <div className='d-flex align-items-center mx-5'>
            <span className='me-2'>{intl.formatMessage({id: 'BTN.SHOW'})}</span>
            <select
              className='form-select form-select-solid fw-bold w-75px me-2'
              value={pageSize}
              onChange={(e) => setPageSize(Number(e.target.value))}
            >
              {pageOptions.map((option) => (
                <option key={option} value={option} className='select-bg'>
                  {option}
                </option>
              ))}
            </select>
          </div>
        </Pagination>
      )}
    </>
  )
}

const LeaveTypesTable = ({leavetypesData}) => {
  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'img',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark fw-bold text-hover-primary d-block fs-6 dafault-cursor'>
                {row.original.leave_type}
              </p>
            </div>
          )
        },
        disableSortBy: true,
      },

      {
        Header: 'Category',
        accessor: 'category',
        Cell: ({row}) => {
          return (
            <p className='text-dark  fw-bold text-hover-primary d-block fs-6 default-cursor'>
              {row.original.category.toUpperCase()}
            </p>
          )
        },
      },

      {
        Header: 'Approval',
        accessor: 'approval',
        Cell: ({row}) => {
          const carryForward = row.original.carry_forward

          return (
            <div className='default-cursor'>
              {carryForward === 'yes' ? (
                <KTIcon iconName='archive-tick' className='text-success fs-2x me-2' />
              ) : (
                <KTIcon iconName='cross' className='text-danger fs-2x me-2' />
              )}
            </div>
          )
        },
      },

      {
        Header: 'Entitlement',
        accessor: 'entitlemen',
        Cell: ({row}) => {
          return (
            <p className='text-dark  fw-bold text-hover-primary d-block fs-6 default-cursor'>
              {row.original.max_allowed} Days
            </p>
          )
        },
      },
    ],
    []
  )

  return <DataTable columns={columns} data={leavetypesData} />
}

export default LeaveTypesTable
