/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {SketchPicker} from 'react-color'
import {KTIcon} from '../../../../../_metronic/helpers'
import {useIntl} from 'react-intl'
import Swal from 'sweetalert2'
import {baseUrl} from '../../../core'
import secureLocalStorage from 'react-secure-storage'
import useApiCall from '../../../custom_hooks/useApiCall'
import usePostApi from '../../../custom_hooks/usePostApi'
import {useParams} from 'react-router-dom'
const ThemePreferences = () => {
  const intl = useIntl()
  const userInfo = secureLocalStorage.getItem('user_info')
  const {data, isLoading} = useApiCall(`/pref/theme/1`)
  const {execute} = usePostApi()
  const {id} = useParams()
  const themeColors = data && data[0]
  const {is_admin, is_hr, organization_id} = userInfo || {}
  const handleClick = (id) => {
    setShowPicker(isEditMode)
    setSelectedIndex(id)
  }
  const [showPicker, setShowPicker] = useState(false)
  const [colorData, setColorData] = useState([])
  useEffect(() => {
    setColorData([
      {
        name: 'Primary (Light Mode)',
        code: themeColors?.brand_primary_color,
        id: 1,
      },
      {
        name: intl.formatMessage({id: 'LABEL.ORGANIZATION_Secondary Color'}),
        code: themeColors?.brand_secondary_color,
        id: 2,
      },
      {
        name: 'Primary (Dark Mode)',
        code: themeColors?.brand_accent_color,
        id: 3,
      },
      {
        name: intl.formatMessage({id: 'LABEL.ORGANIZATION_BACKGROUND_COLOR'}),
        code: themeColors?.brand_background_color,
        id: 4,
      },
      {
        name: intl.formatMessage({id: 'LABEL.ORGANIZATION_TEXT_COLOR'}),
        code: themeColors?.brand_text_color,
        id: 5,
      },
    ])
  }, [themeColors])
  const [selectedIndex, setSelectedIndex] = useState(null)
  const [isEditMode, setIsEditMode] = useState(false)
  const handleChange = (color) => {
    const updatedColorData = [...colorData]
    updatedColorData[selectedIndex - 1].code = color.hex
    setColorData(updatedColorData)
  }
  const handleEditClick = () => {
    setIsEditMode(true)
  }
  const handleCancelClick = () => {
    setIsEditMode(false)
  }

  const handleSaveClick = async () => {
    setIsEditMode(false)
    try {
      const result = await Swal.fire({
        title: intl.formatMessage({id: 'LABEL.UPDATING_THEME_PREFERENCES'}),
        text: intl.formatMessage({id: 'LABEL.CONFIRM_UPDATE_THEME_PREFERENCES'}),
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: intl.formatMessage({id: 'BTN.CONFIRM'}),
        cancelButtonText: intl.formatMessage({id: 'LABEL.CANCEL'}),
        customClass: {
          confirmButton: 'btn btn-light-primary btn-sm',
          cancelButton: 'btn btn-sm btn-secondary',
        },
      })

      if (result.isConfirmed) {
        const requestData = {
          organization_id: organization_id,
          brand_primary_color: colorData[0].code,
          brand_secondary_color: colorData[1].code,
          brand_accent_color: colorData[2].code,
          brand_background_color: colorData[3].code,
          brand_text_color: colorData[4].code,
          brand_button_color: '#66CC33',
        }
        await handleEditSubmit(requestData)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleEditSubmit = async (requestData) => {
    const url = `${baseUrl}/pref/theme/1`
    try {
      await execute(url, 'PATCH', requestData, id)
      Swal.fire({
        title: intl.formatMessage({id: 'LABEL.THEME_PREFERENCES_UPDATED'}),
        text: intl.formatMessage({id: 'LABEL.CHANGES_SAVED'}),
        icon: 'success',
        showConfirmButton: true,
        confirmButtonText: 'OK',
        customClass: {
          confirmButton: 'btn btn-light-primary btn-sm',
        },
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload()
        }
      })
    } catch (error) {
      console.error(error)
      Swal.fire({
        icon: 'error',
        title: intl.formatMessage({id: 'LABEL.OOPS'}),
        text: intl.formatMessage({id: 'LABEL.SOMETHING_WRONG'}),
      })
    }
  }

  return (
    <>
      {isLoading ? (
        <div className='card card-xl-stretch' style={{width: '100%'}}>
          <div className='m-auto d-flex flex-column align-items-center'>
            <div className='spinner-border spinner-primary mr-15'></div>
          </div>
        </div>
      ) : (
        <div className={`card card-xl-stretch`}>
          <div className='card-header'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold fs-5 mb-1'>
                {intl.formatMessage({id: 'LABEL.ORGANIZATION_THEME_PREFERENCES'})}
              </span>
              <span className='text-muted fw-bold fs-7'>
                {intl.formatMessage({id: 'LABEL.ORGANIZATION_CONFIGURE_THE_APP_TO_MATCH'})}
              </span>
            </h3>
            {is_admin === 1 && (
              <div className='card-toolbar'>
                {!isEditMode && (
                  <button
                    type='button'
                    className='btn btn-sm btn-light-primary btn-ripple'
                    onClick={handleEditClick}
                  >
                    <KTIcon iconName='pencil' className='fs-7' />
                    {intl.formatMessage({id: 'BTN.EDIT'})}
                  </button>
                )}
                {isEditMode && (
                  <React.Fragment>
                    <button
                      className='btn btn-light-primary btn-sm'
                      onClick={() => handleSaveClick()}
                    >
                      <KTIcon iconName='check' className='fs-7' />
                      {intl.formatMessage({id: 'BTN.SAVE'})}
                    </button>

                    <div
                      className='btn btn-icon btn-sm btn-active-light-danger ms-2'
                      onClick={() => handleCancelClick()}
                    >
                      <KTIcon iconName='cross' className='fs-2' />
                    </div>
                  </React.Fragment>
                )}
              </div>
            )}
          </div>
          <div className='card-body'>
            <div className='row'>
              {colorData.map((color, index) => (
                <div className=' col-md-6 col-sm-12' key={index}>
                  <div className='row mb-6'>
                    <div className='col-3 d-flex'>
                      <div
                        className='color-container'
                        style={{
                          backgroundColor: color.code,
                          width: '40px',
                          height: '40px',
                          borderRadius: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          position: 'relative',
                          verticalAlign: 'middle',
                        }}
                      ></div>
                    </div>
                    <div className='col-9 d-flex'>
                      <div className='row'>
                        <div className='col-12'>
                          <p className='text-dark fw-bold text-hover-primary mb-1 fs-6 default-cursor'>
                            {color.name}
                          </p>
                        </div>
                        <div className='col-12'>
                          <div clas='d-flex'>
                            <span className='text-muted fw-bold fs-7'>{color.code}</span>
                            {isEditMode && (
                              <span
                                type='button'
                                className='badge bg-light fw-bold fs-7 ms-2'
                                onClick={() => handleClick(color.id)}
                              >
                                CHANGE
                              </span>
                            )}
                            {isEditMode && showPicker && selectedIndex === color.id && (
                              <div style={{position: 'absolute', zIndex: '2'}}>
                                <div
                                  style={{
                                    position: 'fixed',
                                    top: 0,
                                    right: 0,
                                    bottom: 0,
                                    left: 0,
                                  }}
                                  onClick={() => handleClick(null)}
                                />
                                <SketchPicker
                                  color={color.code}
                                  onChange={(newColor) => handleChange(newColor)}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export {ThemePreferences}
