import {Formik, Form, Field, ErrorMessage} from 'formik'
import * as Yup from 'yup'

import Swal from 'sweetalert2'
import {useState} from 'react'
import {useIntl} from 'react-intl'
import {useLang} from '../../../../_metronic/i18n/Metronici18n'
import {useEffect} from 'react'
import {baseUrl} from '../../core'

import {format} from 'date-fns'

import {useThemeMode} from '../../../../_metronic/partials'
import 'react-datepicker/dist/react-datepicker.css'
import usePostApi from '../../custom_hooks/usePostApi'
import useApiCall from '../../custom_hooks/useApiCall'
import DatePicker from 'react-datepicker'
import '../../DatepickerStyles.css'
import 'react-datepicker/dist/react-datepicker.css'

const ScheduleInterview = (props) => {
  const {execute} = usePostApi()
  const intl = useIntl()
  const {data: hiringManager, fetchData: fetchManager} = useApiCall(
    props.props.department_id ? `/masterData/managers/${props.props.department_id}` : null
  )
  const {data, fetchData, isLoading} = useApiCall(
    `/recruitment/candidates/candidate/${props.props.id}`
  )
  let candidateData = data && data[0]

  const [first_name, setFirstName] = useState([])
  const [last_name, setLastName] = useState([])
  const [email, setemail] = useState([])
  const [city_name, setcityname] = useState([])
  useEffect(() => {
    if (candidateData) {
      setFirstName(candidateData?.first_name)
      setLastName(candidateData?.last_name)
      setemail(candidateData?.email)

      setcityname(candidateData?.city_name)
    }
  }, [candidateData])
  useEffect(() => {
    fetchData()
  }, [props.props.id])
  const {data: interviewtypes} = useApiCall('/hrConfig/interviewTypes')
  const {mode} = useThemeMode()

  const [isSubmitting, setIsSubmitting] = useState(false)

  const [selectedDate, setSelectedDate] = useState('')
  useEffect(() => {
    fetchManager()
  }, [props.props.department_id])
  const validationSchema = Yup.object().shape({
    interviewer_id: Yup.string().required(
      intl.formatMessage({id: 'ERR.MSG.INTERVIEWER_IS_REQUIRED'})
    ),
    interview_type_id: Yup.string().required(
      intl.formatMessage({id: 'ERR.MSG.INTERVIEW_TYPE_IS_REQUIRED'})
    ),
    interview_mode: Yup.string().required(
      intl.formatMessage({id: 'ERR.MSG.INTERVIEW_MODE_IS_REQUIRED'})
    ),
    interview_timestamp: Yup.string().required(
      intl.formatMessage({id: 'ERR.MSG.INTERVIEW_DATE_IS_REQUIRED'})
    ),
    interview_location: Yup.string().required(
      intl.formatMessage({id: 'ERR.MSG.INTERVIEW_LOCATION_IS_REQUIRED'})
    ),
  })

  const initialValues = {
    interviewer_id: '',
    interview_type_id: '',
    interview_mode: '',
    interview_location: '',
    interview_timestamp: '',
    status: '',
  }

  const handleSubmit = async (values, formikHelpers) => {
    const {resetForm} = formikHelpers
    const formattedStartDate = values.interview_timestamp
      ? format(new Date(values.interview_timestamp), 'yyyy-MM-dd HH:mm:ss')
      : ''

    try {
      const url = `${baseUrl}/recruitment/interview`
      const formData = {
        organization_id: 4,
        candidate_id: props.props.id,
        interviewer_id: values.interviewer_id,
        interview_type_id: values.interview_type_id,
        interview_mode: values.interview_mode,
        interview_location: values.interview_mode === 'Online' ? '-' : values.interview_location,
        interview_timestamp: formattedStartDate,
        status: 'Pending',
      }

      await execute(url, 'POST', formData)
      resetForm()

      Swal.fire({
        title: intl.formatMessage({id: 'LABEL.INTERVIEW_SCHEDULED_SUCCESSFULLY'}),
        text: 'You can now track the status of the Interview in the “Interviews” section..',
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-light-primary btn-sm',
        },
      })
    } catch (error) {
      console.error(error)
      Swal.fire({
        icon: 'error',
        title: intl.formatMessage({id: 'LABEL.OOPS'}),
        text: intl.formatMessage({id: 'LABEL.SOMETHING_WENT_WRONG'}),
        customClass: {
          confirmButton: 'btn btn-light-primary btn-sm',
        },
      })
    }
  }

  return (
    <div className='card shadow-none rounded-0'>
      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({values, setFieldValue, handleChange}) => (
            <Form>
              <div className='card-header'>
                <h3 className='card-title fw-bolder text-dark'>
                  {intl.formatMessage({id: 'BTN.SCHEDULE_INTERVIEW'})}
                </h3>
                <div className='card-toolbar'>
                  <button
                    type='button'
                    className='btn btn-sm btn-icon btn-active-light-primary me-n5'
                    id='side_activities_close'
                  >
                    <i className='bi bi-x fs-1'></i>
                  </button>
                </div>
              </div>
              <div className='card-body position-relative' id='side_activities_body'>
                <div>
                  <div
                    id='side_activities_scroll'
                    className='position-relative scroll-y me-n5 pe-5 w-400px h-500px'
                    data-kt-scroll='true'
                    data-kt-scroll-height='auto'
                    data-kt-scroll-wrappers='#side_activities_body'
                    data-kt-scroll-dependencies='#side_activities_header, #side_activities_footer'
                    data-kt-scroll-offset='5px'
                  >
                    {isLoading ? (
                      <>
                        <div className='card h-350px'>
                          <div className='m-auto d-flex flex-column align-items-center'>
                            <div className='spinner-border spinner-primary mr-15'></div>
                          </div>
                        </div>
                      </>
                    ) : candidateData ? (
                      <>
                        <div className='row mb-4'>
                          <label className='col-lg-4 fw-bold text-muted'>
                            {' '}
                            {intl.formatMessage({id: 'LABEL.CANDIDATE'})}
                          </label>
                          <div className='col-lg-8'>
                            <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                              {first_name} {last_name}
                            </span>
                          </div>
                        </div>

                        <div className='row mb-4'>
                          <label className='col-lg-4 fw-bold text-muted'>
                            {' '}
                            {intl.formatMessage({id: 'LABEL.EMAIL'})}
                          </label>
                          <div className='col-lg-8'>
                            <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                              {email}
                            </span>
                          </div>
                        </div>

                        <div className='row mb-4'>
                          <label className='col-lg-4 fw-bold text-muted'>
                            {' '}
                            {intl.formatMessage({id: 'LABEL.CITY'})}
                          </label>
                          <div className='col-lg-8'>
                            <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                              {city_name}
                            </span>
                          </div>
                        </div>

                        <h5 className='mt-6 text-muted'>Interview Details</h5>
                        <hr className='text-dark mt-2 mb-6' />
                        <div className='row mb-5 d-flex align-items-center'>
                          <label className='col-lg-4 fw-bold text-muted text-start'>
                            {intl.formatMessage({id: 'LABEL.SELECT_INTERVIEWER'})}
                          </label>
                          <div className='col-lg-8 text-start'>
                            <Field
                              as='select'
                              className='form-select form-select-solid'
                              id='floatingInput'
                              name='interviewer_id'
                              value={values.interviewer_id}
                              onChange={handleChange}
                            >
                              <option value=''>
                                {intl.formatMessage({id: 'LABEL.SELECT_INTERVIEWER'})}
                              </option>
                              {hiringManager?.map((hiringmanager) => (
                                <option
                                  key={hiringmanager.employee_id}
                                  value={hiringmanager.employee_id}
                                >
                                  {hiringmanager.employee_name}
                                </option>
                              ))}
                            </Field>
                            <ErrorMessage
                              name='interviewer_id'
                              component='div'
                              className='text-danger'
                            />
                          </div>
                        </div>
                        <div className='row mb-5 d-flex align-items-center'>
                          <label className='col-lg-4 fw-bold text-muted text-start'>
                            {intl.formatMessage({id: 'LABEL.SELECT_ROUND'})}
                          </label>
                          <div className='col-lg-8 text-start'>
                            <Field
                              as='select'
                              className='form-select form-select-solid'
                              id='floatingInput'
                              name='interview_type_id'
                              value={values.interview_type_id}
                              onChange={handleChange}
                            >
                              <option value=''>
                                {' '}
                                {intl.formatMessage({id: 'LABEL.SELECT_ROUND'})}
                              </option>
                              {interviewtypes?.map((interviewType) => (
                                <option
                                  key={interviewType.interview_type_id}
                                  value={interviewType.interview_type_id}
                                >
                                  {interviewType.interview_type}
                                </option>
                              ))}
                            </Field>
                            <ErrorMessage
                              name='interview_type_id'
                              component='div'
                              className='text-danger'
                            />
                          </div>
                        </div>

                        <div className='row mb-5 d-flex align-items-center'>
                          <label className='col-lg-4 fw-bold text-muted text-start'>
                            {intl.formatMessage({id: 'LABEL.SELECT_INTERVIEW_MODE'})}
                          </label>
                          <div className='col-lg-8 text-start'>
                            <Field
                              type='text'
                              name='interview_mode'
                              className='form-control form-control-solid'
                              value={values.interview_mode}
                              placeholder={intl.formatMessage({id: 'LABEL.SELECT_INTERVIEW_MODE'})}
                              onChange={(e) => {
                                setFieldValue('interview_mode', e.target.value)
                                if (e.target.value === 'Online') {
                                  setFieldValue('interview_location', 'Online')
                                }
                              }}
                            />
                            <ErrorMessage
                              name='interview_mode'
                              component='div'
                              className='text-danger'
                            />
                          </div>
                        </div>
                        <div className='row mb-5 d-flex align-items-center'>
                          <label className='col-lg-4 fw-bold text-muted text-start'>
                            {intl.formatMessage({id: 'LABEL.SELECT_INTERVIEW_LOCATION'})}
                          </label>
                          <div className='col-lg-8 text-start'>
                            <Field
                              type='text'
                              name='interview_location'
                              className='form-control form-control-solid'
                              value={values.interview_location}
                              placeholder={intl.formatMessage({
                                id: 'LABEL.SELECT_INTERVIEW_LOCATION',
                              })}
                              onChange={(e) => {
                                if (values.interview_mode === 'Online') {
                                  setFieldValue('interview_location', 'Online')
                                } else {
                                  setFieldValue('interview_location', e.target.value)
                                }
                              }}
                            />
                            <ErrorMessage
                              name='interview_location'
                              component='div'
                              className='text-danger'
                            />
                          </div>
                        </div>

                        <div className='row mb-5 d-flex align-items-center'>
                          <label className='col-lg-4 fw-bold text-muted text-start'>
                            {intl.formatMessage({id: 'LABEL.SELECT_DATE_TIME'})}
                          </label>
                          <div className='col-lg-8 text-start'>
                            <Field name='interview_timestamp'>
                              {({form, field}) => {
                                const {setFieldValue} = form
                                const {value} = field

                                const handleChange = (date) => {
                                  setFieldValue('interview_timestamp', date)
                                  setSelectedDate(date)
                                }

                                return (
                                  <div
                                    className={`react-datepicker-fullwidth-wrapper test ${
                                      mode === 'dark' ? 'dark-mode' : 'light-mode'
                                    }`}
                                  >
                                    <DatePicker
                                      selected={values.interview_timestamp}
                                      minDate={new Date()}
                                      isClearable
                                      showYearDropdown
                                      scrollableYearDropdown
                                      onChange={(date) => {
                                        handleChange(date)
                                      }}
                                      placeholderText={intl.formatMessage({
                                        id: 'LABEL.SELECT_DATE_TIME',
                                      })}
                                      showMonthDropdown
                                      useShortMonthInDropdown
                                      dateFormat='MM/dd/yyyy h:mm aa'
                                      timeInputLabel='Time:'
                                      className='form-control form-control-solid'
                                      popperPlacement='bottom'
                                    />
                                  </div>
                                )
                              }}
                            </Field>
                            <ErrorMessage
                              name='interview_timestamp'
                              component='div'
                              className='text-danger'
                            />
                          </div>
                        </div>

                        <div className='card-footer p-0 py-5 text-end'>
                          <button
                            type='submit'
                            className='btn btn-sm btn-light-primary ms-2'
                            onClick={() => {
                              setIsSubmitting(true)
                            }}
                          >
                            {intl.formatMessage({id: 'BTN.SCHEDULE_INTERVIEW'})}
                          </button>
                        </div>
                      </>
                    ) : (
                      <div className='d-flex justify-content-center'>
                        <div className='h-250px d-flex flex-column justify-content-center'>
                          <button
                            className='btn btn-light-primary btn-sm w-100px m-auto'
                            onClick={() => fetchData()}
                          >
                            Retry!
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export default ScheduleInterview
