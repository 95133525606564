import React, {useState, useEffect, useRef} from 'react'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import * as Yup from 'yup'

import Swal from 'sweetalert2'
import {useIntl} from 'react-intl'

import {KTSVG} from '../../../../_metronic/helpers'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import {baseUrl} from '../../core'
import secureLocalStorage from 'react-secure-storage'

import DatePicker from 'react-datepicker'
import '../../DatepickerStyles.css'
import 'react-datepicker/dist/react-datepicker.css'

import {DateBasicFormat} from '../../DateHelpers'
import {useThemeMode} from '../../../../_metronic/partials'
import useApiCall from '../../custom_hooks/useApiCall'
import usePostApi from '../../custom_hooks/usePostApi'

const AddRoleKpi = ({}) => {
  let job_role_id
  let organization_id
  const userInfo = secureLocalStorage.getItem('user_info')
  if (userInfo) {
    ;({organization_id, job_role_id} = userInfo)
  }

  const {mode} = useThemeMode()
  const {execute} = usePostApi()
  const {data: jobroles} = useApiCall('/hrConfig/jobRole')
  const intl = useIntl()
  const [selectedid, setSelectedid] = useState(null)

  const validationSchema = Yup.object().shape({
    job_role_id: Yup.string().required(`${intl.formatMessage({id: 'ERR.MSG.ROLE_IS_REQUIRED'})}`),
    start_date: Yup.string().required(
      `${intl.formatMessage({id: 'ERR.MSG.START_DATE_IS_REQUIRED'})}`
    ),
    end_date: Yup.string().when('start_date', (start_date, schema) => {
      return schema
        .test(
          'end_date',
          `${intl.formatMessage({id: 'ERR.MSG.END_DATE_GREATER_THAN_REQUIRED'})}`,
          function (value) {
            return !start_date || !value || new Date(value) >= new Date(start_date)
          }
        )
        .required(`${intl.formatMessage({id: 'ERR.MSG.END_DATE_IS_REQUIRED'})}`)
    }),

    kpi_name: Yup.string().required(`${intl.formatMessage({id: 'ERR.MSG.KPI_IS_REQUIRED'})}`),
  })
  const [isSubmitting, setIsSubmitting] = useState(false)
  const initialValues = {
    roleType: '',
    start_date: '',
    end_date: '',
    kpi_name: '',
    job_role_id: '',
  }

  const handleSubmit = async (values, formikHelpers) => {
    const {resetForm} = formikHelpers
    try {
      const url = `${baseUrl}/performance/kpi`
      const organizationId = secureLocalStorage.getItem('organization_id')
      const selectedroleType = jobroles.find(
        (roleType) => roleType.role_name === values.job_role_id
      )

      const formData = {
        organization_id: organizationId,
        job_role_id: selectedroleType.job_role_id,
        kpi_name: values.kpi_name,
        start_date: DateBasicFormat(values.start_date),
        end_date: DateBasicFormat(values.end_date),
        kpi_status: 'Pending',
      }

      await execute(url, 'POST', formData)

      resetForm()
      Swal.fire({
        title: `${intl.formatMessage({id: 'MSG.KPI_CREATED_SUCCESSFULLY'})}`,
        text: intl.formatMessage({id: 'LABEL.CHANGES_SAVED'}),
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-light-primary btn-sm',
        },
      })
    } catch (error) {
      console.error(error)
      Swal.fire({
        icon: 'error',
        title: intl.formatMessage({id: 'LABEL.OOPS'}),
        text: intl.formatMessage({id: 'LABEL.SOMETHING_WRONG'}),
        customClass: {
          confirmButton: 'btn btn-light-primary btn-sm',
        },
      })
    }
  }

  return (
    <div className='card shadow-none rounded-0'>
      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({values, setFieldValue}) => (
            <Form>
              <div className='card-header' id='side_activities_header'>
                <h3 className='card-title fw-bolder text-dark'>
                  {intl.formatMessage({id: 'LABEL.CREATE_KPI'})}
                </h3>
                <div className='card-toolbar '>
                  <React.Fragment>
                    <div
                      className='btn btn-icon btn-sm btn-active-danger'
                      id='side_activities_close'
                    >
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr061.svg'
                        className='svg-icon svg-icon-2x'
                      />
                    </div>
                  </React.Fragment>
                </div>
              </div>

              <div className='card-body position-relative' id='side_activities_body'>
                <div>
                  <div
                    id='side_activities_scroll'
                    className='position-relative scroll-y me-n5 pe-5 w-400px h-500px'
                    data-kt-scroll='true'
                    data-kt-scroll-height='auto'
                    data-kt-scroll-wrappers='#side_activities_body'
                    data-kt-scroll-dependencies='#side_activities_header, #side_activities_footer'
                    data-kt-scroll-offset='5px'
                  >
                    <div className='row mb-5 d-flex align-items-center'>
                      <label className='col-lg-4 fw-bold text-muted text-start'>
                        {intl.formatMessage({id: 'LABEL.SELECT_ROLE'})}
                      </label>
                      <div className='col-lg-8 text-start'>
                        <Field
                          as='select'
                          className='form-select form-select-solid'
                          id='floatingInput'
                          name='job_role_id'
                          value={values.job_role_id || ''}
                          onChange={(e) => setFieldValue('job_role_id', e.target.value)}
                          placeholder={intl.formatMessage({id: 'LABEL.SELECT_ROLE'})}
                        >
                          <option value=''>{intl.formatMessage({id: 'LABEL.SELECT_ROLE'})}</option>
                          {jobroles?.map((roleType) => (
                            <option key={roleType.job_role_id} value={roleType.role_name}>
                              {roleType.role_name}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage name='job_role_id' component='div' className='text-danger' />
                      </div>
                    </div>

                    <div className='row mb-5 d-flex align-items-center'>
                      <label className='col-lg-4 fw-bold text-muted text-start'>
                        {intl.formatMessage({id: 'LABEL.KPI_NAME'})}
                      </label>
                      <div className='col-lg-8 text-start'>
                        <Field
                          type='text'
                          name='kpi_name'
                          className='form-control form-control-solid'
                          value={values.kpi_name || ''}
                          onChange={(e) => setFieldValue('kpi_name', e.target.value)}
                          placeholder={intl.formatMessage({id: 'LABEL.KPI_NAME'})}
                        />
                        <ErrorMessage name='kpi_name' component='div' className='text-danger' />
                      </div>
                    </div>

                    <div className='row mb-5 d-flex align-items-center'>
                      <label className='col-lg-4 fw-bold text-muted text-start'>
                        {intl.formatMessage({id: 'LABEL.START_DATE'})}
                      </label>

                      <div className='col-lg-8 text-start'>
                        <Field name='start_date'>
                          {({form, field}) => {
                            const {setFieldValue} = form
                            const {value} = field

                            const handleChange = (date) => {
                              setFieldValue('start_date', date)
                            }

                            return (
                              <div
                                className={`react-datepicker-fullwidth-wrapper test ${
                                  mode === 'dark' ? 'dark-mode' : 'light-mode'
                                }`}
                              >
                                <DatePicker
                                  selected={value}
                                  isClearable
                                  showYearDropdown
                                  scrollableYearDropdown
                                  onChange={(date) => {
                                    handleChange(date)
                                  }}
                                  placeholderText={intl.formatMessage({
                                    id: 'LABEL.SELECT_START_DATE',
                                  })}
                                  showMonthDropdown
                                  useShortMonthInDropdown
                                  dateFormat='dd/MM/yyyy'
                                  className='form-control form-control-solid'
                                  popperPlacement='bottom'
                                />
                              </div>
                            )
                          }}
                        </Field>
                        <ErrorMessage name='start_date' component='div' className='text-danger' />
                      </div>
                    </div>

                    <div className='row mb-5 d-flex align-items-center'>
                      <label className='col-lg-4 fw-bold text-muted text-start'>
                        {intl.formatMessage({id: 'LABEL.END_DATE'})}
                      </label>
                      <div className='col-lg-8 text-start'>
                        <Field name='end_date'>
                          {({form, field}) => {
                            const {setFieldValue} = form
                            const {value} = field

                            const handleChange = (date) => {
                              setFieldValue('end_date', date)
                            }

                            let minDate = null
                            if (values && values.start_date) {
                              const startDate = new Date(values.start_date)
                              minDate = startDate.setDate(startDate.getDate() + 1)
                            }

                            return (
                              <div
                                className={`react-datepicker-fullwidth-wrapper test ${
                                  mode === 'dark' ? 'dark-mode' : 'light-mode'
                                }`}
                              >
                                <DatePicker
                                  selected={value}
                                  isClearable
                                  showYearDropdown
                                  scrollableYearDropdown
                                  onChange={(date) => {
                                    handleChange(date)
                                  }}
                                  placeholderText={intl.formatMessage({
                                    id: 'LABEL.SELECT_END_DATE',
                                  })}
                                  showMonthDropdown
                                  useShortMonthInDropdown
                                  dateFormat='dd/MM/yyyy'
                                  className='form-control form-control-solid'
                                  popperPlacement='bottom'
                                  minDate={minDate}
                                />
                              </div>
                            )
                          }}
                        </Field>
                        <ErrorMessage name='end_date' component='div' className='text-danger' />
                      </div>
                    </div>

                    <div className='card-footer p-0 py-5 text-end'>
                      <button
                        type='submit'
                        className='btn btn-sm btn-light-primary ms-2'
                        onClick={() => {
                          setIsSubmitting(true)
                        }}
                      >
                        {intl.formatMessage({id: 'BTN.ADD'})}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export default AddRoleKpi
