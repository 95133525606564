import {useState, useEffect} from 'react'
import axios from 'axios'
import {baseUrl} from '../core'
import secureLocalStorage from 'react-secure-storage'

function useApiCall(url, employeeId) {
  const [data, setData] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const fetchData = async () => {
    try {
      if (!url) {
        setIsLoading(false)
        return
      }
      setIsLoading(true)
      // setData([])
      let emp_id
      let user_id
      let auth_token
      let organization_id
      const userInfo = secureLocalStorage.getItem('user_info')
      if (userInfo) {
        ;({emp_id, user_id, auth_token, organization_id} = userInfo)
      }
      const headers = {
        Authorization: `Bearer ${auth_token}`,
        'Content-Type': 'application/json',
        i18n_language: 'en',
        user_id: user_id,
        employee_id: employeeId ? employeeId : emp_id,
        organization_id: organization_id,
        zylk_license_key: 'FCMA-A237-36CE-390B-42F2-8957-83F4-D869-3190',
      }
      const response = await axios.get(baseUrl + url, {
        headers,
      })
      setData(response.data.api_response_data)
      setIsLoading(false)
    } catch (error) {
      console.error('Error fetching user data:', error)
      setIsLoading(false)
    }
  }
  useEffect(() => {
    fetchData()
  }, [url])
  return {data, isLoading, fetchData}
}

export default useApiCall
