import {useEffect, useState} from 'react'
import useApiCall from '../custom_hooks/useApiCall'
import secureLocalStorage from 'react-secure-storage'
export function GetAllLeaves() {
  let emp_id
  let user_id
  const userInfo = secureLocalStorage.getItem('user_info')
  if (userInfo) {
    ;({emp_id, user_id} = userInfo)
  }
  const {
    data: leavesData,
    isLoading: isLeavesLoading,
    fetchData,
  } = useApiCall(`/leave/emp/${emp_id}`)

  useEffect(() => {
    fetchData()
  }, [])

  return {leavesData, isLeavesLoading}
}

export function GetAllApprovedLeaves() {
  let emp_id
  let user_id
  const userInfo = secureLocalStorage.getItem('user_info')
  if (userInfo) {
    ;({emp_id, user_id} = userInfo)
  }
  const {
    data: approvedleavesData,
    isLoading: isapprovedLoading,
    fetchData,
  } = useApiCall(`/leave/emp/${emp_id}`)

  useEffect(() => {
    fetchData()
  }, [])

  // Filter and return only approved leaves
  const approvedLeaves = approvedleavesData?.filter((leave) => leave.status === 'Approved')

  return {approvedLeaves, isapprovedLoading}
}
export function GetAllEmpLeaves() {
  const {
    data: allleaveData,
    isLoading: isallleaveLoading,
    fetchData,
  } = useApiCall(`/leave/employees`)

  useEffect(() => {
    fetchData()
  }, [])

  return {allleaveData, isallleaveLoading}
}

export function GetAllApprovedEmpLeaves() {
  const {
    data: getallapprovedleavesData,
    isLoading: isallapprovedLoading,
    fetchData,
  } = useApiCall(`/leave/employees`)

  useEffect(() => {
    fetchData()
  }, [])

  // Filter and return only approved leaves
  const allapprovedleaves = getallapprovedleavesData?.filter(
    (request) => request.status === 'Approved'
  )

  return {allapprovedleaves, isallapprovedLoading}
}
