import {useIntl} from 'react-intl'

interface ToggleProps {
  name: string
  image: string
}

export function Toggle(props: ToggleProps) {
  const intl = useIntl()
  const handleDownload = () => {
    window.open(props.image, '_blank')
  }

  return (
    <div className='menu menu-sub menu-sub-dropdown w-250px w-md-250px' data-kt-menu='true'>
      <div className='px-7 py-5'>
        <div className='fs-5 text-dark fw-bolder'>{props.name}</div>
      </div>
      <div className='px-7 symbol pr-0 symbol-200px me-2 pl-0'>
        <img
          src={`${props.image}?timestamp=${Date.now()}`}
          className='h-200 align-self-center'
          alt={props.name}
        />
      </div>
      <div className='mb-5 m-4 d-flex justify-content-end'>
        <button
          type='button'
          className='btn btn-sm btn-light-primary btn-ripple'
          data-kt-menu-dismiss='true'
          onClick={handleDownload}
        >
          {intl.formatMessage({id: 'BUTTON.DOWNLOAD'})}
        </button>
      </div>
    </div>
  )
}
