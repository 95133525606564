import {Route, Routes, useLocation} from 'react-router-dom'
import {useState} from 'react'

import {PageLink, PageTitle} from '../../../_metronic/layout/core'

import {useIntl} from 'react-intl'

import RecruitmentNav from './RecruitmentNav'

import Offers from './offers/Offers'
import AllCandidatesTable from './candidates/AllCandidatesFile'

import Vacancies from './vacancies/Vacancies'
import CandidatesTable from './candidates/CandidatesTable'
import InterviewTable from './interviews/InterviewTable'
import VacancyContext from './vacancies/VacancyContext'
import AllCandidates from './candidates/AllCandidates'
import VacanciesTable from './vacancies/VacanciesTable'
import OffersTable from './offers/OffersTable'
const RecruitmentRoutes = () => {
  const intl = useIntl()
  const recruitmentBreadCrumbs = [
    {
      title: intl.formatMessage({id: 'MENU.RECRUITMENT'}),
      path: '/recruitment/vacancies',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  const {pathname} = useLocation()
  const [VacancyId, updateVACANCYID] = useState('0')
  const [interviewID, updateINTERVIEWID] = useState('0')
  const [candidateID, updateCANDIDATEID] = useState('0')
  const switchMethod = () => {
    switch (pathname) {
      case '/recruitment/vacancies':
        return (
          <>
            <PageTitle breadcrumbs={recruitmentBreadCrumbs}>
              {intl.formatMessage({id: 'MENU.RECRUITMENT.VACANCIES'})}
            </PageTitle>
            <VacanciesTable />
          </>
        )

      case '/recruitment/candidates':
        return (
          <>
            <PageTitle breadcrumbs={recruitmentBreadCrumbs}>
              {intl.formatMessage({id: 'MENU.RECRUITMENT.CANDIDATES'})}
            </PageTitle>
            <CandidatesTable />
          </>
        )

      case '/recruitment/allcandidates':
        return (
          <>
            <PageTitle breadcrumbs={recruitmentBreadCrumbs}>
              {intl.formatMessage({id: 'MENU.RECRUITMENT.CANDIDATES'})}
            </PageTitle>
            <AllCandidates />
          </>
        )

      case '/recruitment/all-job-offers':
        return (
          <>
            <PageTitle breadcrumbs={recruitmentBreadCrumbs}>
              {intl.formatMessage({id: 'MENU.RECRUITMENT.JOB_OFFERS'})}
            </PageTitle>
            <OffersTable />
          </>
        )

      case '/recruitment/interviews':
        return (
          <>
            <PageTitle breadcrumbs={recruitmentBreadCrumbs}>
              {intl.formatMessage({id: 'MENU.RECRUITMENT.INTERVIEWS'})}
            </PageTitle>
            <InterviewTable />
          </>
        )

      // case '/recruitment/all-job-offers':
      //   return (
      //     <>
      //       <PageTitle breadcrumbs={recruitmentBreadCrumbs}>
      //         {intl.formatMessage({id: 'MENU.RECRUITMENT.JOB_OFFERS'})}
      //       </PageTitle>
      //       <OffersTable />
      //     </>
      //   )

      default:
        return (
          <>
            <PageTitle breadcrumbs={recruitmentBreadCrumbs}>
              {intl.formatMessage({id: 'MENU.RECRUITMENT.VACANCIES'})}
            </PageTitle>
            <Vacancies />
          </>
        )
    }
  }

  const UpdatingId = (VACANCYID) => {
    updateVACANCYID(VACANCYID)
  }

  const UpdatingInterviewId = (INTERVIEWID) => {
    updateINTERVIEWID(INTERVIEWID)
  }
  const UpdatingCandidateId = (CANDIDATEID) => {
    updateCANDIDATEID(CANDIDATEID)
  }

  return (
    <>
      <VacancyContext.Provider
        value={{
          VACANCYID: VacancyId,
          updateVACANCYID: UpdatingId,
          INTERVIEWID: interviewID,
          updateINTERVIEWID: UpdatingInterviewId,
          CANDIDATEID: candidateID,
          updateCANDIDATEID: UpdatingCandidateId,
        }}
      >
        <RecruitmentNav id={VacancyId} />
        {switchMethod()}
      </VacancyContext.Provider>
    </>
  )
}

export default RecruitmentRoutes
