/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import {Toggle} from './LogosBannerToggle'
import {useIntl} from 'react-intl'
import Swal from 'sweetalert2'
import {baseUrl} from '../../../core'
import secureLocalStorage from 'react-secure-storage'
import useApiCall from '../../../custom_hooks/useApiCall'
import usePostApi from '../../../custom_hooks/usePostApi'
import {useParams} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
const LogosBanners = () => {
  const intl = useIntl()
  const {execute} = usePostApi()
  const {id}: any | null = useParams()
  const userInfo: any | null = secureLocalStorage.getItem('user_info')
  const {is_admin, is_hr} = userInfo || {}
  const {data: logosData, fetchData}: any | null = useApiCall(`/pref/logos/1`)
  let BrandlogosData = logosData && logosData[0]
  interface LogoUrls {
    brand_logo_url: string
    brand_favicon_url: string
    brand_header_image_url: string
  }
  const [data, setData] = useState<LogoUrls>({
    brand_logo_url: '',
    brand_favicon_url: '',
    brand_header_image_url: '',
  })
  useEffect(() => {
    if (BrandlogosData) {
      setData(BrandlogosData)
    }
  }, [BrandlogosData])

  const brandLogoInputRef = useRef<HTMLInputElement>(null)
  const faviconInputRef = useRef<HTMLInputElement>(null)
  const brandHeaderInputRef = useRef<HTMLInputElement>(null)

  const handleUploadClick = (inputRef: React.RefObject<HTMLInputElement>) => {
    if (inputRef.current !== null) {
      inputRef.current.click()
    }
  }

  const handleImageChange = (field: keyof LogoUrls, file: File) => {
    const reader = new FileReader()
    reader.onloadend = () => {
      const previewImage = reader.result as string
      setData((prevData) => ({
        ...prevData,
        [field]: previewImage,
      }))
    }
    reader.readAsDataURL(file)
  }

  const handleLogoEditSubmit = async () => {
    const url = `${baseUrl}/pref/logo/1`
    try {
      const result = await Swal.fire({
        title: intl.formatMessage({id: 'LABEL.UPDATING_BRAND_LOGO'}),
        text: intl.formatMessage({id: 'LABEL.CONFIRM_UPDATE_BRAND_LOGO'}),
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: intl.formatMessage({id: 'BTN.CONFIRM'}),
        cancelButtonText: intl.formatMessage({id: 'LABEL.CANCEL'}),
        customClass: {
          confirmButton: 'btn btn-light-primary btn-sm',
          cancelButton: 'btn btn-sm btn-secondary',
        },
      })

      if (result.isConfirmed) {
        const formData = new FormData()
        const brandLogoField = 'brand_logo_url'
        if (data[brandLogoField]) {
          const file = dataURItoFile(data[brandLogoField], `image_${brandLogoField}`)
          formData.append(brandLogoField, file)
        }
        await execute(url, 'PATCH', formData, id)
        Swal.fire({
          title: 'Brand Logo Edited Successfully',
          text: intl.formatMessage({id: 'LABEL.CHANGES_SAVED'}),
          icon: 'success',
          showConfirmButton: true,
          confirmButtonText: 'OK',
          customClass: {
            confirmButton: 'btn btn-light-primary btn-sm',
          },
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload()
          }
        })
      }
    } catch (error) {
      console.error(error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: intl.formatMessage({id: 'LABEL.SOMETHING_WRONG'}),
      })
    }
  }

  const handleFaviconEditSubmit = async () => {
    const url = `${baseUrl}/pref/favicon/1`
    try {
      const result = await Swal.fire({
        title: intl.formatMessage({id: 'LABEL.UPDATING_FAVICON_LOGO'}),
        text: intl.formatMessage({id: 'LABEL.CONFIRM_UPDATE_FAVICON_LOGO'}),
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: intl.formatMessage({id: 'BTN.CONFIRM'}),
        cancelButtonText: intl.formatMessage({id: 'LABEL.CANCEL'}),
        customClass: {
          confirmButton: 'btn btn-light-primary btn-sm',
          cancelButton: 'btn btn-sm btn-secondary',
        },
      })

      if (result.isConfirmed) {
        const formData = new FormData()
        const brandFaviconField = 'brand_favicon_url'
        if (data[brandFaviconField]) {
          const file = dataURItoFile(data[brandFaviconField], `image_${brandFaviconField}`)
          formData.append(brandFaviconField, file)
        }
        await execute(url, 'PATCH', formData, id)
        Swal.fire({
          title: 'Favicon Logo Edited Successfully',
          text: intl.formatMessage({id: 'LABEL.CHANGES_SAVED'}),
          icon: 'success',
          showConfirmButton: true,
          confirmButtonText: 'OK',
          customClass: {
            confirmButton: 'btn btn-light-primary btn-sm',
          },
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload()
          }
        })
      }
    } catch (error) {
      console.error(error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: intl.formatMessage({id: 'LABEL.SOMETHING_WRONG'}),
      })
    }
  }

  const handleHeaderEditSubmit = async () => {
    const url = `${baseUrl}/pref/headerImage/1`
    try {
      const result = await Swal.fire({
        title: intl.formatMessage({id: 'LABEL.UPDATING_HEADER_LOGO'}),
        text: intl.formatMessage({id: 'LABEL.CONFIRM_UPDATE_HEADER_LOGO'}),
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: intl.formatMessage({id: 'BTN.CONFIRM'}),
        cancelButtonText: intl.formatMessage({id: 'LABEL.CANCEL'}),
        customClass: {
          confirmButton: 'btn btn-light-primary btn-sm',
          cancelButton: 'btn btn-sm btn-secondary',
        },
      })

      if (result.isConfirmed) {
        const formData = new FormData()
        const brandHeaderField = 'brand_header_image_url'
        if (data[brandHeaderField]) {
          const file = dataURItoFile(data[brandHeaderField], `image_${brandHeaderField}`)
          formData.append(brandHeaderField, file)
        }
        await execute(url, 'PATCH', formData, id)
        Swal.fire({
          title: 'Header Logo Edited Successfully',
          text: intl.formatMessage({id: 'LABEL.CHANGES_SAVED'}),
          icon: 'success',
          showConfirmButton: true,
          confirmButtonText: 'OK',
          customClass: {
            confirmButton: 'btn btn-light-primary btn-sm',
          },
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload()
          }
        })
      }
    } catch (error) {
      console.error(error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: intl.formatMessage({id: 'LABEL.SOMETHING_WRONG'}),
      })
    }
  }

  const [uploadedFile, setUploadedFile] = useState<File | null>(null)
  const [faviconFile, setFaviconFile] = useState<File | null>(null)
  const [headerFile, setHeaderFile] = useState<File | null>(null)
  const handleBrandLogoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (file) {
      setUploadedFile(file) // Store the uploaded file
      handleImageChange('brand_logo_url', file)
    }
  }
  const handleFaviconChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (file) {
      setFaviconFile(file) // Store the uploaded file
      handleImageChange('brand_favicon_url', file)
    }
  }
  const handleHeaderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (file) {
      setHeaderFile(file) // Store the uploaded file
      handleImageChange('brand_header_image_url', file)
    }
  }

  const handleCancelClick = () => {
    setUploadedFile(null)
    setFaviconFile(null)
    setHeaderFile(null)
    fetchData()
  }

  const isSaveEnabled = !!uploadedFile
  const isSaveFaviconEnabled = !!faviconFile
  const isSaveHeaderEnabled = !!headerFile

  const dataURItoFile = (dataURI: string, fileName: string): File => {
    const byteString = atob(dataURI.split(',')[1])
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
    const ab = new ArrayBuffer(byteString.length)
    const ia = new Uint8Array(ab)
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i)
    }
    return new File([ab], fileName, {type: mimeString})
  }
  return (
    <div className={`card card-xl-stretch`}>
      <div className='card-header'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-5 mb-1'>
            {intl.formatMessage({id: 'LABEL.ORGANIZATION_LOGOS_BANNERS'})}
          </span>
          <span className='text-muted fw-bold fs-7'>
            {intl.formatMessage({id: 'LABEL.ORGANIZATION_UPLOAD_YOUR_BRAND_LOGO'})}
          </span>
        </h3>
      </div>
      <div className='card-body'>
        <div className='row'>
          <div className='col-md-12 col-sm-12 mb-3'>
            <div className='row'>
              <div className='col-5 d-flex'>
                <div className='col-6 d-flex'>
                  {isSaveEnabled ? (
                    <div className='symbol symbol-60px me-2'>
                      <img src={data?.brand_logo_url} alt='Brand Logo' />
                    </div>
                  ) : (
                    <div className='symbol symbol-60px me-2'>
                      <img
                        src={
                          data?.brand_logo_url
                            ? `${data?.brand_logo_url}?timestamp=${Date.now()}`
                            : toAbsoluteUrl('/media/zylk-logo.svg')
                        }
                        alt='Brand Logo'
                      />
                    </div>
                  )}
                </div>
                <div className='col-6 d-flex'>
                  <div className='row'>
                    <div className='col-12'>
                      <h1 className='text-dark fw-bold text-hover-primary fs-6'>
                        {intl.formatMessage({id: 'LABEL.ORGANIZATION_BRAND_LOGO'})}
                      </h1>
                    </div>
                    <div className='col-12'>
                      <div className='d-flex'>
                        <p className='text-dark text-hover-primary fs-8'>
                          {intl.formatMessage({id: 'LABEL.ACCEPTS_JPG/PNG'})}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-7 d-flex justify-content-end '>
                <div className='btn-toolbar' role='toolbar' aria-label='...'>
                  <div className='btn-group mr-2' role='group' aria-label='...'>
                    <button
                      type='button'
                      className='btn btn-active-color-primary btn-icon border'
                      data-kt-menu-trigger='click'
                      data-kt-menu-placement='bottom-start'
                      data-kt-menu-flip='top-end'
                    >
                      <i className='fs-1 la la-eye'></i>
                    </button>
                    <Toggle
                      name={intl.formatMessage({id: 'LABEL.ORGANIZATION_BRAND_LOGO'})}
                      image={data?.brand_logo_url}
                    />
                    <button
                      type='button'
                      className='btn btn-active-color-primary btn-icon border'
                      onClick={() => handleUploadClick(brandLogoInputRef)}
                      data-kt-menu-trigger='click'
                      data-kt-menu-placement='bottom-start'
                      data-kt-menu-flip='top-end'
                      disabled={!(is_admin === 1)}
                    >
                      <i className='fs-1 la la-paperclip'></i>
                    </button>
                    <input
                      ref={brandLogoInputRef}
                      type='file'
                      accept='image/*'
                      onChange={handleBrandLogoChange}
                      style={{display: 'none'}}
                    />
                    {isSaveEnabled && (
                      <>
                        <button
                          type='button'
                          className={`btn btn-active-color-primary btn-icon border${
                            isSaveEnabled ? '' : ' disabled'
                          }`}
                          onClick={handleLogoEditSubmit}
                          disabled={!isSaveEnabled}
                        >
                          <i className='fs-1 la la-floppy-o'></i>
                        </button>
                        <button
                          type='button'
                          className={`btn btn-active-color-primary btn-icon border${
                            isSaveEnabled ? '' : ' disabled'
                          }`}
                          onClick={handleCancelClick}
                          disabled={!isSaveEnabled}
                        >
                          <i className='fs-1 la la-times'></i>
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-12 col-sm-12 mb-3'>
            <div className='row'>
              <div className='col-5 d-flex'>
                <div className='col-6 d-flex'>
                  {isSaveFaviconEnabled ? (
                    <div className='symbol symbol-60px me-2'>
                      <img src={data.brand_favicon_url} alt='Brand Logo' />
                    </div>
                  ) : (
                    <div className='symbol symbol-60px me-2'>
                      <img
                        src={
                          data?.brand_favicon_url
                            ? `${data.brand_favicon_url}?timestamp=${Date.now()}`
                            : toAbsoluteUrl('/media/logos/favicon.ico')
                        }
                        alt='Brand Logo'
                      />
                    </div>
                  )}
                </div>
                <div className='col-6 d-flex'>
                  <div className='row'>
                    <div className='col-12'>
                      <h1 className='text-dark fw-bold text-hover-primary fs-6'>
                        {intl.formatMessage({id: 'LABEL.ORGANIZATION_Favicon'})}
                      </h1>
                    </div>
                    <div className='col-12'>
                      <div className='d-flex'>
                        <p className='text-dark text-hover-primary fs-8'>
                          {intl.formatMessage({id: 'LABEL.ACCEPTS_JPG/PNG'})}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-7 d-flex justify-content-end '>
                <div className='btn-toolbar' role='toolbar' aria-label='...'>
                  <div className='btn-group mr-2' role='group' aria-label='...'>
                    <button
                      type='button'
                      className='btn btn-active-color-primary btn-icon border'
                      data-kt-menu-trigger='click'
                      data-kt-menu-placement='bottom-start'
                      data-kt-menu-flip='top-end'
                    >
                      <i className='fs-1 la la-eye'></i>
                    </button>
                    <Toggle
                      name={intl.formatMessage({id: 'LABEL.ORGANIZATION_Favicon'})}
                      image={data.brand_favicon_url}
                    />
                    <button
                      type='button'
                      className='btn btn-active-color-primary btn-icon border'
                      onClick={() => handleUploadClick(faviconInputRef)}
                      data-kt-menu-trigger='click'
                      data-kt-menu-placement='bottom-start'
                      data-kt-menu-flip='top-end'
                      disabled={!(is_admin === 1)}
                    >
                      <i className='fs-1 la la-paperclip'></i>
                    </button>
                    <input
                      ref={faviconInputRef}
                      type='file'
                      accept='image/*'
                      onChange={handleFaviconChange}
                      style={{display: 'none'}}
                    />
                    {isSaveFaviconEnabled && (
                      <>
                        <button
                          type='button'
                          className={`btn btn-active-color-primary btn-icon border${
                            isSaveFaviconEnabled ? '' : ' disabled'
                          }`}
                          onClick={handleFaviconEditSubmit}
                          disabled={!isSaveFaviconEnabled}
                        >
                          <i className='fs-1 la la-save'></i>
                        </button>
                        <button
                          type='button'
                          className={`btn btn-active-color-primary btn-icon border${
                            isSaveFaviconEnabled ? '' : ' disabled'
                          }`}
                          onClick={handleCancelClick}
                          disabled={!isSaveFaviconEnabled}
                        >
                          <i className='fs-1 la la-times'></i>
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-12 col-sm-12 mb-3'>
            <div className='row'>
              <div className='col-5 d-flex'>
                <div className='col-6 d-flex'>
                  {isSaveHeaderEnabled ? (
                    <div className='symbol symbol-60px me-2 '>
                      <img src={data.brand_header_image_url} alt='header Logo' />
                    </div>
                  ) : (
                    <div className='symbol symbol-60px me-2 '>
                      <img
                        src={
                          data?.brand_header_image_url
                            ? `${data.brand_header_image_url}?timestamp=${Date.now()}`
                            : toAbsoluteUrl('/media/zylk-logo-white.svg')
                        }
                        alt='header Logo'
                      />
                    </div>
                  )}
                </div>
                <div className='col-6 d-flex'>
                  <div className='row'>
                    <div className='col-12'>
                      <h1 className='text-dark fw-bold text-hover-primary fs-6'>
                        {intl.formatMessage({id: 'LABEL.ORGANIZATION_HEADER_IMAGE'})}
                      </h1>
                    </div>
                    <div className='col-12'>
                      <div className='d-flex'>
                        <p className='text-dark text-hover-primary fs-8'>
                          {intl.formatMessage({id: 'LABEL.ACCEPTS_JPG/PNG'})}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-7 d-flex justify-content-end '>
                <div className='btn-toolbar' role='toolbar' aria-label='...'>
                  <div className='btn-group mr-2' role='group' aria-label='...'>
                    <button
                      type='button'
                      className='btn btn-active-color-primary btn-icon border'
                      data-kt-menu-trigger='click'
                      data-kt-menu-placement='bottom-start'
                      data-kt-menu-flip='top-end'
                    >
                      <i className='fs-1 la la-eye'></i>
                    </button>
                    <Toggle
                      name={intl.formatMessage({id: 'LABEL.ORGANIZATION_HEADER_IMAGE'})}
                      image={data.brand_header_image_url}
                    />
                    <button
                      type='button'
                      className='btn btn-active-color-primary btn-icon border'
                      onClick={() => handleUploadClick(brandHeaderInputRef)}
                      data-kt-menu-trigger='click'
                      data-kt-menu-placement='bottom-start'
                      data-kt-menu-flip='top-end'
                      disabled={!(is_admin === 1)}
                    >
                      <i className='fs-1 la la-paperclip'></i>
                    </button>
                    <input
                      ref={brandHeaderInputRef}
                      type='file'
                      accept='image/*'
                      onChange={handleHeaderChange}
                      style={{display: 'none'}}
                    />
                    {isSaveHeaderEnabled && (
                      <>
                        <button
                          type='button'
                          className={`btn btn-active-color-primary btn-icon border${
                            isSaveHeaderEnabled ? '' : ' disabled'
                          }`}
                          onClick={handleHeaderEditSubmit}
                          disabled={!isSaveHeaderEnabled}
                        >
                          <i className='fs-1 la la-floppy-o'></i>
                        </button>
                        <button
                          type='button'
                          className={`btn btn-active-color-primary btn-icon border${
                            isSaveHeaderEnabled ? '' : ' disabled'
                          }`}
                          onClick={handleCancelClick}
                          disabled={!isSaveHeaderEnabled}
                        >
                          <i className='fs-1 la la-times'></i>
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {LogosBanners}
