import {useEffect, useMemo, useState} from 'react'
import {useTable, usePagination} from 'react-table'
import {Pagination} from 'react-bootstrap'
import {KTIcon} from '../../../../_metronic/helpers'
import Context from '../../../../_metronic/partials/layout/activity-drawer/context'
import {SideDrawer} from '../../../../_metronic/partials/layout/activity-drawer/Side-drawer'
import {useIntl} from 'react-intl'
import useApiCall from '../../custom_hooks/useApiCall'

import usePageTitle from '../../custom_hooks/usePageTitle'

import {useThemeMode} from '../../../../_metronic/partials'
import {defaultProfileImage} from '../../core'

const DataTable = ({columns, data, loading}) => {
  const [inputVal, setInputVal] = useState('')
  const intl = useIntl()
  const {mode} = useThemeMode()
  const [selectedDate, setSelectedDate] = useState(null)
  usePageTitle(intl.formatMessage({id: 'MENU.ASSET_MANAGEMENT.ISSUANCE'}))
  // Filter data based on input value
  const filteredData = useMemo(() => {
    return data.filter(
      (each) =>
        each.asset_type_name.toLowerCase().includes(inputVal.toLowerCase()) ||
        each.brand.toLowerCase().includes(inputVal.toLowerCase())
    )
  }, [data, inputVal])

  // Create separate datasets for active and inactive records

  const activeData = useMemo(() => {
    return filteredData?.filter((leave) => leave.request_status === 'Pending')
  }, [filteredData])

  const inactiveData = useMemo(() => {
    return filteredData?.filter((leave) => leave.request_status === 'Approved')
  }, [filteredData])

  // Table instances for active and inactive dataset
  const RejectedData = useMemo(() => {
    return filteredData?.filter((leave) => leave.request_status === 'Rejected')
  }, [filteredData])

  // Table instances for active and inactive dataset
  const activeTableInstance = useTable({columns, data: activeData}, usePagination)
  const inactiveTableInstance = useTable({columns, data: inactiveData}, usePagination)
  const RejectedDataInstance = useTable({columns, data: RejectedData}, usePagination)

  // Extract required values from table instances
  const activePage = activeTableInstance.page
  const activePageCount = activeTableInstance.pageCount
  const activePageIndex = activeTableInstance.state.pageIndex
  const activePageSize = activeTableInstance.state.pageSize

  const inactivePage = inactiveTableInstance.page
  const inactivePageCount = inactiveTableInstance.pageCount
  const inactivePageIndex = inactiveTableInstance.state.pageIndex
  const inactivePageSize = inactiveTableInstance.state.pageSize

  const RejectedPage = RejectedDataInstance.page
  const RejectedPageCount = RejectedDataInstance.pageCount
  const RejectedPageIndex = RejectedDataInstance.state.pageIndex
  const RejectedPageSize = RejectedDataInstance.state.pageSize

  const gotoActivePage = (pageIndex) => {
    activeTableInstance.gotoPage(pageIndex)
  }

  const setActivePageSize = (pageSize) => {
    activeTableInstance.setPageSize(pageSize)
  }

  // Handle pagination for inactive dataset
  const gotoInactivePage = (pageIndex) => {
    inactiveTableInstance.gotoPage(pageIndex)
  }

  const setInactivePageSize = (pageSize) => {
    inactiveTableInstance.setPageSize(pageSize)
  }

  const gotoRejectedPage = (pageIndex) => {
    RejectedDataInstance.gotoPage(pageIndex)
  }

  const setRejectedPage = (pageSize) => {
    RejectedDataInstance.setPageSize(pageSize)
  }

  const pageOptions = [5, 10, 20, 30, 40, 50]

  return (
    <Context.Consumer>
      {(value) => {
        const {id, updateID} = value
        return (
          <>
            {loading ? (
              <div className='card h-250px'>
                <div className='m-auto d-flex flex-column align-items-center'>
                  <div className='spinner-border spinner-primary mr-15'></div>
                </div>
              </div>
            ) : data.length > 0 ? (
              <>
                <div className='d-flex'>
                  <div className='d-flex flex-wrap flex-stack mb-6'>
                    <h3 className='fw-bolder my-2'>Asset Issuance</h3>
                  </div>
                </div>
                <div className='card'>
                  <>
                    <div className='card-header border-0 pt-5'>
                      <div className='d-flex flex-wrap flex-stack'>
                        <div className='fw-bolder'>
                          <ul className='nav'>
                            <li className='nav-item'>
                              <a
                                className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary active fw-bold px-4 me-1 ms-0'
                                data-bs-toggle='tab'
                                href='#kt_table_widget_4_tab_1'
                              >
                                {intl.formatMessage({id: 'LABEL.PENDING'})}
                              </a>
                            </li>
                            <li className='nav-item'>
                              <a
                                className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1'
                                data-bs-toggle='tab'
                                href='#kt_table_widget_4_tab_2'
                              >
                                {intl.formatMessage({id: 'LABEL.APPROVED'})}
                              </a>
                            </li>
                            <li className='nav-item'>
                              <a
                                className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1'
                                data-bs-toggle='tab'
                                href='#kt_table_widget_4_tab_3'
                              >
                                {intl.formatMessage({id: 'LABEL.REJECTED'})}
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className='card-toolbar'>
                        <div className='d-flex align-items-center position-relative me-3'>
                          <KTIcon iconName='magnifier' className='fs-3 position-absolute ms-3' />
                          <input
                            type='search'
                            id='kt_filter_search'
                            className='form-control form-control-white form-control-sm w-150px ps-9'
                            placeholder={intl.formatMessage({id: 'LABEL.SEARCH'})}
                            value={inputVal}
                            onChange={(e) => setInputVal(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='tab-content p-2 pt-0'>
                      <div
                        className='card-body tab-pane fade show active table-responsive'
                        id='kt_table_widget_4_tab_1'
                      >
                        <table
                          className='table table-row-dashed align-middle table-row-gray-300'
                          {...activeTableInstance.getTableProps()}
                        >
                          <thead>
                            <tr className='fw-bold text-muted'>
                              <th className='min-w-140px default-cursor'>
                                {intl.formatMessage({id: 'LABEL.CANDIDATE'})}
                              </th>
                              <th className='min-w-120px text-start default-cursor'>
                                {intl.formatMessage({id: 'LABEL.ASSET_NAME'})}
                              </th>

                              <th className='min-w-100px text-start  default-cursor'>
                                {intl.formatMessage({id: 'LABEL.ASSET_BRAND'})}
                              </th>

                              <th className='min-w-100px text-start default-cursor'>
                                {' '}
                                {intl.formatMessage({id: 'LABEL.ASSET_MODEL'})}
                              </th>

                              {/* <th className='min-w-100px text-start default-cursor'>
                                {intl.formatMessage({id: 'LABEL.STATUS'})}
                              </th> */}

                              <th className='min-w-100px text-start default-cursor'>
                                {intl.formatMessage({id: 'MENU.ACTIONS'})}
                              </th>
                            </tr>
                          </thead>
                          <tbody {...activeTableInstance.getTableBodyProps()}>
                            {activePage.length > 0 ? (
                              activePage.map((row) => {
                                activeTableInstance.prepareRow(row)
                                return (
                                  <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => (
                                      <td className=' ' {...cell.getCellProps()}>
                                        {cell.render('Cell')}
                                      </td>
                                    ))}
                                  </tr>
                                )
                              })
                            ) : (
                              <tr>
                                <td className='card'>
                                  <p className='text-dark fw-bold text-hover-primary default-cursor d-block fs-6 m-0 my-5'>
                                    {intl.formatMessage({id: 'NO_RESULTS_FOUND_MSG'})}
                                  </p>
                                </td>
                                <td>
                                  <p></p>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>

                        {data.length > 10 && (
                          <Pagination>
                            <Pagination.First
                              onClick={() => gotoActivePage(0)}
                              disabled={!activeTableInstance.canPreviousPage}
                            />
                            <Pagination.Prev
                              onClick={activeTableInstance.previousPage}
                              disabled={!activeTableInstance.canPreviousPage}
                            />
                            {[...Array(activePageCount)].map((_, i) => (
                              <Pagination.Item
                                key={i}
                                active={i === activePageIndex}
                                onClick={() => gotoActivePage(i)}
                              >
                                {i + 1}
                              </Pagination.Item>
                            ))}
                            <Pagination.Next
                              onClick={activeTableInstance.nextPage}
                              disabled={!activeTableInstance.canNextPage}
                            />
                            <Pagination.Last
                              onClick={() => gotoActivePage(activePageCount - 1)}
                              disabled={!activeTableInstance.canNextPage}
                            />
                            <div className='d-flex align-items-center mx-5'>
                              <span className='me-2'>{intl.formatMessage({id: 'LABEL.SHOW'})}</span>
                              <select
                                className='form-select form-select-solid fw-bold w-75px me-2'
                                value={activePageSize}
                                onChange={(e) => setActivePageSize(Number(e.target.value))}
                              >
                                {pageOptions.map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </Pagination>
                        )}
                      </div>

                      <div
                        className='card-body tab-pane fade  table-responsive'
                        id='kt_table_widget_4_tab_2'
                      >
                        <table
                          className='table table-row-dashed align-middle table-row-gray-300'
                          {...inactiveTableInstance.getTableProps()}
                        >
                          <thead>
                            <tr className='fw-bold text-muted'>
                              <th className='min-w-140px default-cursor'>
                                {intl.formatMessage({id: 'LABEL.CANDIDATE'})}
                              </th>
                              <th className='min-w-120px text-start default-cursor'>
                                {intl.formatMessage({id: 'LABEL.ASSET_NAME'})}
                              </th>

                              <th className='min-w-100px text-start  default-cursor'>
                                {intl.formatMessage({id: 'LABEL.ASSET_BRAND'})}
                              </th>

                              <th className='min-w-100px text-start default-cursor'>
                                {' '}
                                {intl.formatMessage({id: 'LABEL.ASSET_MODEL'})}
                              </th>

                              {/* <th className='min-w-100px text-start default-cursor'>
                                {intl.formatMessage({id: 'LABEL.STATUS'})}
                              </th> */}

                              <th className='min-w-100px text-start default-cursor'>
                                {intl.formatMessage({id: 'MENU.ACTIONS'})}
                              </th>
                            </tr>
                          </thead>
                          <tbody {...inactiveTableInstance.getTableBodyProps()}>
                            {inactivePage.length > 0 ? (
                              inactivePage.map((row) => {
                                inactiveTableInstance.prepareRow(row)
                                return (
                                  <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => (
                                      <td className=' ' {...cell.getCellProps()}>
                                        {cell.render('Cell')}
                                      </td>
                                    ))}
                                  </tr>
                                )
                              })
                            ) : (
                              <tr>
                                <td className='card'>
                                  <p className='text-dark fw-bold text-hover-primary default-cursor d-block fs-6 m-0 my-5'>
                                    {intl.formatMessage({id: 'NO_RESULTS_FOUND_MSG'})}
                                  </p>
                                </td>

                                <td>
                                  <p></p>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>

                        {data.length > 10 && (
                          <Pagination>
                            <Pagination.First
                              onClick={() => gotoInactivePage(0)}
                              disabled={!inactiveTableInstance.canPreviousPage}
                            />
                            <Pagination.Prev
                              onClick={inactiveTableInstance.previousPage}
                              disabled={!inactiveTableInstance.canPreviousPage}
                            />
                            {[...Array(inactivePageCount)].map((_, i) => (
                              <Pagination.Item
                                key={i}
                                active={i === inactivePageIndex}
                                onClick={() => gotoInactivePage(i)}
                              >
                                {i + 1}
                              </Pagination.Item>
                            ))}
                            <Pagination.Next
                              onClick={inactiveTableInstance.nextPage}
                              disabled={!inactiveTableInstance.canNextPage}
                            />
                            <Pagination.Last
                              onClick={() => gotoInactivePage(inactivePageCount - 1)}
                              disabled={!inactiveTableInstance.canNextPage}
                            />
                            <div className='d-flex align-items-center mx-5'>
                              <span className='me-2'>{intl.formatMessage({id: 'LABEL.SHOW'})}</span>
                              <select
                                className='form-select form-select-solid fw-bold w-75px me-2'
                                value={inactivePageSize}
                                onChange={(e) => setInactivePageSize(Number(e.target.value))}
                              >
                                {pageOptions.map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </Pagination>
                        )}
                      </div>

                      <div
                        className='card-body tab-pane fade  table-responsive'
                        id='kt_table_widget_4_tab_3'
                      >
                        <table
                          className='table table-row-dashed align-middle table-row-gray-300'
                          {...inactiveTableInstance.getTableProps()}
                        >
                          <thead>
                            <tr className='fw-bold text-muted'>
                              <th className='min-w-140px default-cursor'>
                                {intl.formatMessage({id: 'LABEL.CANDIDATE'})}
                              </th>
                              <th className='min-w-120px text-start default-cursor'>
                                {intl.formatMessage({id: 'LABEL.ASSET_NAME'})}
                              </th>

                              <th className='min-w-100px text-start  default-cursor'>
                                {intl.formatMessage({id: 'LABEL.ASSET_BRAND'})}
                              </th>

                              <th className='min-w-100px text-start default-cursor'>
                                {' '}
                                {intl.formatMessage({id: 'LABEL.ASSET_MODEL'})}
                              </th>

                              {/* <th className='min-w-100px text-start default-cursor'>
                                {intl.formatMessage({id: 'LABEL.STATUS'})}
                              </th> */}

                              <th className='min-w-100px text-start default-cursor'>
                                {intl.formatMessage({id: 'MENU.ACTIONS'})}
                              </th>
                            </tr>
                          </thead>

                          <tbody {...RejectedDataInstance.getTableBodyProps()}>
                            {RejectedPage.length > 0 ? (
                              RejectedPage.map((row) => {
                                RejectedDataInstance.prepareRow(row)
                                return (
                                  <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => (
                                      <td className=' ' {...cell.getCellProps()}>
                                        {cell.render('Cell')}
                                      </td>
                                    ))}
                                  </tr>
                                )
                              })
                            ) : (
                              <tr>
                                <td className='card'>
                                  <p className='text-dark fw-bold text-hover-primary default-cursor d-block fs-6 m-0 my-5'>
                                    {intl.formatMessage({id: 'NO_RESULTS_FOUND_MSG'})}
                                  </p>
                                </td>
                                <td>
                                  <p></p>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>

                        {data.length > 10 && (
                          <Pagination>
                            <Pagination.First
                              onClick={() => gotoRejectedPage(0)}
                              disabled={!RejectedDataInstance.canPreviousPage}
                            />
                            <Pagination.Prev
                              onClick={RejectedDataInstance.previousPage}
                              disabled={!RejectedDataInstance.canPreviousPage}
                            />
                            {[...Array(RejectedPageCount)].map((_, i) => (
                              <Pagination.Item
                                key={i}
                                active={i === RejectedPageIndex}
                                onClick={() => gotoRejectedPage(i)}
                              >
                                {i + 1}
                              </Pagination.Item>
                            ))}
                            <Pagination.Next
                              onClick={RejectedDataInstance.nextPage}
                              disabled={!RejectedDataInstance.canNextPage}
                            />
                            <Pagination.Last
                              onClick={() => gotoRejectedPage(RejectedPageCount - 1)}
                              disabled={!RejectedDataInstance.canNextPage}
                            />
                            <div className='d-flex align-items-center mx-5'>
                              <span className='me-2'>{intl.formatMessage({id: 'LABEL.SHOW'})}</span>
                              <select
                                className='form-select form-select-solid fw-bold w-75px me-2'
                                value={RejectedPageSize}
                                onChange={(e) => setRejectedPage(Number(e.target.value))}
                              >
                                {pageOptions.map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </Pagination>
                        )}
                      </div>
                    </div>
                  </>
                </div>
              </>
            ) : (
              <div className='card h-250px'>
                <div className='m-auto d-flex flex-column align-items-center'>
                  <h1 className='mb-5'> {intl.formatMessage({id: 'LABEL.NO_ASSETS_FOUND'})}</h1>
                </div>
              </div>
            )}
            <SideDrawer value={id} />
          </>
        )
      }}
    </Context.Consumer>
  )
}

const IssuanceTable = () => {
  const {data: TableData, isLoading, fetchData} = useApiCall('/assetManagement/assets/all')

  const columns = useMemo(
    () => [
      {
        Header: 'Employee Name',
        accessor: 'employee_name',
        Cell: ({row}) => {
          return (
            <div className='d-flex align-items-center'>
              <div className='symbol symbol-45px me-5'>
                <img
                  src={
                    row.original.employee_profile_pic
                      ? row.original.employee_profile_pic
                      : defaultProfileImage
                  }
                  alt='img'
                  className='rounded-circle'
                />
              </div>
              <div className='d-flex justify-content-start flex-column'>
                <p className='text-dark fw-bold text-hover-primary default-cursor fs-6 m-0'>
                  {row.original.employee_name}
                </p>
                <span className='text-muted fw-semibold text-muted d-block fs-7 text-start'>
                  {row.original.employee_code}
                </span>
              </div>
            </div>
          )
        },
        disableSortBy: true,
      },

      {
        Header: 'Asset Type Name',
        accessor: 'Asset Type Name',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark fw-bold text-hover-primary d-block fs-6 default-cursor'>
                {row.original.asset_type_name}
              </p>
            </div>
          )
        },
        disableSortBy: true,
      },

      {
        Header: 'Brand',
        accessor: 'Brand',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark fw-bold text-hover-primary d-block fs-6 default-cursor'>
                {row.original.brand}
              </p>
            </div>
          )
        },
        disableSortBy: true,
      },

      {
        Header: 'Issued Date',
        accessor: 'Issued Date',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark fw-bold text-hover-primary d-block fs-6 default-cursor'>
                {row.original.model}
              </p>
            </div>
          )
        },
        disableSortBy: true,
      },

      // {
      //   Header: 'Status',
      //   accessor: 'status',
      //   Cell: ({row}) => {
      //     const statusColor =
      //       row.original.request_status === 'Approved'
      //         ? 'success'
      //         : row.original.request_status === 'Pending'
      //         ? 'muted'
      //         : 'danger'

      //     return (
      //       <p
      //         className={`text-${
      //           statusColor === 'info' ? 'info' : statusColor
      //         } fw-bold text-hover-primary d-block fs-6 default-cursor`}
      //       >
      //         {row.original.request_status}
      //       </p>
      //     )
      //   },
      // },

      {
        Header: 'Actions',
        accessor: 'Actions',
        Cell: ({row}) => {
          return (
            <Context.Consumer>
              {(value) => {
                const {updateId} = value
                const hrReviewedStatus = row.original.request_status

                return (
                  <div className='d-flex justify-content-start'>
                    {hrReviewedStatus === 'Pending' ? (
                      <div
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                        id='side_activities_toggle'
                        onClick={() => {
                          updateId({
                            ...row.original,
                            type: 'update_asset',
                            fetchData,
                          })
                        }}
                      >
                        <KTIcon iconName='pencil' className='fs-3' />
                      </div>
                    ) : (
                      <div
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                        id='side_activities_toggle'
                        onClick={() => {
                          updateId({
                            ...row.original,
                            type: 'update_asset',

                            // for getting sidedrawer
                          })
                        }}
                      >
                        <KTIcon iconName='eye' className='fs-3' />
                      </div>
                    )}
                  </div>
                )
              }}
            </Context.Consumer>
          )
        },
      },
    ],
    []
  )

  return <DataTable columns={columns} data={TableData ? TableData : []} loading={isLoading} />
}

export default IssuanceTable
