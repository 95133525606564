import {Link} from 'react-router-dom'
import usePageTitle from '../../custom_hooks/usePageTitle'
import {KTIcon} from '../../../../_metronic/helpers'
import DateChart from './DateChart'
import PayslipData from './Payslip.json'

function Payslips() {
  usePageTitle('Salary Structure')

  return (
    <div>
      <div className='d-flex flex-wrap flex-stack mb-6'>
        <h3 className='fw-bolder my-2'>My Payslips</h3>
      </div>
      <div className='col-xl-8'>
        <div className='card mb-5 mb-xl-10'>
          <div className='d-flex h-40px'>
            <ul className='card-header nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
              {PayslipData.slice(0, 4).map((item, index) => (
                <li className='nav-item' key={index}>
                  <a
                    className={`nav-link text-active-primary text-grey me-6 ${
                      index === 0 ? 'active' : ''
                    }`}
                    data-bs-toggle='tab'
                    href={`#kt_table_widget_4_tab_${item.month.replace(/\s+/g, '')}`}
                  >
                    {item.month}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div className='tab-content'>
            {PayslipData.slice(0, 4).map((item, index) => (
              <div
                className={`card-body tab-pane fade show ${
                  index === 0 ? 'active' : ''
                } table-responsive mt-4`}
                key={index}
                id={`kt_table_widget_4_tab_${item.month.replace(/\s+/g, '')}`}
              >
                <div className='flex-grow-1'>
                  <div className='col-md-12 col-sm-12 mb-3'>
                    <div className='row'>
                      <div className='col-3 d-flex'>
                        <DateChart value={item.month} data={item} />
                      </div>
                      <div className='col-9'>
                        <div className='d-flex mt-4'>
                          <span className='bullet bullet-vertical h-40px bg-success'></span>
                          <div className='flex-grow-1 ms-4'>
                            <a href='#' className='text-gray-800 text-hover-primary fw-bold fs-6'>
                              Salary
                            </a>
                            <span className='text-muted fw-semibold d-block'>{item.gross_pay}</span>
                          </div>
                        </div>
                        <div className='d-flex mt-4'>
                          <span className='bullet bullet-vertical h-40px bg-danger'></span>
                          <div className='flex-grow-1 ms-4'>
                            <a href='#' className='text-gray-800 text-hover-primary fw-bold fs-6'>
                              Deductions
                            </a>
                            <span className='text-muted fw-semibold d-block'>
                              {item.deductions}
                            </span>
                          </div>
                        </div>
                        <div className='d-flex mt-4'>
                          <span className='bullet bullet-vertical h-40px bg-secondary'></span>
                          <div className='flex-grow-1 ms-4'>
                            <a href='#' className='text-gray-800 text-hover-primary fw-bold fs-6'>
                              Gross Pay
                            </a>
                            <span className='text-muted fw-semibold d-block'>{item.gross_pay}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='card-footer text-center pb-0' id='kt_activities_footer'>
                  <Link
                    to={`/payroll-management/paypage-bar/my-payslip/${item.month.replace(
                      /\s+/g,
                      ''
                    )}`}
                    className='btn btn-bg-body text-primary'
                  >
                    View Payslip
                    <KTIcon iconName='arrow-right' className='fs-3 text-primary' />
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Payslips
