import {useState} from 'react'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import * as Yup from 'yup'
import {useIntl} from 'react-intl'
import {KTIcon} from '../../../../_metronic/helpers'
import {baseUrl, defaultProfileImage} from '../../core'
import Swal from 'sweetalert2'
import secureLocalStorage from 'react-secure-storage'
import useApiCall from '../../custom_hooks/useApiCall'
import usePostApi from '../../custom_hooks/usePostApi'
import DatePicker from 'react-datepicker'
import '../../DatepickerStyles.css'
import 'react-datepicker/dist/react-datepicker.css'
import {useThemeMode} from '../../../../_metronic/partials'
import {DateBasicFormat} from '../../DateHelpers'
import {useAuth} from '../../auth'
import {FirstUserId} from '../../core'

const AddExpenseSideDrawer = ({updateExpensesFunc}) => {
  const intl = useIntl()
  const {execute} = usePostApi()
  const {mode} = useThemeMode()
  const {currentUser} = useAuth()
  const [data, setData] = useState(null)

  const [projectIdErr, setProjectIdErr] = useState(false)

  let organization_id

  const userInfo = secureLocalStorage.getItem('user_info')

  if (userInfo) {
    ;({organization_id} = userInfo)
  }
  const {data: expenseTypes} = useApiCall('/masterData/expense_types')
  const {data: currencyTypes} = useApiCall('/currencyTypes')
  const {data: empProjects} = useApiCall('/projects')

  const validationSchema = Yup.object().shape({
    is_billed_to_project: Yup.string().required(intl.formatMessage({id: 'ERR.BILLABILITY_IS_REQ'})),
    expenseName: Yup.string().required(intl.formatMessage({id: 'ERR.EXPENSE_TYPE_REQUIRED'})),
    expenseAmount: Yup.number()
      .required(intl.formatMessage({id: 'LABEL.EXPENSE_AMOUNT_REQUIRED'}))
      .min(0.1, intl.formatMessage({id: 'ERR.EXPENSE_AMOUNT_NOT_NAGATIVE'})),
    expenseDate: Yup.string().required(intl.formatMessage({id: 'LABEL.EXPENSE_DATE_REQUIRED'})),
    currencyType: Yup.string().required(intl.formatMessage({id: 'ERR.MSG.CURRENCY_IS_REQUIRED'})),
    document: Yup.mixed()
      .required(intl.formatMessage({id: 'ERR.DOCUMENT_REQUIRED'}))
      .test('is-file-selected', intl.formatMessage({id: 'ERR.DOCUMENT_REQUIRED'}), (value) => {
        return value && value.name !== ''
      }),
  })

  const initialValues = {
    expenseName: '',
    expenseAmount: '',
    expenseDate: '',
    currencyType: '',
    projectId: '',
    document: null,
    is_billed_to_project: 'company',
  }

  const dataURLtoFile = (dataURI, fileName) => {
    if (!dataURI) {
      return null
    }
    const byteString = atob(dataURI.split(',')[1])
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
    const ab = new ArrayBuffer(byteString.length)
    const ia = new Uint8Array(ab)
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i)
    }
    return new File([ab], fileName, {type: mimeString})
  }

  const addNewExpense = async (values, resetForm) => {
    try {
      const url = `${baseUrl}/employee/expenses`
      const formData = new FormData()
      const documentName = `${Date.now()}`
      const file = dataURLtoFile(data, `expense_${documentName}`)
      formData.append('document', file)
      formData.append('organization_id', organization_id)
      formData.append('expense_type_id', parseInt(values.expenseName))
      formData.append('expense_amount', parseFloat(values.expenseAmount))
      formData.append('currency_id', parseInt(values.currencyType))
      formData.append('expense_date', DateBasicFormat(values.expenseDate))
      formData.append(
        'expense_status',
        currentUser?.user_id === FirstUserId ? 'HR Pending' : 'Manager Pending'
      )
      formData.append('is_reimbursible', 1)
      formData.append('is_billed_to_project', values.is_billed_to_project === 'company' ? 0 : 1)
      formData.append(
        'project_id',
        values.is_billed_to_project === 'project' ? parseInt(values.projectId) : 0
      )

      const response = await execute(url, 'POST', formData)
      const expeRes = JSON.parse(response.api_response_data)
      updateExpensesFunc()
      resetForm()
      setProjectIdErr(false)
      const sideDrawer = document.getElementById('addNewExpenseDrawerClose')
      if (sideDrawer) {
        sideDrawer.click()
      }

      Swal.fire({
        title: 'Your expense has been submitted.',
        text: `The expense reference ID is ${expeRes?.expense_code}`,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-light-primary btn-sm',
        },
      })
    } catch (error) {
      console.error('Error:', error)
      Swal.fire({
        icon: 'error',
        title: intl.formatMessage({id: 'LABEL.OOPS'}),
        text: intl.formatMessage({id: 'API_FAILURE_TEXT'}),
        customClass: {
          confirmButton: 'btn btn-light-primary btn-sm',
        },
      }).then((result) => {
        if (result.isConfirmed) {
          const sideDrawer = document.getElementById('addNewExpenseDrawerClose')
          if (sideDrawer) {
            sideDrawer.click()
          }
        }
      })
    }
  }

  const handleSubmit = (values, {resetForm}) => {
    if (values.is_billed_to_project === 'project' && values.projectId === '') {
      setProjectIdErr(true)
      return
    }
    addNewExpense(values, resetForm)
  }

  const handleCancel = (resetForm, touched) => {
    if (Object.keys(touched).length === 0) {
      const sideDrawer = document.getElementById('addNewExpenseDrawerClose')
      if (sideDrawer) {
        sideDrawer.click()
      }
    } else {
      resetForm()
    }
  }

  return (
    <div className='card shadow-none rounded-0'>
      <div
        id='addNewExpense'
        className='bg-body'
        data-kt-drawer='true'
        data-kt-drawer-name='activities'
        data-kt-drawer-activate='true'
        data-kt-drawer-overlay='true'
        data-kt-drawer-width="{default:'60%', 'lg': '450px'}"
        data-kt-drawer-direction='end'
        data-kt-drawer-toggle={`#addNewExpenseToggle`}
        data-kt-drawer-close={`#addNewExpenseDrawerClose`}
      >
        <div className='w-100'>
          <div className='card-header'>
            <h3 className='card-title fw-bolder text-dark'>
              {intl.formatMessage({id: 'LABEL.SUBMIT_AN_EXPENSE'})}
            </h3>
            <div className='card-toolbar'>
              <button
                type='button'
                className='btn btn-sm btn-icon btn-active-light-primary me-n5'
                id='addNewExpenseDrawerClose'
              >
                <KTIcon iconName='cross' className='fs-1' />
              </button>
            </div>
          </div>

          <div className='m-8 mt-6 mb-7'>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({resetForm, errors, touched, values}) => {
                return (
                  <Form>
                    <div className='modal-body'>
                      <div className='d-flex align-items-start'>
                        <img
                          src={
                            currentUser && currentUser?.employee_profile_pic
                              ? currentUser?.employee_profile_pic
                              : defaultProfileImage
                          }
                          alt='img'
                          className='activity-img rounded w-60px h-60px me-3'
                        />
                        <div className='m-3 mt-0'>
                          <p className='text-dark fw-bold text-hover-primary fs-5 m-0'>
                            {currentUser?.first_name &&
                            currentUser?.middle_name &&
                            currentUser?.last_name
                              ? `${currentUser?.first_name} ${currentUser?.middle_name} ${currentUser?.last_name}`
                              : currentUser?.first_name && currentUser?.last_name
                              ? `${currentUser?.first_name} ${currentUser?.last_name}`
                              : '-'}
                          </p>
                          <p className='text-dark fw-bold text-hover-primary fs-7 text-muted m-0'>
                            {currentUser?.job_title ? currentUser?.job_title : '-'}
                          </p>
                          <p className='text-dark fw-bold text-hover-primary fs-7 text-muted m-0'>
                            {currentUser?.employee_code ? currentUser?.employee_code : '-'}
                          </p>
                        </div>
                      </div>
                      <h5 className='mt-2 text-muted'>
                        {intl.formatMessage({id: 'LABEL.BASIC_INFORMATION'})}
                      </h5>
                      <hr className='text-dark mt-2 mb-6' />

                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          {intl.formatMessage({id: 'LABEL.MSG.EXPENSE_TYPE'})}
                        </label>
                        {/* <div className='col-lg-8 d-flex align-items-center'> */}
                        {/* <div className='col-lg-11 text-start'> */}
                        <div className='col-lg-8 text-start'>
                          <Field
                            as='select'
                            name='expenseName'
                            className='form-select form-select-solid'
                          >
                            <option value=''>
                              {intl.formatMessage({id: 'LABEL.SELECT_EXP_TYPE'})}
                            </option>
                            {expenseTypes?.map((expense) => (
                              <option key={expense.expense_type_id} value={expense.expense_type_id}>
                                {expense.expense_type_name}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            name='expenseName'
                            component='div'
                            className='text-danger'
                          />
                        </div>
                        {/* <span
                          className='col-lg-1 d-flex align-items-center ms-1'
                          data-toggle='tooltip'
                          title='Select Expense Type'
                        >
                          <KTIcon iconName='information-2' className='fs-3' />
                        </span> */}
                        {/* </div> */}
                      </div>

                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          {intl.formatMessage({id: 'LABEL.EXPENSE_DATE'})}
                        </label>

                        <div className='col-lg-8 text-start'>
                          <Field name='expenseDate'>
                            {({form, field}) => {
                              const {setFieldValue} = form
                              const {value} = field

                              const handleChange = (date) => {
                                setFieldValue('expenseDate', date)
                              }

                              return (
                                <div
                                  className={`react-datepicker-fullwidth-wrapper test ${
                                    mode === 'dark' ? 'dark-mode' : 'light-mode'
                                  }`}
                                >
                                  <DatePicker
                                    className='form-control form-control-solid'
                                    selected={value}
                                    isClearable={value !== null && value !== ''}
                                    showYearDropdown
                                    scrollableYearDropdown
                                    dateFormat='dd/MM/yyyy'
                                    onChange={(date) => {
                                      handleChange(date)
                                    }}
                                    placeholderText={intl.formatMessage({id: 'LABEL.EXPENSE_DATE'})}
                                    showMonthDropdown
                                    useShortMonthInDropdown
                                    maxDate={new Date()}
                                  />
                                </div>
                              )
                            }}
                          </Field>
                          <ErrorMessage
                            name='expenseDate'
                            component='div'
                            className='text-danger'
                          />
                        </div>
                      </div>

                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          {intl.formatMessage({id: 'LABEL.BILLABILITY'})}
                        </label>

                        <div className='col-lg-8 text-start d-flex flex-column'>
                          <label className='d-flex align-items-center'>
                            <Field
                              type='radio'
                              name='is_billed_to_project'
                              value='company'
                              className='me-1'
                            />
                            {intl.formatMessage({id: 'LABEL.BILL_TO_COMPANY'})}
                          </label>
                          <label className='d-flex align-items-center'>
                            <Field
                              type='radio'
                              name='is_billed_to_project'
                              value='project'
                              className='me-1'
                            />
                            {intl.formatMessage({id: 'LABEL.BILL_TO_PROJECT'})}
                          </label>
                        </div>
                      </div>

                      {values.is_billed_to_project === 'project' && (
                        <div className='row mb-5 d-flex align-items-center'>
                          <label className='col-lg-4 fw-bold text-muted text-start'>
                            {intl.formatMessage({id: 'LABEL.PROJECT'})}
                          </label>
                          <div className='col-lg-8 text-start'>
                            <Field
                              as='select'
                              name='projectId'
                              className='form-select form-select-solid'
                            >
                              <option value=''>
                                {intl.formatMessage({id: 'LABEL.SELECT_PROJECT'})}
                              </option>
                              {empProjects?.map((project) => (
                                <option key={project.project_id} value={project.project_id}>
                                  {project.project_name}
                                </option>
                              ))}
                            </Field>

                            {projectIdErr && values.projectId === '' && (
                              <p className='text-danger'>
                                {intl.formatMessage({id: 'ERR.PROJECT_NAME_IS_REQ'})}
                              </p>
                            )}
                          </div>
                        </div>
                      )}

                      <h5 className='mt-6 text-muted'>
                        {intl.formatMessage({id: 'LABEL.EXP_INFORMATION'})}
                      </h5>
                      <hr className='text-dark mt-2 mb-6' />

                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          {intl.formatMessage({id: 'LABEL.CURRENCY'})}
                        </label>

                        <div className='col-lg-8 text-start'>
                          <Field
                            as='select'
                            name='currencyType'
                            className='form-select form-select-solid'
                          >
                            <option value=''>
                              {intl.formatMessage({id: 'LABEL.SELECT_CURRENCY'})}
                            </option>
                            {currencyTypes?.map((currency) => (
                              <option key={currency.currency_id} value={currency.currency_id}>
                                {currency.currency_name}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            name='currencyType'
                            component='div'
                            className='text-danger'
                          />
                        </div>
                      </div>

                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          {intl.formatMessage({id: 'LABEL.EXPENSE_AMOUNT'})}
                        </label>

                        <div className='col-lg-8 text-start'>
                          <Field
                            type='number'
                            id='expenseAmount'
                            name='expenseAmount'
                            className='form-control form-control-solid'
                            placeholder={intl.formatMessage({id: 'LABEL.EXPENSE_AMOUNT'})}
                          />
                          <ErrorMessage
                            component='div'
                            className='text-danger'
                            name='expenseAmount'
                          />
                        </div>
                      </div>

                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          {intl.formatMessage({id: 'LABEL.ATTACH_FILES'})}
                        </label>

                        <div className='col-lg-8 text-start'>
                          <Field name='document'>
                            {({form, field}) => {
                              const {setFieldValue} = form
                              const {value} = field
                              const handleImageChange = (file) => {
                                const reader = new FileReader()
                                reader.onloadend = () => {
                                  const previewImage = reader.result
                                  setData(previewImage)
                                }
                                reader.readAsDataURL(file)
                              }
                              const handleHeaderChange = (e) => {
                                const file = e.target.files?.[0]
                                setFieldValue('document', file ? file.name : '')
                                if (file) {
                                  handleImageChange(file)
                                }
                              }
                              const handleInputClick = () => {
                                document.getElementById('get-document-from-user').click()
                              }
                              return (
                                <div>
                                  <input
                                    type='file'
                                    onChange={handleHeaderChange}
                                    id='get-document-from-user'
                                    className='form-control form-control-solid d-none'
                                  />
                                  <p
                                    className='form-control form-control-solid d-flex justify-content-between pe-10 cursor-pointer mb-0'
                                    onClick={handleInputClick}
                                  >
                                    <span>{value ? value : 'Choose file to upload'}</span>
                                    <span>
                                      <KTIcon
                                        iconName='paper-clip'
                                        className='fs-3 position-absolute ms-3'
                                      />
                                    </span>
                                  </p>
                                </div>
                              )
                            }}
                          </Field>
                          <ErrorMessage name='document' component='div' className='text-danger' />
                        </div>
                      </div>
                    </div>

                    <div className='card-footer p-0 py-5 mt-5 text-end'>
                      <button
                        type='button'
                        className='btn btn-light me-2 btn-sm'
                        onClick={() => handleCancel(resetForm, touched)}
                      >
                        {Object.keys(touched).length === 0
                          ? intl.formatMessage({id: 'BTN.CLOSE'})
                          : intl.formatMessage({id: 'BTN.CLEAR'})}
                      </button>

                      <button type='submit' className='btn btn-light-primary ms-2 btn-sm'>
                        {intl.formatMessage({id: 'BTN.SUBMIT_EXPENSE'})}
                      </button>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddExpenseSideDrawer
