import {ErrorMessage, Field, Form, Formik} from 'formik'
import Swal from 'sweetalert2'
import * as Yup from 'yup'
import {useIntl} from 'react-intl'
import usePostApi from '../../../custom_hooks/usePostApi'
import {useParams} from 'react-router-dom'
import secureLocalStorage from 'react-secure-storage'
import {baseUrl} from '../../../core'
function Manager({managerDetails}) {
  const intl = useIntl()
  const {execute} = usePostApi()
  const {id} = useParams()
  const userInfo = secureLocalStorage.getItem('user_info')
  const {organization_id, role} = userInfo || {}
  const {
    manager_reviewed_status = null,
    manager_review_comments = '',
    manager_reviewed_timestamp = '',
    exit_id = '',
    fetchData,
    exitFetchData,
  } = managerDetails

  const validationSchema = Yup.object().shape({
    exit_comments: Yup.string().required('Interview Comments is Required'),
  })

  const handleSubmit = async (values, formikHelpers) => {
    const {resetForm} = formikHelpers
    const url = `${baseUrl}/exit/details/manager/${exit_id}`
    try {
      const requestData = {
        organization_id: organization_id,
        reviewed_by: 1,
        reviewer_comments: values.exit_comments,
        exit_status: 'Pending - HR',
      }
      await execute(url, 'PATCH', requestData, id)
      resetForm()
      exitFetchData()
      fetchData()
      Swal.fire({
        title: 'Request Updated',
        text: 'Your comments have been updated.',
        icon: 'success',
        showConfirmButton: true,
        confirmButtonText: 'OK',
        customClass: {
          confirmButton: 'btn btn-light-primary btn-sm',
        },
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          const sideDrawer = document.getElementById('side_activities_close')
          if (sideDrawer) {
            sideDrawer.click()
          }
        }
      })
    } catch (error) {
      console.error(error)
      Swal.fire({
        icon: 'error',
        title: intl.formatMessage({id: 'LABEL.OOPS'}),
        text: intl.formatMessage({id: 'LABEL.SOMETHING_WRONG'}),
      })
    }
  }
  const getManagerView = (status) => {
    if (status === null) {
      return (
        <>
          <Formik
            initialValues={{
              exit_comments: '',
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {() => {
              return (
                <Form>
                  <div className='fw-bold mb-2 required'>Interview Comments</div>
                  <div className='mb-4'>
                    <Field
                      as='textarea'
                      className='form-control form-control-solid'
                      id='floatingInput'
                      row={3}
                      name='exit_comments'
                    />
                    <ErrorMessage component='div' className='text-danger' name='exit_comments' />
                  </div>
                  <div className='d-flex flex-row justify-content-end'>
                    <button className='btn btn-light-success btn-sm py-2' type='submit'>
                      UPDATE
                    </button>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </>
      )
    } else if (manager_reviewed_status) {
      return (
        <>
          <p className='m-0 text-muted'>
            {manager_reviewed_timestamp && manager_reviewed_timestamp.slice(0, 19)}
          </p>
          {manager_reviewed_status === 'Submitted' ? (
            <p className='m-0 text-muted'>{`${manager_review_comments}`}</p>
          ) : (
            <p className='m-0 text-muted'>{`${manager_review_comments}`}</p>
          )}
        </>
      )
    } else {
      return null
    }
  }

  const getStatusMsg = (status) => {
    if (status === null) {
      return 'Pending'
    } else if (status) {
      return `Updated`
    } else {
      return status
    }
  }

  const getStatusColor = (status) => {
    if (status === null) {
      return 'muted'
    } else if (status) {
      return 'success'
    }
  }

  const getIconColor = (status) => {
    if (status === null) {
      return 'secondary'
    } else if (status) {
      return 'success'
    }
  }

  return (
    <div className='timeline-item'>
      <div className='timeline-line w-20px'></div>
      <div className='timeline-icon symbol symbol-circle symbol-20px me-4'>
        <div className={`symbol-label bg-${getIconColor(manager_reviewed_status)}`}></div>
      </div>
      <div className='timeline-content mb-12 mt-n1'>
        <div className='d-flex justify-content-between mt-1'>
          <p className={`mb-0 fw-bold text-${getStatusColor(manager_reviewed_status)}`}>
            {getStatusMsg(manager_reviewed_status)}
          </p>
          <div className=''>
            <p className='designation-btn bg-secondary m-auto'>Manager</p>
          </div>
        </div>
        {getManagerView(manager_reviewed_status)}
      </div>
    </div>
  )
}

export default Manager
