import React, {useState, useEffect} from 'react'
import OrgChart from 'react-orgchart'
import 'react-orgchart/index.css'
import {TransformWrapper, TransformComponent} from 'react-zoom-pan-pinch'
import usePageTitle from '../../custom_hooks/usePageTitle'
import {KTIcon, toAbsoluteUrl} from '../../../../_metronic/helpers'
import DateFormatter from '../../DateFormatter'
import Swal from 'sweetalert2'
import useApiCall from '../../custom_hooks/useApiCall'
import {defaultProfileImage} from '../../core'
import {useIntl} from 'react-intl'
import html2canvas from 'html2canvas'
import html2pdf from 'html2pdf.js'
function createOrgChart(employees) {
  const orgChart = {}
  employees.forEach((employee) => {
    const {employee_id, reporting_to_id} = employee
    if (!orgChart[employee_id]) {
      orgChart[employee_id] = {...employee, children: []}
    } else {
      orgChart[employee_id] = {...orgChart[employee_id], ...employee}
    }
    if (!orgChart[reporting_to_id]) {
      orgChart[reporting_to_id] = {children: []}
    }
    orgChart[reporting_to_id].children.push(orgChart[employee_id])
  })
  const topLevelEmployees = Object.values(orgChart).filter(
    (employee) => employee.reporting_to_id === null
  )
  return topLevelEmployees[0] || {}
}

const MyNodeComponent = ({node, onNodeClick, orgChartData}) => {
  let orgData = null
  const findNodeByCode = (orgNode, targetCode) => {
    if (orgNode.employee_code === targetCode) {
      return orgNode
    }
    if (orgNode.children && orgNode.children.length > 0) {
      for (const child of orgNode.children) {
        const result = findNodeByCode(child, targetCode)
        if (result) {
          return result
        }
      }
    }
    return null
  }
  if (orgChartData) {
    orgData = findNodeByCode(orgChartData, node.employee_code)
  }
  return (
    <>
      <div
        className={`d-inline-flex rounded shadow w-250px h-70px align-items-center m-2 mb-0 position-relative`}
        onClick={() => onNodeClick(node)}
        style={{cursor: 'pointer'}}
      >
        <div className='director-icon-container symbol symbol-45px m-2'>
          <img
            src={toAbsoluteUrl(
              node.employee_profile_pic ? node.employee_profile_pic : defaultProfileImage
            )}
            alt='icon'
            className='rounded-circle'
          />
        </div>
        <div className='d-flex flex-column ms-2 text-start'>
          <p className={`fs-6 mb-0 fw-bold`}>{node.employee_name}</p>
          <p className={`fs-6 mb-0 `}>{node.employee_code}</p>
          <p className={`fs-6 mb-0 `}>{node.designation_name}</p>
        </div>
        {orgData && orgData.children && orgData.children.length > 0 ? (
          node.children.length > 0 ? (
            <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-secondary rounded-circle border border-2 border-white h-20px w-20px fw-bold'>
              <KTIcon iconName='minus' className='fs-7' />
            </div>
          ) : (
            <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-secondary rounded-circle border border-2 border-white h-20px w-20px fw-bold'>
              <KTIcon iconName='plus' className='fs-7' />
            </div>
          )
        ) : null}
      </div>
    </>
  )
}

const OrgChartExample = () => {
  const displayName = localStorage.getItem('displayName')
  const intl = useIntl()
  usePageTitle(intl.formatMessage({id: 'MENU.LABEL.ORGANOGRAM'}))
  const {data, isLoading} = useApiCall(`/organization/organogram`)
  const orgChartData = createOrgChart(data ? data : [])
  const [scale, setScale] = useState(0.8)
  const [expandedNodes, setExpandedNodes] = useState([])
  useEffect(() => {
    if (orgChartData) {
      const allNodeCodes = getAllNodeCodes(orgChartData)
      setExpandedNodes(allNodeCodes)
    }
  }, [data])

  const getAllNodeCodes = (node) => {
    let codes = [node.employee_code]
    if (node.children && node.children.length > 0) {
      for (const child of node.children) {
        codes = [...codes, ...getAllNodeCodes(child)]
      }
    }
    return codes
  }
  const handleNodeClick = (node) => {
    setExpandedNodes((prevExpandedNodes) => {
      if (prevExpandedNodes.includes(node.employee_code)) {
        return prevExpandedNodes.filter((employee_code) => employee_code !== node.employee_code)
      } else {
        return [...prevExpandedNodes, ...getAllNodeCodes(node)]
      }
    })
  }
  const handleZoomIn = () => {
    setScale((prevScale) => Math.min(prevScale + 0.1, 2))
  }
  const handleZoomOut = () => {
    setScale((prevScale) => Math.max(prevScale - 0.1, 0.2))
  }

  const handleFitToScreen = () => {
    return new Promise((resolve) => {
      const chartContainer = document.getElementById('orgChartContainer')
      const orgChart = chartContainer.querySelector('.reactOrgChart')
      if (orgChart) {
        requestAnimationFrame(() => {
          const containerWidth = chartContainer.clientWidth
          const containerHeight = chartContainer.clientHeight
          const orgChartWidth = orgChart.offsetWidth
          const orgChartHeight = orgChart.offsetHeight
          const scaleX = containerWidth / orgChartWidth
          const scaleY = containerHeight / orgChartHeight
          const newScale = Math.min(scaleX, scaleY)
          setScale(newScale - 0.02)
          setTimeout(() => {
            resolve()
          }, 100)
        })
      } else {
        setTimeout(() => {
          handleFitToScreen().then(resolve)
        }, 100)
      }
    })
  }

  const handleDownloadImage = async () => {
    try {
      const result = await Swal.fire({
        title: 'Confirm Download',
        text: 'The Organization Chart download will begin shortly, please do not close or refresh this page.',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: intl.formatMessage({id: 'BTN.CONFIRM'}),
        cancelButtonText: intl.formatMessage({id: 'LABEL.CANCEL'}),
        customClass: {
          confirmButton: 'btn btn-light-primary btn-sm',
          cancelButton: 'btn btn-sm btn-secondary',
        },
      })
      if (result.isConfirmed) {
        const element = document.getElementById('orgChartContainer')
        const contentWidth = element.clientWidth
        const currentDate = new Date()
        try {
          if (element) {
            const pdfOptions = {
              filename: `${displayName} - Org Chart - ${DateFormatter(currentDate)}.pdf`,
              html2canvas: {scale: 5},
              jsPDF: {
                unit: 'mm',
                format: [contentWidth, element.clientHeight - 350],
                orientation: 'landscape',
              },
            }
            html2pdf()
              .from(element)
              .set(pdfOptions)
              .output('blob')
              .then((blob) => {
                const url = URL.createObjectURL(blob)
                const a = document.createElement('a')
                a.href = url
                a.download = pdfOptions.filename
                a.click()
                URL.revokeObjectURL(url)
              })
            Swal.fire({
              title: 'Org Chart Downloaded',
              text: 'Image downloaded successfully.',
              icon: 'success',
              showConfirmButton: true,
              confirmButtonText: 'OK',
              customClass: {
                confirmButton: 'btn btn-light-primary btn-sm',
              },
            })
          }
        } catch (error) {
          console.error('Error generating PDF:', error)
        }
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: intl.formatMessage({id: 'LABEL.OOPS'}),
        text: intl.formatMessage({id: 'LABEL.SOMETHING_WRONG'}),
      })
      console.error('Error updating data:', error)
    }
  }

  const generateTree = (node) => {
    const isNodeExpanded = expandedNodes.includes(node.employee_code)
    return {
      ...node,
      children: isNodeExpanded ? (node.children || []).map(generateTree) : [],
    }
  }
  const expandedTree = generateTree(orgChartData)

  return (
    <>
      {isLoading ? (
        <div className='card h-250px'>
          <div className='m-auto d-flex flex-column align-items-center'>
            <div className='spinner-border spinner-primary mr-15'></div>
          </div>
        </div>
      ) : (
        <div className='card'>
          <div className='card-header'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold fs-3 mb-1'>Organization Chart</span>
            </h3>
            <div className='card-toolbar'>
              <div className='btn-toolbar' role='toolbar' aria-label='...'>
                <div className='btn-group mr-2' role='group' aria-label='...'>
                  <button
                    type='button'
                    className='btn btn-active-color-primary btn-icon border'
                    onClick={handleZoomOut}
                  >
                    <i className='fs-1 la la-search-minus'></i>
                  </button>
                  <button
                    type='button'
                    className='btn btn-active-color-primary btn-icon border'
                    onClick={handleZoomIn}
                  >
                    <i className='fs-1 la la-search-plus'></i>
                  </button>
                  <button
                    type='button'
                    className='btn btn-active-color-primary border fs-7'
                    onClick={handleFitToScreen}
                  >
                    FIT TO SCREEN
                  </button>
                  <button
                    type='button'
                    className='btn btn-active-color-primary border fs-7'
                    onClick={handleDownloadImage}
                  >
                    <KTIcon iconName='cloud-download' className='fs-7' />
                    DOWNLOAD IMAGE
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            id='orgChartContainer'
            className={`card-body d-flex justify-content-center`}
            style={{
              cursor: 'move',
            }}
          >
            <TransformWrapper scale={scale}>
              <TransformComponent>
                <div
                  style={{
                    transform: `scale(${scale})`,
                    transformOrigin: 'top left',
                    cursor: 'move',
                  }}
                >
                  <OrgChart
                    tree={expandedTree}
                    NodeComponent={({node}) => (
                      <MyNodeComponent
                        node={node}
                        onNodeClick={handleNodeClick}
                        orgChartData={orgChartData}
                      />
                    )}
                    getClassName={({node}) =>
                      expandedNodes.includes(node.employee_code)
                        ? 'expanded-node'
                        : 'collapsed-node'
                    }
                  />
                </div>
              </TransformComponent>
            </TransformWrapper>
          </div>
        </div>
      )}
    </>
  )
}

export default OrgChartExample
