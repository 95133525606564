import {useIntl} from 'react-intl'
import {defaultProfileImage} from '../../core'
import {KTIcon} from '../../../../_metronic/helpers'
import Manager from './reviewers_flow/Manager'
import HR from './reviewers_flow/HR'
import Finance from './reviewers_flow/Finance'
import RejectedExit from './reviewers_flow/RejectedExit'
import DateFormatter from '../../DateFormatter'
import useApiCall from '../../custom_hooks/useApiCall'
const EditEmployeeExit = (exitInfo: any) => {
  const {props}: any = exitInfo
  const {data, isLoading, fetchData} = useApiCall(`/exit/${props?.exit_id}`)
  let exitDetails: any | null = data && data[0]
  const intl = useIntl()
  return (
    <div className='card shadow-none rounded-0'>
      <div className='card-header' id='side_activities_header'>
        <h3 className='card-title fw-bolder text-dark'>Employee Exit</h3>
        <div className='card-toolbar'>
          <button
            type='button'
            className='btn btn-sm btn-icon btn-active-light-primary me-n5'
            id='side_activities_close'
          >
            <KTIcon iconName='cross' className='fs-1' />
          </button>
        </div>
      </div>
      <div className='card-body position-relative' id='side_activities_body'>
        {isLoading ? (
          <div className='card card-xl-stretch'>
            <div className='m-auto d-flex flex-column align-items-center w-350px'>
              <div className='spinner-border spinner-primary mr-15'></div>
            </div>
          </div>
        ) : (
          <div>
            <div
              id='side_activities_scroll'
              className='position-relative scroll-y me-n5 pe-5 w-400px'
              data-kt-scroll='true'
              data-kt-scroll-height='auto'
              data-kt-scroll-wrappers='#side_activities_body'
              data-kt-scroll-dependencies='#side_activities_header, #side_activities_footer'
              data-kt-scroll-offset='5px'
            >
              <div className='d-flex align-items-start'>
                <img
                  src={
                    exitDetails?.employee_profile_pic
                      ? exitDetails?.employee_profile_pic
                      : defaultProfileImage
                  }
                  alt='img'
                  className='activity-img rounded w-65px h-65px me-3'
                />
                <div className='mt-0 pt-0'>
                  <p className='text-dark fw-bold text-hover-primary m-0 fs-5'>
                    {exitDetails?.first_name} {exitDetails?.last_name}
                  </p>
                  <p className='text-dark fw-bold text-hover-primary text-muted m-0 mt-1'>
                    <KTIcon iconName='profile-circle' className='fs-7 me-1' />
                    {exitDetails?.employee_code}
                  </p>
                  <p className='text-dark fw-bold text-hover-primary text-muted m-0'>
                    <KTIcon iconName='home' className='fs-7 me-1' />
                    {exitDetails?.department_name}
                  </p>
                </div>
              </div>
              <hr className='text-dark mt-4 mb-4' />
              <div className='timeline'>
                <div className='timeline-item'>
                  <div className='timeline-line w-40px'></div>
                  <div className='timeline-icon symbol symbol-circle symbol-40px'>
                    <div className='symbol-label bg-light'>
                      <KTIcon iconName='delivery-door' className='fs-2 text-gray-500' />
                    </div>
                  </div>
                  <div className='timeline-content mb-8 mt-n1'>
                    <div className='overflow-auto pe-3'>
                      <div className='fw-bold text-muted'>Exit Reason</div>
                      <span className='fw-bolder text-dark'>{exitDetails?.exit_reason}</span>
                    </div>
                  </div>
                </div>
                <div className='timeline-item'>
                  <div className='timeline-line w-40px'></div>
                  <div className='timeline-icon symbol symbol-circle symbol-40px'>
                    <div className='symbol-label bg-light'>
                      <KTIcon iconName='calendar' className='fs-2 text-gray-500' />
                    </div>
                  </div>
                  <div className='timeline-content mb-4 mt-n2'>
                    <div className='overflow-auto pe-3'>
                      <div className='fw-bold text-muted mt-1'>Req. Exit Date</div>
                      <span className='fw-bolder text-dark'>
                        {DateFormatter(exitDetails?.exit_date)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <h5 className='mt-4 text-dark'>Workflow</h5>
              <hr className='text-dark mt-2' />
              {props.exit_status === 'Revoked' ? (
                <div className='timeline'>
                  <RejectedExit
                    revokedDetails={{
                      revoked_timestamp: exitDetails?.revoked_timestamp,
                    }}
                  />
                </div>
              ) : (
                <div className='timeline'>
                  <Manager
                    managerDetails={{
                      manager_reviewed_status: exitDetails?.reviewed_by,
                      manager_review_comments: exitDetails?.reviewer_comments,
                      manager_reviewed_timestamp: exitDetails?.reviewed_timestamp,
                      exit_id: exitDetails?.exit_id,
                      fetchData,
                      exitFetchData: props?.fetchData,
                    }}
                  />
                  <HR
                    hrDetails={{
                      manager_reviewed_status: exitDetails?.reviewed_by,
                      hr_reviewed_status: exitDetails?.hr_reviewed_by,
                      hr_review_comments: exitDetails?.hr_reviewed_comments,
                      hr_reviewed_timestamp: exitDetails?.hr_reviewed_timestamp,
                      exit_id: exitDetails?.exit_id,
                      exit_date: exitDetails?.exit_date,
                      fetchData,
                      exitFetchData: props?.fetchData,
                    }}
                  />
                  <Finance
                    financerDetails={{
                      manager_reviewed_status: exitDetails?.reviewed_by,
                      hr_reviewed_status: exitDetails?.hr_reviewed_by,
                      finance_reviewed_status: exitDetails?.finance_reviewed_by,
                      finance_review_comments: exitDetails?.finance_reviewed_comments,
                      finance_reviewed_timestamp: exitDetails?.finance_reviewed_timestamp,
                      exit_id: exitDetails?.exit_id,
                      fetchData,
                      exitFetchData: props?.fetchData,
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <div className='card-footer text-end' id='side_activities_footer'>
        <div className='btn btn-sm btn-secondary m-2' id='side_activities_close'>
          {intl.formatMessage({id: 'BTN.CLOSE'})}
        </div>
      </div>
    </div>
  )
}

export {EditEmployeeExit}
