import {useEffect, useMemo, useState} from 'react'
import {useTable, usePagination} from 'react-table'
import {Pagination} from 'react-bootstrap'
import {KTIcon} from '../../../_metronic/helpers'
import Context from '../../../_metronic/partials/layout/activity-drawer/context'
import {SideDrawer} from '../../../_metronic/partials/layout/activity-drawer/Side-drawer'
import {useIntl} from 'react-intl'
import PageTitle from '../custom_hooks/usePageTitle'
import secureLocalStorage from 'react-secure-storage'
import {useLocation} from 'react-router-dom'
import usePageTitle from '../custom_hooks/usePageTitle'
import DateFormatter from '../DateFormatter'
import '../DatepickerStyles.css'
import 'react-datepicker/dist/react-datepicker.css'
import {useThemeMode} from '../../../_metronic/partials'
import DatePicker from 'react-datepicker'
import {baseUrl} from '../core'
import useApiCall from '../custom_hooks/useApiCall'
import usePostApi from '../custom_hooks/usePostApi'
import Swal from 'sweetalert2'

function range(start, end, step = 1) {
  const result = []
  for (let i = start; i <= end; i += step) {
    result.push(i)
  }
  return result
}

const DataTable = ({columns, data, loading}) => {
  const [inputVal, setInputVal] = useState('')
  const [selectedDate, setSelectedDate] = useState(null)
  const {mode} = useThemeMode()
  let is_admin
  let is_itadmin
  const userInfo = secureLocalStorage.getItem('user_info')
  if (userInfo) {
    ;({is_admin, is_itadmin} = userInfo)
  }

  const currentYear = new Date().getFullYear()
  const years = range(1990, currentYear + 1, 1)

  const intl = useIntl()
  usePageTitle(intl.formatMessage({id: 'LABEL.ALL_ASSETS'}))

  const filteredData = useMemo(() => {
    return data.filter(
      (each) =>
        each.asset_type_name.toLowerCase().includes(inputVal.toLowerCase()) ||
        each.brand.toLowerCase().includes(inputVal.toLowerCase())
    )
  }, [data, inputVal])

  const filteredDataByDate = useMemo(() => {
    if (!selectedDate) {
      return filteredData
      // No date selected, return the current filtered data
    }

    // Adjust the selected date to account for the time zone offset
    const selectedDateStr = new Date(
      selectedDate.getTime() - selectedDate.getTimezoneOffset() * 60000
    )
      .toISOString()
      .slice(0, 10) // Convert selected date to 'yyyy-MM-dd' format

    return filteredData.filter((each) => {
      return each.created_at.slice(0, 10) === selectedDateStr
    })
  }, [selectedDate, filteredData])
  // Create separate datasets for active and inactive records

  // Table instances for active and inactive datasets
  const activeData = useMemo(() => {
    return filteredDataByDate.filter((request) => request.working_status === 'Working')
  }, [filteredDataByDate])

  const inactiveData = useMemo(() => {
    return filteredDataByDate.filter((request) => request.working_status === 'Partially Working')
  }, [filteredDataByDate])

  const RejectedData = useMemo(() => {
    return filteredDataByDate.filter((request) => request.request_status === 'Defunct')
  }, [filteredDataByDate])

  const activeTableInstance = useTable({columns, data: activeData}, usePagination)
  const inactiveTableInstance = useTable({columns, data: inactiveData}, usePagination)
  const RejectedDataInstance = useTable({columns, data: RejectedData}, usePagination)

  // Extract required values from table instances
  const activePage = activeTableInstance.page
  const activePageCount = activeTableInstance.pageCount
  const activePageIndex = activeTableInstance.state.pageIndex
  const activePageSize = activeTableInstance.state.pageSize

  const inactivePage = inactiveTableInstance.page
  const inactivePageCount = inactiveTableInstance.pageCount
  const inactivePageIndex = inactiveTableInstance.state.pageIndex
  const inactivePageSize = inactiveTableInstance.state.pageSize

  const RejectedPage = RejectedDataInstance.page
  const RejectedPageCount = RejectedDataInstance.pageCount
  const RejectedPageIndex = RejectedDataInstance.state.pageIndex
  const RejectedPageSize = RejectedDataInstance.state.pageSize

  // Handle pagination for active dataset
  const gotoActivePage = (pageIndex) => {
    activeTableInstance.gotoPage(pageIndex)
  }

  const setActivePageSize = (pageSize) => {
    activeTableInstance.setPageSize(pageSize)
  }

  // Handle pagination for inactive dataset
  const gotoInactivePage = (pageIndex) => {
    inactiveTableInstance.gotoPage(pageIndex)
  }

  const setInactivePageSize = (pageSize) => {
    inactiveTableInstance.setPageSize(pageSize)
  }

  const gotoRejectedPage = (pageIndex) => {
    RejectedDataInstance.gotoPage(pageIndex)
  }

  const setRejectedPage = (pageSize) => {
    RejectedDataInstance.setPageSize(pageSize)
  }

  const pageOptions = [5, 10, 20, 30, 40, 50]

  // Render the component
  return (
    <>
      <Context.Consumer>
        {(value) => {
          const {id, updateId} = value
          return (
            <>
              {loading ? (
                <div className='card h-250px'>
                  <div className='m-auto d-flex flex-column align-items-center'>
                    <div className='spinner-border spinner-primary mr-15'></div>
                  </div>
                </div>
              ) : data.length > 0 ? (
                <>
                  <div className='d-flex'>
                    <div className='d-flex flex-wrap flex-stack mb-6'>
                      <h3 className='fw-bolder my-2'> All Assets</h3>
                    </div>
                  </div>
                  {/* <div className='d-flex flex-wrap flex-stack mb-6'>
                    <div className='fw-bolder my-2'>
                      <h3 className='fw-bolder my-2'>All Assets</h3>
                    </div>
                    {role === 'Admin' && (
                      <div className='d-flex'>
                        <a
                          href='#'
                          className='btn btn-sm btn-light-primary mx-2'
                          id='side_activities_toggle'
                          onClick={() => {
                            updateId({
                              type: 'add_asset',
                            })
                          }}
                        >
                          <KTIcon iconName='plus' className='fs-3' />
                          Add Asset
                        </a>
                      </div>
                    )}
                  </div> */}
                  <div className='card'>
                    <>
                      <div className='card-header border-0 pt-5'>
                        <div className='d-flex flex-wrap flex-stack'>
                          <div className='fw-bolder'>
                            <ul className='nav'>
                              <li className='nav-item'>
                                <a
                                  className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary active fw-bold px-4 me-1 ms-0'
                                  data-bs-toggle='tab'
                                  href='#kt_table_widget_4_tab_1'
                                >
                                  {intl.formatMessage({id: 'LABEL.WORKING'})}
                                </a>
                              </li>
                              <li className='nav-item'>
                                <a
                                  className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1'
                                  data-bs-toggle='tab'
                                  href='#kt_table_widget_4_tab_2'
                                >
                                  {intl.formatMessage({id: 'LABEL.PARTIALLY_WORKING'})}
                                </a>
                              </li>

                              <li className='nav-item'>
                                <a
                                  className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1'
                                  data-bs-toggle='tab'
                                  href='#kt_table_widget_4_tab_3'
                                >
                                  {intl.formatMessage({id: 'LABEL.DEFUNCT'})}
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className='card-toolbar'>
                          <div className='d-flex align-items-center position-relative me-4'>
                            <KTIcon iconName='magnifier' className='fs-3 position-absolute ms-3' />
                            <input
                              type='text'
                              id='kt_filter_search'
                              className='form-control form-control-white form-control-sm w-150px ps-9'
                              placeholder={intl.formatMessage({id: 'LABEL.SEARCH'})}
                              value={inputVal}
                              onChange={(e) => setInputVal(e.target.value)}
                            />
                          </div>
                          {/* 
                          <button className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                            <KTIcon iconName='calendar' className='fs-2 m-0' />
                          </button> */}

                          <div
                            className={`react-datepicker-fullwidth-wrapper test ${
                              mode === 'dark' ? 'dark-mode' : 'light-mode'
                            }`}
                          >
                            <DatePicker
                              className='form-control form-control-sm'
                              selected={selectedDate}
                              isClearable
                              showYearDropdown
                              scrollableYearDropdown
                              dateFormat='dd/MM/yyyy'
                              onChange={(date) => setSelectedDate(date)}
                              placeholderText='Select Date'
                              showMonthDropdown
                              useShortMonthInDropdown
                            />
                          </div>
                          {is_admin === 1 && (
                            <a
                              href='#'
                              className='btn btn-sm btn-light-primary mx-2'
                              id='side_activities_toggle'
                              onClick={() => {
                                updateId({
                                  type: 'add_asset',
                                })
                              }}
                            >
                              <KTIcon iconName='plus' className='fs-3' />
                              {intl.formatMessage({id: 'LABEL.ADD_ASSET'})}
                            </a>
                          )}
                        </div>
                      </div>
                      <div className='tab-content p-2 pt-0'>
                        <div
                          className='card-body tab-pane fade show active table-responsive'
                          id='kt_table_widget_4_tab_1'
                        >
                          <table
                            className='table table-row-dashed align-middle table-row-gray-300'
                            {...activeTableInstance.getTableProps()}
                          >
                            <thead>
                              <tr className='fw-bold text-muted'>
                                <th className='min-w-140px default-cursor'>
                                  {' '}
                                  {intl.formatMessage({id: 'LABEL.ASSET_TAG'})}
                                </th>

                                <th className='min-w-140px default-cursor'>
                                  {intl.formatMessage({id: 'LABEL.ASSET_NAME'})}
                                </th>

                                <th className='min-w-120px text-start default-cursor'>
                                  {intl.formatMessage({id: 'LABEL.ASSET_BRAND'})}
                                </th>

                                <th className='min-w-120px text-start default-cursor'>
                                  {' '}
                                  {intl.formatMessage({id: 'LABEL.ASSET_MODEL'})}
                                </th>
                                <th className='min-w-120px text-start default-cursor'>
                                  {intl.formatMessage({id: 'LABEL.SERIAL_NUMBER'})}
                                </th>

                                <th className='min-w-100px text-start default-cursor'>
                                  {' '}
                                  {intl.formatMessage({id: 'LABEL.SUPPLIER'})}
                                </th>

                                <th className='min-w-100px text-start default-cursor'>
                                  {intl.formatMessage({id: 'LABEL.RAISED_DATE'})}
                                </th>

                                {/* <th className='min-w-100px text-start default-cursor'>
                                  {intl.formatMessage({id: 'LABEL.STATUS'})}
                                </th> */}

                                <th className='min-w-100px text-center default-cursor'>
                                  {' '}
                                  {intl.formatMessage({id: 'MENU.ACTIONS'})}
                                </th>
                              </tr>
                            </thead>

                            <tbody {...activeTableInstance.getTableBodyProps()}>
                              {activePage.length > 0 ? (
                                activePage.map((row) => {
                                  activeTableInstance.prepareRow(row)
                                  return (
                                    <tr {...row.getRowProps()}>
                                      {row.cells.map((cell) => (
                                        <td className=' ' {...cell.getCellProps()}>
                                          {cell.render('Cell')}
                                        </td>
                                      ))}
                                    </tr>
                                  )
                                })
                              ) : (
                                <tr>
                                  <td>
                                    <p></p>
                                  </td>
                                  <td className='card'>
                                    <p className='text-dark fw-bold text-hover-primary default-cursor d-block fs-6 m-0 my-5'>
                                      {intl.formatMessage({id: 'NO_RESULTS_FOUND_MSG'})}
                                    </p>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>

                          {data.length > 10 && (
                            <Pagination>
                              <Pagination.First
                                onClick={() => gotoActivePage(0)}
                                disabled={!activeTableInstance.canPreviousPage}
                              />
                              <Pagination.Prev
                                onClick={activeTableInstance.previousPage}
                                disabled={!activeTableInstance.canPreviousPage}
                              />
                              {[...Array(activePageCount)].map((_, i) => (
                                <Pagination.Item
                                  key={i}
                                  active={i === activePageIndex}
                                  onClick={() => gotoActivePage(i)}
                                >
                                  {i + 1}
                                </Pagination.Item>
                              ))}
                              <Pagination.Next
                                onClick={activeTableInstance.nextPage}
                                disabled={!activeTableInstance.canNextPage}
                              />
                              <Pagination.Last
                                onClick={() => gotoActivePage(activePageCount - 1)}
                                disabled={!activeTableInstance.canNextPage}
                              />
                              <div className='d-flex align-items-center mx-5'>
                                <span className='me-2'>
                                  {intl.formatMessage({id: 'LABEL.SHOW'})}
                                </span>
                                <select
                                  className='form-select form-select-solid fw-bold w-75px me-2'
                                  value={activePageSize}
                                  onChange={(e) => setActivePageSize(Number(e.target.value))}
                                >
                                  {pageOptions.map((option) => (
                                    <option key={option} value={option}>
                                      {option}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </Pagination>
                          )}
                        </div>

                        <div
                          className='card-body tab-pane fade  table-responsive'
                          id='kt_table_widget_4_tab_2'
                        >
                          <table
                            className='table table-row-dashed align-middle table-row-gray-300'
                            {...inactiveTableInstance.getTableProps()}
                          >
                            <thead>
                              <tr className='fw-bold text-muted'>
                                <th className='min-w-140px default-cursor'>
                                  {' '}
                                  {intl.formatMessage({id: 'LABEL.ASSET_TAG'})}
                                </th>

                                <th className='min-w-140px default-cursor'>
                                  {intl.formatMessage({id: 'LABEL.ASSET_NAME'})}
                                </th>

                                <th className='min-w-120px text-start default-cursor'>
                                  {intl.formatMessage({id: 'LABEL.ASSET_BRAND'})}
                                </th>

                                <th className='min-w-120px text-start default-cursor'>
                                  {' '}
                                  {intl.formatMessage({id: 'LABEL.ASSET_MODEL'})}
                                </th>
                                <th className='min-w-120px text-start default-cursor'>
                                  {intl.formatMessage({id: 'LABEL.SERIAL_NUMBER'})}
                                </th>

                                <th className='min-w-100px text-start default-cursor'>
                                  {' '}
                                  {intl.formatMessage({id: 'LABEL.SUPPLIER'})}
                                </th>

                                <th className='min-w-100px text-start default-cursor'>
                                  {intl.formatMessage({id: 'LABEL.RAISED_DATE'})}
                                </th>

                                {/* <th className='min-w-100px text-start default-cursor'>
                                  {intl.formatMessage({id: 'LABEL.STATUS'})}
                                </th> */}

                                <th className='min-w-100px text-center default-cursor'>
                                  {' '}
                                  {intl.formatMessage({id: 'MENU.ACTIONS'})}
                                </th>
                              </tr>
                            </thead>

                            <tbody {...inactiveTableInstance.getTableBodyProps()}>
                              {inactivePage.length > 0 ? (
                                inactivePage.map((row) => {
                                  inactiveTableInstance.prepareRow(row)
                                  return (
                                    <tr {...row.getRowProps()}>
                                      {row.cells.map((cell) => (
                                        <td className=' ' {...cell.getCellProps()}>
                                          {cell.render('Cell')}
                                        </td>
                                      ))}
                                    </tr>
                                  )
                                })
                              ) : (
                                <tr>
                                  <td>
                                    <p></p>
                                  </td>
                                  <td className='card'>
                                    <p className='text-dark fw-bold text-hover-primary default-cursor d-block fs-6 m-0 my-5'>
                                      {intl.formatMessage({id: 'NO_RESULTS_FOUND_MSG'})}
                                    </p>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>

                          {data.length > 10 && (
                            <Pagination>
                              <Pagination.First
                                onClick={() => gotoInactivePage(0)}
                                disabled={!inactiveTableInstance.canPreviousPage}
                              />
                              <Pagination.Prev
                                onClick={inactiveTableInstance.previousPage}
                                disabled={!inactiveTableInstance.canPreviousPage}
                              />
                              {[...Array(inactivePageCount)].map((_, i) => (
                                <Pagination.Item
                                  key={i}
                                  active={i === inactivePageIndex}
                                  onClick={() => gotoInactivePage(i)}
                                >
                                  {i + 1}
                                </Pagination.Item>
                              ))}
                              <Pagination.Next
                                onClick={inactiveTableInstance.nextPage}
                                disabled={!inactiveTableInstance.canNextPage}
                              />
                              <Pagination.Last
                                onClick={() => gotoInactivePage(inactivePageCount - 1)}
                                disabled={!inactiveTableInstance.canNextPage}
                              />
                              <div className='d-flex align-items-center mx-5'>
                                <span className='me-2'>
                                  {intl.formatMessage({id: 'LABEL.SHOW'})}
                                </span>
                                <select
                                  className='form-select form-select-solid fw-bold w-75px me-2'
                                  value={inactivePageSize}
                                  onChange={(e) => setInactivePageSize(Number(e.target.value))}
                                >
                                  {pageOptions.map((option) => (
                                    <option key={option} value={option}>
                                      {option}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </Pagination>
                          )}
                        </div>
                        <div
                          className='card-body tab-pane fade show  table-responsive'
                          id='kt_table_widget_4_tab_3'
                        >
                          <table
                            className='table table-row-dashed align-middle table-row-gray-300'
                            {...RejectedDataInstance.getTableProps()}
                          >
                            <thead>
                              <tr className='fw-bold text-muted'>
                                <th className='min-w-140px default-cursor'>
                                  {' '}
                                  {intl.formatMessage({id: 'LABEL.ASSET_TAG'})}
                                </th>

                                <th className='min-w-140px default-cursor'>
                                  {intl.formatMessage({id: 'LABEL.ASSET_NAME'})}
                                </th>

                                <th className='min-w-120px text-start default-cursor'>
                                  {intl.formatMessage({id: 'LABEL.ASSET_BRAND'})}
                                </th>

                                <th className='min-w-120px text-start default-cursor'>
                                  {' '}
                                  {intl.formatMessage({id: 'LABEL.ASSET_MODEL'})}
                                </th>
                                <th className='min-w-120px text-start default-cursor'>
                                  {intl.formatMessage({id: 'LABEL.SERIAL_NUMBER'})}
                                </th>

                                <th className='min-w-100px text-start default-cursor'>
                                  {' '}
                                  {intl.formatMessage({id: 'LABEL.SUPPLIER'})}
                                </th>

                                <th className='min-w-100px text-start default-cursor'>
                                  {intl.formatMessage({id: 'LABEL.RAISED_DATE'})}
                                </th>

                                {/* <th className='min-w-100px text-start default-cursor'>
                                  {intl.formatMessage({id: 'LABEL.STATUS'})}
                                </th> */}

                                <th className='min-w-100px text-center default-cursor'>
                                  {' '}
                                  {intl.formatMessage({id: 'MENU.ACTIONS'})}
                                </th>
                              </tr>
                            </thead>

                            <tbody {...RejectedDataInstance.getTableBodyProps()}>
                              {RejectedPage.length > 0 ? (
                                RejectedPage.map((row) => {
                                  RejectedDataInstance.prepareRow(row)
                                  return (
                                    <tr {...row.getRowProps()}>
                                      {row.cells.map((cell) => (
                                        <td className=' ' {...cell.getCellProps()}>
                                          {cell.render('Cell')}
                                        </td>
                                      ))}
                                    </tr>
                                  )
                                })
                              ) : (
                                <tr>
                                  <td>
                                    <p></p>
                                  </td>
                                  <td className='card'>
                                    <p className='text-dark fw-bold text-hover-primary default-cursor d-block fs-6 m-0 my-5'>
                                      {intl.formatMessage({id: 'NO_RESULTS_FOUND_MSG'})}
                                    </p>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>

                          {data.length > 10 && (
                            <Pagination>
                              <Pagination.First
                                onClick={() => gotoRejectedPage(0)}
                                disabled={!RejectedDataInstance.canPreviousPage}
                              />
                              <Pagination.Prev
                                onClick={RejectedDataInstance.previousPage}
                                disabled={!RejectedDataInstance.canPreviousPage}
                              />
                              {[...Array(RejectedPageCount)].map((_, i) => (
                                <Pagination.Item
                                  key={i}
                                  active={i === RejectedPageIndex}
                                  onClick={() => gotoRejectedPage(i)}
                                >
                                  {i + 1}
                                </Pagination.Item>
                              ))}
                              <Pagination.Next
                                onClick={RejectedDataInstance.nextPage}
                                disabled={!RejectedDataInstance.canNextPage}
                              />
                              <Pagination.Last
                                onClick={() => gotoRejectedPage(RejectedPageCount - 1)}
                                disabled={!RejectedDataInstance.canNextPage}
                              />
                              <div className='d-flex align-items-center mx-5'>
                                <span className='me-2'>
                                  {intl.formatMessage({id: 'LABEL.SHOW'})}
                                </span>
                                <select
                                  className='form-select form-select-solid fw-bold w-75px me-2'
                                  value={RejectedPageSize}
                                  onChange={(e) => setRejectedPage(Number(e.target.value))}
                                >
                                  {pageOptions.map((option) => (
                                    <option key={option} value={option}>
                                      {option}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </Pagination>
                          )}
                        </div>
                      </div>
                    </>
                  </div>
                </>
              ) : (
                <div className='card h-250px'>
                  <div className='m-auto d-flex flex-column align-items-center'>
                    <h1>{intl.formatMessage({id: 'LABEL.NO_ASSETS_FOUND'})}</h1>

                    <button
                      className='btn btn-sm btn-primary mt-3'
                      id='side_activities_toggle'
                      onClick={() => {
                        updateId({
                          type: 'add_asset',
                        })
                      }}
                    >
                      <KTIcon iconName='plus' className='fs-3' />
                      Add Asset
                    </button>
                  </div>
                </div>
              )}
              <SideDrawer value={id} />
            </>
          )
        }}
      </Context.Consumer>
    </>
  )
}

const AllAssets = () => {
  let emp_id
  let user_id
  let role

  let is_admin
  let is_itadmin
  const userInfo = secureLocalStorage.getItem('user_info')
  if (userInfo) {
    ;({is_admin, is_itadmin} = userInfo)
  }
  const location = useLocation()
  const {data: filteredDataByDate, isLoading, fetchData} = useApiCall(`/assetManagement/asset/all`)

  const {execute} = usePostApi()
  const intl = useIntl()

  useEffect(() => {
    const targetElement = document.getElementById('side_activities')
    if (targetElement && location.pathname === '/assetManagement/asset/all') {
      const body = document.querySelector('body')
      const observer = new MutationObserver(() => {
        if (!body.hasAttribute('data-kt-drawer-side-activities')) {
          fetchData()
        }
      })
      observer.observe(targetElement, {attributes: true})
      return () => {
        observer.disconnect()
      }
    }
  }, [location.pathname])

  const columns = useMemo(
    () => [
      {
        Header: 'Asset Tag',
        accessor: 'Asset Tag',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark fw-bold text-hover-primary d-block fs-6 default-cursor'>
                {row.original.asset_tag}
              </p>
            </div>
          )
        },
      },
      {
        Header: 'Asset Type Name',
        accessor: 'Asset Type Name',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark fw-bold text-hover-primary d-block fs-6 default-cursor'>
                {row.original.asset_type_name}
              </p>
            </div>
          )
        },
        disableSortBy: true,
      },

      {
        Header: 'Brand',
        accessor: 'Brand',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark fw-bold text-hover-primary d-block fs-6 default-cursor'>
                {row.original.brand}
              </p>
            </div>
          )
        },
        disableSortBy: true,
      },

      {
        Header: 'Model',
        accessor: 'Model',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark fw-bold text-hover-primary d-block fs-6 default-cursor'>
                {row.original.model}
              </p>
            </div>
          )
        },
        disableSortBy: true,
      },

      {
        Header: 'Serial Number',
        accessor: 'Serial Number',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark fw-bold text-hover-primary d-block fs-6 default-cursor'>
                {row.original.serial_number}
              </p>
            </div>
          )
        },
        disableSortBy: true,
      },

      {
        Header: 'Supplier',
        accessor: 'Supplier',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark fw-bold text-hover-primary d-block fs-6 default-cursor'>
                {row.original.supplier}
              </p>
            </div>
          )
        },
        disableSortBy: true,
      },

      {
        Header: 'Created Date',
        accessor: 'Created Date',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark fw-bold text-hover-primary d-block fs-6 default-cursor'>
                {DateFormatter(row.original.created_at.slice(0, 10))}
              </p>
            </div>
          )
        },
        disableSortBy: true,
      },

      // {
      //   Header: 'Status',
      //   accessor: 'Status',
      //   Cell: ({row}) => {
      //     return (
      //       <div className='text-start'>
      //         <p className='text-dark fw-bold text-hover-primary d-block fs-6 default-cursor'>
      //           {row.original.working_status}
      //         </p>
      //       </div>
      //     )
      //   },
      //   disableSortBy: true,
      // },

      {
        Header: 'Actions',
        accessor: 'Actions',
        Cell: ({row}) => {
          const handleSubmit = async (values, formikHelpers) => {
            // const {resetForm} = formikHelpers
            try {
              const organizationId = secureLocalStorage.getItem('organization_id')
              const url = `${baseUrl}/assetManagement/empAssetRequest`
              const formData = {
                organization_id: organizationId,

                asset_id: row.original.asset_id,
                request_status: 'Pending',
              }

              await execute(url, 'POST', formData)
              // resetForm()

              Swal.fire({
                title: intl.formatMessage({id: 'LABEL.REQUEST_RAISED_SUCCESSFULLY'}),
                text: intl.formatMessage({id: 'LABEL.CHANGES_SAVED'}),
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-light-primary btn-sm',
                },
              })
            } catch (error) {
              console.error(error)
              Swal.fire({
                icon: 'error',
                title: intl.formatMessage({id: 'LABEL.OOPS'}),
                text: intl.formatMessage({id: 'LABEL.SOMETHING_WENT_WRONG'}),
                customClass: {
                  confirmButton: 'btn btn-light-primary btn-sm',
                },
              })
            }
          }

          const handleInfoIconClick = () => {
            Swal.fire({
              title: intl.formatMessage({id: 'LABEL.SUBMIT_ASSET_REQUEST'}),
              text: intl.formatMessage({
                id: 'LABEL.PRESS_THE_CONFIRM_BUTTON_TO_SUBMIT_ASSET_REQUEST',
              }),
              icon: 'info',
              showCancelButton: true,
              confirmButtonText: intl.formatMessage({id: 'BTN.CONFIRM'}),
              cancelButtonText: intl.formatMessage({id: 'LABEL.CANCEL'}),
              customClass: {
                confirmButton: 'btn btn-light-primary btn-sm',
                cancelButton: 'btn btn-sm',
              },
            }).then((result) => {
              if (result.isConfirmed) {
                handleSubmit()
              }
            })
          }

          return (
            <Context.Consumer>
              {(value) => {
                const {updateId} = value

                return (
                  <div>
                    {is_admin === 1 ? (
                      <>
                        <div className='text-center'>
                          <div
                            className='btn btn-light-primary btn-sm mb-1'
                            id='side_activities_toggle'
                            onClick={() => {
                              updateId({
                                id: row.original.asset_id,
                                type: 'edit_asset',
                                fetchData,
                              })
                            }}
                          >
                            <KTIcon iconName='pencil' className='fs-3' />
                            {intl.formatMessage({id: 'LABEL.EDIT_ASSET'})}
                          </div>
                          <div
                            className='btn btn-light-primary btn-sm ms-4 mt-1 mt-lg-0 mb-1'
                            id='side_activities_toggle'
                            onClick={() => {
                              updateId({
                                id: row.original.asset_id,
                                type: 'issue_asset',
                              })
                            }}
                          >
                            <KTIcon iconName='plus' className='fs-3' />
                            {intl.formatMessage({id: 'LABEL.ISSUE_ASSET'})}
                          </div>

                          {/* <div
                            className='btn btn-light-primary btn-sm me-3'
                            id='side_activities_toggle'
                            onClick={() => {
                              updateId({
                                id: row.original.asset_id,
                                type: 'request_asset',
                              })
                            }}
                          >
                            <KTIcon iconName='plus' className='fs-3' />
                            Request Asset
                          </div> */}

                          {/* <div className='text-center'>
                            <button
                              className='btn btn-light-primary btn-sm'
                              onClick={handleInfoIconClick}
                            >
                              <KTIcon iconName='plus' className='fs-3 text-danger' />
                              {intl.formatMessage({id: 'LABEL.REQUEST_ASSET'})}
                            </button>
                          </div> */}

                          {/* <div
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                            id='side_activities_toggle'
                            onClick={() => {
                              updateId({
                                id: row.original.asset_id,
                                type: 'edit_asset',
                              })
                            }}
                          >
                            <KTIcon iconName='eye' className='fs-3' />
                          </div> */}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className='text-center'>
                          <div
                            className='btn btn-light-primary btn-sm'
                            onClick={handleInfoIconClick}
                          >
                            <KTIcon iconName='plus' className='fs-3' />
                            {intl.formatMessage({id: 'LABEL.REQUEST_ASSET'})}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                )
              }}
            </Context.Consumer>
          )
        },
      },
    ],
    []
  )

  return (
    <DataTable
      columns={columns}
      data={filteredDataByDate !== null ? filteredDataByDate : []}
      loading={isLoading}
    />
  )
}

export default AllAssets
