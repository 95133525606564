import {useMemo, useState} from 'react'
import {useTable, usePagination} from 'react-table'
import {useIntl} from 'react-intl'
import {Pagination} from 'react-bootstrap'
import {KTIcon} from '../../../../_metronic/helpers'
import DateFormatter from '../../DateFormatter'

import usePageTitle from '../../custom_hooks/usePageTitle'

const DataTable = ({columns, data, loading}) => {
  const [inputVal, setInputVal] = useState('')
  const intl = useIntl()
  const [currentStatus, setCurrentStatus] = useState('Pending')
  usePageTitle('My Leaves')

  const filteredData = useMemo(() => {
    return data
      ? data.filter((each) => {
          const leaveTypeMatches =
            each.leave_type_name &&
            each.leave_type_name.toLowerCase().includes(inputVal.toLowerCase())

          const leaveStatus =
            each.status !== 'Approved' && each.status !== 'Rejected' ? 'Pending' : each.status
          const currentStatusMatches =
            leaveStatus && leaveStatus.toLowerCase() === currentStatus.toLowerCase()

          return leaveTypeMatches && currentStatusMatches
        })
      : []
  }, [inputVal, data, currentStatus])

  const tableInstance = useTable({columns, data: filteredData}, usePagination)
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    state: {pageIndex, pageSize},
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageCount,
    gotoPage,
    setPageSize,
  } = tableInstance

  const pageOptions = [5, 10, 20, 30, 40, 50]

  // Render the component

  return (
    <>
      <div className='card'>
        <div className='card-header border-0 pt-5'>
          <div className='d-flex flex-wrap flex-stack'>
            <div className='fw-bolder'>
              <ul className='nav'>
                <li className='nav-item'>
                  <a
                    className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary active fw-bold px-4 me-1 ms-0 '
                    data-bs-toggle='tab'
                    onClick={() => setCurrentStatus('Pending')}
                  >
                    {intl.formatMessage({id: 'LABEL.PENDING'})}
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1'
                    data-bs-toggle='tab'
                    onClick={() => setCurrentStatus('Approved')}
                  >
                    {intl.formatMessage({id: 'LABEL.APPROVED'})}
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1'
                    data-bs-toggle='tab'
                    onClick={() => setCurrentStatus('Rejected')}
                  >
                    {intl.formatMessage({id: 'LABEL.REJECTED'})}
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className='card-toolbar'>
            <div className='d-flex align-items-center position-relative me-4'>
              <KTIcon iconName='magnifier' className='fs-3 position-absolute ms-3' />
              <input
                type='search'
                id='kt_filter_search'
                className='form-control form-control-white form-control-sm w-150px ps-9'
                placeholder={intl.formatMessage({id: 'LABEL.SEARCH'})}
                value={inputVal}
                onChange={(e) => setInputVal(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className='mb-5 mb-xl-8 p-10 pb-0'>
          <table
            className='table table-row-dashed table-row-gray-300 align-middle'
            {...getTableProps()}
          >
            <thead className=''>
              <tr className='fw-bold text-muted'>
                <th className='w-25 text-start  default-cursor'>
                  {intl.formatMessage({id: 'LABEL.LEAVE_TYPE'})}
                </th>
                <th className='w-25 text-start default-cursor'>
                  {intl.formatMessage({id: 'LABEL.START_DATE'})}
                </th>
                <th className='w-25 text-start  default-cursor'>
                  {intl.formatMessage({id: 'LABEL.END_DATE'})}
                </th>
                <th className='w-25 text-start default-cursor'>
                  {intl.formatMessage({id: 'LABEL.NO_OF_DAYS'})}
                </th>
                {/* <th className='w-25 text-center  default-cursor'>
                  {intl.formatMessage({id: 'LABEL.STATUS'})}
                </th> */}
              </tr>
            </thead>

            <tbody {...getTableBodyProps()}>
              {page && page.length > 0 ? (
                page.map((row) => {
                  prepareRow(row)
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <td className='text-center' {...cell.getCellProps()}>
                          {cell.render('Cell')}
                        </td>
                      ))}
                    </tr>
                  )
                })
              ) : (
                <tr>
                  <td className='card'>
                    <p className='text-dark fw-bold text-hover-primary default-cursor d-block fs-6 m-0 my-5'>
                      {intl.formatMessage({id: 'NO_RESULTS_FOUND_MSG'})}
                    </p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {filteredData.length > 10 && (
            <Pagination>
              <Pagination.First onClick={() => gotoPage(0)} disabled={!canPreviousPage} />
              <Pagination.Prev onClick={previousPage} disabled={!canPreviousPage} />

              {[...Array(pageCount)].map((_, i) => (
                <Pagination.Item key={i} active={i === pageIndex} onClick={() => gotoPage(i)}>
                  {i + 1}
                </Pagination.Item>
              ))}

              <Pagination.Next onClick={nextPage} disabled={!canNextPage} />
              <Pagination.Last onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} />

              <div className='d-flex align-items-center mx-5'>
                <span className='me-2'>{intl.formatMessage({id: 'LABEL.SHOW'})}</span>
                <select
                  className='form-select form-select-solid fw-bold w-75px me-2'
                  value={pageSize}
                  onChange={(e) => setPageSize(Number(e.target.value))}
                >
                  {pageOptions.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            </Pagination>
          )}
        </div>
      </div>
    </>
  )
}

const MyLeavesTable = ({data}) => {
  const TableData = data
  const intl = useIntl()

  const sortDataByMonthAndDate = (data) => {
    return data.sort((a, b) => {
      const dateA = new Date(a.start_date)
      const dateB = new Date(b.end_date)

      if (dateA.getMonth() === dateB.getMonth()) {
        return dateA.getDate() - dateB.getDate()
      }

      return dateA.getMonth() - dateB.getMonth()
    })
  }

  const sortedTableData = sortDataByMonthAndDate(TableData || [])

  const columns = useMemo(
    () => [
      {
        Header: 'Leave Type Name',
        accessor: 'Leave Type Name',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark fw-bold text-hover-primary default-cursor d-block fs-6'>
                {row.original.leave_type_name}
              </p>
            </div>
          )
        },
      },
      {
        Header: 'Start Date',
        accessor: 'Start Date',
        Cell: ({row}) => {
          return (
            <div className='text-end'>
              <p className='text-dark fw-bold text-hover-primary default-cursor d-block fs-6 text-start'>
                {DateFormatter(row.original.start_date)}
              </p>
            </div>
          )
        },
      },
      {
        Header: 'End Date',
        accessor: 'End Date',
        Cell: ({row}) => {
          return (
            <div className='text-end'>
              <p className='text-dark fw-bold text-hover-primary default-cursor d-block fs-6 text-start'>
                {DateFormatter(row.original.end_date)}
              </p>
            </div>
          )
        },
      },
      {
        Header: 'No of Days',
        accessor: 'No of Days',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark fw-bold text-hover-primary default-cursor d-block fs-6 '>
                {row.original.no_of_days}
              </p>
            </div>
          )
        },
      },
      // {
      //   Header: 'Status',
      //   accessor: 'status',
      //   Cell: ({row}) => {
      //     const statusColor =
      //       row.original.status === 'Approved'
      //         ? 'success'
      //         : row.original.status === 'Pending'
      //         ? 'muted'
      //         : 'danger'

      //     return (
      //       <p
      //         className={`text-${
      //           statusColor === 'info' ? 'info' : statusColor
      //         } fw-bold text-hover-primary d-block fs-6 default-cursor`}
      //       >
      //         {row.original.status}
      //       </p>
      //     )
      //   },
      // },
    ],
    []
  )
  return <DataTable columns={columns} data={sortedTableData ? sortedTableData : []} />
}

export default MyLeavesTable
