import React from 'react'
import payslipData from './PayslipData.json'

function Payslip() {
  const {
    employee_info,
    pay_run_info,
    banking_info,
    earnings,
    deductions,
    loan_advances,
    reimbursements,
    adjustments,
  } = payslipData
  const calculateTotalAmount = (category: any) => {
    return category.reduce((total: any, item: any) => total + item.amount, 0)
  }
  const totalEarnings = calculateTotalAmount(earnings)
  const totalDeductions = calculateTotalAmount(deductions)
  const totalLoanAdvances = calculateTotalAmount(loan_advances)
  const netPay = totalEarnings - totalDeductions - totalLoanAdvances

  return (
    <>
      <div className='card-body bg-light d-flex col-12 mb-4 pb-2'>
        <div className='col-4'>
          <p className='text-muted fw-bold text-hover-primary fs-6 default-cursor'>November 2023</p>
          <p className='text-dark fw-bold text-hover-primary fs-6 default-cursor'>
            Paid Days : 30 | LOP Days : 0
          </p>
        </div>
        <div className='col-4'>
          <p className='text-muted fw-bold text-hover-primary fs-6 default-cursor'>
            Bank Account No
          </p>
          <p className='text-dark fw-bold text-hover-primary fs-6 default-cursor'>5010030600XXXX</p>
        </div>
        <div className='col-4'>
          <p className='text-muted fw-bold text-hover-primary fs-6 default-cursor'>UAN</p>
          <p className='text-dark fw-bold text-hover-primary fs-6 default-cursor'>-</p>
        </div>
      </div>
      <div className=''>
        <table className='table table-bordered mb-8 mt-8'>
          <thead>
            <tr>
              <th colSpan={2} className='fw-bold text-muted'>
                Earnings
              </th>
              <th colSpan={2} className='fw-bold text-muted'>
                Deductions
              </th>
            </tr>
            <tr>
              <th className='text-center'>Component</th>
              <th className='text-center'>Amount (AED)</th>
              <th className='text-center'>Component</th>
              <th className='text-center'>Amount (AED)</th>
            </tr>
          </thead>
          <tbody>
            {earnings.map((earning, index) => (
              <tr key={`earning_${earning.component_id}`}>
                <td>{earning.component_name}</td>
                <td className='text-end'>{earning.amount.toFixed(2)}</td>
                {index < deductions.length ? (
                  <>
                    <td className='d-flex flex-column'>
                      <p className='mb-0'>{deductions[index].component_name}</p>
                      <span>Ref: 23AJ0000123</span>
                    </td>
                    <td className='text-end'>{deductions[index].amount.toFixed(2)}</td>
                  </>
                ) : (
                  <>
                    <td colSpan={2}></td>
                  </>
                )}
              </tr>
            ))}
            {deductions.slice(earnings.length).map((deduction, index) => (
              <tr key={`deduction_${deduction.component_id}`}>
                <td colSpan={2}></td>
                <td>{deduction.component_name}</td>
                <td className='text-end'>{deduction.amount.toFixed(2)}</td>
              </tr>
            ))}
            <tr>
              <td>
                <p></p>
              </td>
              <td>
                <p></p>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th>Total Earnings (TE)</th>
              <td className='text-end'>{totalEarnings.toFixed(2)}</td>
            </tr>
            <tr>
              <th>Total Reimbursements (TR)</th>
              <td className='text-end'>{totalEarnings.toFixed(2)}</td>
              <th>Total Deductions (TD)</th>
              <td className='text-end'>{totalDeductions.toFixed(2)}</td>
            </tr>
            <tr>
              <th>Total Credit Adjustments (TCA)</th>
              <td className='text-end'>{netPay.toFixed(2)}</td>
              <th>Total Debit Adjustments (TDA)</th>
              <td className='text-end'>{totalLoanAdvances.toFixed(2)}</td>
            </tr>
          </tfoot>
        </table>
        <table className='table table-bordered'>
          <tbody>
            <tr>
              <td>Net Pay (TE - TD + TRA)</td>
              <td>AED 1,26,225</td>
            </tr>
            <tr>
              <td>Net Pay (in words)</td>
              <td>
                One-hundred and twenty-six thousand two-hundred and twenty-five United Arab Emirates
                Dirhams only.
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className='fw-bold mb-5 fs-4 text-center'>
        <span style={{borderBottom: '1px solid black'}}>Annexure</span>
      </div>
      <div className='d-flex col-12'>
        <div className='col-6'>
          <div className='fw-bold text-muted mb-4'>Employee Reimbursements</div>
          <table className='table table-bordered'>
            <thead>
              <tr>
                <th className='text-center'>Reimbursement</th>
                <th className='text-center'>Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className='d-flex flex-column'>
                  <p className='mb-0'>Medical Expense</p>
                  <span>Ref: 23EXP00101231 dt. 12-Oct-2023</span>
                </td>
                <td className='text-end'>1,000</td>
              </tr>
              <tr>
                <td className='d-flex flex-column'>
                  <p className='mb-0'>Marketing Expense</p>
                  <span>Ref: 23EXP00101245 dt. 15-Oct-2023</span>
                </td>
                <td className='text-end'>350</td>
              </tr>
              <tr>
                <td>
                  <p></p>
                </td>
                <td>
                  <p></p>
                </td>
              </tr>
              <tr>
                <th>Total Reimbursement (TR)</th>
                <td className='text-end'>1,350</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className='d-flex col-12'>
        <div className='col-6'>
          <div className='fw-bold text-muted mb-4'>Credit Adjustments</div>
          <table className='table table-bordered'>
            <thead>
              <tr>
                <th className='text-center'>Reimbursement</th>
                <th className='text-center'>Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className='d-flex flex-column'>
                  <p className='mb-0'>Revised Salary Adjustment for Oct</p>
                  <span>Ref: 23ADJ0000124</span>
                </td>
                <td className='text-end'>1,525</td>
              </tr>
              <tr>
                <td>
                  <p></p>
                </td>
                <td>
                  <p></p>
                </td>
              </tr>
              <tr>
                <th>Total Credit Adjustments (TCA)</th>
                <td className='text-end'>1,525</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className='col-6'>
          <div className='fw-bold text-muted mb-4'>Debit Adjustments</div>
          <table className='table table-bordered'>
            <thead>
              <tr>
                <th className='text-center'>Adjustment</th>
                <th className='text-center'>Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className='d-flex flex-column'>
                  <p className='mb-0'>LOP Adjustment for 23 Oct</p>
                  <span>Ref: 23ADJ0000123</span>
                </td>
                <td className='text-end'>650</td>
              </tr>
              <tr>
                <td>
                  <p></p>
                </td>
                <td>
                  <p></p>
                </td>
              </tr>
              <tr>
                <th>Total Debit Adjustments (TDA)</th>
                <td className='text-end'>650</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className=''>
        <div className='fw-bold text-muted mb-4'>Loans</div>
        <table className='table table-bordered'>
          <thead>
            <tr>
              <th className='text-center'>Loan ID</th>
              <th className='text-center'>Instalment</th>
              <th className='text-center'>Loan Amount(AED)</th>
              <th className='text-center'>Paid Amount(AED)</th>
              <th className='text-center'>Outstaning(AED)</th>
              <th className='text-center'>Current Instalment(AED)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className='text-center'>23L0N00101231</td>
              <td className='text-center'>4 of 10</td>
              <td className='text-center'>10,000</td>
              <td className='text-center'>3,000</td>
              <td className='text-center'>7,000</td>
              <td className='text-center'>1,000</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  )
}

export default Payslip
