/* eslint-disable jsx-a11y/anchor-is-valid */

import {useIntl} from 'react-intl'
import TeamRequestsTable from './TeamRequestsTable'
function TeamRequests() {
  const intl = useIntl()
  return (
    <>
      <div className='d-flex flex-wrap flex-stack mb-6'>
        <h3 className='fw-bolder my-2'> {intl.formatMessage({id: 'LABEL.TEAM_REQUESTS'})}</h3>
      </div>
      <div className='card mb-5 mb-xl-10'>
        <TeamRequestsTable />
      </div>
    </>
  )
}

export default TeamRequests
