import {useState, useEffect} from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'

import {useIntl} from 'react-intl'

import React from 'react'

import TimeSheetHR from './TimeSheetHr'
import TimeSheetManager from './TimeSheetManager'
import useApiCall from '../../../custom_hooks/useApiCall'
import {defaultProfileImage} from '../../../core'

const TeamTimeSheetDrawer = (timeSheetData: any) => {
  const {props}: any = timeSheetData
  const intl = useIntl()
  const {data, fetchData, isLoading}: any | null = useApiCall(
    `/timesheets/timesheet/${props?.timesheet_id}`
  )
  let timesheetData = data && data[0]

  const reviewerDetails = {
    reviewer_status: timesheetData?.reviewer_status || '',
    reviewer_comments: timesheetData?.reviewer_comments || '',

    fetchData: fetchData,
    fetchtimesheetData: props?.fetchData,
    timesheet_id: timesheetData?.timesheet_id,
  }

  const hrDetails = {
    reviewer_status: timesheetData?.reviewer_status || '',
    hr_review_comments: timesheetData?.hr_review_comments || '',
    hr_reviewed_status: timesheetData?.hr_reviewed_status || '',
    fetchData: fetchData,

    fetchtimesheetData: props?.fetchData,
    timesheet_id: timesheetData?.timesheet_id,
  }

  return (
    <div className='card shadow-none rounded-0'>
      <div>
        <div className='card-header' id='side_activities_header'>
          <h3 className='card-title fw-bolder text-dark'>
            {intl.formatMessage({id: 'LABEL.TIME_SHEET_REQUESTS'})}
          </h3>
          <div className='card-toolbar '>
            <React.Fragment>
              <div className='btn btn-icon btn-sm btn-active-danger' id='side_activities_close'>
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </React.Fragment>
          </div>
        </div>

        <div className='card-body position-relative' id='side_activities_body'>
          <div>
            <div
              id='side_activities_scroll'
              className='position-relative scroll-y me-n5 pe-5 w-400px'
              data-kt-scroll='true'
              data-kt-scroll-height='auto'
              data-kt-scroll-wrappers='#side_activities_body'
              data-kt-scroll-dependencies='#side_activities_header, #side_activities_footer'
              data-kt-scroll-offset='5px'
            >
              {isLoading ? (
                <div className='card h-350px'>
                  <div className='m-auto d-flex flex-column align-items-center'>
                    <div className='spinner-border spinner-primary mr-15'></div>
                  </div>
                </div>
              ) : (
                <>
                  <div className='d-flex'>
                    <div className='d-flex'>
                      <img
                        src={
                          timeSheetData?.employee_profile_pic
                            ? timeSheetData?.employee_profile_pic
                            : defaultProfileImage
                        }
                        alt=''
                        className='activity-img rounded w-60px h-60px me-3'
                      />
                      <div className=''>
                        <a href='#' className='text-dark fw-bold text-hover-primary fs-5'>
                          {timesheetData?.first_name &&
                          timesheetData?.middle_name &&
                          timesheetData?.last_name
                            ? `${timesheetData?.first_name} ${timesheetData?.middle_name} ${timesheetData?.last_name}`
                            : timesheetData?.first_name && timesheetData?.last_name
                            ? `${timesheetData?.first_name} ${timesheetData?.last_name}`
                            : '-'}
                        </a>
                        <br />
                        <a href='#' className='text-muted fw-bold text-hover-primary fs-7'>
                          {timesheetData?.employee_code ? timesheetData?.employee_code : '-'}
                        </a>
                        <br />
                        <a href='#' className='text-muted fw-bold text-hover-primary fs-7'>
                          {timesheetData?.job_title ? timesheetData?.job_title : '-'}
                        </a>
                        <br />
                        {/* <a href='#' className='text-muted fw-bold text-hover-primary fs-7'>
                          {timesheetData?.department}
                        </a> */}
                        <br />
                      </div>
                    </div>
                  </div>
                  <h5 className='mt-1 text-dark'>Basic Information</h5>
                  <hr className='text-dark mt-1' />
                  <div className='row mb-2'>
                    <label className='col-lg-4 fw-bold text-muted'>
                      {' '}
                      {intl.formatMessage({id: 'LABEL.PROJECT_NAME'})}
                    </label>
                    <div className='col-lg-8'>
                      <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                        {timesheetData?.employee_project_name}
                      </span>
                    </div>
                  </div>
                  <div className='row mb-2'>
                    <label className='col-lg-4 fw-bold text-muted'>
                      {' '}
                      {intl.formatMessage({id: 'LABEL.ACTIVITY'})}
                    </label>
                    <div className='col-lg-8'>
                      <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                        {timesheetData?.activity}
                      </span>
                    </div>
                  </div>
                  <div className='row mb-2'>
                    <label className='col-lg-4 fw-bold text-muted'>
                      {' '}
                      {intl.formatMessage({id: 'LABEL.TOTAL_TIME'})}
                    </label>
                    <div className='col-lg-8'>
                      <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                        {timesheetData?.hours}
                      </span>
                    </div>
                  </div>
                  <h5 className='mt-4 text-dark'> {intl.formatMessage({id: 'LABEL.WORKFLOW'})}</h5>
                  <hr className='text-dark mt-2' />
                  <div className='card-body position-relative p-0' id='kt_activities_body'>
                    <div className='timeline'>
                      <TimeSheetManager reviewerDetails={reviewerDetails} />
                      <TimeSheetHR hrDetails={hrDetails} />
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TeamTimeSheetDrawer
