import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import * as Yup from 'yup'
import {ErrorMessage, Field, Form, Formik} from 'formik'
import Swal from 'sweetalert2'
import secureLocalStorage from 'react-secure-storage'
import {useParams} from 'react-router-dom'
import usePostApi from '../../../custom_hooks/usePostApi'
import useApiCall from '../../../custom_hooks/useApiCall'
import {KTIcon} from '../../../../../_metronic/helpers'
import {baseUrl} from '../../../core'

const EditCity = (props: any) => {
  const intl = useIntl()
  const {execute} = usePostApi()
  const {id}: any | null = useParams()
  const userInfo: any | null = secureLocalStorage.getItem('user_info')
  const {data, fetchData}: any | null = useApiCall(`/city/city/${props.id.id}`)
  let cityData = data && data[0]
  const {organization_id, is_admin, is_hr} = userInfo || {}
  const [isEditMode, setIsEditMode] = useState(false)
  const [cityName, setCityName] = useState<string>('')
  const [isChecked, setIsChecked] = useState<boolean>(true)
  useEffect(() => {
    if (cityData) {
      setCityName(cityData.city_name)
      setIsChecked(cityData.status === 1)
    }
  }, [cityData])

  const validationSchemaEdit = Yup.object().shape({
    city_name: Yup.string().required(intl.formatMessage({id: 'LABEL.NAME_REQUIRED'})),
    status: Yup.string().required(intl.formatMessage({id: 'LABEL.STATUS_REQUIRED'})),
  })

  const initialValues = {
    city_name: cityName || '',
    status: isChecked || '',
  }

  const handleEditSubmit = async (values: any) => {
    const url = `${baseUrl}/city/${props.id.id}`
    try {
      const requestData = {
        organization_id: organization_id,
        city_name: values.city_name,
        state_id: cityData?.state_id,
        status: values.status ? 1 : 0,
      }
      await execute(url, 'PATCH', requestData, id)
      setIsEditMode(false)
      props.id.refreshData()
      Swal.fire({
        title: 'City Edited Successfully',
        text: intl.formatMessage({id: 'LABEL.CHANGES_SAVED'}),
        icon: 'success',
        showConfirmButton: true,
        confirmButtonText: 'OK',
        customClass: {
          confirmButton: 'btn btn-light-primary btn-sm',
        },
      })
    } catch (error) {
      console.error(error)
      Swal.fire({
        icon: 'error',
        title: intl.formatMessage({id: 'LABEL.OOPS'}),
        text: intl.formatMessage({id: 'LABEL.SOMETHING_WRONG'}),
      })
    }
  }

  useEffect(() => {
    fetchData()
  }, [props.id.id])

  const handleEditClick = () => {
    setIsEditMode(true)
  }

  const handleSaveClick = () => {
    setIsEditMode(false)
    // Add your save logic here
  }
  return (
    <div>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationSchemaEdit}
        onSubmit={handleEditSubmit}
      >
        {({values, handleChange}) => {
          return (
            <Form>
              <div className='modal-dialog'>
                <div className='modal-content'>
                  <div className='modal-header'>
                    {is_admin === 1 ? (
                      <h3 className='modal-title fw-bolder text-dark'>Edit City Name</h3>
                    ) : (
                      <h3 className='modal-title fw-bolder text-dark'>City Information</h3>
                    )}
                    {is_admin === 1 && (
                      <div className='modal-toolbar'>
                        {!isEditMode ? (
                          <button
                            type='button'
                            className='btn btn-sm btn-light-primary btn-ripple'
                            data-kt-menu-trigger='click'
                            data-kt-menu-placement='bottom-start'
                            data-kt-menu-flip='top-end'
                            onClick={handleEditClick}
                          >
                            <KTIcon iconName='pencil' className='fs-7' />
                            {intl.formatMessage({id: 'BTN.EDIT'})}
                          </button>
                        ) : (
                          <React.Fragment>
                            <div
                              className='btn btn-icon btn-sm btn-active-light-danger'
                              onClick={handleSaveClick}
                              data-bs-dismiss='modal'
                              aria-label='Close'
                            >
                              <KTIcon iconName='cross' className='fs-2' />
                            </div>
                          </React.Fragment>
                        )}
                      </div>
                    )}
                  </div>
                  <div className='modal-body position-relative'>
                    <div>
                      {isEditMode ? (
                        <>
                          <div className='form-floating mb-4'>
                            <Field
                              type='text'
                              className='form-control'
                              id='floatingInput'
                              placeholder='type...'
                              name='city_name'
                              value={!isEditMode ? cityName : values.city_name}
                              disabled={!isEditMode}
                              onChange={handleChange}
                            />
                            <label htmlFor='floatingInput'>
                              {intl.formatMessage({id: 'LABEL.NAME'})}
                            </label>
                            <ErrorMessage
                              component='div'
                              className='text-danger'
                              name='city_name'
                            />
                          </div>
                          <div className='form-floating mb-0'>
                            <div className='form-check form-check-solid form-switch'>
                              <Field
                                className='form-check-input w-40px h-20px'
                                type='checkbox'
                                id='toggle'
                                name='status'
                                checked={!isEditMode ? isChecked : values.status}
                                disabled={!isEditMode}
                                onChange={handleChange}
                              />
                              {!isEditMode ? (
                                <span
                                  className={`me-4 fw-bold ${
                                    isChecked ? 'text-success' : 'text-danger'
                                  }`}
                                >
                                  {isChecked ? 'Active' : 'Inactive'}
                                </span>
                              ) : (
                                <span
                                  className={`me-4 fw-bold ${
                                    values.status ? 'text-success' : 'text-danger'
                                  }`}
                                >
                                  {values.status ? 'Active' : 'Inactive'}
                                </span>
                              )}
                            </div>
                            <ErrorMessage component='div' className='text-danger' name='status' />
                          </div>
                        </>
                      ) : (
                        <div>
                          <div className='card-body ms-0 ps-0 mt-0 pt-0 mb-0 pb-0 '>
                            <div className='row mb-4 mt-0'>
                              <label className='col-lg-4 fw-bold text-muted'>
                                {intl.formatMessage({id: 'LABEL.NAME'})}
                              </label>
                              <div className='col-lg-8'>
                                <span className='fw-bolder fs-6 text-dark'>{cityName}</span>
                              </div>
                            </div>
                            <div className='row mb-0'>
                              <label className='col-lg-4 fw-bold text-muted'>
                                {intl.formatMessage({id: 'LABEL.STATUS'})}
                              </label>
                              <div className='col-lg-8 d-flex align-items-center'>
                                <span className='fw-bolder fs-6 me-2'>
                                  {cityData?.status === 1 ? 'Active' : 'Inactive'}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='modal-footer text-end'>
                    {isEditMode ? (
                      <>
                        <div className='btn btn-sm btn-secondary m-2' onClick={handleSaveClick}>
                          {intl.formatMessage({id: 'LABEL.CANCEL'})}
                        </div>
                        <button className='btn btn-light-primary btn-sm' type='submit'>
                          <KTIcon iconName='check' className='fs-7' />
                          {intl.formatMessage({id: 'BTN.SAVE'})}
                        </button>
                      </>
                    ) : (
                      <button
                        className='btn btn-sm btn-secondary m-2 mt-0'
                        type='button'
                        data-bs-dismiss='modal'
                        aria-label='Close'
                        onClick={handleSaveClick}
                      >
                        {intl.formatMessage({id: 'BTN.CLOSE'})}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export {EditCity}
