import {useState, useEffect} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'

import {useIntl} from 'react-intl'

import useApiCall from '../../custom_hooks/useApiCall'
import React from 'react'
import AssetHr from './AssetHr'
import {defaultProfileImage} from '../../core'
import AssetManager from './AssetManager'
const UpdateAssetDrawer = (assetInfo: any) => {
  const intl = useIntl()
  const {props}: any = assetInfo
  const {data, fetchData, isLoading}: any | null = useApiCall(
    `/assetManagement/empAssets/${props?.employee_asset_id}`
  )
  let AssetData = data && data[0]

  // const [approved_comments, setApproveComments] = useState<string>('')
  // const [approved_status, setApproveStatus] = useState<string>('')

  // const [employee_name, setemployeeName] = useState<string>('')

  // const [employee_profile_pic, setpic] = useState<string>('')
  // const [employee_id, setemployeeID] = useState<string>('')
  // const [employee_role, setemployeeRole] = useState<string>('')
  // const [department, setDepartment] = useState<string>('')
  // const [asset_type_name, setassetType] = useState<string>('')
  // const [brand, setbrand] = useState<string>('')
  // const [working_status, setstatus] = useState<string>('')
  // const [created_timestamp, setcreatedTimestamp] = useState<string>('')
  // const [hr_reviewed_timestamp, sethrcreatedTimestamp] = useState<string>('')
  // useEffect(() => {
  //   if (AssetData) {
  //     setApproveComments(AssetData?.approved_comments)
  //     setApproveStatus(AssetData?.approved_status)
  //     setassetType(AssetData?.asset_type_name)
  //     setemployeeID(AssetData?.employee_id)
  //     setemployeeRole(AssetData?.employee_role)
  //     setDepartment(AssetData?.department)
  //     setbrand(AssetData?.brand)
  //     setstatus(AssetData?.working_status)
  //     setemployeeName(AssetData?.employee_name)

  //     setpic(AssetData?.employee_profile_pic)
  //   }
  // }, [AssetData])

  // useEffect(() => {
  //   fetchData()
  // }, [props.props.id])

  const managerDetails = {
    manager_approved_comments: AssetData?.manager_approved_comments || '',
    manager_approved_status: AssetData?.manager_approved_status || '',
    employee_asset_id: AssetData?.employee_asset_id,
    fetchData: fetchData,
    assetData: props?.fetchData,
  }

  const hrDetails = {
    approved_comments: AssetData?.approved_comments || '',
    approved_status: AssetData?.approved_status || '',
    employee_asset_id: AssetData?.employee_asset_id,
    fetchData: fetchData,
    assetData: props?.fetchData,
  }

  return (
    <div className='card shadow-none rounded-0'>
      <div>
        <div className='card-header' id='side_activities_header'>
          <h3 className='card-title fw-bolder text-dark'>
            {intl.formatMessage({id: 'EMP.TEAM_REQUESTS'})}
          </h3>
          <div className='card-toolbar '>
            <React.Fragment>
              <div className='btn btn-icon btn-sm btn-active-danger' id='side_activities_close'>
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </React.Fragment>
          </div>
        </div>

        <div className='card-body position-relative' id='side_activities_body'>
          <div>
            <div
              id='side_activities_scroll'
              className='position-relative scroll-y me-n5 pe-5 w-400px'
              data-kt-scroll='true'
              data-kt-scroll-height='auto'
              data-kt-scroll-wrappers='#side_activities_body'
              data-kt-scroll-dependencies='#side_activities_header, #side_activities_footer'
              data-kt-scroll-offset='5px'
            >
              {isLoading ? (
                <div className='card h-350px'>
                  <div className='m-auto d-flex flex-column align-items-center'>
                    <div className='spinner-border spinner-primary mr-15'></div>
                  </div>
                </div>
              ) : (
                <>
                  <div className='d-flex mb-5'>
                    <div className='d-flex'>
                      <img
                        src={
                          AssetData?.employee_profile_pic
                            ? AssetData?.employee_profile_pic
                            : defaultProfileImage
                        }
                        className='activity-img rounded h-80px w-80px me-2'
                      />
                      <div className=''>
                        <a href='#' className='text-dark fw-bold text-hover-primary fs-5'>
                          {AssetData?.employee_name ? AssetData?.employee_name : '-'}
                        </a>
                        <br />
                        <a href='#' className='text-muted fw-normal text-hover-primary fs-7'>
                          {AssetData?.employee_code ? AssetData?.employee_code : '-'}
                        </a>
                        <br />
                        <a href='#' className='text-muted fw-normal text-hover-primary fs-7'>
                          {AssetData?.job_title ? AssetData?.job_title : '-'}
                        </a>
                        <br />
                        <a href='#' className='text-muted fw-normal text-hover-primary fs-7'>
                          {AssetData?.department ? AssetData?.department : '-'}
                        </a>
                        <br />
                      </div>
                    </div>
                  </div>
                  <h5 className='mt-1 text-dark'>Basic Information</h5>
                  <hr className='text-dark mt-1' />
                  <div className=''>
                    <div className='row mb-2'>
                      <label className='col-lg-4 fw-bold text-muted'>Asset Type</label>
                      <div className='col-lg-8'>
                        <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                          {AssetData?.asset_type_name}
                        </span>
                      </div>
                    </div>

                    <div className='row mb-2'>
                      <label className='col-lg-4 fw-bold text-muted'>
                        {' '}
                        {intl.formatMessage({id: 'LABEL.ASSET_BRAND'})}
                      </label>
                      <div className='col-lg-8'>
                        <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                          {AssetData?.brand}
                        </span>
                      </div>
                    </div>

                    <div className='row mb-2'>
                      <label className='col-lg-4 fw-bold text-muted'>
                        {' '}
                        {intl.formatMessage({id: 'LABEL.WORKING_STATUS'})}
                      </label>
                      <div className='col-lg-8'>
                        <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                          {AssetData?.working_status}
                        </span>
                      </div>
                    </div>

                    <h5 className='mt-4 text-dark'>{intl.formatMessage({id: 'LABEL.WORKFLOW'})}</h5>
                    <hr className='text-dark mt-2' />
                    <div className='card-body position-relative p-0' id='kt_activities_body'>
                      <div className='timeline'>
                        <AssetManager managerDetails={managerDetails} />
                        <AssetHr hrDetails={hrDetails} />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UpdateAssetDrawer
