import React from 'react'
import {useIntl} from 'react-intl'
import usePageTitle from '../../custom_hooks/usePageTitle'
import secureLocalStorage from 'react-secure-storage'
import useApiCall from '../../custom_hooks/useApiCall'
import {defaultProfileImage} from '../../core'
import numberToWords from 'number-to-words'
import payslipData from './PayslipData.json'

const PaySlipPDF = (props) => {
  const {month, take_home} = props.data
  const intl = useIntl()
  const {data, isLoading} = useApiCall(`/organization/1`)
  const locationName = data && data[0]
  const brandLogos = secureLocalStorage.getItem('brand_logos')
  const {brand_logo_url} = brandLogos || {}
  const numericValue = parseFloat(take_home.replace(/[^\d.]/g, ''))
  const words = numberToWords.toWords(numericValue)
  const {
    employee_info,
    pay_run_info,
    banking_info,
    earnings,
    deductions,
    loan_advances,
    reimbursements,
    adjustments,
  } = payslipData
  const calculateTotalAmount = (category) => {
    return category.reduce((total, item) => total + item.amount, 0)
  }
  const totalEarnings = calculateTotalAmount(earnings)
  const totalDeductions = calculateTotalAmount(deductions)
  const totalLoanAdvances = calculateTotalAmount(loan_advances)
  const netPay = totalEarnings - totalDeductions - totalLoanAdvances
  const wordsForNetPay = numberToWords.toWords(netPay)
  usePageTitle(intl.formatMessage({id: 'MENU.PAYROLL_MANAGEMENT.MY_PAYSLIPS'}))
  return (
    <div id='pdf-container'>
      <div className={`card-body shadow mb-8 pb-2`}>
        <div className='d-flex mt-2 align-items-start'>
          <div className='symbol symbol-50px symbol-lg-90px symbol-fixed position-relative'>
            <img
              src={brand_logo_url ? `${brand_logo_url}?v=${Date.now()}` : defaultProfileImage}
              alt='Image'
            />
          </div>
          <div className='ms-4'>
            <p className='fs-4 text-hover-primary fw-bolder default-cursor mb-0'>
              {locationName?.organization_name}
            </p>
            <p className='fs-4 d-flex align-items-center text-gray-400 text-hover-primary default-cursor mb-0'>
              {locationName?.address_line1} {locationName?.country_name}
            </p>
          </div>
          <div className='ms-auto'>
            <p className='fs-4 text-hover-primary  default-cursor mb-0 text-gray-400'>
              Payslip For the Month
            </p>
            <p className='fs-4 text-hover-primary fw-bolder default-cursor mb-0'>{month}</p>
          </div>
        </div>
        <hr />
        <div className='fw-bold mb-5 fs-4'>Employee Summary</div>
        <div className='d-flex flex-row justify-content-between'>
          <section className='employee-summary text-dark col-xl-6'>
            <div className='row mb-5'>
              <label className='col-6 fw-bold text-muted'>Employee Name</label>
              <div className='col-6'>
                <span className='fw-bolder fs-6 text-dark text-hover-primary'>John Doe</span>
              </div>
            </div>
            <div className='row mb-5'>
              <label className='col-6 fw-bold text-muted'>Designation</label>
              <div className='col-6'>
                <span className='fw-bolder fs-6 text-dark text-hover-primary'>
                  Jr.Software Engineer
                </span>
              </div>
            </div>
            <div className='row mb-5'>
              <label className='col-6 fw-bold text-muted'>Employee ID</label>
              <div className='col-6'>
                <span className='fw-bolder fs-6 text-dark text-hover-primary'>IN000020</span>
              </div>
            </div>
            <div className='row mb-5'>
              <label className='col-6 fw-bold text-muted'>Department</label>
              <div className='col-6'>
                <span className='fw-bolder fs-6 text-dark text-hover-primary'>Technical</span>
              </div>
            </div>
            <div className='row mb-5'>
              <label className='col-6 fw-bold text-muted'>Date of Joining</label>
              <div className='col-6'>
                <span className='fw-bolder fs-6 text-dark text-hover-primary'>17/04/2023</span>
              </div>
            </div>
            <div className='row mb-5'>
              <label className='col-6 fw-bold text-muted'>Pay Period</label>
              <div className='col-6'>
                <span className='fw-bolder fs-6 text-dark text-hover-primary'>14 Days</span>
              </div>
            </div>
            <div className='row mb-5'>
              <label className='col-6 fw-bold text-muted'>Pay Date</label>
              <div className='col-6'>
                <span className='fw-bolder fs-6 text-dark text-hover-primary'>28/04/2021</span>
              </div>
            </div>
            <div className='row mb-5'>
              <label className='col-6 fw-bold text-muted'>Bank Account No.</label>
              <div className='col-6'>
                <span className='fw-bolder fs-6 text-dark text-hover-primary'>12345678</span>
              </div>
            </div>
            <div className='row mb-5'>
              <label className='col-6 fw-bold text-muted'>Work Location</label>
              <div className='col-6'>
                <span className='fw-bolder fs-6 text-dark text-hover-primary'>Chennai</span>
              </div>
            </div>
          </section>
          <div className='d-flex flex-column col-xl-4 justify-content-start'>
            <div className='border rounded p-4 bg-light'>
              <div className='d-flex ms-4'>
                <span className='bullet bullet-vertical h-40px bg-success'></span>
                <div className='flex-grow-1 ms-4'>
                  <p className='text-gray-800 text-hover-primary fw-bold fs-6 mb-0'>AED 126,225</p>
                  <span className='text-muted fw-semibold d-block'>Net Pay for November 2023</span>
                </div>
              </div>
              <hr />
              <div className='row ms-4'>
                <label className='col-6 text-muted fw-semibold'>Working Days</label>
                <div className='col-6'>
                  <span className='fw-bolder fs-6 text-dark text-hover-primary'>10</span>
                </div>
              </div>
              <div className='row ms-4'>
                <label className='col-6 text-muted fw-semibold'>Paid Days</label>
                <div className='col-6'>
                  <span className='fw-bolder fs-6 text-dark text-hover-primary'>0</span>
                </div>
              </div>
              <div className='row ms-4'>
                <label className='col-6 text-muted fw-semibold'>LOP Days</label>
                <div className='col-6'>
                  <span className='fw-bolder fs-6 text-dark text-hover-primary'>0</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='border-dashed border border-gray-300 mb-4' />
        <div className='col-12 d-flex'>
          <div className='col-xl-6'>
            <div className='row'>
              <label className='col-6 fw-bold text-muted'>Bank Account No</label>
              <div className='col-6'>
                <span className='fw-bolder fs-6 text-dark text-hover-primary'>50100306009445</span>
              </div>
            </div>
          </div>
          <div className='col-xl-6'>
            <div className='row'>
              <label className='col-4 fw-bold text-muted'>PAN</label>
              <div className='col-6'>
                <span className='fw-bolder fs-6 text-dark text-hover-primary'>BQCPV9525L</span>
              </div>
            </div>
          </div>
        </div>
        <div className=''>
          <table className='table table-bordered mb-8 mt-8'>
            <thead>
              <tr>
                <th colSpan={2} className='fw-bold text-muted'>
                  Earnings
                </th>
                <th colSpan={2} className='fw-bold text-muted'>
                  Deductions
                </th>
              </tr>
              <tr>
                <th className='text-center'>Component</th>
                <th className='text-center'>Amount (AED)</th>
                <th className='text-center'>Component</th>
                <th className='text-center'>Amount (AED)</th>
              </tr>
            </thead>
            <tbody>
              {earnings.map((earning, index) => (
                <tr key={`earning_${earning.component_id}`}>
                  <td>{earning.component_name}</td>
                  <td className='text-end'>{earning.amount.toFixed(2)}</td>
                  {index < deductions.length ? (
                    <>
                      <td className='d-flex flex-column'>
                        <p className='mb-0'>{deductions[index].component_name}</p>
                        <span>Ref: 23AJ0000123</span>
                      </td>
                      <td className='text-end'>{deductions[index].amount.toFixed(2)}</td>
                    </>
                  ) : (
                    <>
                      <td colSpan={2}></td>
                    </>
                  )}
                </tr>
              ))}
              {deductions.slice(earnings.length).map((deduction, index) => (
                <tr key={`deduction_${deduction.component_id}`}>
                  <td colSpan={2}></td>
                  <td>{deduction.component_name}</td>
                  <td className='text-end'>{deduction.amount.toFixed(2)}</td>
                </tr>
              ))}
              <tr>
                <td>
                  <p></p>
                </td>
                <td>
                  <p></p>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th>Total Earnings (TE)</th>
                <td className='text-end'>{totalEarnings.toFixed(2)}</td>
              </tr>
              <tr>
                <th>Total Reimbursements (TR)</th>
                <td className='text-end'>{totalEarnings.toFixed(2)}</td>
                <th>Total Deductions (TD)</th>
                <td className='text-end'>{totalDeductions.toFixed(2)}</td>
              </tr>
              <tr>
                <th>Total Credit Adjustments (TCA)</th>
                <td className='text-end'>{netPay.toFixed(2)}</td>
                <th>Total Debit Adjustments (TDA)</th>
                <td className='text-end'>{totalLoanAdvances.toFixed(2)}</td>
              </tr>
            </tfoot>
          </table>
          <table className='table table-bordered'>
            <tbody>
              <tr>
                <td>Net Pay (TE - TD + TRA)</td>
                <td>AED 1,26,225</td>
              </tr>
              <tr>
                <td>Net Pay (in words)</td>
                <td>
                  One-hundred and twenty-six thousand two-hundred and twenty-five United Arab
                  Emirates Dirhams only.
                </td>
              </tr>
            </tbody>
          </table>
          <p className='mb-8'>
            Reimbursements, adjustments, loan, salary advance (if applicable) are available in
            subsequent page(S) as annexure.
          </p>
        </div>
        <hr />
        <h2 className='text-center fs-6 mb-4'>
          This is an electronically issued document and requires no signature.
        </h2>
      </div>
      <div className={`card-body shadow mb-4 pb-2`} style={{pageBreakBefore: 'always'}}>
        <div className='d-flex mt-2 align-items-start'>
          <div className='symbol symbol-50px symbol-lg-90px symbol-fixed position-relative'>
            <img
              src={brand_logo_url ? `${brand_logo_url}?v=${Date.now()}` : defaultProfileImage}
              alt='Image'
            />
          </div>
          <div className='ms-4'>
            <p className='fs-4 text-hover-primary fw-bolder default-cursor mb-0'>
              {locationName?.organization_name}
            </p>
            <p className='fs-4 d-flex align-items-center text-gray-400 text-hover-primary default-cursor mb-0'>
              {locationName?.address_line1} {locationName?.country_name}
            </p>
          </div>
          <div className='ms-auto'>
            <p className='fs-4 text-hover-primary  default-cursor mb-0 text-gray-400'>
              Payslip For the Month
            </p>
            <p className='fs-4 text-hover-primary fw-bolder default-cursor mb-0'>{month}</p>
          </div>
        </div>
        <hr />
        <div className='fw-bold mb-5 fs-4'>Employee Summary</div>
        <div className='d-flex flex-row justify-content-between'>
          <section className='employee-summary text-dark col-xl-6'>
            <div className='row mb-5'>
              <label className='col-6 fw-bold text-muted'>Employee Name</label>
              <div className='col-6'>
                <span className='fw-bolder fs-6 text-dark text-hover-primary'>John Doe</span>
              </div>
            </div>
            <div className='row mb-5'>
              <label className='col-6 fw-bold text-muted'>Designation</label>
              <div className='col-6'>
                <span className='fw-bolder fs-6 text-dark text-hover-primary'>
                  Jr.Software Engineer
                </span>
              </div>
            </div>
            <div className='row mb-5'>
              <label className='col-6 fw-bold text-muted'>Employee ID</label>
              <div className='col-6'>
                <span className='fw-bolder fs-6 text-dark text-hover-primary'>IN000020</span>
              </div>
            </div>
          </section>
        </div>
        <div className='fw-bold mb-5 fs-4 text-center'>
          <span style={{borderBottom: '1px solid black'}}>Annexure</span>
        </div>
        <div className='d-flex col-12'>
          <div className='col-6'>
            <div className='fw-bold text-muted mb-4'>Employee Reimbursements</div>
            <table className='table table-bordered'>
              <thead>
                <tr>
                  <th className='text-center'>Reimbursement</th>
                  <th className='text-center'>Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className='d-flex flex-column'>
                    <p className='mb-0'>Medical Expense</p>
                    <span>Ref: 23EXP00101231 dt. 12-Oct-2023</span>
                  </td>
                  <td className='text-end'>1,000</td>
                </tr>
                <tr>
                  <td className='d-flex flex-column'>
                    <p className='mb-0'>Marketing Expense</p>
                    <span>Ref: 23EXP00101245 dt. 15-Oct-2023</span>
                  </td>
                  <td className='text-end'>350</td>
                </tr>
                <tr>
                  <td>
                    <p></p>
                  </td>
                  <td>
                    <p></p>
                  </td>
                </tr>
                <tr>
                  <th>Total Reimbursement (TR)</th>
                  <td className='text-end'>1,350</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className='d-flex col-12'>
          <div className='col-6'>
            <div className='fw-bold text-muted mb-4'>Credit Adjustments</div>
            <table className='table table-bordered'>
              <thead>
                <tr>
                  <th className='text-center'>Reimbursement</th>
                  <th className='text-center'>Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className='d-flex flex-column'>
                    <p className='mb-0'>Revised Salary Adjustment for Oct</p>
                    <span>Ref: 23ADJ0000124</span>
                  </td>
                  <td className='text-end'>1,525</td>
                </tr>

                <tr>
                  <td>
                    <p></p>
                  </td>
                  <td>
                    <p></p>
                  </td>
                </tr>
                <tr>
                  <th>Total Credit Adjustments (TCA)</th>
                  <td className='text-end'>1,525</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className='col-6'>
            <div className='fw-bold text-muted mb-4'>Debit Adjustments</div>
            <table className='table table-bordered'>
              <thead>
                <tr>
                  <th className='text-center'>Adjustment</th>
                  <th className='text-center'>Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className='d-flex flex-column'>
                    <p className='mb-0'>LOP Adjustment for 23 Oct</p>
                    <span>Ref: 23ADJ0000123</span>
                  </td>
                  <td className='text-end'>650</td>
                </tr>
                <tr>
                  <td>
                    <p></p>
                  </td>
                  <td>
                    <p></p>
                  </td>
                </tr>
                <tr>
                  <th>Total Debit Adjustments (TDA)</th>
                  <td className='text-end'>650</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className=''>
          <div className='fw-bold text-muted mb-4'>Loans</div>
          <table className='table table-bordered'>
            <thead>
              <tr>
                <th className='text-center'>Loan ID</th>
                <th className='text-center'>Instalment</th>
                <th className='text-center'>Loan Amount(AED)</th>
                <th className='text-center'>Paid Amount(AED)</th>
                <th className='text-center'>Outstaning(AED)</th>
                <th className='text-center'>Current Instalment(AED)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className='text-center'>23L0N00101231</td>
                <td className='text-center'>4 of 10</td>
                <td className='text-center'>10,000</td>
                <td className='text-center'>3,000</td>
                <td className='text-center'>7,000</td>
                <td className='text-center'>1,000</td>
              </tr>
            </tbody>
          </table>
        </div>
        <hr />
        <h2 className='text-center fs-6 mb-4'>
          This is an electronically issued document and requires no signature.
        </h2>
        <div className='fw-bold fs-4 text-center'>****</div>
      </div>
    </div>
  )
}
export {PaySlipPDF}
