import React, {useEffect, useState} from 'react'
import {KTIcon} from '../../../../../../_metronic/helpers'
import {useIntl} from 'react-intl'
import Swal from 'sweetalert2'
import * as MetronicI18n from '../../../../../../_metronic/i18n/Metronici18n'
import {baseUrl} from '../../../../core'
import secureLocalStorage from 'react-secure-storage'
import usePostApi from '../../../../custom_hooks/usePostApi'
const FontPreview = (value) => {
  const {props} = value
  const {selectedFont, id, locale} = props
  const userInfo = secureLocalStorage.getItem('user_info')
  const {organization_id} = userInfo || {}
  const {execute} = usePostApi()
  const [fontSize, setFontSize] = useState(16) // Initial font size
  const [inputText, setInputText] = useState('The quick brown fox jumps over the lazy dog.')
  const intl = useIntl()
  const selectedLang = MetronicI18n.getLanguage()
  useEffect(() => {
    addPreviewFont(selectedFont)
  })

  const addPreviewFont = (fontName) => {
    if (typeof fontName != 'undefined') {
      let fontURL = 'https://fonts.googleapis.com/css?family=' + fontName.replaceAll(' ', '+')
      let newCssImportElement = document.createElement('link')
      newCssImportElement.href = fontURL
      newCssImportElement.rel = 'stylesheet'
      document.getElementsByTagName('head')[0].appendChild(newCssImportElement)
    }
  }

  const handleSubmit = async () => {
    const url = `${baseUrl}/pref/font/${id}`
    try {
      const requestData = {
        organization_id: organization_id,
        default_font: selectedFont,
      }
      await execute(url, 'PATCH', requestData, id)
      if (selectedLang === locale) {
        localStorage.setItem('themeFont', selectedFont)
        Swal.fire({
          title: 'Font Preferences Edited Successfully',
          text: intl.formatMessage({id: 'LABEL.CHANGES_SAVED'}),
          icon: 'success',
          showConfirmButton: true,
          confirmButtonText: 'OK',
          customClass: {
            confirmButton: 'btn btn-light-primary btn-sm',
          },
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload()
          }
        })
      }
    } catch (error) {
      console.error(error)
      Swal.fire({
        icon: 'error',
        title: intl.formatMessage({id: 'LABEL.OOPS'}),
        text: intl.formatMessage({id: 'LABEL.SOMETHING_WRONG'}),
      })
    }
  }

  return (
    <div className='container'>
      <div className='card shadow-none rounded-0'>
        <div className='card-header' id='side_activities_header'>
          <h3 className='card-title fw-bolder text-dark'>
            {intl.formatMessage({id: 'LABEL.FONT_PREVIEW'})}
          </h3>
          <div className='card-toolbar'>
            <button
              type='button'
              className='btn btn-sm btn-icon btn-active-light-primary me-n5'
              id='side_activities_close'
            >
              <KTIcon iconName='cross' className='fs-1' />
            </button>
          </div>
        </div>
        <div className='card-body position-relative text-center' id='side_activities_body'>
          <input
            type='text'
            className='p-2 form-select-white w-400px h-80px border border-secondary rounded custom-input form-control'
            placeholder='Type here to preview text'
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
          />
          <div className='d-flex flex-row justify-content-start my-4'>
            <div className='fontsize-wrapper my-4 mx-4'>
              <span className='fontsize-label'>{fontSize}px</span>
            </div>
            <div className='my-4'>
              <input
                type='range'
                min='12'
                max='36'
                value={fontSize}
                onChange={(e) => setFontSize(Number(e.target.value))}
                className='form-range'
              />
            </div>
            <div className='my-4 mx-8'>
              <p className='text-dark fw-bold text-hover-primary fs-6'>{selectedFont}</p>
            </div>
          </div>

          <div>
            <div className='my-4 w-400px mx-4 text-start'>
              <div className='border-top my-4' style={{height: '2px'}}></div>
              <p style={{fontSize: `${fontSize}px`, fontFamily: selectedFont, fontWeight: 'bold'}}>
                {inputText}
              </p>
              <div className='border-bottom' style={{height: '2px'}}></div>
            </div>
            <div className='my-4 w-400px mx-4 text-start'>
              <div className='border-top my-4' style={{height: '1px'}}></div>
              <p style={{fontSize: `${fontSize}px`, fontFamily: selectedFont, fontStyle: 'normal'}}>
                {inputText}
              </p>
              <div className='border-bottom' style={{height: '1px'}}></div>
            </div>
            <div className='my-4 w-400px mx-4 text-start'>
              <div className='border-top my-4' style={{height: '1px'}}></div>
              <p style={{fontSize: `${fontSize}px`, fontFamily: selectedFont, fontStyle: 'italic'}}>
                {inputText}
              </p>
              <div className='border-bottom' style={{height: '1px'}}></div>
            </div>
          </div>
        </div>
        <div className='card-footer py-5 text-center' id='side_activities_footer'>
          <button className='btn btn-sm btn-primary m-2' onClick={handleSubmit}>
            {intl.formatMessage({id: 'LABEL.APPLY'})}
          </button>
          <button className='btn btn-sm btn-secondary m-2' id='side_activities_close'>
            {intl.formatMessage({id: 'LABEL.CANCEL'})}
          </button>
        </div>
      </div>
    </div>
  )
}

export {FontPreview}
