import React from 'react'
import TeamTripsTable from './TeamTripsTable.jsx'

function TeamTrips() {
  return (
    <>
      <TeamTripsTable />
    </>
  )
}

export default TeamTrips
