import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PaySlipPDF} from './PaySlipPDF'
import {KTIcon, checkIsActive} from '../../../../_metronic/helpers'
import {Link, useLocation} from 'react-router-dom'
import clsx from 'clsx'
import PayslipData from './Payslip.json'
import DateChart from './DateChart'
import Payslip from './Payslip'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import html2pdf from 'html2pdf.js' // Import the library

import Swal from 'sweetalert2'
function MyPaySlips() {
  const intl = useIntl()
  const [showPDF, setShowPDF] = useState(false)
  const [selectedMonthData, setSelectedMonthData] = useState(null)
  const {pathname} = useLocation()
  useEffect(() => {
    const parts = pathname.split('/')
    const formattedMonth = parts[parts.length - 1]
    const matchingMonthData = PayslipData.find(
      (item) => item.month.replace(/\s+/g, '') === formattedMonth
    )
    setSelectedMonthData(matchingMonthData)
  }, [pathname])
  const handleDownloadPDF = async () => {
    setShowPDF(true)
    try {
      const result = await Swal.fire({
        title: 'Confirm Download',
        text: 'Payslip download will begin shortly, please do not close or refresh this page.',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: intl.formatMessage({id: 'BTN.CONFIRM'}),
        cancelButtonText: intl.formatMessage({id: 'LABEL.CANCEL'}),
        customClass: {
          confirmButton: 'btn btn-light-primary btn-sm',
          cancelButton: 'btn btn-sm btn-secondary',
        },
      })
      if (result.isConfirmed) {
        const element = document.getElementById('pdf-container')
        try {
          if (element) {
            const pdfOptions = {
              margin: 10,
              filename: `Payslip - November2023.pdf`,
              html2canvas: {scale: 5},
              jsPDF: {unit: 'mm', format: 'a4', orientation: 'portrait'},
            }
            html2pdf()
              .from(element)
              .set(pdfOptions)
              .output('blob')
              .then((blob) => {
                const url = URL.createObjectURL(blob)
                const a = document.createElement('a')
                a.href = url
                a.download = pdfOptions.filename
                a.click()
                URL.revokeObjectURL(url)
              })

            Swal.fire({
              title: 'Payslip Downloaded',
              text: 'PDF downloaded successfully.',
              icon: 'success',
              showConfirmButton: true,
              confirmButtonText: 'OK',
              customClass: {
                confirmButton: 'btn btn-light-primary btn-sm',
              },
            })
          }
        } catch (error) {
          console.error('Error generating PDF:', error)
        }
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: intl.formatMessage({id: 'LABEL.OOPS'}),
        text: intl.formatMessage({id: 'LABEL.SOMETHING_WRONG'}),
      })
      console.error('Error updating data:', error)
    }
  }
  return (
    <>
      <div className='d-flex flex-wrap flex-stack mb-6'>
        <h3 className='fw-bolder my-2 mx-2'>Payslip</h3>
        <div className='d-flex flex-wrap my-2'>
          <Link
            className='btn btn-secondary btn-sm me-4'
            to='/payroll-management/paypage-bar/payroll-batch'
          >
            <KTIcon iconName='black-left' className='fs-4' />
            {intl.formatMessage({id: 'AUTH.GENERAL.BACK_BUTTON'})}
          </Link>
        </div>
      </div>
      <div className='row g-5 g-xxl-8'>
        <div className='col-xl-3'>
          <div className={`card mb-4 p-2`}>
            <div className='d-flex align-items-center m-4'>
              <span className='fw-bold text-gray-700 pe-4'>Financial Year :</span>
              <select
                className='form-select form-select-sm w-md-125px'
                data-control='select2'
                data-placeholder='Latest'
                data-hide-search='true'
              >
                <option value='1'>2023 - 24</option>
              </select>
            </div>
            {PayslipData.map((item, index) => {
              const formattedMonth = item.month.replace(/\s+/g, '')
              return (
                <div className='' key={index}>
                  <Link
                    className={clsx(
                      'menu-item ps-4 without-sub btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold flex-column ps-0',
                      {
                        active: checkIsActive(
                          pathname,
                          `/payroll-management/paypage-bar/my-payslip/${formattedMonth}`
                        ),
                      }
                    )}
                    to={`/payroll-management/paypage-bar/my-payslip/${formattedMonth}`}
                  >
                    <div className='fw-bold text-start'>{item.month}</div>
                    <p className='text-muted fw-bold text-start'>{`Salary: ${item.take_home}`}</p>
                  </Link>
                </div>
              )
            })}
          </div>
        </div>
        <div className='col-xl-9'>
          <div className='card'>
            <div className='card-header'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold fs-3 mb-1'>
                  {`Payslip for ${selectedMonthData?.month}`}
                </span>
              </h3>
            </div>
            <div className='card-body'>
              <div className='row'>
                <div className='col-3'>
                  <DateChart value={selectedMonthData?.month} data={selectedMonthData} />
                </div>
                <div className='col-5 d-flex'>
                  <div className='d-flex justify-content-center flex-column'>
                    <h3 className='card-label fw-bold fs-3 mb-4'>{`Salary: ${selectedMonthData?.take_home}`}</h3>
                    <div className='d-flex flex-row'>
                      <div className='d-flex'>
                        <span className='bullet bullet-vertical h-50px bg-success'></span>
                        <div className='flex-grow-1 ms-4'>
                          <p className='text-gray-800 text-hover-primary fw-bold'>Earnings</p>
                          <span className='text-muted fw-semibold d-block'>
                            {selectedMonthData?.gross_pay}
                          </span>
                        </div>
                      </div>
                      <div className='d-flex ms-6'>
                        <span className='bullet bullet-vertical h-50px bg-info'></span>
                        <div className='flex-grow-1 ms-4'>
                          <p className='text-gray-800 text-hover-primary fw-bold'>Reimbursements</p>
                          <span className='text-muted fw-semibold d-block'>
                            {selectedMonthData?.reimbursements}
                          </span>
                        </div>
                      </div>
                      <div className='d-flex ms-6'>
                        <span className='bullet bullet-vertical h-50px bg-danger'></span>
                        <div className='flex-grow-1 ms-4'>
                          <p className='text-gray-800 text-hover-primary fw-bold'>Deductions</p>
                          <span className='text-muted fw-semibold d-block'>
                            {selectedMonthData?.deductions}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='d-flex justify-content-center flex-column col-2 align-item-right ms-auto'>
                  <button
                    type='button'
                    className='btn btn-sm btn-light-primary btn-ripple'
                    onClick={handleDownloadPDF}
                  >
                    <KTIcon iconName='cloud-download' className='fs-7' />
                    Download PDF
                  </button>
                </div>
              </div>
              <hr />
              <div className='card-header p-0'>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bold fs-3 mb-1'>Payslip Details</span>
                </h3>
                <div className='card-toolbar'>
                  <span className={`me-4 fw-bold`}>Show in PDF mode</span>
                  <div className='form-floating flex-fill'>
                    <div className='form-check form-check-solid form-switch mb-0'>
                      <input
                        className='form-check-input w-40px h-20px'
                        type='checkbox'
                        name='status'
                        id='toggle'
                        checked={showPDF}
                        onChange={() => setShowPDF(!showPDF)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {!showPDF ? <Payslip /> : <PaySlipPDF data={selectedMonthData} />}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MyPaySlips
