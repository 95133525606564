import {useState} from 'react'
import TravelNavBar from './TravelNavBar'

import {useLocation} from 'react-router-dom'

import {PageLink, PageTitle} from '../../../_metronic/layout/core'

import Context from '../../../_metronic/partials/layout/activity-drawer/context'
import {useIntl} from 'react-intl'
import MyTripsTable from './my_trips/MyTripsTable'
import TeamTrips from './team_trips/TeamTrips'

const TravelRoutes = () => {
  const intl = useIntl()
  const TravelBreadCrumbs = [
    {
      title: intl.formatMessage({id: 'MENU.TRAVEL'}),
      path: '/travel/my-travel',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]
  const {pathname} = useLocation()

  const [SideDrawerId, updateId] = useState('0')

  const switchMethod = () => {
    switch (pathname) {
      case '/travel/my-travel':
        return (
          <>
            <PageTitle breadcrumbs={TravelBreadCrumbs}>
              {intl.formatMessage({id: 'MENU.TRAVEL.MY_TRAVEL'})}
            </PageTitle>
            <MyTripsTable />
          </>
        )
      case '/travel/team-travel':
        return (
          <>
            <PageTitle breadcrumbs={TravelBreadCrumbs}>
              {intl.formatMessage({id: 'MENU.TRAVEL.TEAM_TRAVEL'})}
            </PageTitle>
            <TeamTrips />
          </>
        )

      default:
        return (
          <>
            <PageTitle breadcrumbs={TravelBreadCrumbs}>
              {' '}
              {intl.formatMessage({id: 'MENU.TRAVEL.MY_TRAVEL'})}
            </PageTitle>
            <MyTripsTable />
          </>
        )
    }
  }

  const UpdatingId = (id) => {
    updateId(id)
  }

  return (
    <>
      <Context.Provider value={{id: SideDrawerId, updateId: UpdatingId}}>
        <TravelNavBar />
        {switchMethod()}
      </Context.Provider>
    </>
  )
}

export default TravelRoutes
