import {FC} from 'react'
import {KTIcon} from '../../../helpers'

const ActivityDrawer: FC = () => (
  <div className='card shadow-none rounded-0'>
    <div
      id='kt_activities'
      className='bg-body'
      data-kt-drawer='true'
      data-kt-drawer-name='activities'
      data-kt-drawer-activate='true'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'300px', 'lg': '400px'}"
      data-kt-drawer-direction='end'
      data-kt-drawer-toggle='#kt_activities_toggle'
      data-kt-drawer-close='#kt_activities_close'
    >
      <div className='w-100'>
        <div className='card-header'>
          <h3 className='card-title fw-bolder text-dark'>Activity Logs</h3>
          <div className='card-toolbar'>
            <button
              type='button'
              className='btn btn-sm btn-icon btn-active-light-primary me-n5'
              id='employeeInfoDrawerClose'
            >
              <KTIcon iconName='cross' className='fs-1' />
            </button>
          </div>
        </div>
        <div className='m-8 mt-6 mb-7'></div>
      </div>
    </div>
  </div>
)

export {ActivityDrawer}
