import {useMemo} from 'react'
import {useTable} from 'react-table'
import {useIntl} from 'react-intl'
import PayrollData from './Payslip.json'
import {Chart} from './Chart'
import {Link} from 'react-router-dom'

const DataTable = ({columns, data}) => {
  const intl = useIntl()
  const activeTableInstance = useTable({columns, data: data})
  const activePage = activeTableInstance.rows

  return (
    <>
      <div className='d-flex flex-wrap flex-stack mb-6'>
        <h3 className='fw-bolder my-2'>Annual Data</h3>
        <div className='d-flex align-items-center'>
          <span className='fw-bold text-gray-700 pe-4'>Financial Year :</span>
          <select
            className='form-select form-select-sm w-md-125px'
            data-control='select2'
            data-placeholder='Latest'
            data-hide-search='true'
          >
            <option value='1'>2023 - 24</option>
            <option value='2'>2022 - 23</option>
            <option value='3'>2021 - 22</option>
          </select>
        </div>
      </div>
      {false ? (
        <div className='card h-250px mb-8'>
          <div className='m-auto d-flex flex-column align-items-center'>
            <div className='spinner-border spinner-primary mr-15'></div>
          </div>
        </div>
      ) : data.length > 0 ? (
        <div className='card mb-8'>
          <div className='card-body overflow-y-auto'>
            <table className='table table-row-dashed align-middle table-row-gray-300'>
              <thead
              // style={{
              //   background: '#ffff',
              //   position: 'sticky',
              //   top: 0,
              //   zIndex: 1,
              // }}
              >
                <tr className='fw-bold text-muted'>
                  <th className='min-w-120px'>Month</th>
                  <th className='min-w-120px'>Gross Pay</th>
                  <th className='min-w-120px'>Reimbursements</th>
                  <th className='min-w-120px'>Deductions</th>
                  <th className='min-w-120px'>Salary</th>
                  <th className='min-w-120px'>Payslip</th>
                </tr>
              </thead>
              <tbody>
                {activePage.length > 0 ? (
                  activePage.map((row) => {
                    activeTableInstance.prepareRow(row)
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => (
                          <td className=' ' {...cell.getCellProps()}>
                            {cell.render('Cell')}
                          </td>
                        ))}
                      </tr>
                    )
                  })
                ) : (
                  <tr>
                    <td colSpan={7}>
                      <p className='text-dark fw-bold text-hover-primary d-block fs-6 m-0 my-5'>
                        {intl.formatMessage({id: 'NO_RESULTS_FOUND_MSG'})}
                      </p>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div className='card h-250px mb-8'>
          <div className='m-auto d-flex flex-column align-items-center'>
            <h1 className='mb-5'>No Data Found</h1>
          </div>
        </div>
      )}
      <Chart className='card-xl-stretch mb-xl-8' data={PayrollData} />
    </>
  )
}

const AnnualEarnings = () => {
  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'month',
        Cell: ({row}) => {
          return (
            <div className='d-flex align-items-center'>
              <div className='d-flex justify-content-start flex-column'>
                <p className='text-dark fw-bold text-hover-primary fs-6 default-cursor'>
                  {row.original.month}
                </p>
              </div>
            </div>
          )
        },
        disableSortBy: true,
      },
      {
        Header: 'Description',
        accessor: 'gross_pay',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark fw-bold text-hover-primary d-block fs-6 default-cursor'>
                {row.original.gross_pay}
              </p>
            </div>
          )
        },
      },
      {
        Header: 'Reimbursements',
        accessor: 'reimbursements',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark fw-bold text-hover-primary d-block fs-6 default-cursor'>
                {row.original.reimbursements}
              </p>
            </div>
          )
        },
      },
      {
        Header: 'Deductions',
        accessor: 'deductions',
        Cell: ({row}) => {
          return (
            <p className='text-dark fw-bold text-hover-primary d-block fs-6 default-cursor'>
              {row.original.deductions}
            </p>
          )
        },
      },
      {
        Header: 'Take_home',
        accessor: 'take_home',
        Cell: ({row}) => {
          return (
            <p className='text-dark fw-bold text-hover-primary d-block fs-6 default-cursor'>
              {row.original.take_home}
            </p>
          )
        },
      },
      {
        Header: 'Action',
        accessor: 'action',
        Cell: ({row}) => {
          const formattedMonth = row.original.month.replace(/\s+/g, '')
          return (
            <Link
              to={`/payroll-management/paypage-bar/my-payslip/${formattedMonth}`}
              className='btn btn-sm btn-light-primary btn-ripple'
            >
              View
            </Link>
          )
        },
      },
    ],
    []
  )

  return <DataTable columns={columns} data={PayrollData ? PayrollData : []} />
}

export default AnnualEarnings
