//businessBlock
import {useMemo, useState} from 'react'
import {useTable, usePagination} from 'react-table'
import {Pagination} from 'react-bootstrap'
import {KTIcon, toAbsoluteUrl} from '../../../../_metronic/helpers'
import Context from '../../../../_metronic/partials/layout/activity-drawer/context'
import {SideDrawer} from '../../../../_metronic/partials/layout/activity-drawer/Side-drawer'
import {useIntl} from 'react-intl'
import usePageTitle from '../../custom_hooks/usePageTitle'
import useApiCall from '../../custom_hooks/useApiCall'
import {Link} from 'react-router-dom'
const DataTable = ({columns, data, loading}) => {
  const [inputVal, setInputVal] = useState('')
  const intl = useIntl()
  usePageTitle(intl.formatMessage({id: 'MENU.ADMINISTRATION.COUNTRIES'}))
  const filteredData = useMemo(() => {
    const sortedData = data
      .filter((each) => each.country_name.toLowerCase().includes(inputVal.toLowerCase()))
      .sort((a, b) => a.country_name.localeCompare(b.country_name))

    return sortedData
  }, [data, inputVal])
  const activeTableInstance = useTable({columns, data: filteredData}, usePagination)
  // Extract required values from table instances
  const activePage = activeTableInstance.page
  const activePageCount = activeTableInstance.pageCount
  const activePageIndex = activeTableInstance.state.pageIndex
  const activePageSize = activeTableInstance.state.pageSize

  // Handle pagination for active dataset
  const gotoActivePage = (pageIndex) => {
    activeTableInstance.gotoPage(pageIndex)
  }
  const setActivePageSize = (pageSize) => {
    activeTableInstance.setPageSize(pageSize)
  }
  const pageOptions = [5, 10, 20, 30, 40, 50]
  // Render the component
  return (
    <Context.Consumer>
      {(value) => {
        const {id} = value
        return (
          <>
            <div className='d-flex flex-wrap flex-stack mb-6'>
              <h3 className='fw-bolder my-2'>
                {intl.formatMessage({id: 'MENU.ADMINISTRATION.COUNTRIES'})}
              </h3>
              <div className='d-flex flex-wrap flex-stack justify-content-end mb-0'>
                <div className='d-flex'>
                  <div className='d-flex align-items-center position-relative me-4 mb-0'>
                    <KTIcon iconName='magnifier' className='fs-3 position-absolute ms-3' />
                    <input
                      type='text'
                      id='kt_filter_search'
                      className='form-control form-control-white form-control-sm w-150px ps-9'
                      placeholder={intl.formatMessage({id: 'LABEL.SEARCH'})}
                      value={inputVal}
                      onChange={(e) => setInputVal(e.target.value)}
                      autoComplete='off'
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='row g-5 g-xxl-8'>
              <div className='col-xl-12'>
                <>
                  {loading ? (
                    <div className='card h-250px'>
                      <div className='m-auto d-flex flex-column align-items-center'>
                        <div className='spinner-border spinner-primary mr-15'></div>
                      </div>
                    </div>
                  ) : data.length > 0 ? (
                    <div className='card'>
                      <div
                        className='card-body tab-pane fade show active table-responsive ms-4 mt-0'
                        id='kt_table_widget_4_tab_1'
                      >
                        <table
                          className='table table-row-dashed align-middle table-row-gray-300'
                          {...activeTableInstance.getTableProps()}
                        >
                          <thead>
                            <tr className='fw-bold text-muted'>
                              <th className='min-w-140px'>
                                {intl.formatMessage({id: 'LABEL.FLAG'})}
                              </th>
                              <th className='min-w-140px'>
                                {intl.formatMessage({id: 'LABEL.NAME'})}
                              </th>
                              <th className='min-w-140px'>
                                {intl.formatMessage({id: 'LABEL.PHONE_PREFIX'})}
                              </th>
                              <th className='min-w-120px'>
                                {intl.formatMessage({id: 'LABEL.ISO_CODE-1'})}
                              </th>
                              <th className='min-w-120px'>
                                {intl.formatMessage({id: 'LABEL.ISO_CODE-2'})}
                              </th>
                              <th className='min-w-140px'>
                                {intl.formatMessage({id: 'LABEL.STATES'})}
                              </th>
                            </tr>
                          </thead>
                          <tbody {...activeTableInstance.getTableBodyProps()}>
                            {activePage.length > 0 ? (
                              activePage.map((row) => {
                                activeTableInstance.prepareRow(row)
                                return (
                                  <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => (
                                      <td className=' ' {...cell.getCellProps()}>
                                        {cell.render('Cell')}
                                      </td>
                                    ))}
                                  </tr>
                                )
                              })
                            ) : (
                              <tr>
                                <td>
                                  <p></p>
                                </td>
                                <td className='card'>
                                  <p className='text-dark fw-bold text-hover-primary d-block fs-6 m-0 my-5'>
                                    {intl.formatMessage({id: 'NO_RESULTS_FOUND_MSG'})}
                                  </p>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                        {data.length > 10 && (
                          <Pagination>
                            <Pagination.First
                              onClick={() => gotoActivePage(0)}
                              disabled={!activeTableInstance.canPreviousPage}
                            />
                            <Pagination.Prev
                              onClick={activeTableInstance.previousPage}
                              disabled={!activeTableInstance.canPreviousPage}
                            />
                            {[...Array(activePageCount)].map((_, i) => {
                              const pageToDisplay = i + 1
                              const isFirstPage = i === 0
                              const isLastPage = i === activePageCount - 1

                              if (
                                isFirstPage ||
                                isLastPage ||
                                pageToDisplay === activePageIndex + 1
                              ) {
                                return (
                                  <Pagination.Item
                                    key={i}
                                    active={pageToDisplay === activePageIndex + 1}
                                    onClick={() => gotoActivePage(i)}
                                  >
                                    {pageToDisplay}
                                  </Pagination.Item>
                                )
                              }
                              if (isFirstPage && pageToDisplay === 2) {
                                return <Pagination.Ellipsis key={`ellipsis-${i}`} />
                              }

                              if (isLastPage && pageToDisplay === activePageCount) {
                                return <Pagination.Ellipsis key={`ellipsis-${i}`} />
                              }

                              return null
                            })}
                            <Pagination.Next
                              onClick={activeTableInstance.nextPage}
                              disabled={!activeTableInstance.canNextPage}
                            />
                            <Pagination.Last
                              onClick={() => gotoActivePage(activePageCount - 1)}
                              disabled={!activeTableInstance.canNextPage}
                            />
                            <div className='d-flex align-items-center mx-5'>
                              <span className='me-2'>{intl.formatMessage({id: 'LABEL.SHOW'})}</span>
                              <select
                                className='form-select form-select-solid fw-bold w-75px me-2'
                                value={activePageSize}
                                onChange={(e) => setActivePageSize(Number(e.target.value))}
                              >
                                {pageOptions.map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </Pagination>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className='card h-250px'>
                      <div className='m-auto d-flex flex-column align-items-center'>
                        <h1 className='mb-5'>No countries found.</h1>
                      </div>
                    </div>
                  )}
                  <SideDrawer value={id} />
                </>
              </div>
            </div>
          </>
        )
      }}
    </Context.Consumer>
  )
}

const Countries = () => {
  const {data: TableData, isLoading} = useApiCall(`/country`)
  const columns = useMemo(
    () => [
      {
        Header: 'Flag',
        accessor: 'Flag',
        Cell: ({row}) => {
          const countryIsoCode = row.original.country_iso_code_2.toLowerCase()
          return (
            <div className='d-flex align-items-center'>
              <div className='symbol symbol-45px me-5'>
                <img
                  src={toAbsoluteUrl(`/media/ISOcodeFlags/${countryIsoCode}.svg`)}
                  alt='Loading'
                />
              </div>
            </div>
          )
        },
        disableSortBy: true,
      },
      {
        Header: 'Name',
        accessor: 'Name',
        Cell: ({row}) => {
          return (
            <div className='d-flex align-items-center'>
              <div className='d-flex justify-content-start flex-column'>
                <p className='text-dark fw-bold text-hover-primary fs-6 default-cursor'>
                  {row.original.country_name}
                </p>
              </div>
            </div>
          )
        },
        disableSortBy: true,
      },
      {
        Header: 'Phone Prefix',
        accessor: 'country_phone_prefix',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark fw-bold text-hover-primary d-block fs-6 default-cursor'>
                {row.original.country_phone_prefix}
              </p>
            </div>
          )
        },
      },
      {
        Header: 'ISO Code-1',
        accessor: 'country_iso_code_2',
        Cell: ({row}) => {
          return (
            <p className='text-dark fw-bold text-hover-primary d-block fs-6 default-cursor'>
              {row.original.country_iso_code_2}
            </p>
          )
        },
      },
      {
        Header: 'ISO Code-2',
        accessor: 'country_iso_code_3',
        Cell: ({row}) => {
          return (
            <p className='text-dark fw-bold text-hover-primary d-block fs-6 default-cursor'>
              {row.original.country_iso_code_3}
            </p>
          )
        },
      },
      {
        Header: 'States',
        accessor: 'Actions',
        Cell: ({row}) => {
          return (
            <Context.Consumer>
              {(value) => {
                const {updateId} = value
                return (
                  <Link to='/organization-configuration/states'>
                    <div
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                      onClick={() => {
                        updateId({
                          id: row.original.country_id,
                          name: row.original.country_name,
                        })
                      }}
                    >
                      <KTIcon iconName='black-right' className='fs-2x' />
                    </div>
                  </Link>
                )
              }}
            </Context.Consumer>
          )
        },
      },
    ],
    []
  )

  return <DataTable columns={columns} data={TableData ? TableData : []} loading={isLoading} />
}

export default Countries
