import {useEffect, useMemo, useState} from 'react'
import {useTable, usePagination} from 'react-table'
import {Pagination} from 'react-bootstrap'
import {KTIcon} from '../../../../../_metronic/helpers'
import Context from '../../../../../_metronic/partials/layout/activity-drawer/context'
import {SideDrawer} from '../../../../../_metronic/partials/layout/activity-drawer/Side-drawer'
import {useIntl} from 'react-intl'
import {defaultProfileImage} from '../../../core'
import secureLocalStorage from 'react-secure-storage'

import usePageTitle from '../../../custom_hooks/usePageTitle'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import DateFormatter from '../../../DateFormatter'
import useApiCall from '../../../custom_hooks/useApiCall'
import {useAuth} from '../../../auth'
import DatePicker from 'react-datepicker'
import '../../../DatepickerStyles.css'
import 'react-datepicker/dist/react-datepicker.css'
import {useThemeMode} from '../../../../../_metronic/partials'
function range(start, end, step = 1) {
  const result = []
  for (let i = start; i <= end; i += step) {
    result.push(i)
  }
  return result
}

const DataTable = ({columns, data, loading}) => {
  const [inputVal, setInputVal] = useState('')
  const [selectedDate, setSelectedDate] = useState(null)
  const {mode} = useThemeMode()
  const currentYear = new Date().getFullYear()
  const years = range(1990, currentYear + 1, 1)

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  const intl = useIntl()
  usePageTitle(intl.formatMessage({id: 'LABEL.TIME_SHEET.SUB_ITEM2'}))
  // Filter data based on input value
  const filteredData = useMemo(() => {
    return data.filter(
      (each) =>
        each.activity.toLowerCase().includes(inputVal.toLowerCase()) ||
        each.employee_project_name.toLowerCase().includes(inputVal.toLowerCase())
    )
  }, [data, inputVal])

  const filteredDataByDate = useMemo(() => {
    if (!selectedDate) {
      return filteredData
      // No date selected, return the current filtered data
    }

    // Adjust the selected date to account for the time zone offset
    const selectedDateStr = new Date(
      selectedDate.getTime() - selectedDate.getTimezoneOffset() * 60000
    )
      .toISOString()
      .slice(0, 10) // Convert selected date to 'yyyy-MM-dd' format

    return filteredData.filter((each) => {
      return each.created_timestamp.slice(0, 10) === selectedDateStr
    })
  }, [selectedDate, filteredData])

  // Create separate datasets for active and inactive records

  // Table instances for active and inactive datasets
  const activeData = useMemo(() => {
    return filteredDataByDate.filter((timesheet) => timesheet.timesheet_status === 'Pending')
  }, [filteredDataByDate])

  const inactiveData = useMemo(() => {
    return filteredDataByDate.filter((timesheet) => timesheet.timesheet_status === 'Approved')
  }, [filteredDataByDate])

  // Table instances for active and inactive dataset
  const RejectedData = useMemo(() => {
    return filteredDataByDate?.filter((timesheet) => timesheet.timesheet_status === 'Rejected')
  }, [filteredDataByDate])

  // Table instances for active and inactive dataset
  const activeTableInstance = useTable({columns, data: activeData}, usePagination)
  const inactiveTableInstance = useTable({columns, data: inactiveData}, usePagination)
  const RejectedDataInstance = useTable({columns, data: RejectedData}, usePagination)

  // Extract required values from table instances
  const activePage = activeTableInstance.page
  const activePageCount = activeTableInstance.pageCount
  const activePageIndex = activeTableInstance.state.pageIndex
  const activePageSize = activeTableInstance.state.pageSize

  const inactivePage = inactiveTableInstance.page
  const inactivePageCount = inactiveTableInstance.pageCount
  const inactivePageIndex = inactiveTableInstance.state.pageIndex
  const inactivePageSize = inactiveTableInstance.state.pageSize

  const RejectedPage = RejectedDataInstance.page
  const RejectedPageCount = RejectedDataInstance.pageCount
  const RejectedPageIndex = RejectedDataInstance.state.pageIndex
  const RejectedPageSize = RejectedDataInstance.state.pageSize

  // Handle pagination for active dataset
  const gotoActivePage = (pageIndex) => {
    activeTableInstance.gotoPage(pageIndex)
  }

  const setActivePageSize = (pageSize) => {
    activeTableInstance.setPageSize(pageSize)
  }

  // Handle pagination for inactive dataset
  const gotoInactivePage = (pageIndex) => {
    inactiveTableInstance.gotoPage(pageIndex)
  }

  const setInactivePageSize = (pageSize) => {
    inactiveTableInstance.setPageSize(pageSize)
  }

  const gotoRejectedPage = (pageIndex) => {
    RejectedDataInstance.gotoPage(pageIndex)
  }

  const setRejectedPage = (pageSize) => {
    RejectedDataInstance.setPageSize(pageSize)
  }

  const pageOptions = [5, 10, 20, 30, 40, 50]

  return (
    <Context.Consumer>
      {(value) => {
        const {id, updateId} = value
        return (
          <>
            {loading ? (
              <div className='card h-250px'>
                <div className='m-auto d-flex flex-column align-items-center'>
                  <div className='spinner-border spinner-primary mr-15'></div>
                </div>
              </div>
            ) : data.length > 0 ? (
              <>
                <div className='card'>
                  <>
                    <div className='card-header border-0 pt-5'>
                      <div className='d-flex flex-wrap flex-stack'>
                        <div className='fw-bolder'>
                          <ul className='nav'>
                            <li className='nav-item'>
                              <a
                                className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary active fw-bold px-4 me-1 ms-0'
                                data-bs-toggle='tab'
                                href='#kt_table_widget_4_tab_1'
                              >
                                {intl.formatMessage({id: 'LABEL.PENDING'})}
                              </a>
                            </li>
                            <li className='nav-item'>
                              <a
                                className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1'
                                data-bs-toggle='tab'
                                href='#kt_table_widget_4_tab_2'
                              >
                                {intl.formatMessage({id: 'LABEL.APPROVED'})}
                              </a>
                            </li>
                            <li className='nav-item'>
                              <a
                                className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1'
                                data-bs-toggle='tab'
                                href='#kt_table_widget_4_tab_3'
                              >
                                {intl.formatMessage({id: 'LABEL.REJECTED'})}
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className='card-toolbar'>
                        <div className='d-flex align-items-center position-relative me-4'>
                          <KTIcon iconName='magnifier' className='fs-3 position-absolute ms-3' />
                          <input
                            type='search'
                            id='kt_filter_search'
                            className='form-control form-control-white form-control-sm w-150px ps-9'
                            placeholder={intl.formatMessage({id: 'LABEL.SEARCH'})}
                            value={inputVal}
                            onChange={(e) => setInputVal(e.target.value)}
                          />
                        </div>

                        {/* <button className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                          <KTIcon iconName='calendar' className='fs-2 m-0' />
                        </button> */}
                        <div
                          className={`react-datepicker-fullwidth-wrapper test me-4 ${
                            mode === 'dark' ? 'dark-mode' : 'light-mode'
                          }`}
                        >
                          <DatePicker
                            className='form-control form-control-solid'
                            selected={selectedDate}
                            isClearable
                            showYearDropdown
                            scrollableYearDropdown
                            dateFormat='dd/MM/yyyy'
                            onChange={(date) => setSelectedDate(date)}
                            placeholderText={intl.formatMessage({id: 'LABEL.SELECT_DATE'})}
                            showMonthDropdown
                            useShortMonthInDropdown
                          />
                        </div>
                      </div>
                    </div>
                    <div className='tab-content p-2 pt-0'>
                      <div
                        className='card-body tab-pane fade show active table-responsive'
                        id='kt_table_widget_4_tab_1'
                      >
                        <table
                          className='table table-row-dashed align-middle table-row-gray-300'
                          {...activeTableInstance.getTableProps()}
                        >
                          <thead>
                            <tr className='fw-bold text-muted'>
                              <th className='min-w-140px default-cursor'>
                                {' '}
                                {intl.formatMessage({id: 'LABEL.CANDIDATE'})}
                              </th>
                              <th className='min-w-140px default-cursor'>
                                {' '}
                                {intl.formatMessage({id: 'LABEL.PROJECT_NAME'})}
                              </th>

                              <th className='min-w-120px text-start default-cursor'>
                                {' '}
                                {intl.formatMessage({id: 'LABEL.ACTIVITY'})}
                              </th>
                              <th className='min-w-120px text-start default-cursor'>
                                {intl.formatMessage({id: 'LABEL.RAISED_DATE'})}
                              </th>

                              <th className='min-w-120px text-start default-cursor'>
                                {' '}
                                {intl.formatMessage({id: 'LABEL.START_DATE'})}
                              </th>

                              <th className='min-w-120px text-start default-cursor'>
                                {' '}
                                {intl.formatMessage({id: 'LABEL.END_DATE'})}
                              </th>

                              <th className='min-w-120px text-start default-cursor'>
                                {' '}
                                {intl.formatMessage({id: 'LABEL.START_TIME'})}
                              </th>

                              <th className='min-w-120px text-start default-cursor'>
                                {' '}
                                {intl.formatMessage({id: 'LABEL.END_TIME'})}
                              </th>

                              <th className='min-w-120px text-start default-cursor'>
                                {intl.formatMessage({id: 'LABEL.TOTAL_TIME'})}
                              </th>

                              {/* <th className='min-w-100px text-start default-cursor'>
                                {intl.formatMessage({id: 'LABEL.STATUS'})}
                              </th> */}

                              <th className='min-w-100px text-start default-cursor'>
                                {intl.formatMessage({id: 'MENU.ACTIONS'})}
                              </th>
                            </tr>
                          </thead>
                          <tbody {...activeTableInstance.getTableBodyProps()}>
                            {activePage.length > 0 ? (
                              activePage.map((row) => {
                                activeTableInstance.prepareRow(row)
                                return (
                                  <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => (
                                      <td className=' ' {...cell.getCellProps()}>
                                        {cell.render('Cell')}
                                      </td>
                                    ))}
                                  </tr>
                                )
                              })
                            ) : (
                              <tr>
                                <td>
                                  <p></p>
                                </td>
                                <td className='card'>
                                  <p className='text-dark fw-bold text-hover-primary default-cursor d-block fs-6 m-0 my-5'>
                                    {intl.formatMessage({id: 'NO_RESULTS_FOUND_MSG'})}
                                  </p>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>

                        {data.length > 10 && (
                          <Pagination>
                            <Pagination.First
                              onClick={() => gotoActivePage(0)}
                              disabled={!activeTableInstance.canPreviousPage}
                            />
                            <Pagination.Prev
                              onClick={activeTableInstance.previousPage}
                              disabled={!activeTableInstance.canPreviousPage}
                            />
                            {[...Array(activePageCount)].map((_, i) => (
                              <Pagination.Item
                                key={i}
                                active={i === activePageIndex}
                                onClick={() => gotoActivePage(i)}
                              >
                                {i + 1}
                              </Pagination.Item>
                            ))}
                            <Pagination.Next
                              onClick={activeTableInstance.nextPage}
                              disabled={!activeTableInstance.canNextPage}
                            />
                            <Pagination.Last
                              onClick={() => gotoActivePage(activePageCount - 1)}
                              disabled={!activeTableInstance.canNextPage}
                            />
                            <div className='d-flex align-items-center mx-5'>
                              <span className='me-2'>{intl.formatMessage({id: 'LABEL.SHOW'})}</span>
                              <select
                                className='form-select form-select-solid fw-bold w-75px me-2'
                                value={activePageSize}
                                onChange={(e) => setActivePageSize(Number(e.target.value))}
                              >
                                {pageOptions.map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </Pagination>
                        )}
                      </div>

                      <div
                        className='card-body tab-pane fade  table-responsive'
                        id='kt_table_widget_4_tab_2'
                      >
                        <table
                          className='table table-row-dashed align-middle table-row-gray-300'
                          {...inactiveTableInstance.getTableProps()}
                        >
                          <thead>
                            <tr className='fw-bold text-muted'>
                              <th className='min-w-140px default-cursor'>
                                {' '}
                                {intl.formatMessage({id: 'LABEL.CANDIDATE'})}
                              </th>
                              <th className='min-w-140px default-cursor'>
                                {' '}
                                {intl.formatMessage({id: 'LABEL.PROJECT_NAME'})}
                              </th>

                              <th className='min-w-120px text-start default-cursor'>
                                {' '}
                                {intl.formatMessage({id: 'LABEL.ACTIVITY'})}
                              </th>
                              <th className='min-w-120px text-start default-cursor'>
                                {intl.formatMessage({id: 'LABEL.RAISED_DATE'})}
                              </th>

                              <th className='min-w-120px text-start default-cursor'>
                                {' '}
                                {intl.formatMessage({id: 'LABEL.START_DATE'})}
                              </th>

                              <th className='min-w-120px text-start default-cursor'>
                                {' '}
                                {intl.formatMessage({id: 'LABEL.END_DATE'})}
                              </th>

                              <th className='min-w-120px text-start default-cursor'>
                                {' '}
                                {intl.formatMessage({id: 'LABEL.START_TIME'})}
                              </th>

                              <th className='min-w-120px text-start default-cursor'>
                                {' '}
                                {intl.formatMessage({id: 'LABEL.END_TIME'})}
                              </th>

                              <th className='min-w-120px text-start default-cursor'>
                                {intl.formatMessage({id: 'LABEL.TOTAL_TIME'})}
                              </th>

                              {/* <th className='min-w-100px text-start default-cursor'>
                                {intl.formatMessage({id: 'LABEL.STATUS'})}
                              </th> */}

                              <th className='min-w-100px text-start default-cursor'>
                                {intl.formatMessage({id: 'MENU.ACTIONS'})}
                              </th>
                            </tr>
                          </thead>

                          <tbody {...inactiveTableInstance.getTableBodyProps()}>
                            {inactivePage.length > 0 ? (
                              inactivePage.map((row) => {
                                inactiveTableInstance.prepareRow(row)
                                return (
                                  <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => (
                                      <td className=' ' {...cell.getCellProps()}>
                                        {cell.render('Cell')}
                                      </td>
                                    ))}
                                  </tr>
                                )
                              })
                            ) : (
                              <tr>
                                <td>
                                  <p></p>
                                </td>
                                <td className='card'>
                                  <p className='text-dark fw-bold text-hover-primary default-cursor d-block fs-6 m-0 my-5'>
                                    {intl.formatMessage({id: 'NO_RESULTS_FOUND_MSG'})}
                                  </p>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>

                        {data.length > 10 && (
                          <Pagination>
                            <Pagination.First
                              onClick={() => gotoInactivePage(0)}
                              disabled={!inactiveTableInstance.canPreviousPage}
                            />
                            <Pagination.Prev
                              onClick={inactiveTableInstance.previousPage}
                              disabled={!inactiveTableInstance.canPreviousPage}
                            />
                            {[...Array(inactivePageCount)].map((_, i) => (
                              <Pagination.Item
                                key={i}
                                active={i === inactivePageIndex}
                                onClick={() => gotoInactivePage(i)}
                              >
                                {i + 1}
                              </Pagination.Item>
                            ))}
                            <Pagination.Next
                              onClick={inactiveTableInstance.nextPage}
                              disabled={!inactiveTableInstance.canNextPage}
                            />
                            <Pagination.Last
                              onClick={() => gotoInactivePage(inactivePageCount - 1)}
                              disabled={!inactiveTableInstance.canNextPage}
                            />
                            <div className='d-flex align-items-center mx-5'>
                              <span className='me-2'>{intl.formatMessage({id: 'LABEL.SHOW'})}</span>
                              <select
                                className='form-select form-select-solid fw-bold w-75px me-2'
                                value={inactivePageSize}
                                onChange={(e) => setInactivePageSize(Number(e.target.value))}
                              >
                                {pageOptions.map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </Pagination>
                        )}
                      </div>

                      <div
                        className='card-body tab-pane fade  table-responsive'
                        id='kt_table_widget_4_tab_3'
                      >
                        <table
                          className='table table-row-dashed align-middle table-row-gray-300'
                          {...inactiveTableInstance.getTableProps()}
                        >
                          <thead>
                            <tr className='fw-bold text-muted'>
                              <th className='min-w-140px default-cursor'>
                                {' '}
                                {intl.formatMessage({id: 'LABEL.CANDIDATE'})}
                              </th>
                              <th className='min-w-140px default-cursor'>
                                {' '}
                                {intl.formatMessage({id: 'LABEL.PROJECT_NAME'})}
                              </th>

                              <th className='min-w-120px text-start default-cursor'>
                                {' '}
                                {intl.formatMessage({id: 'LABEL.ACTIVITY'})}
                              </th>
                              <th className='min-w-120px text-start default-cursor'>
                                {intl.formatMessage({id: 'LABEL.RAISED_DATE'})}
                              </th>

                              <th className='min-w-120px text-start default-cursor'>
                                {' '}
                                {intl.formatMessage({id: 'LABEL.START_DATE'})}
                              </th>

                              <th className='min-w-120px text-start default-cursor'>
                                {' '}
                                {intl.formatMessage({id: 'LABEL.END_DATE'})}
                              </th>

                              <th className='min-w-120px text-start default-cursor'>
                                {' '}
                                {intl.formatMessage({id: 'LABEL.START_TIME'})}
                              </th>

                              <th className='min-w-120px text-start default-cursor'>
                                {' '}
                                {intl.formatMessage({id: 'LABEL.END_TIME'})}
                              </th>

                              <th className='min-w-120px text-start default-cursor'>
                                {intl.formatMessage({id: 'LABEL.TOTAL_TIME'})}
                              </th>

                              {/* <th className='min-w-100px text-start default-cursor'>
                                {intl.formatMessage({id: 'LABEL.STATUS'})}
                              </th> */}

                              <th className='min-w-100px text-start default-cursor'>
                                {intl.formatMessage({id: 'MENU.ACTIONS'})}
                              </th>
                            </tr>
                          </thead>

                          <tbody {...RejectedDataInstance.getTableBodyProps()}>
                            {RejectedPage.length > 0 ? (
                              RejectedPage.map((row) => {
                                RejectedDataInstance.prepareRow(row)
                                return (
                                  <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => (
                                      <td className=' ' {...cell.getCellProps()}>
                                        {cell.render('Cell')}
                                      </td>
                                    ))}
                                  </tr>
                                )
                              })
                            ) : (
                              <tr>
                                <td>
                                  <p></p>
                                </td>
                                <td className='card'>
                                  <p className='text-dark fw-bold text-hover-primary default-cursor d-block fs-6 m-0 my-5'>
                                    {intl.formatMessage({id: 'NO_RESULTS_FOUND_MSG'})}
                                  </p>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>

                        {data.length > 10 && (
                          <Pagination>
                            <Pagination.First
                              onClick={() => gotoRejectedPage(0)}
                              disabled={!RejectedDataInstance.canPreviousPage}
                            />
                            <Pagination.Prev
                              onClick={RejectedDataInstance.previousPage}
                              disabled={!RejectedDataInstance.canPreviousPage}
                            />
                            {[...Array(RejectedPageCount)].map((_, i) => (
                              <Pagination.Item
                                key={i}
                                active={i === RejectedPageIndex}
                                onClick={() => gotoRejectedPage(i)}
                              >
                                {i + 1}
                              </Pagination.Item>
                            ))}
                            <Pagination.Next
                              onClick={RejectedDataInstance.nextPage}
                              disabled={!RejectedDataInstance.canNextPage}
                            />
                            <Pagination.Last
                              onClick={() => gotoRejectedPage(RejectedPageCount - 1)}
                              disabled={!RejectedDataInstance.canNextPage}
                            />
                            <div className='d-flex align-items-center mx-5'>
                              <span className='me-2'>{intl.formatMessage({id: 'LABEL.SHOW'})}</span>
                              <select
                                className='form-select form-select-solid fw-bold w-75px me-2'
                                value={RejectedPageSize}
                                onChange={(e) => setRejectedPage(Number(e.target.value))}
                              >
                                {pageOptions.map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </Pagination>
                        )}
                      </div>
                    </div>
                  </>
                </div>
              </>
            ) : (
              <div className='card h-250px'>
                <div className='m-auto d-flex flex-column align-items-center'>
                  <h1 className='mb-5'>{intl.formatMessage({id: 'LABEL.NO_TIMESHEETS_FOUND'})}</h1>
                </div>
              </div>
            )}
            <SideDrawer value={id} />
          </>
        )
      }}
    </Context.Consumer>
  )
}

const TeamTimeSheetTable = () => {
  const {currentUser} = useAuth()
  let emp_id

  const userInfo = secureLocalStorage.getItem('user_info')
  if (userInfo) {
    ;({emp_id} = userInfo)
  }
  const {
    data: filteredDataByDate,
    isLoading,
    fetchData,
  } = useApiCall(emp_id ? `/timesheets/TeamTimesheet/${emp_id}` : null)

  const columns = useMemo(
    () => [
      {
        Header: 'Employee Name',
        accessor: 'employee_name',
        Cell: ({row}) => {
          return (
            <div className='d-flex align-items-center'>
              <div className='symbol symbol-45px me-5'>
                <img
                  src={
                    row.original.employee_profile_pic
                      ? row.original.employee_profile_pic
                      : defaultProfileImage
                  }
                  alt='img'
                />
              </div>
              <div className='d-flex justify-content-start flex-column'>
                <p className='text-dark fw-bold text-hover-primary default-cursor fs-6 m-0'>
                  {row.original.first_name} {row.original.middle_name} {''}
                  {row.original.last_name}
                </p>
                <span className='text-muted fw-semibold text-muted d-block fs-7 text-start'>
                  {row.original.employee_code}
                </span>
              </div>
            </div>
          )
        },
        disableSortBy: true,
      },
      {
        Header: 'Employee Project Name',
        accessor: 'Employee Project Name',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark fw-bold text-hover-primary d-block fs-6 default-cursor'>
                {row.original.employee_project_name}
              </p>
            </div>
          )
        },
      },
      {
        Header: 'Activity',
        accessor: 'Activity',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark fw-bold text-hover-primary d-block fs-6 default-cursor'>
                {row.original.activity}
              </p>
            </div>
          )
        },
      },

      {
        Header: 'Created Date',
        accessor: 'Created Date',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark fw-bold text-hover-primary d-block fs-6 default-cursor'>
                {row.original.created_timestamp.slice(0, 10)}
              </p>
            </div>
          )
        },
      },

      {
        Header: 'Start Date',
        accessor: 'Start Date',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark fw-bold text-hover-primary d-block fs-6 default-cursor'>
                {DateFormatter(row.original.start_time.slice(0, 10))}
              </p>
            </div>
          )
        },
        disableSortBy: true,
      },

      {
        Header: 'End Date',
        accessor: 'End Date',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark fw-bold text-hover-primary d-block fs-6 default-cursor'>
                {DateFormatter(row.original.start_time.slice(0, 10))}
              </p>
            </div>
          )
        },
        disableSortBy: true,
      },

      {
        Header: 'Start Time',
        accessor: 'Start Time',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark fw-bold text-hover-primary d-block fs-6 default-cursor'>
                {row.original.start_time.slice(11, 16)}
              </p>
            </div>
          )
        },
        disableSortBy: true,
      },

      {
        Header: 'End Time',
        accessor: 'End Time',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark fw-bold text-hover-primary d-block fs-6 default-cursor'>
                {row.original.end_time.slice(11, 16)}
              </p>
            </div>
          )
        },
        disableSortBy: true,
      },
      {
        Header: 'Total Time',
        accessor: 'Total Time',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark fw-bold text-hover-primary d-block fs-6 default-cursor'>
                {row.original.hours}
              </p>
            </div>
          )
        },
        disableSortBy: true,
      },

      {
        Header: 'Actions',
        accessor: 'Actions',
        Cell: ({row}) => {
          return (
            <Context.Consumer>
              {(value) => {
                const {updateId} = value
                const hrReviewedStatus = row.original.timesheet_status

                return (
                  <div>
                    {hrReviewedStatus === 'Pending' ? (
                      <div
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                        id='side_activities_toggle'
                        onClick={() => {
                          updateId({
                            ...row.original,
                            type: 'edit_Timesheets',
                            fetchData,
                          })
                        }}
                      >
                        <KTIcon iconName='pencil' className='fs-3' />
                      </div>
                    ) : (
                      <div
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                        id='side_activities_toggle'
                        onClick={() => {
                          updateId({
                            id: row.original.timesheet_id,
                            type: 'edit_Timesheets',
                          })
                        }}
                      >
                        <KTIcon iconName='eye' className='fs-3' />
                      </div>
                    )}
                  </div>
                )
              }}
            </Context.Consumer>
          )
        },
      },
    ],
    []
  )

  return (
    <DataTable
      columns={columns}
      data={filteredDataByDate ? filteredDataByDate : []}
      loading={isLoading}
    />
  )
}

export default TeamTimeSheetTable
