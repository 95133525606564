import MyAssetsTable from './MyAssetsTable.jsx'

function MyAssets() {
  return (
    <>
      <div className='d-flex'>
        <div className='d-flex flex-wrap flex-stack mb-6'>
          <h3 className='fw-bolder my-2'>My Assets</h3>
        </div>
      </div>

      <div className='card mb-5 mb-xl-10'>
        <MyAssetsTable />
      </div>
    </>
  )
}

export default MyAssets
