import {ErrorMessage, Field, Form, Formik} from 'formik'
import Swal from 'sweetalert2'
import * as Yup from 'yup'
import {useIntl} from 'react-intl'
import usePostApi from '../../../custom_hooks/usePostApi'
import {useParams} from 'react-router-dom'
import secureLocalStorage from 'react-secure-storage'
import {baseUrl} from '../../../core'
function Finance({financerDetails}) {
  const {
    manager_reviewed_status = null,
    hr_reviewed_status = null,
    finance_reviewed_status = null,
    finance_review_comments = null,
    finance_reviewed_timestamp = '',
    exit_id = '',
    fetchData,
    exitFetchData,
  } = financerDetails
  const intl = useIntl()
  const {execute} = usePostApi()
  const {id} = useParams()
  const userInfo = secureLocalStorage.getItem('user_info')
  const {organization_id} = userInfo || {}
  const validationSchema = Yup.object().shape({
    exit_comments: Yup.string().required('Interview Comments is Required'),
  })

  const handleSubmit = async (values, formikHelpers) => {
    const {resetForm} = formikHelpers
    const url = `${baseUrl}/exit/details/finance/${exit_id}`
    try {
      const requestData = {
        organization_id: organization_id,
        finance_reviewed_by: 1,
        finance_reviewed_comments: 'Updated Finance Comments',
        exit_status: 'Exit Processed',
      }
      await execute(url, 'PATCH', requestData, id)
      resetForm()
      fetchData()
      exitFetchData()
      Swal.fire({
        title: 'Request Updated',
        text: 'Your comments have been updated.',
        icon: 'success',
        showConfirmButton: true,
        confirmButtonText: 'OK',
        customClass: {
          confirmButton: 'btn btn-light-primary btn-sm',
        },
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          const sideDrawer = document.getElementById('side_activities_close')
          if (sideDrawer) {
            sideDrawer.click()
          }
        }
      })
    } catch (error) {
      console.error(error)
      Swal.fire({
        icon: 'error',
        title: intl.formatMessage({id: 'LABEL.OOPS'}),
        text: intl.formatMessage({id: 'LABEL.SOMETHING_WRONG'}),
      })
    }
  }
  const getFinancerView = (ManagerStatus, hrStatus, financerStatus) => {
    if (ManagerStatus && hrStatus && financerStatus === null) {
      return (
        <>
          <Formik
            initialValues={{
              exit_comments: '',
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {() => {
              return (
                <Form>
                  <div className='d-flex flex-row justify-content-start'>
                    <button className='btn btn-light-primary btn-sm' type='submit'>
                      Status Upate
                    </button>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </>
      )
    } else if (financerStatus) {
      return (
        <>
          <p className='m-0 text-muted'>
            {finance_reviewed_timestamp && finance_reviewed_timestamp.slice(0, 19)}
          </p>
          <p className='m-0 text-muted'>{`${finance_review_comments}`}</p>
        </>
      )
    } else {
      return null
    }
  }

  const getStatusMsg = (ManagerStatus, hrStatus, financerStatus) => {
    if (ManagerStatus === null) {
      return "Awaiting Manager's Action"
    } else if (ManagerStatus && hrStatus === null) {
      return "Awaiting HR's Action"
    } else if (ManagerStatus && hrStatus && financerStatus === null) {
      return 'Pending'
    } else {
      return 'Updated'
    }
  }

  const getStatusColor = (status) => {
    if (status) {
      return 'success'
    } else {
      return 'muted'
    }
  }

  return (
    <div className='timeline-item'>
      <div className='timeline-line w-20px'></div>

      <div className='timeline-icon symbol symbol-circle symbol-20px'>
        <div
          className={`symbol-label bg-${getStatusColor(
            finance_reviewed_status
          )} icon-dotted-border text-${getStatusColor(finance_reviewed_status)}`}
        ></div>
      </div>

      <div className='timeline-content mb-12 mt-n1'>
        <div className='d-flex justify-content-between mt-1'>
          <p className={`mb-0 fw-bold text-${getStatusColor(finance_reviewed_status)}`}>
            {getStatusMsg(manager_reviewed_status, hr_reviewed_status, finance_reviewed_status)}
          </p>
          <div className=''>
            <p className='designation-btn bg-secondary m-auto'>Finance</p>
          </div>
        </div>
        {getFinancerView(manager_reviewed_status, hr_reviewed_status, finance_reviewed_status)}
      </div>
    </div>
  )
}

export default Finance
