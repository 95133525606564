import React from 'react'

import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'
import {Link} from 'react-router-dom'

import {useLocation} from 'react-router'
import {useIntl} from 'react-intl'
import secureLocalStorage from 'react-secure-storage'
import useApiCall from '../custom_hooks/useApiCall'
import {GetAllAssets} from './AssetManagementAPI'
import {GetMyAssets} from './AssetManagementAPI'
import {defaultProfileImage} from '../core'
const AssetManagementNavBar = () => {
  let role
  let emp_id
  let user_id
  let is_admin
  let is_itadmin
  const userInfo = secureLocalStorage.getItem('user_info')
  if (userInfo) {
    ;({emp_id, user_id, is_admin, is_itadmin} = userInfo)
  }

  const location = useLocation()
  const intl = useIntl()
  const {data, isLoading} = useApiCall('/employee/details', emp_id)
  const userData = data ? data[0] : null

  const {allassetsData} = GetAllAssets()
  const {myassetData} = GetMyAssets()

  return (
    <div className='card mb-4 mb-xl-6'>
      <div className='card-body pt-8 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap'>
          <div className='me-4 mb-3'>
            <div className='symbol symbol-100px symbol-lg-150px symbol-fixed position-relative default-cursor'>
              <img
                src={
                  userData?.employee_profile_pic
                    ? userData?.employee_profile_pic
                    : defaultProfileImage
                }
                alt='img'
              />
            </div>
          </div>
          {userData ? (
            <div className='flex-grow-1'>
              <div className='d-flex justify-content-between align-items-start flex-wrap'>
                <div className='d-flex flex-column'>
                  <div className='d-flex align-items-center mb-2'>
                    <p className='text-gray-800 text-hover-primary fs-2 fw-bolder  me-1 default-cursor mb-0'>
                      {`${userData?.first_name}${
                        userData?.middle_name ? ' ' + userData?.middle_name : ''
                      } ${userData?.last_name}`}
                    </p>
                  </div>

                  <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-0'>
                    <p className='d-flex align-items-center text-gray-400 text-hover-primary  me-5 mb-2 default-cursor'>
                      <KTIcon iconName='profile-circle' className='fs-4 me-1' />
                      {userData?.designation_name}
                    </p>
                    <p className='d-flex align-items-center text-gray-400 text-hover-primary  me-5 mb-2 default-cursor'>
                      <KTIcon iconName='geolocation' className='fs-4 me-1' />
                      {userData?.location_name}
                    </p>
                    <p className='d-flex align-items-center text-gray-400 text-hover-primary   mb-2 default-cursor'>
                      <KTIcon iconName='sms' className='fs-4 me-1' />
                      {userData?.email}
                    </p>
                  </div>
                </div>
              </div>
              <div className='d-flex flex-wrap flex-stack'>
                <div className='d-flex flex-column flex-grow-1 pe-8'>
                  <div className='d-flex flex-wrap'>
                    {/* <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        <div className='fs-2 fw-bolder'>{allassetsData?.length}</div>
                      </div>

                      <div className='fw-bold fs-6 text-warning-400'>No.of.Assets</div>
                    </div> */}

                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        <div className='fs-2 fw-bolder'>
                          {' '}
                          {myassetData?.length > 0 ? myassetData?.length : 0}
                        </div>
                      </div>

                      <div className='fw-bold fs-6 text-gray-400'>
                        {intl.formatMessage({id: 'MENU.ASSET_MANAGEMENT.MY_ASSETS'})}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className='m-auto d-flex flex-column align-items-center'>
              {isLoading ? (
                <div className='spinner-border spinner-primary mr-15'></div>
              ) : (
                <h3> {intl.formatMessage({id: 'LABEL.NO_DATA'})}</h3>
              )}
            </div>
          )}
        </div>

        <div className='d-flex overflow-auto h-55px'>
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
            <>
              <li className='nav-item nav-item-links'>
                <Link
                  className={
                    `nav-link text-active-primary text-grey me-6 ` +
                    (location.pathname === '/asset-management/allassets' && 'active')
                  }
                  to='/asset-management/allassets'
                >
                  {intl.formatMessage({id: 'LABEL.ALL_ASSETS'})}
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary text-grey me-6 ` +
                    (location.pathname === '/asset-management/my-assets' && 'active')
                  }
                  to='/asset-management/my-assets'
                >
                  {intl.formatMessage({id: 'MENU.ASSET_MANAGEMENT.MY_ASSETS'})}
                </Link>
              </li>
              {/* {is_admin === 1 && (
                <li className='nav-item nav-item-links'>
                  <Link
                    className={
                      `nav-link text-active-primary text-grey me-6 ` +
                      (location.pathname === '/asset-management/issuance-and-returns' && 'active')
                    }
                    to='/asset-management/issuance-and-returns'
                  >
                    {intl.formatMessage({id: 'MENU.ASSET_MANAGEMENT.ISSUANCE'})}
                  </Link>
                </li>
              )} */}
            </>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default AssetManagementNavBar
