/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {KTIcon, checkIsActive} from '../../../../helpers'
import HrConfiguration from '../../../../../app/modules/hr_configuration/HrConfiguration'
import {useLayout} from '../../../core'
import {useLocation} from 'react-router-dom'

const ToolbarSaas: FC = () => {
  const {config} = useLayout()
  const {pathname} = useLocation()
  useEffect(() => {
    document.body.setAttribute('data-kt-app-toolbar-fixed', 'true')
  }, [])

  return (
    <div className='d-flex align-items-center gap-2'>
      {config.app?.header?.default?.menu?.display && checkIsActive(pathname, '/hr-configuration') && (
        <div className='app-navbar-item d-lg-none ms-2 me-n3' title='Show header menu'>
          <div className='card-toolbar'>
            <button
              type='button'
              className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='top-end'
            >
              <KTIcon iconName='category' className='fs-2' />
            </button>

            <div
              className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold w-200px'
              data-kt-menu='true'
            >
              <div className='menu-item px-3'>
                <div className='menu-content fs-6 text-dark fw-bold px-3 py-4'>
                  Hr Configuration
                </div>
              </div>

              <div className='separator mb-3 opacity-75'></div>
              <HrConfiguration />
            </div>
          </div>
        </div>
      )}
      {/* end::Action wrapper */}
    </div>
  )
}

export {ToolbarSaas}
