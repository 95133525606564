import {useEffect, useState} from 'react'

import secureLocalStorage from 'react-secure-storage'

import useApiCall from '../custom_hooks/useApiCall'
import {useAuth} from '../auth'
export function GetServiceRequest() {
  let emp_id
  let user_id
  const userInfo = secureLocalStorage.getItem('user_info')
  if (userInfo) {
    ;({emp_id, user_id} = userInfo)
  }
  const {
    data: requestData,
    isLoading: isRequestLoading,
    fetchData,
  } = useApiCall(`/selfService/${emp_id}`)

  useEffect(() => {
    fetchData()
  }, [])

  return {requestData, isRequestLoading}
}

export function GetApprovedRequests() {
  let emp_id
  let user_id
  const userInfo = secureLocalStorage.getItem('user_info')
  if (userInfo) {
    ;({emp_id, user_id} = userInfo)
  }
  const {
    data: approvedrequestsData,
    isLoading: isrequestLoading,
    fetchData,
  } = useApiCall(`/selfService/${emp_id}`)

  useEffect(() => {
    fetchData()
  }, [])

  // Filter and return only approved leaves
  const approvedrequests = approvedrequestsData?.filter(
    (leave) => leave.request_status === 'Approved'
  )

  return {approvedrequests, isrequestLoading}
}

export function GetAllRequests() {
  let emp_id

  const userInfo = secureLocalStorage.getItem('user_info')
  if (userInfo) {
    ;({emp_id} = userInfo)
  }

  const {
    data: allrequestData,
    isLoading: isallRequestLoading,
    fetchData,
  } = useApiCall(emp_id ? `/selfService/teamRequests/${emp_id}` : null)

  useEffect(() => {
    fetchData()
  }, [])

  return {allrequestData, isallRequestLoading}
}

export function GetAllApprovedRequests() {
  const {currentUser} = useAuth()
  let emp_id
  let user_id
  const userInfo = secureLocalStorage.getItem('user_info')
  if (userInfo) {
    ;({emp_id, user_id} = userInfo)
  }
  const {
    data: getallapprovedrequestsData,
    isLoading: isallapprovedLoading,
    fetchData,
  } = useApiCall(emp_id ? `/selfService/teamRequests/${emp_id}` : null)

  useEffect(() => {
    fetchData()
  }, [])

  // Filter and return only approved leaves
  const allapprovedrequests = getallapprovedrequestsData?.filter(
    (request) => request.request_status === 'Approved'
  )

  return {allapprovedrequests, isallapprovedLoading}
}
