import {useEffect, useMemo, useState} from 'react'
import {useTable, usePagination} from 'react-table'
import {Pagination} from 'react-bootstrap'
import {KTIcon} from '../../../../_metronic/helpers'
import Context from '../../../../_metronic/partials/layout/activity-drawer/context'
import {SideDrawer} from '../../../../_metronic/partials/layout/activity-drawer/Side-drawer'
import {useIntl} from 'react-intl'
import DateFormatter from '../../DateFormatter'
import secureLocalStorage from 'react-secure-storage'
import {useLocation} from 'react-router-dom'
import usePageTitle from '../../custom_hooks/usePageTitle'

import useApiCall from '../../custom_hooks/useApiCall'
import DatePicker from 'react-datepicker'
import '../../DatepickerStyles.css'
import 'react-datepicker/dist/react-datepicker.css'
import {useThemeMode} from '../../../../_metronic/partials'

const DataTable = ({columns, data, loading}) => {
  const [inputVal, setInputVal] = useState('')
  const {mode} = useThemeMode()
  const [selectedDate, setSelectedDate] = useState(null)
  const [currentStatus, setCurrentStatus] = useState('Pending')

  const intl = useIntl()
  usePageTitle(intl.formatMessage({id: 'MENU.TRAVEL.MY_TRAVEL'}))

  // Filter data based on input value
  const filteredData = useMemo(() => {
    return data
      ? data.filter((each) => {
          const activityMatches =
            each.place_of_visit &&
            each.place_of_visit.toLowerCase().includes(inputVal.toLowerCase())
          const projectNameMatches =
            each.mode_of_transport &&
            each.mode_of_transport.toLowerCase().includes(inputVal.toLowerCase())

          const timesheetStatus =
            each.status !== 'Approved' && each.status !== 'Rejected' ? 'Pending' : each.status
          const currentStatusMatches =
            timesheetStatus && timesheetStatus.toLowerCase() === currentStatus.toLowerCase()

          return activityMatches && currentStatusMatches && projectNameMatches
        })
      : []
  }, [inputVal, data, currentStatus])

  const filteredDataByDate = useMemo(() => {
    if (!selectedDate) {
      return filteredData
      // No date selected, return the current filtered data
    }

    // Adjust the selected date to account for the time zone offset
    const selectedDateStr = new Date(
      selectedDate.getTime() - selectedDate.getTimezoneOffset() * 60000
    )
      .toISOString()
      .slice(0, 10) // Convert selected date to 'yyyy-MM-dd' format

    return filteredData.filter((each) => {
      return each.created_timestamp?.slice(0, 10) === selectedDateStr
    })
  }, [selectedDate, filteredData])

  // Table instances for active and inactive dataset
  const tableInstance = useTable({columns, data: filteredDataByDate}, usePagination)
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    state: {pageIndex, pageSize},
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageCount,
    gotoPage,
    setPageSize,
  } = tableInstance

  const pageOptions = [5, 10, 20, 30, 40, 50]

  // Render the component

  // Render the component

  return (
    <Context.Consumer>
      {(value) => {
        const {id, updateId} = value
        return (
          <>
            {loading ? (
              <div className='card h-250px'>
                <div className='m-auto d-flex flex-column align-items-center'>
                  <div className='spinner-border spinner-primary mr-15'></div>
                </div>
              </div>
            ) : data.length > 0 ? (
              <>
                <div className='d-flex'>
                  <div className='d-flex flex-wrap flex-stack mb-6'>
                    <h3 className='fw-bolder my-2'>My Trips</h3>
                  </div>
                </div>
                <div className='card'>
                  <>
                    <div className='card-header border-0 pt-5'>
                      <div className='d-flex flex-wrap flex-stack'>
                        <div className='fw-bolder'>
                          <ul className='nav'>
                            <li className='nav-item'>
                              <a
                                className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary active fw-bold px-4 me-1 ms-0'
                                data-bs-toggle='tab'
                                onClick={() => setCurrentStatus('Pending')}
                              >
                                {intl.formatMessage({id: 'LABEL.PENDING'})}
                              </a>
                            </li>
                            <li className='nav-item'>
                              <a
                                className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1'
                                data-bs-toggle='tab'
                                onClick={() => setCurrentStatus('Approved')}
                              >
                                {intl.formatMessage({id: 'LABEL.APPROVED'})}
                              </a>
                            </li>
                            <li className='nav-item'>
                              <a
                                className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1'
                                data-bs-toggle='tab'
                                onClick={() => setCurrentStatus('Rejected')}
                              >
                                {intl.formatMessage({id: 'LABEL.REJECTED'})}
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className='card-toolbar'>
                        <div className='d-flex align-items-center position-relative me-4'>
                          <KTIcon iconName='magnifier' className='fs-3 position-absolute ms-2' />
                          <input
                            type='search'
                            id='kt_filter_search'
                            className='form-control form-control-white form-control-sm w-150px ps-9'
                            placeholder={intl.formatMessage({id: 'LABEL.SEARCH'})}
                            value={inputVal}
                            onChange={(e) => setInputVal(e.target.value)}
                          />
                        </div>

                        <div
                          className={`react-datepicker-fullwidth-wrapper test me-4 ${
                            mode === 'dark' ? 'dark-mode' : 'light-mode'
                          }`}
                        >
                          <DatePicker
                            className='form-control form-control-sm'
                            selected={selectedDate}
                            isClearable
                            showYearDropdown
                            scrollableYearDropdown
                            dateFormat='dd/MM/yyyy'
                            onChange={(date) => setSelectedDate(date)}
                            placeholderText={intl.formatMessage({id: 'LABEL.SELECT_DATE'})}
                            showMonthDropdown
                            useShortMonthInDropdown
                          />
                        </div>

                        <button
                          className='btn btn-sm btn-light-primary mx-2'
                          id='side_activities_toggle'
                          onClick={() => {
                            updateId({
                              type: 'raise_travel_request',
                            })
                          }}
                        >
                          <KTIcon iconName='plus' className='fs-3' />
                          {intl.formatMessage({id: 'BTN.ADD_TRAVEL_REQUEST'})}
                        </button>
                      </div>
                    </div>
                    <div className='tab-content p-2 pt-0'>
                      <div className='card-body tab-pane fade show active table-responsive'>
                        <table
                          className='table table-row-dashed table-row-gray-300 align-middle'
                          {...getTableProps()}
                        >
                          <thead>
                            <tr className='fw-bold text-muted'>
                              <th className='min-w-140px default-cursor'>
                                {intl.formatMessage({id: 'LABEL.PLACE_OF_VISIT'})}
                              </th>

                              <th className='min-w-120px text-start default-cursor'>
                                Project Name
                              </th>

                              <th className='min-w-120px text-start default-cursor'>
                                {intl.formatMessage({id: 'LABEL.MODE_OF_TRANSPORT'})}
                              </th>

                              <th className='min-w-120px text-start default-cursor'>
                                {intl.formatMessage({id: 'LABEL.DEPARTURE_DATE'})}
                              </th>

                              <th className='min-w-120px text-start default-cursor'>
                                {intl.formatMessage({id: 'LABEL.ARRIVAL_DATE'})}
                              </th>

                              <th className='min-w-120px text-start default-cursor'>
                                {intl.formatMessage({id: 'LABEL.RAISED_DATE'})}
                              </th>

                              {/* <th className='min-w-100px text-start default-cursor'>
                                {intl.formatMessage({id: 'LABEL.STATUS'})}
                              </th> */}
                            </tr>
                          </thead>
                          <tbody {...getTableBodyProps()}>
                            {page && page.length > 0 ? (
                              page.map((row) => {
                                prepareRow(row)
                                return (
                                  <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => (
                                      <td className='text-center' {...cell.getCellProps()}>
                                        {cell.render('Cell')}
                                      </td>
                                    ))}
                                  </tr>
                                )
                              })
                            ) : (
                              <tr>
                                <td className='card'>
                                  <p className='text-dark fw-bold text-hover-primary default-cursor d-block fs-6 m-0 my-5'>
                                    {intl.formatMessage({id: 'NO_RESULTS_FOUND_MSG'})}
                                  </p>
                                </td>
                                <td>
                                  <p></p>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>

                        {filteredData.length > 10 && (
                          <Pagination>
                            <Pagination.First
                              onClick={() => gotoPage(0)}
                              disabled={!canPreviousPage}
                            />
                            <Pagination.Prev onClick={previousPage} disabled={!canPreviousPage} />

                            {[...Array(pageCount)].map((_, i) => (
                              <Pagination.Item
                                key={i}
                                active={i === pageIndex}
                                onClick={() => gotoPage(i)}
                              >
                                {i + 1}
                              </Pagination.Item>
                            ))}

                            <Pagination.Next onClick={nextPage} disabled={!canNextPage} />
                            <Pagination.Last
                              onClick={() => gotoPage(pageCount - 1)}
                              disabled={!canNextPage}
                            />

                            <div className='d-flex align-items-center mx-5'>
                              <span className='me-2'>{intl.formatMessage({id: 'LABEL.SHOW'})}</span>
                              <select
                                className='form-select form-select-solid fw-bold w-75px me-2'
                                value={pageSize}
                                onChange={(e) => setPageSize(Number(e.target.value))}
                              >
                                {pageOptions.map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </Pagination>
                        )}
                      </div>
                    </div>
                  </>
                </div>
              </>
            ) : (
              <div className='card h-250px'>
                <div className='m-auto d-flex flex-column align-items-center'>
                  <h1 className='mb-5'>
                    {intl.formatMessage({id: 'LABEL.NO_TRAVELREQUESTS_FOUND'})}
                  </h1>

                  <button
                    className='btn btn-sm btn-light-primary'
                    id='side_activities_toggle'
                    onClick={() => {
                      updateId({
                        type: 'raise_travel_request',
                      })
                    }}
                  >
                    <KTIcon iconName='plus' className='fs-3' />
                    {intl.formatMessage({id: 'BTN.ADD_TRAVEL_REQUEST'})}
                  </button>
                </div>
              </div>
            )}
            <SideDrawer value={id} />
          </>
        )
      }}
    </Context.Consumer>
  )
}

const MyTripsTable = () => {
  let emp_id
  let user_id
  const userInfo = secureLocalStorage.getItem('user_info')
  if (userInfo) {
    ;({emp_id, user_id} = userInfo)
  }

  const {data: filteredDataByDate, isLoading, fetchData} = useApiCall(`/travel/${emp_id}`)

  const location = useLocation()

  useEffect(() => {
    const targetElement = document.getElementById('side_activities')
    if (targetElement && location.pathname === '/travel/my-travel') {
      const body = document.querySelector('body')
      const observer = new MutationObserver(() => {
        if (!body.hasAttribute('data-kt-drawer-side-activities')) {
          fetchData()
        }
      })
      observer.observe(targetElement, {attributes: true})
      return () => {
        observer.disconnect()
      }
    }
  }, [location.pathname, fetchData])

  const columns = useMemo(
    () => [
      {
        Header: 'Place of Visit',
        accessor: 'Place of Visit',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark fw-bold text-hover-primary d-block fs-6 default-cursor'>
                {row.original.place_of_visit}
              </p>
            </div>
          )
        },
      },

      {
        Header: 'Client Name',
        accessor: 'Client Name',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark fw-bold text-hover-primary d-block fs-6 default-cursor'>
                {row.original.project_name}
              </p>
            </div>
          )
        },
        disableSortBy: true,
      },

      {
        Header: 'Mode of Transport',
        accessor: 'Mode of Transport',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark fw-bold text-hover-primary d-block fs-6 default-cursor'>
                {row.original.mode_of_transport}
              </p>
            </div>
          )
        },
        disableSortBy: true,
      },

      {
        Header: 'Date of Departure',
        accessor: 'Date of Departure',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark fw-bold text-hover-primary d-block fs-6 default-cursor'>
                {DateFormatter(row.original.expected_date_of_departure)}
              </p>
            </div>
          )
        },
        disableSortBy: true,
      },

      {
        Header: 'Date of Arrival',
        accessor: 'Date of Arrival',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark fw-bold text-hover-primary d-block fs-6 default-cursor'>
                {DateFormatter(row.original.expected_date_of_arrival)}
              </p>
            </div>
          )
        },
        disableSortBy: true,
      },

      {
        Header: 'Created Timestamp',
        accessor: 'Created Timestamp',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark fw-bold text-hover-primary d-block fs-6 default-cursor'>
                {DateFormatter(row.original.created_timestamp.slice(0, 10))}
              </p>
            </div>
          )
        },
        disableSortBy: true,
      },

      // {
      //   Header: 'Status',
      //   accessor: 'status',
      //   Cell: ({row}) => {
      //     const statusColor =
      //       row.original.hr_reviewed_status === 'Approved'
      //         ? 'success'
      //         : row.original.hr_reviewed_status === 'Pending'
      //         ? 'muted'
      //         : 'danger'

      //     return (
      //       <p
      //         className={`text-${
      //           statusColor === 'info' ? 'info' : statusColor
      //         } fw-bold text-hover-primary d-block fs-6 default-cursor`}
      //       >
      //         {row.original.hr_reviewed_status}
      //       </p>
      //     )
      //   },
      // },
    ],
    []
  )

  return (
    <DataTable
      columns={columns}
      data={filteredDataByDate ? filteredDataByDate : []}
      loading={isLoading}
    />
  )
}

export default MyTripsTable
