import React, {useEffect, useState} from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import {useIntl} from 'react-intl'
import * as Yup from 'yup'
import {ErrorMessage, Field, Form, Formik} from 'formik'
import Swal from 'sweetalert2'
import {baseUrl} from '../../core'
import secureLocalStorage from 'react-secure-storage'
import usePostApi from '../../custom_hooks/usePostApi'
import {useParams} from 'react-router-dom'
import useApiCall from '../../custom_hooks/useApiCall'
const EditJobRole = (props: any) => {
  const {id: jobRoleId, refreshData} = props.id
  const intl = useIntl()
  const {execute} = usePostApi()
  const {id}: any | null = useParams()
  const userInfo: any | null = secureLocalStorage.getItem('user_info')
  const {data, fetchData, isLoading}: any | null = useApiCall(
    jobRoleId ? `/hrConfig/jobRole/${jobRoleId}` : null
  )
  const {data: depName}: any | null = useApiCall(`/masterData/departments`)
  let jobRoleTypeData = data && data[0]
  const {organization_id} = userInfo || {}
  const [name, setName] = useState<string | null>('')
  const [code, setCode] = useState<string | null>('')
  const [departmentid, setDepartmentId] = useState<number>(0)
  const [roleDescription, setRoleDescription] = useState<string | null>('')
  const [isChecked, setIsChecked] = useState<boolean>(true)

  useEffect(() => {
    if (jobRoleTypeData) {
      setName(jobRoleTypeData.role_name)
      setCode(jobRoleTypeData.job_role_code)
      setDepartmentId(jobRoleTypeData.department_id)
      setRoleDescription(jobRoleTypeData.role_description)
      setIsChecked(jobRoleTypeData.status === 1)
    }
  }, [jobRoleTypeData])
  const initialValues = {
    name: name || '',
    code: code || '',
    status: isChecked || false,
    description: roleDescription || '',
    department_id: departmentid || '',
  }
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(intl.formatMessage({id: 'LABEL.NAME_REQUIRED'})),
    code: Yup.string().required(intl.formatMessage({id: 'LABEL.CODE_REQUIRED'})),
    description: Yup.string().required(intl.formatMessage({id: 'LABEL.DESCRIPTION_REQUIRED'})),
    department_id: Yup.string().required(intl.formatMessage({id: 'LABEL.DEP_NAME_REQUIRED'})),
    status: Yup.boolean().required(intl.formatMessage({id: 'LABEL.STATUS_REQUIRED'})),
  })

  const handleEditSubmit = async (values: any) => {
    const url = `${baseUrl}/hrConfig/jobRole/${jobRoleId}`
    try {
      const requestData = {
        organization_id: organization_id,
        role_name: values.name,
        job_role_code: values.code,
        role_description: values.description,
        department_id: values.department_id,
        status: values.status ? 1 : 0,
      }
      await execute(url, 'PATCH', requestData, id)
      refreshData()
      const closeBtn = document.querySelector(
        '#kt_modal_edit_jobRole [data-bs-dismiss="modal"]'
      ) as HTMLElement
      if (closeBtn) {
        closeBtn.click()
      }
      Swal.fire({
        title: 'Job Role Type Edited Successfully',
        text: intl.formatMessage({id: 'LABEL.CHANGES_SAVED'}),
        icon: 'success',
        showConfirmButton: true,
        confirmButtonText: 'OK',
        customClass: {
          confirmButton: 'btn btn-light-primary btn-sm',
        },
      })
    } catch (error) {
      console.error(error)
      Swal.fire({
        icon: 'error',
        title: intl.formatMessage({id: 'LABEL.OOPS'}),
        text: intl.formatMessage({id: 'LABEL.SOMETHING_WRONG'}),
      })
    }
  }
  //
  useEffect(() => {
    fetchData()
  }, [jobRoleId])

  return (
    <div>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={handleEditSubmit}
      >
        {({values, handleChange}) => {
          return (
            <Form>
              <div className='modal-dialog'>
                <div className='modal-content'>
                  <div className='modal-header'>
                    <h3 className='modal-title fw-bolder text-dark'>
                      {intl.formatMessage({id: 'LABEL.EDIT_JOB_ROLE'})}
                    </h3>
                    <div className='modal-toolbar'>
                      <div
                        className='btn btn-icon btn-sm btn-active-light-danger'
                        data-bs-dismiss='modal'
                        aria-label='Close'
                      >
                        <KTIcon iconName='cross' className='fs-2' />
                      </div>
                    </div>
                  </div>
                  <div className='modal-body position-relative'>
                    {isLoading ? (
                      <div className='card card-xl-stretch'>
                        <div className='m-auto d-flex flex-column align-items-center'>
                          <div className='spinner-border spinner-primary mr-15'></div>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <div className='form-floating flex-fill mb-4'>
                          <Field
                            type='text'
                            className='form-control'
                            placeholder={intl.formatMessage({id: 'LABEL.NAME'})}
                            name='name'
                            value={values.name}
                            onChange={handleChange}
                          />
                          <label htmlFor='name'>{intl.formatMessage({id: 'LABEL.NAME'})}</label>
                          <ErrorMessage component='div' className='text-danger' name='name' />
                        </div>
                        <div className='d-flex mb-4'>
                          <div className='form-floating flex-fill'>
                            <Field
                              type='text'
                              className='form-control'
                              placeholder={intl.formatMessage({id: 'LABEL.NAME'})}
                              name='code'
                              value={values.code}
                              onChange={handleChange}
                            />
                            <label htmlFor='code'>{intl.formatMessage({id: 'LABEL.CODE'})}</label>
                            <ErrorMessage component='div' className='text-danger' name='code' />
                          </div>
                          <div className='form-floating flex-fill ms-2'>
                            <Field
                              as='select'
                              name='department_id'
                              id='department_id'
                              className='form-select'
                              value={values.department_id}
                              onChange={handleChange}
                            >
                              <option value=''>
                                {intl.formatMessage({id: 'LABEL.SELECT_DEPARTMENT_NAME'})}
                              </option>
                              {depName?.map((option: any) => (
                                <option key={option.department_id} value={option.department_id}>
                                  {option.department_name}
                                </option>
                              ))}
                            </Field>
                            <label htmlFor='department_id'>
                              {intl.formatMessage({id: 'LABEL.DEPARTMENT_NAME'})}
                            </label>
                            <ErrorMessage
                              component='div'
                              className='text-danger'
                              name='department_id'
                            />
                          </div>
                        </div>
                        <div className='form-floating flex-fill mb-4'>
                          <Field
                            type='text'
                            className='form-control'
                            name='description'
                            value={values.description}
                            onChange={handleChange}
                            id='description'
                          />
                          <label htmlFor='description'>
                            {intl.formatMessage({id: 'LABEL.DESCRIPTION'})}
                          </label>
                          <ErrorMessage
                            component='div'
                            className='text-danger'
                            name='description'
                          />
                        </div>
                        <div className='form-floating mb-0'>
                          <div className='form-check form-check-solid form-switch'>
                            <Field
                              className='form-check-input w-40px h-20px'
                              type='checkbox'
                              id='toggle'
                              name='status'
                              checked={values.status}
                              onChange={handleChange}
                            />
                            <span
                              className={`me-4 fw-bold ${
                                values.status ? 'text-success' : 'text-danger'
                              }`}
                            >
                              {values.status ? 'Active' : 'Inactive'}
                            </span>
                          </div>
                          <ErrorMessage component='div' className='text-danger' name='status' />
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='modal-footer text-end'>
                    <div
                      className='btn btn-sm btn-secondary m-2'
                      data-bs-dismiss='modal'
                      aria-label='Close'
                    >
                      {intl.formatMessage({id: 'LABEL.CANCEL'})}
                    </div>
                    <button className='btn btn-light-primary btn-sm' type='submit'>
                      <KTIcon iconName='check' className='fs-7' />
                      {intl.formatMessage({id: 'BTN.SAVE'})}
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export {EditJobRole}
