import {useState, useEffect} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'

import {useIntl} from 'react-intl'

import React from 'react'
import DateFormatter from '../../DateFormatter'
import useApiCall from '../../custom_hooks/useApiCall'
import {defaultProfileImage} from '../../core'

const ViewTeamTrips = (tripsData: any) => {
  const intl = useIntl()
  const {props}: any = tripsData
  const {data, fetchData, isLoading}: any | null = useApiCall(
    `/travel/request/${props?.request_id}`
  )
  let travelData = data && data[0]

  return (
    <div className='card shadow-none rounded-0'>
      <div>
        <div className='card-header' id='side_activities_header'>
          <h3 className='card-title fw-bolder text-dark'>
            {intl.formatMessage({id: 'LABEL.TRAVEL_REQUESTS'})}
          </h3>
          <div className='card-toolbar '>
            <React.Fragment>
              <div className='btn btn-icon btn-sm btn-active-danger' id='side_activities_close'>
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </React.Fragment>
          </div>
        </div>

        <div className='card-body position-relative' id='side_activities_body'>
          <div>
            <div
              id='side_activities_scroll'
              className='position-relative scroll-y me-n5 pe-5 w-400px'
              data-kt-scroll='true'
              data-kt-scroll-height='auto'
              data-kt-scroll-wrappers='#side_activities_body'
              data-kt-scroll-dependencies='#side_activities_header, #side_activities_footer'
              data-kt-scroll-offset='5px'
            >
              {isLoading ? (
                <div className='card h-350px'>
                  <div className='m-auto d-flex flex-column align-items-center'>
                    <div className='spinner-border spinner-primary mr-15'></div>
                  </div>
                </div>
              ) : (
                <>
                  <div className='d-flex mb-5'>
                    <div className='d-flex'>
                      <img
                        src={
                          travelData?.employee_profile_pic
                            ? travelData?.employee_profile_pic
                            : defaultProfileImage
                        }
                        alt=''
                        className='activity-img rounded w-80px  h-80px me-2'
                      />
                      <div className=''>
                        <a href='#' className='text-dark fw-bold text-hover-primary fs-5'>
                          {travelData?.first_name} {''}
                          {travelData?.middle_name} {travelData?.last_name}
                        </a>
                        <br />
                        <a href='#' className='text-muted fw-bold text-hover-primary fs-7'>
                          {travelData?.employee_code}
                        </a>
                        <br />
                        <a href='#' className='text-muted fw-bold text-hover-primary fs-7'>
                          {travelData?.employee_role}
                        </a>
                        <br />
                        <a href='#' className='text-muted fw-bold text-hover-primary fs-7'>
                          {travelData?.department_name}
                        </a>
                        <br />
                      </div>
                    </div>
                  </div>
                  <div className='row mb-4'>
                    <label className='col-lg-4 fw-bold text-muted'>
                      {intl.formatMessage({id: 'LABEL.PROJECT_NAME'})}
                    </label>
                    <div className='col-lg-8'>
                      <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                        {travelData?.project_name}
                      </span>
                    </div>
                  </div>
                  <div className='row mb-4'>
                    <label className='col-lg-4 fw-bold text-muted'>
                      {intl.formatMessage({id: 'LABEL.MODE_OF_TRANSPORT'})}
                    </label>
                    <div className='col-lg-8'>
                      <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                        {travelData?.mode_of_transport}
                      </span>
                    </div>
                  </div>
                  <div className='row mb-4'>
                    <label className='col-lg-4 fw-bold text-muted'>
                      {' '}
                      {intl.formatMessage({id: 'LABEL.PLACE_OF_VISIT'})}
                    </label>
                    <div className='col-lg-8'>
                      <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                        {travelData?.place_of_visit}
                      </span>
                    </div>
                  </div>
                  <div className='row mb-4'>
                    <label className='col-lg-4 fw-bold text-muted'>
                      {' '}
                      {intl.formatMessage({id: 'LABEL.DEPARTURE_DATE'})}
                    </label>
                    <div className='col-lg-8'>
                      <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                        {DateFormatter(travelData?.expected_date_of_departure)}
                      </span>
                    </div>
                  </div>

                  <div className='row mb-4'>
                    <label className='col-lg-4 fw-bold text-muted'>
                      {intl.formatMessage({id: 'LABEL.ARRIVAL_DATE'})}
                    </label>
                    <div className='col-lg-8'>
                      <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                        {DateFormatter(travelData?.expected_date_of_arrival)}
                      </span>
                    </div>
                  </div>

                  <div className='row mb-4'>
                    <label className='col-lg-4 fw-bold text-muted'>
                      {intl.formatMessage({id: 'LABEL.NO_OF_DAYS'})}
                    </label>
                    <div className='col-lg-8'>
                      <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                        {travelData?.expected_duration_in_days} Days
                      </span>
                    </div>
                  </div>

                  <div className='row mb-4'>
                    <label className='col-lg-4 fw-bold text-muted'>Advance Amount</label>
                    <div className='col-lg-8'>
                      <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                        {travelData?.travel_advance_currency} {travelData?.travel_advance_amount}
                      </span>
                    </div>
                  </div>

                  <div className='row mb-4'>
                    <label className='col-lg-4 fw-bold text-muted'>
                      {' '}
                      {intl.formatMessage({id: 'LABEL.STATUS'})}
                    </label>
                    <div className='col-lg-8'>
                      <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                        {travelData?.status}
                      </span>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ViewTeamTrips
