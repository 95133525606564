import React, {useState, useEffect, useRef} from 'react'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import * as Yup from 'yup'

import Swal from 'sweetalert2'
import {useIntl} from 'react-intl'

import {KTSVG} from '../../../../_metronic/helpers'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import {baseUrl} from '../../core'
import secureLocalStorage from 'react-secure-storage'
import {format, parseISO, differenceInHours} from 'date-fns'
import DatePicker from 'react-datepicker'
import '../../DatepickerStyles.css'
import 'react-datepicker/dist/react-datepicker.css'

import {useThemeMode} from '../../../../_metronic/partials'
import useApiCall from '../../custom_hooks/useApiCall'
import usePostApi from '../../custom_hooks/usePostApi'

const CreateTimeSheet = ({}) => {
  let emp_id
  let user_id
  const userInfo = secureLocalStorage.getItem('user_info')
  if (userInfo) {
    ;({emp_id, user_id} = userInfo)
  }
  const {mode} = useThemeMode()
  const {execute} = usePostApi()
  const {data: projectDetails} = useApiCall('/projects', emp_id)
  const intl = useIntl()
  const [hours, setHours] = useState([])
  const validationSchema = Yup.object().shape({
    start_time: Yup.string().required(
      `${intl.formatMessage({id: 'ERR.MSG.START_TIME_IS_REQUIRED'})}`
    ),
    end_time: Yup.string().required(`${intl.formatMessage({id: 'ERR.MSG.END_TIME_IS_REQUIRED'})}`),
    activity: Yup.string().required(`${intl.formatMessage({id: 'ERR.MSG.ACTIVITY_IS_REQUIRED'})}`),
    // hours: Yup.number()

    //   .moreThan(-1, 'Hours must be  positive  or zero')
    //   .required('Hours is Required'),

    projectType: Yup.string().required(
      `${intl.formatMessage({id: 'ERR.MSG.PROJECT_NAME_IS_REQUIRED'})}`
    ),
  })
  const [isSubmitting, setIsSubmitting] = useState(false)
  const initialValues = {
    projectType: '',
    hours: '',
    start_time: '',
    end_time: '',
    activity: '',
  }

  const calculateHours = (start_time, end_time) => {
    if (start_time && end_time) {
      const hoursDiff = differenceInHours(end_time, start_time)
      return hoursDiff.toFixed(2)
    }
    return ''
  }

  const handleSubmit = async (values, formikHelpers) => {
    const {resetForm} = formikHelpers
    try {
      const url = `${baseUrl}/timesheets/timesheet`
      const organizationId = secureLocalStorage.getItem('organization_id')
      const selectedprojectType = projectDetails.find(
        (projectType) => projectType.project_name === values.projectType
      )
      const formattedStartDate = values.start_time
        ? format(new Date(values.start_time), 'yyyy-MM-dd HH:mm:ss')
        : ''
      const formattedEndDate = values.end_time
        ? format(new Date(values.end_time), 'yyyy-MM-dd HH:mm:ss')
        : ''

      const formData = {
        organization_id: organizationId,
        employee_project_id: selectedprojectType.project_id,
        hours: calculateHours(values.start_time, values.end_time),
        start_time: formattedStartDate,
        end_time: formattedEndDate,
        activity: values.activity,
        timesheet_status: 'Manager Pending',
        hr_reviewed_status: 'Pending',
        reviewer_status: 'Pending',
      }

      await execute(url, 'POST', formData)

      resetForm()
      Swal.fire({
        title: intl.formatMessage({id: 'MSG.TIMESHEET_CREATED_SUCCESSFULLY'}),
        text: 'You can now track the status of the Timesheet in the “My Timesheet” section..',
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-light-primary btn-sm',
        },
      })
    } catch (error) {
      console.error(error)
      Swal.fire({
        icon: 'error',
        title: intl.formatMessage({id: 'LABEL.OOPS'}),
        text: intl.formatMessage({id: 'LABEL.SOMETHING_WRONG'}),
        customClass: {
          confirmButton: 'btn btn-light-primary btn-sm',
        },
      })
    }
  }

  return (
    <div className='card shadow-none rounded-0'>
      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({values, setFieldValue}) => (
            <Form>
              <div className='card-header' id='side_activities_header'>
                <h3 className='card-title fw-bolder text-dark'>Create Timesheet</h3>
                <div className='card-toolbar '>
                  <React.Fragment>
                    <div
                      className='btn btn-icon btn-sm btn-active-danger'
                      id='side_activities_close'
                    >
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr061.svg'
                        className='svg-icon svg-icon-2x'
                      />
                    </div>
                  </React.Fragment>
                </div>
              </div>

              <div className='card-body position-relative' id='side_activities_body'>
                <div>
                  <div
                    id='side_activities_scroll'
                    className='position-relative scroll-y me-n5 pe-5 w-400px h-500px'
                    data-kt-scroll='true'
                    data-kt-scroll-height='auto'
                    data-kt-scroll-wrappers='#side_activities_body'
                    data-kt-scroll-dependencies='#side_activities_header, #side_activities_footer'
                    data-kt-scroll-offset='5px'
                  >
                    <div className='row mb-5 d-flex align-items-center'>
                      <label className='col-lg-4 fw-bold text-muted text-start'>
                        {intl.formatMessage({id: 'LABEL.SELECT_PROJECT'})}
                      </label>
                      <div className='col-lg-8 text-start'>
                        <Field
                          as='select'
                          className='form-select form-select-solid'
                          id='floatingInput'
                          name='projectType'
                          value={values.projectType || ''}
                          onChange={(e) => setFieldValue('projectType', e.target.value)}
                          placeholder={intl.formatMessage({id: 'LABEL.SELECT_PROJECT'})}
                        >
                          <option value=''>
                            {intl.formatMessage({id: 'LABEL.SELECT_PROJECT'})}
                          </option>
                          {projectDetails?.map((projectType) => (
                            <option key={projectType.project_id} value={projectType.project_name}>
                              {projectType.project_name}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage name='projectType' component='div' className='text-danger' />
                      </div>
                    </div>

                    <div className='row mb-5 d-flex align-items-center'>
                      <label className='col-lg-4 fw-bold text-muted text-start'>
                        {' '}
                        {intl.formatMessage({id: 'LABEL.ACTIVITY'})}
                      </label>
                      <div className='col-lg-8 text-start'>
                        <Field
                          type='text'
                          name='activity'
                          className='form-control form-control-solid'
                          value={values.activity || ''}
                          onChange={(e) => setFieldValue('activity', e.target.value)}
                          placeholder={intl.formatMessage({id: 'LABEL.ACTIVITY'})}
                        />
                        <ErrorMessage name='activity' component='div' className='text-danger' />
                      </div>
                    </div>

                    <div className='row mb-5 d-flex align-items-center'>
                      <label className='col-lg-4 fw-bold text-muted text-start'>
                        {' '}
                        {intl.formatMessage({id: 'LABEL.START_DATE'})}
                      </label>

                      <div className='col-lg-8 text-start'>
                        <Field name='start_time'>
                          {({form, field}) => {
                            const {setFieldValue} = form
                            const {value} = field

                            const handleChange = (date) => {
                              setFieldValue('start_time', date)
                            }

                            return (
                              <div
                                className={`react-datepicker-fullwidth-wrapper test ${
                                  mode === 'dark' ? 'dark-mode' : 'light-mode'
                                }`}
                              >
                                <DatePicker
                                  selected={value}
                                  showTimeInput
                                  minDate={new Date()}
                                  isClearable
                                  showYearDropdown
                                  scrollableYearDropdown
                                  onChange={(date) => {
                                    handleChange(date)
                                  }}
                                  placeholderText={intl.formatMessage({
                                    id: 'LABEL.SELECT_START_DATE_AND_TIME',
                                  })}
                                  showMonthDropdown
                                  useShortMonthInDropdown
                                  dateFormat='MM/dd/yyyy h:mm aa'
                                  timeInputLabel='Time:'
                                  className='form-control form-control-solid'
                                  popperPlacement='bottom'
                                />
                              </div>
                            )
                          }}
                        </Field>
                        <ErrorMessage name='start_time' component='div' className='text-danger' />
                      </div>
                      {/* <div className='col-lg-8 text-start'>
                        <DatePicker
                          selected={values.start_time} // Use values.start_time
                          onChange={(date) => setFieldValue('start_time', date)} // Update start_time
                          showTimeInput
                          minDate={new Date()}
                          isClearable
                          showYearDropdown
                          scrollableYearDropdown
                          dateFormat='MM/dd/yyyy h:mm aa'
                          timeInputLabel='Time:'
                          placeholderText='Select Date and Time'
                          className='form-control form-control-solid w-300px'
                          popperPlacement='bottom'
                        />
                        <ErrorMessage name='start_time' component='div' className='text-danger' />
                      </div> */}
                    </div>

                    <div className='row mb-5 d-flex align-items-center'>
                      <label className='col-lg-4 fw-bold text-muted text-start'>
                        {' '}
                        {intl.formatMessage({id: 'LABEL.END_DATE'})}
                      </label>
                      <div className='col-lg-8 text-start'>
                        <Field name='end_time'>
                          {({form, field}) => {
                            const {setFieldValue} = form
                            const {value} = field

                            const handleChange = (date) => {
                              setFieldValue('end_time', date)
                            }

                            let minDate = null
                            if (values && values.start_time) {
                              const starttime = new Date(values.start_time)
                              minDate = starttime.setDate(starttime.getDate() + 1)
                            }

                            return (
                              <div
                                className={`react-datepicker-fullwidth-wrapper test ${
                                  mode === 'dark' ? 'dark-mode' : 'light-mode'
                                }`}
                              >
                                <DatePicker
                                  selected={value}
                                  showTimeInput
                                  isClearable
                                  showYearDropdown
                                  scrollableYearDropdown
                                  onChange={(date) => {
                                    handleChange(date)
                                  }}
                                  placeholderText={intl.formatMessage({
                                    id: 'LABEL.SELECT_END_DATE_AND_TIME',
                                  })}
                                  showMonthDropdown
                                  useShortMonthInDropdown
                                  dateFormat='MM/dd/yyyy h:mm aa'
                                  timeInputLabel='Time:'
                                  className='form-control form-control-solid'
                                  popperPlacement='bottom'
                                  minDate={minDate}
                                />
                              </div>
                            )
                          }}
                        </Field>
                        <ErrorMessage name='end_time' component='div' className='text-danger' />
                      </div>
                    </div>

                    <div className='row mb-5 d-flex align-items-center'>
                      <label className='col-lg-4 fw-bold text-muted text-start'>
                        {' '}
                        {intl.formatMessage({id: 'LABEL.HOURS'})}
                      </label>
                      <div className='col-lg-8 text-start'>
                        <Field
                          type='text'
                          name='hours'
                          className='form-control form-control-solid'
                          value={calculateHours(values.start_time, values.end_time)}
                          placeholder={intl.formatMessage({id: 'LABEL.HOURS'})}
                          readOnly
                        />
                        <ErrorMessage name='hours' component='div' className='text-danger' />
                      </div>
                    </div>

                    <div className='card-footer p-0 py-5 text-end'>
                      <button
                        type='submit'
                        className='btn btn-sm btn-light-primary ms-2'
                        onClick={() => {
                          setIsSubmitting(true)
                        }}
                      >
                        {intl.formatMessage({id: 'BTN.ADD'})}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export default CreateTimeSheet
