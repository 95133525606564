import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router'
import {useIntl} from 'react-intl'
import secureLocalStorage from 'react-secure-storage'
import useApiCall from '../custom_hooks/useApiCall'
import {GetAllApprovedTasks} from './PerformanceAPI'
import {GetAllInputs} from './PerformanceAPI'
import {defaultProfileImage} from '../core'
import {GetAllEmpinputs} from './PerformanceAPI'
import {GetAllApprovedEmpinputs} from './PerformanceAPI'

const PerformanceNavBar = () => {
  let role
  let emp_id
  let user_id
  let is_manager
  let is_hr
  const userInfo = secureLocalStorage.getItem('user_info')
  if (userInfo) {
    ;({emp_id, user_id, role, is_manager, is_hr} = userInfo)
  }

  const location = useLocation()
  const intl = useIntl()
  const {data, isLoading} = useApiCall('/employee/details', emp_id)
  const userData = data ? data[0] : null

  const {performanceData} = GetAllInputs()
  const {approvedtasks} = GetAllApprovedTasks()
  const {allinputData} = GetAllEmpinputs()
  const {allapprovedinputs} = GetAllApprovedEmpinputs()

  return (
    <div className='card mb-4 mb-xl-6'>
      <div className='card-body pt-8 pb-0'>
        <>
          <div className='d-flex flex-wrap flex-sm-nowrap'>
            <div className='me-4 mb-3'>
              <div className='symbol symbol-100px symbol-lg-150px symbol-fixed position-relative default-cursor'>
                <img
                  src={
                    userData?.employee_profile_pic
                      ? userData?.employee_profile_pic
                      : defaultProfileImage
                  }
                  alt='img'
                />
              </div>
            </div>
            {userData ? (
              <div className='flex-grow-1'>
                <div className='d-flex justify-content-between align-items-start flex-wrap'>
                  <div className='d-flex flex-column'>
                    <div className='d-flex align-items-center mb-2'>
                      <p className='text-gray-800 text-hover-primary fs-2 fw-bolder  me-1 default-cursor mb-0'>
                        {`${userData?.first_name}${
                          userData?.middle_name ? ' ' + userData?.middle_name : ''
                        } ${userData?.last_name}`}
                      </p>
                    </div>

                    <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-0'>
                      <p className='d-flex align-items-center text-gray-400 text-hover-primary  me-5 mb-2 default-cursor'>
                        <KTIcon iconName='profile-circle' className='fs-4 me-1' />
                        {userData?.designation_name}
                      </p>
                      <p className='d-flex align-items-center text-gray-400 text-hover-primary  me-5 mb-2 default-cursor'>
                        <KTIcon iconName='geolocation' className='fs-4 me-1' />
                        {userData?.location_name}
                      </p>
                      <p className='d-flex align-items-center text-gray-400 text-hover-primary   mb-2 default-cursor'>
                        <KTIcon iconName='sms' className='fs-4 me-1' />
                        {userData?.email}
                      </p>
                    </div>
                  </div>
                </div>

                <div className='d-flex flex-wrap flex-stack'>
                  <div className='d-flex flex-column flex-grow-1 pe-8'>
                    <div className='d-flex flex-wrap'>
                      <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                        <div className='d-flex align-items-center'>
                          <div className='fs-2 fw-bolder'>
                            {performanceData?.length > 0 ? performanceData?.length : 0}
                          </div>
                        </div>

                        <div className='fw-bold fs-6 text-gray-400'>
                          {intl.formatMessage({id: 'MENU.NO_OF_INPUTS'})}
                        </div>
                      </div>

                      <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                        <div className='d-flex align-items-center'>
                          <div className='fs-2 fw-bolder'>
                            {approvedtasks?.length > 0 ? approvedtasks?.length : 0}
                          </div>
                        </div>

                        <div className='fw-bold fs-6 text-gray-400'>
                          {intl.formatMessage({id: 'MENU.APPROVED_INPUTS'})}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className='m-auto d-flex flex-column align-items-center'>
                {isLoading ? (
                  <div className='spinner-border spinner-primary mr-15'></div>
                ) : (
                  <h3> {intl.formatMessage({id: 'LABEL.NO_DATA'})}</h3>
                )}
              </div>
            )}
          </div>

          <div className='d-flex overflow-auto h-55px'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
              {/* <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary text-grey me-6 ` +
                  (location.pathname == '/performance-management/objectives' && 'active')
                }
                to='/performance-management/objectives'
              >
                Assigning KPI
              </Link>
            </li> */}

              {/* <li className='nav-item'>
                  <Link
                    className={
                      `nav-link text-active-primary text-grey me-6 ` +
                      (location.pathname == '/performance-management/team-reviews' && 'active')
                    }
                    to='/performance-management/team-reviews'
                  >
                    {intl.formatMessage({id: 'MENU.PERFORMANCE_MANAGEMENT.TEAM_REVIEW'})}
                  </Link>
                </li> */}

              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary text-grey me-6 ` +
                    (location.pathname == '/performance-management/assigningkpi' && 'active')
                  }
                  to='/performance-management/assigningkpi'
                >
                  My KPI
                </Link>
              </li>

              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary text-grey me-6 ` +
                    (location.pathname == '/performance-management/my-inputs' && 'active')
                  }
                  to='/performance-management/my-inputs'
                >
                  {intl.formatMessage({id: 'MENU.PERFORMANCE_MANAGEMENT.MY_INPUTS'})}
                </Link>
              </li>
              {is_manager === 1 && is_hr === 1 && (
                <li className='nav-item'>
                  <Link
                    className={`nav-link text-active-primary text-grey me-6 ${
                      location.pathname === '/performance-management/objectives' ? 'active' : ''
                    }`}
                    to='/performance-management/objectives'
                  >
                    Assigning KPI
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </>
      </div>
    </div>
  )
}

export default PerformanceNavBar
