import {KTIcon} from '../../../../_metronic/helpers'
import {useIntl} from 'react-intl'
import Manager from './reviewers_flow/Manager'
import HR from './reviewers_flow/HR'
import Finance from './reviewers_flow/Finance'
import DateFormatter from '../../DateFormatter'

const TeamMemberExpInfoSD = ({details}) => {
  const managerDetails = {
    manager_reviewed_status: details?.manager_reviewed_status,
    manager_reviewed_amount: details?.manager_reviewed_amount,
    manager_review_comments: details?.manager_review_comments,
    expense_amount: details?.expense_amount,
    emp_expense_id: details?.emp_expense_id,
    manager_reviewed_timestamp: details?.manager_reviewed_timestamp,
    currency_code: details?.currency_code,
    itemStatus: details?.expense_status,
    employee_id: details?.employee_id,
  }
  const hrDetails = {
    manager_reviewed_status: details?.manager_reviewed_status,
    hr_reviewed_status: details?.hr_reviewed_status,
    hr_reviewed_amount: details?.hr_reviewed_amount,
    hr_review_comments: details?.hr_review_comments,
    expense_amount: details?.expense_amount,
    emp_expense_id: details?.emp_expense_id,
    hr_reviewed_timestamp: details?.hr_reviewed_timestamp,
    currency_code: details?.currency_code,
    itemStatus: details?.expense_status,
    employee_id: details?.employee_id,
  }
  const financerDetails = {
    manager_reviewed_status: details?.manager_reviewed_status,
    hr_reviewed_status: details?.hr_reviewed_status,
    finance_reviewed_status: details?.finance_reviewed_status,
    finance_reviewed_amount: details?.finance_reviewed_amount,
    finance_review_comments: details?.finance_review_comments,
    expense_amount: details?.expense_amount,
    emp_expense_id: details?.emp_expense_id,
    finance_reviewed_timestamp: details?.finance_reviewed_timestamp,
    currency_code: details?.currency_code,
    itemStatus: details?.expense_status,
    employee_id: details?.employee_id,
  }
  const intl = useIntl()

  const getCurrMonth = () => {
    const currentDate = new Date()
    const month = currentDate.toLocaleString('default', {month: 'long'})
    return month
  }

  const getCurrYear = () => {
    const currentDate = new Date()
    const year = currentDate.getFullYear()
    return year
  }

  const getStatusColor = (status) => {
    switch (status) {
      case 'Approved':
        return 'success'
      case 'Rejected':
        return 'danger'
      default:
        return 'muted'
    }
  }

  return (
    <div className='card shadow-none rounded-0'>
      <div
        id='teamMemberExpInfo'
        className='bg-body'
        data-kt-drawer='true'
        data-kt-drawer-name='activities'
        data-kt-drawer-activate='true'
        data-kt-drawer-overlay='true'
        data-kt-drawer-width="{default:'60%', 'md':'45%', 'lg': '400px'}"
        data-kt-drawer-direction='end'
        data-kt-drawer-toggle={`#teamMemberExpInfoToggle`}
        data-kt-drawer-close={`#teamMemberExpInfoDrawerClose`}
      >
        <div className='w-100'>
          <div className='card-header px-8' id='kt_activities_header'>
            <h3 className='card-title fw-bolder text-dark default-cursor'>
              {intl.formatMessage({id: 'LABEL.EXP_INFORMATION'})}
            </h3>

            <div className='card-toolbar'>
              <button
                type='button'
                className='btn btn-sm btn-icon btn-active-light-primary me-n5'
                id='teamMemberExpInfoDrawerClose'
              >
                <KTIcon iconName='cross' className='fs-1' />
              </button>
            </div>
          </div>

          {details ? (
            <div className='' id='side_activities_body'>
              <div className='m-8 mt-6 mb-7'>
                <div className='row mb-2'>
                  <label className='col-lg-4 fw-bold text-muted text-start'>
                    {intl.formatMessage({id: 'LABEL.CATEGORY'})}
                  </label>

                  <div className='col-lg-8 text-start'>
                    <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                      {details.expense_name}
                    </span>
                  </div>
                </div>

                <div className='row mb-2'>
                  <label className='col-lg-4 fw-bold text-muted text-start'>
                    {intl.formatMessage({id: 'LABEL.STATUS'})}
                  </label>

                  <div className='col-lg-8 text-start'>
                    <span
                      className={`text-${getStatusColor(
                        details?.expense_status
                      )} fw-bolder fs-6 text-hover-primary default-cursor`}
                    >
                      {details?.expense_status}
                    </span>
                  </div>
                </div>

                <div className='row mb-2'>
                  <label className='col-lg-4 fw-bold text-muted text-start'>
                    {intl.formatMessage({id: 'LABEL.EXPENSE_DATE'})}
                  </label>

                  <div className='col-lg-8 text-start'>
                    <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                      {details.expense_date ? DateFormatter(details.expense_date) : '-'}
                    </span>
                  </div>
                </div>

                <div className='row mb-2'>
                  <label className='col-lg-4 fw-bold text-muted text-start'>
                    {intl.formatMessage({id: 'LABEL.SUBMITTED_DATE'})}
                  </label>

                  <div className='col-lg-8 text-start'>
                    <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                      {details.created_timestamp ? DateFormatter(details.created_timestamp) : '-'}
                    </span>
                  </div>
                </div>

                <div className='row mb-2'>
                  <label className='col-lg-4 fw-bold text-muted text-start'>
                    {intl.formatMessage({id: 'LABEL.BILLED_TO'})}
                  </label>

                  <div className='col-lg-8 text-start'>
                    <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                      {details?.is_billed_to_project == 0
                        ? intl.formatMessage({id: 'LABEL.COMPANY'})
                        : intl.formatMessage({id: 'LABEL.PROJECT'})}
                    </span>
                  </div>
                </div>

                {details?.is_billed_to_project == 1 && (
                  <div className='row mb-2'>
                    <label className='col-lg-4 fw-bold text-muted text-start'>
                      {intl.formatMessage({id: 'LABEL.PROJECT_NAME'})}
                    </label>

                    <div className='col-lg-8 text-start'>
                      <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                        {details?.project_name ? details?.project_name : '-'}
                      </span>
                    </div>
                  </div>
                )}

                <h5 className='mt-4 text-dark default-cursor'>
                  {intl.formatMessage({id: 'LABEL.EXPENSE_DETAILS'})}
                </h5>
                <hr className='text-dark mt-2' />
                <div className='d-flex justify-content-between align-items-center default-cursor'>
                  <p className='d-flex align-items-center'>
                    <KTIcon iconName='cheque' className='fs-1' />
                    <span className='fw-bold text-muted ms-3'>
                      {intl.formatMessage({id: 'LABEL.REQUESTED_AMOUNT'})}
                    </span>
                  </p>
                  <p className='fw-bold text-dark text-hover-primary'>
                    {details.currency_code} {details.expense_amount}
                  </p>
                </div>
                {details?.finance_reviewed_status === 'Approved' && (
                  <div className='d-flex justify-content-between align-items-center default-cursor'>
                    <p className='d-flex align-items-center'>
                      <KTIcon iconName='check-square' className='fs-1' />
                      <span className='fw-bold text-success bg-opacity-75 ms-3'>
                        {intl.formatMessage({id: 'LABEL.APPROVED_AMOUNT'})}
                      </span>
                    </p>
                    <p className='fw-bold text-success text-hover-primary'>
                      {details.currency_code} {details?.finance_reviewed_amount}
                    </p>
                  </div>
                )}
                {details?.finance_reviewed_status === 'Rejected' ||
                details?.hr_reviewed_status === 'Rejected' ||
                details?.manager_reviewed_status === 'Rejected' ? (
                  <div className='d-flex justify-content-between align-items-center default-cursor'>
                    <p className='d-flex align-items-center'>
                      <KTIcon iconName='cross' className='fs-1' />
                      <span className='fw-bold text-danger bg-opacity-75 ms-3'>
                        {intl.formatMessage({id: 'LABEL.REJ_AMOUNT'})}
                      </span>
                    </p>
                    <p className='fw-bold text-danger text-hover-primary'>
                      {details.currency_code} {details?.expense_amount}
                    </p>
                  </div>
                ) : null}

                {details?.finance_reviewed_status === 'Approved' && (
                  <p className='bg-light-secondary p-3 rounded default-cursor'>
                    The request has been approved and is expected to be paid out as part of the{' '}
                    {getCurrMonth()} {getCurrYear()} payroll cycle.
                  </p>
                )}

                <h5 className='mt-4 text-dark default-cursor'>
                  {intl.formatMessage({id: 'LABEL.WORKFLOW'})}
                </h5>
                <hr className='text-dark mt-2' />
                <div className='card-body position-relative p-0' id='kt_activities_body'>
                  <div className='timeline'>
                    <Manager managerDetails={managerDetails} />
                    <HR hrDetails={hrDetails} />
                    <Finance financerDetails={financerDetails} />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <p>{intl.formatMessage({id: 'NO_DATA_FOUND'})}</p>
          )}
        </div>
      </div>
    </div>
  )
}

export {TeamMemberExpInfoSD}
