import {useEffect, useMemo, useState} from 'react'

import {useTable, usePagination} from 'react-table'
import usePageTitle from '../../custom_hooks/usePageTitle'
import {Pagination} from 'react-bootstrap'
import useApiCall from '../../custom_hooks/useApiCall'
import {KTIcon} from '../../../../_metronic/helpers'
import DateFormatter from '../../DateFormatter'
import {useIntl} from 'react-intl'

const DataTable = ({columns, data, loading}) => {
  const [inputVal, setInputVal] = useState('')
  const intl = useIntl()
  const filteredData = useMemo(() => {
    return data?.filter(
      (each) =>
        each.candidate_first_name?.toLocaleLowerCase().includes(inputVal.toLocaleLowerCase()) ||
        each.role_name?.toLocaleLowerCase().includes(inputVal.toLocaleLowerCase())
    )
  }, [data, inputVal])
  usePageTitle('All Offers')
  const TableInstance = useTable({columns, data: filteredData}, usePagination)

  const activePage = TableInstance.page
  const activePageCount = TableInstance.pageCount
  const activePageIndex = TableInstance.state.pageIndex
  const activePageSize = TableInstance.state.pageSize

  // Handle pagination for active dataset
  const gotoActivePage = (pageIndex) => {
    TableInstance.gotoPage(pageIndex)
  }

  const setActivePageSize = (pageSize) => {
    TableInstance.setPageSize(pageSize)
  }

  // Handle pagination for inactive dataset

  const pageOptions = [5, 10, 20, 30, 40, 50]

  return (
    <>
      {loading ? (
        <div className='card h-250px'>
          <div className='m-auto d-flex flex-column align-items-center'>
            <div className='spinner-border spinner-primary mr-15'></div>
          </div>
        </div>
      ) : data.length > 0 ? (
        <>
          <div className='d-flex flex-wrap flex-stack mb-6'>
            <h3 className='fw-bolder my-2 ms-1'>
              {intl.formatMessage({id: 'LABEL.ALL_OFFERS'})}
              {/* <span className='fs-6 text-gray-400 fw-bold ms-2'>Over 500 members</span> */}
            </h3>
            <div className='d-flex'>
              <div className='d-flex my-2'>
                <div className='d-flex align-items-center position-relative me-4'>
                  <KTIcon iconName='magnifier' className='fs-3 position-absolute ms-3' />
                  <input
                    type='search'
                    id='kt_filter_search'
                    className='form-control form-control-white form-control-sm w-150px ps-9'
                    placeholder={intl.formatMessage({id: 'LABEL.SEARCH'})}
                    value={inputVal}
                    onChange={(e) => setInputVal(e.target.value)}
                  />
                </div>
              </div>

              {/* <div className='d-flex my-2'>
                <Link className='btn btn-secondary btn-sm me-4' to='/recruitment/interviews'>
                  <KTIcon iconName='black-left' className='fs-4' />
                  {intl.formatMessage({id: 'AUTH.GENERAL.BACK_BUTTON'})}
                </Link>
              </div> */}
            </div>
          </div>

          <div className='card mb-5 mb-xl-8 p-10'>
            <table className='table table-row-dashed table-row-gray-300 align-middle'>
              <thead>
                <tr className='fw-bold text-muted'>
                  <th className='min-w-150px'>
                    {intl.formatMessage({id: 'MENU.RECRUITMENT.CANDIDATES'})}
                  </th>
                  <th className='min-w-140px'>
                    {' '}
                    {intl.formatMessage({id: 'LABEL.SELECTED_ROLE'})}
                  </th>
                  <th className='min-w-120px text-center'>
                    {intl.formatMessage({id: 'LABEL.SELECTED_TITLE'})}
                  </th>

                  <th className='min-w-100px text-center'>
                    {intl.formatMessage({id: 'LABEL.DATE_OF_JOINING'})}
                  </th>
                  <th className='min-w-100px text-center'>
                    {intl.formatMessage({id: 'LABEL.STATUS'})}
                  </th>
                  <th className='min-w-100px text-start'>
                    {intl.formatMessage({id: 'MENU.ACTIONS'})}
                  </th>
                </tr>
              </thead>

              <tbody {...TableInstance.getTableBodyProps()}>
                {activePage.length > 0 ? (
                  activePage.map((row) => {
                    TableInstance.prepareRow(row)
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => (
                          <td className=' ' {...cell.getCellProps()}>
                            {cell.render('Cell')}
                          </td>
                        ))}
                      </tr>
                    )
                  })
                ) : (
                  <tr>
                    <td className='card'>
                      <p className='text-dark fw-bold text-hover-primary d-block fs-6 m-0 my-5'>
                        {intl.formatMessage({id: 'NO_RESULTS_FOUND_MSG'})}
                      </p>
                    </td>
                    <td>
                      <p></p>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            {data.length > 5 && (
              <Pagination>
                <Pagination.First
                  onClick={() => gotoActivePage(0)}
                  disabled={!TableInstance.canPreviousPage}
                />
                <Pagination.Prev
                  onClick={TableInstance.previousPage}
                  disabled={!TableInstance.canPreviousPage}
                />
                {[...Array(activePageCount)].map((_, i) => (
                  <Pagination.Item
                    key={i}
                    active={i === activePageIndex}
                    onClick={() => gotoActivePage(i)}
                  >
                    {i + 1}
                  </Pagination.Item>
                ))}
                <Pagination.Next
                  onClick={TableInstance.nextPage}
                  disabled={!TableInstance.canNextPage}
                />
                <Pagination.Last
                  onClick={() => gotoActivePage(activePageCount - 1)}
                  disabled={!TableInstance.canNextPage}
                />
                <div className='d-flex align-items-center mx-5'>
                  <span className='me-2'>{intl.formatMessage({id: 'LABEL.SHOW'})}</span>
                  <select
                    className='form-select form-select-solid fw-bold w-75px me-2'
                    value={activePageSize}
                    onChange={(e) => setActivePageSize(Number(e.target.value))}
                  >
                    {pageOptions.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
              </Pagination>
            )}
          </div>
        </>
      ) : (
        <div className='card h-250px'>
          <div className='m-auto d-flex flex-column align-items-center'>
            <h1 className='mb-5'>{intl.formatMessage({id: 'LABEL.NO_OFFERS_FOUND'})}</h1>
          </div>
        </div>
      )}
    </>
  )
}
const OffersTable = () => {
  const {data: TableData, isLoading, fetchData} = useApiCall(`/recruitment/offers`)
  const intl = useIntl()

  useEffect(() => {
    fetchData()
  }, [])
  const columns = useMemo(
    () => [
      {
        Header: 'Candidate',

        accessor: 'candidate',

        Cell: ({row}) => {
          return (
            <div className='d-flex align-items-center'>
              <div className='d-flex justify-content-start flex-row'>
                <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                  {row.original.candidate_first_name} {row.original.candidate_last_name}
                </a>
                {/* 
                <span className='text-muted fw-semibold text-muted d-block fs-7 text-start'>
                  {row.original.employeeId}
                </span> */}
              </div>
            </div>
          )
        },

        disableSortBy: true,
      },

      {
        Header: 'Role Selected',

        accessor: 'role',

        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                {row.original.job_role_name}
              </a>
            </div>
          )
        },
      },

      {
        Header: 'Interviewed By',

        accessor: 'Interviewed by',

        Cell: ({row}) => {
          return (
            <div className='text-center'>
              <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                {row.original.job_title_name}
              </a>
            </div>
          )
        },
      },
      {
        Header: 'Issue Date',

        accessor: 'Date',

        Cell: ({row}) => {
          return (
            <div className='text-center'>
              <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                {DateFormatter(row.original.date_of_joining)}
              </a>
            </div>
          )
        },
      },
      {
        Header: 'Status',

        accessor: 'status',

        Cell: ({row}) => {
          return (
            <div className='text-center'>
              <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                {row.original.offer_status}
              </a>
            </div>
          )
        },
      },

      {
        Header: 'Actions',

        accessor: 'Actions',

        Cell: ({row}) => {
          return (
            <div className='d-flex justify-content-start flex-shrink-0'>
              <button
                className='btn btn-light-primary btn-sm me-1'
                onClick={() => {
                  if (row.original.attachments) {
                    window.open(row.original.attachments, '_blank')
                  } else {
                    alert('Not Found')
                  }
                }}
              >
                <KTIcon iconName='eye' className='fs-3 ' />
              </button>
            </div>
          )
        },
      },
    ],

    []
  )

  return <DataTable columns={columns} data={TableData ? TableData : []} loading={isLoading} />
}

export default OffersTable
