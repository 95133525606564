import {Formik, Form, Field, ErrorMessage} from 'formik'
import * as Yup from 'yup'

import Swal from 'sweetalert2'
import {useState, useEffect} from 'react'
import {useIntl} from 'react-intl'

import secureLocalStorage from 'react-secure-storage'
import {baseUrl} from '../../core'

import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import useApiCall from '../../custom_hooks/useApiCall'
import {KTIcon} from '../../../../_metronic/helpers'
import usePostApi from '../../custom_hooks/usePostApi'

const AddCandidateDrawer = ({props, fetchData}) => {
  const {execute} = usePostApi()
  const [data, setData] = useState(null)
  const {data: vacancyDetails} = useApiCall('/recruitment/vacancies')
  const {data: countries} = useApiCall('/country')
  const {data: jobroles} = useApiCall('/hrConfig/jobRole')
  const organizationId = secureLocalStorage.getItem('organization_id')
  const [countryId, setCountryId] = useState(null)
  const [stateId, setStateId] = useState(null)

  const {
    data: states,
    isLoading,
    fetchData: fetchStates,
  } = useApiCall(countryId ? `/state/${countryId}` : null)
  const {
    data: citiesData,
    isLoading: citiesLoading,
    fetchData: fetchCities,
  } = useApiCall(stateId ? `/city/${stateId}` : null)
  let cities = citiesData ? citiesData : null

  useEffect(() => {
    fetchStates()
    cities = []
  }, [countryId])
  useEffect(() => {
    fetchCities()
  }, [stateId])

  let emp_id
  let user_id
  const userInfo = secureLocalStorage.getItem('user_info')
  if (userInfo) {
    ;({emp_id, user_id} = userInfo)
  }
  const intl = useIntl()

  const [isSubmitting, setIsSubmitting] = useState(false)

  const validationSchemaEdit = Yup.object().shape({
    first_name: Yup.string().required(
      `${intl.formatMessage({id: 'ERR.MSG.FIRST_NAME_IS_REQUIRED'})}`
    ),
    last_name: Yup.string().required(
      `${intl.formatMessage({id: 'ERR.MSG.LAST_NAME_IS_REQUIRED'})}`
    ),
    city_name: Yup.string().required(`${intl.formatMessage({id: 'LABEL.CITY_NAME_REQUIRED'})}`),
    state_name: Yup.string().required(`${intl.formatMessage({id: 'LABEL.STATE_NAME_REQUIRED'})}`),
    country_name: Yup.string().required(
      `${intl.formatMessage({id: 'LABEL.COUNTRY_NAME_REQUIRED'})}`
    ),
    email: Yup.string()
      .email(`${intl.formatMessage({id: 'ERR.MSG.INVALID_EMAIL_ADDRESS'})}`)
      .required(`${intl.formatMessage({id: 'ERR.MSG.EMAIL_IS_REQUIRED'})}`),
    contact_number: Yup.string()
      .matches(/^[0-9]+$/, `${intl.formatMessage({id: 'ERR.MSG.INVALID_PHONE_NUMBER'})}`)
      .required(`${intl.formatMessage({id: 'ERR.MSG.CONTACT_NO_REQUIRED'})}`),

    education: Yup.string().required(
      `${intl.formatMessage({id: 'ERR.MSG.EDUCATION_IS_REQUIRED'})}`
    ),
    experience_in_yrs: Yup.number()

      .moreThan(
        -1,
        `${intl.formatMessage({id: 'ERR.MSG.EXPERIENCE_MUST_BE_A_POSITIVE_NUMBER_OR_ZERO'})}`
      )
      .required(`${intl.formatMessage({id: 'ERR.MSG.EXPERIENCE_IS_REQUIRED'})}`),

    keywords: Yup.string().required(`${intl.formatMessage({id: 'ERR.MSG.KEYWORDS_ARE_REQUIRED'})}`),
    // source_of_hire: Yup.string().required(
    //   `${intl.formatMessage({id: 'ERR.MSG.SOURCE_OF_HIRE_REQUIRED'})}`
    // ),
    method_of_application: Yup.string().required(
      `${intl.formatMessage({id: 'ERR.MSG.METHOD_OF_APPLICATION'})}`
    ),
  })

  const initialValues = {
    city_name: '',
    state_name: '',
    country_name: '',
    first_name: '',
    last_name: '',
    email: '',
    contact_number: '',
    resume: null,
    education: '',
    experience_in_yrs: '',
    keywords: '',
    source_of_hire: '',
    method_of_application: '',
    job_role_id: '',
    vacancy_id: '',
  }

  const addCandidate = async (values, formikHelpers) => {
    const {resetForm} = formikHelpers

    try {
      const url = `${baseUrl}/recruitment/candidates`
      const formData = new FormData()
      const documentName = `${Date.now()}`

      const file = dataURLtoFile(data, `image_${documentName}`)

      formData.append('resume', file)

      formData.append('organization_id', organizationId)
      formData.append('first_name', values.first_name)
      formData.append('last_name', values.last_name)
      formData.append('email', values.email)
      formData.append('contact_number', values.contact_number)
      formData.append('education', values.education)
      formData.append('experience_in_yrs', values.experience_in_yrs)
      formData.append('keywords', values.keywords)
      formData.append('method_of_application', values.method_of_application)
      formData.append('job_role_id', values.job_role_id)
      // formData.append('source_of_hire', values.source_of_hire)
      formData.append('vacancy_id', props)
      formData.append('city_id', parseInt(values.city_name))
      formData.append('state_id', parseInt(values.state_name))
      formData.append('country_id', parseInt(values.country_name))
      formData.append('offer_status', 'Pending')

      await execute(url, 'POST', formData)

      Swal.fire({
        title: intl.formatMessage({id: 'LABEL.CANDIDATE_ADDED_SUCCESSFULLY'}), // Use the successMessage variable
        text: 'You can now track the status of the Candidate in the “Candidates” section..',
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-light-primary btn-sm',
        },
      }).then((result) => {
        if (result.isConfirmed) {
          resetForm()
          setData(null)
          const sideDrawer = document.getElementById('employeeInfoDrawerClose')
          if (sideDrawer) {
            sideDrawer.click()
          }
        }
      })
    } catch (error) {
      console.error('Error:', error)

      Swal.fire({
        icon: 'error',
        title: intl.formatMessage({id: 'LABEL.OOPS'}),
        text: intl.formatMessage({id: 'LABEL.SOMETHING_WRONG'}),
        customClass: {
          confirmButton: 'btn btn-light-primary btn-sm',
        },
      }).then((result) => {
        if (result.isConfirmed) {
          const sideDrawer = document.getElementById('employeeInfoDrawerClose')
          if (sideDrawer) {
            sideDrawer.click()
          }
        }
      })
    }
  }

  const handleSubmit = (values, {resetForm}) => {
    addCandidate(values, resetForm)

    fetchData()
  }

  const handleCancel = (resetForm, touched) => {
    if (Object.keys(touched).length === 0) {
      const sideDrawer = document.getElementById('employeeInfoDrawerClose')
      if (sideDrawer) {
        sideDrawer.click()
      }
    } else {
      resetForm()
    }
  }

  const dataURLtoFile = (dataURI, fileName) => {
    if (!dataURI) {
      return null // Add a null check to handle cases where dataURI is null
    }

    const byteString = atob(dataURI.split(',')[1])
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
    const ab = new ArrayBuffer(byteString.length)
    const ia = new Uint8Array(ab)

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i)
    }

    return new File([ab], fileName, {type: mimeString})
  }

  return (
    <div className='card shadow-none rounded-0'>
      <div
        id='candidateinfo'
        className='bg-body'
        data-kt-drawer='true'
        data-kt-drawer-name='activities'
        data-kt-drawer-activate='true'
        data-kt-drawer-overlay='true'
        data-kt-drawer-width="{default:'90%', 'lg': '500px'}"
        data-kt-drawer-direction='end'
        data-kt-drawer-toggle='#addcandidate'
        data-kt-drawer-close='#employeeInfoDrawerClose'
      >
        <div className='w-100'>
          <div className='card-header'>
            <h3 className='card-title fw-bolder text-dark'>
              {intl.formatMessage({id: 'BTN.ADD_CANDIDATE'})}
            </h3>
            <div className='card-toolbar'>
              <button
                type='button'
                className='btn btn-sm btn-icon btn-active-light-primary me-n5'
                id='employeeInfoDrawerClose'
              >
                <i className='bi bi-x fs-1'></i>
              </button>
            </div>
          </div>
          <div className=' m-8 mt-6  mb-7'>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchemaEdit}
              onSubmit={handleSubmit}
            >
              {({values, setFieldValue, handleChange, touched, resetForm}) => {
                return (
                  <>
                    <Form>
                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          {intl.formatMessage({id: 'LABEL.FIRST_NAME'})}
                        </label>
                        <div className='col-lg-8 text-start'>
                          <Field
                            type='text'
                            className='form-control form-control-solid'
                            id='floatingInput'
                            name='first_name'
                            placeholder={intl.formatMessage({id: 'LABEL.FIRST_NAME'})}
                          ></Field>
                          <ErrorMessage name='first_name' component='div' className='text-danger' />
                        </div>
                      </div>
                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          {intl.formatMessage({id: 'LABEL.LAST_NAME'})}
                        </label>
                        <div className='col-lg-8 text-start'>
                          <Field
                            type='text'
                            className='form-control form-control-solid'
                            id='floatingInput'
                            name='last_name'
                            placeholder={intl.formatMessage({id: 'LABEL.LAST_NAME'})}
                          ></Field>
                          <ErrorMessage name='last_name' component='div' className='text-danger' />
                        </div>
                      </div>

                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          {intl.formatMessage({id: 'LABEL.EMAIL'})}
                        </label>
                        <div className='col-lg-8 text-start'>
                          <Field
                            type='text'
                            className='form-control form-control-solid'
                            id='floatingInput'
                            name='email'
                            placeholder={intl.formatMessage({id: 'LABEL.EMAIL'})}
                          ></Field>
                          <ErrorMessage name='email' component='div' className='text-danger' />
                        </div>
                      </div>
                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          {intl.formatMessage({id: 'LABEL.CONTACT_NO'})}
                        </label>
                        <div className='col-lg-8 text-start'>
                          <Field
                            type='text'
                            className='form-control form-control-solid'
                            id='floatingInput'
                            name='contact_number'
                            placeholder={intl.formatMessage({id: 'LABEL.CONTACT_NO'})}
                          ></Field>
                          <ErrorMessage
                            name='contact_number'
                            component='div'
                            className='text-danger'
                          />
                        </div>
                      </div>

                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          {intl.formatMessage({id: 'LABEL.COUNTRY'})}
                        </label>
                        <div className='col-lg-8 text-start'>
                          <Field
                            as='select'
                            name='country_name'
                            className='form-select form-select-solid'
                            onChange={(e) => {
                              setCountryId(e.target.value)
                              handleChange(e)
                            }}
                          >
                            <option value=''>
                              {intl.formatMessage({id: 'LABEL.SELECT_COUNTRY'})}
                            </option>
                            {countries?.map((each) => (
                              <option key={each.country_id} value={each.country_id}>
                                {each.country_name}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            name='country_name'
                            component='div'
                            className='text-danger'
                          />
                        </div>
                      </div>

                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          {intl.formatMessage({id: 'LABEL.STATE'})}
                        </label>
                        <div className='col-lg-8 text-start'>
                          <Field
                            as='select'
                            name='state_name'
                            className='form-select form-select-solid'
                            onChange={(e) => {
                              setStateId(e.target.value)
                              handleChange(e)
                            }}
                          >
                            <option value=''>
                              {intl.formatMessage({id: 'LABEL.SELECT_STATE'})}
                            </option>
                            {states?.map((each) => (
                              <option key={each.state_id} value={each.state_id}>
                                {each.state_name}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage name='state_name' component='div' className='text-danger' />
                        </div>
                      </div>

                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          {intl.formatMessage({id: 'LABEL.CITY'})}
                        </label>
                        <div className='col-lg-8 text-start'>
                          <Field
                            as='select'
                            name='city_name'
                            className='form-select form-select-solid'
                          >
                            <option value=''>
                              {intl.formatMessage({id: 'LABEL.SELECT_CITY'})}
                            </option>
                            {cities?.map((each) => (
                              <option key={each.city_id} value={each.city_id}>
                                {each.city_name}
                              </option>
                            ))}
                          </Field>

                          <ErrorMessage name='city_name' component='div' className='text-danger' />
                        </div>
                      </div>
                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          {intl.formatMessage({id: 'LABEL.EDUCATION'})}
                        </label>
                        <div className='col-lg-8 text-start'>
                          <Field
                            type='text'
                            className='form-control form-control-solid'
                            id='floatingInput'
                            name='education'
                            placeholder={intl.formatMessage({id: 'LABEL.EDUCATION'})}
                          ></Field>
                          <ErrorMessage name='education' component='div' className='text-danger' />
                        </div>
                      </div>

                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          {intl.formatMessage({id: 'LABEL.EXPERIENCE'})}
                        </label>
                        <div className='col-lg-8 text-start'>
                          <Field
                            type='number'
                            className='form-control form-control-solid'
                            id='floatingInput'
                            name='experience_in_yrs'
                            placeholder={intl.formatMessage({id: 'LABEL.EXPERIENCE'})}
                          ></Field>
                          <ErrorMessage
                            name='experience_in_yrs'
                            component='div'
                            className='text-danger'
                          />
                        </div>
                      </div>
                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          {intl.formatMessage({id: 'LABEL.KEYWORDS'})}
                        </label>
                        <div className='col-lg-8 text-start'>
                          <Field
                            type='text'
                            className='form-control form-control-solid'
                            id='floatingInput'
                            name='keywords'
                            placeholder={intl.formatMessage({id: 'LABEL.KEYWORDS'})}
                          ></Field>
                          <ErrorMessage name='keywords' component='div' className='text-danger' />
                        </div>
                      </div>

                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          {intl.formatMessage({id: 'LABEL.SELECT_JOB_ROLE'})}
                        </label>
                        <div className='col-lg-8 text-start'>
                          <Field
                            as='select'
                            className='form-select form-select-solid'
                            id='floatingInput'
                            name='job_role_id'
                            placeholder={intl.formatMessage({id: 'LABEL.SELECT_JOB_ROLE'})}
                          >
                            <option value=''>
                              {' '}
                              {intl.formatMessage({id: 'LABEL.SELECT_JOB_ROLE'})}
                            </option>
                            {jobroles?.map((vacancies) => (
                              <option key={vacancies.job_role_id} value={vacancies.job_role_id}>
                                {vacancies.role_name}
                              </option>
                            ))}
                          </Field>

                          <ErrorMessage
                            name='job_role_id'
                            component='div'
                            className='text-danger'
                          />
                        </div>
                      </div>

                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          {intl.formatMessage({id: 'LABEL.METHOD_OF_APPLICATION'})}
                        </label>
                        <div className='col-lg-8 text-start'>
                          <Field
                            type='text'
                            className='form-control form-control-solid'
                            id='floatingInput'
                            name='method_of_application'
                            placeholder={intl.formatMessage({id: 'LABEL.METHOD_OF_APPLICATION'})}
                          ></Field>
                          <ErrorMessage
                            name='method_of_application'
                            component='div'
                            className='text-danger'
                          />
                        </div>
                      </div>

                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          {intl.formatMessage({id: 'BTN.UPLOAD_DOCUMENT'})}
                        </label>

                        <div className='col-lg-8 text-start'>
                          <Field name='resume'>
                            {({form, field}) => {
                              const {setFieldValue} = form
                              const {value} = field
                              const handleImageChange = (file) => {
                                const reader = new FileReader()
                                reader.onloadend = () => {
                                  const previewImage = reader.result
                                  setData(previewImage)
                                }
                                reader.readAsDataURL(file)
                              }
                              const handleHeaderChange = (e) => {
                                const file = e.target.files?.[0]
                                setFieldValue('resume', file ? file.name : '')
                                if (file) {
                                  handleImageChange(file)
                                }
                              }
                              const handleInputClick = () => {
                                document.getElementById('get-document-from-user').click()
                              }
                              return (
                                <div>
                                  <input
                                    type='file'
                                    onChange={handleHeaderChange}
                                    id='get-document-from-user'
                                    className='form-control form-control-solid d-none'
                                  />
                                  <p
                                    className='form-control form-control-solid d-flex justify-content-between pe-10'
                                    onClick={handleInputClick}
                                  >
                                    <span>
                                      {value
                                        ? value
                                        : intl.formatMessage({id: 'LABEL.CHOOSE_FILE_TO_UPLOAD'})}
                                    </span>
                                    <span>
                                      <KTIcon
                                        iconName='paper-clip'
                                        className='fs-3 position-absolute ms-3'
                                      />
                                    </span>
                                  </p>
                                </div>
                              )
                            }}
                          </Field>
                          <ErrorMessage name='resume' component='div' className='text-danger' />
                        </div>
                      </div>
                      <div className='card-footer p-0 py-5 text-end'>
                        <button
                          type='button'
                          className='btn btn-light-primary btn-sm me-2'
                          onClick={() => handleCancel(resetForm, touched)}
                        >
                          {Object.keys(touched).length === 0 ? 'Close' : 'Clear'}
                        </button>
                        <button
                          type='submit'
                          className='btn btn-light-primary btn-sm ms-2'
                          onClick={() => {
                            setIsSubmitting(true)
                          }}
                        >
                          {intl.formatMessage({id: 'BTN.ADD_CANDIDATE'})}
                        </button>
                      </div>
                    </Form>
                  </>
                )
              }}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  )
}
export default AddCandidateDrawer
