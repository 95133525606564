import React from 'react'
import {Link, useLocation} from 'react-router-dom'
import {useIntl} from 'react-intl'
import secureLocalStorage from 'react-secure-storage'
import useApiCall from '../custom_hooks/useApiCall'
import {defaultProfileImage} from '../core'
import {KTIcon} from '../../../_metronic/helpers'
import DateFormatter from '../DateFormatter'
interface userDetails {
  first_name: string
  last_name: string
  designation_name: string
  location_name: string
  email: string
  employee_profile_pic: string
  hire_date: string
}
const PayNavigationBar: React.FC = () => {
  const location = useLocation()
  const userInfo: any | null = secureLocalStorage.getItem('user_info')
  const {emp_id} = userInfo || {}
  const intl = useIntl()
  const {data, isLoading} = useApiCall('/employee/details', emp_id)
  const userData: userDetails | null = data ? (data[0] as userDetails) : null
  return (
    <>
      <div className='card mb-4 mb-xl-6'>
        <div className='card-body pt-8 pb-0'>
          <div className='d-flex flex-wrap flex-sm-nowrap'>
            <div className='me-4 mb-3'>
              <div className='symbol symbol-100px symbol-lg-150px symbol-fixed position-relative default-cursor'>
                <img
                  src={
                    userData?.employee_profile_pic
                      ? userData?.employee_profile_pic
                      : defaultProfileImage
                  }
                  alt='img'
                />
              </div>
            </div>
            {userData ? (
              <div className='flex-grow-1'>
                <div className='d-flex justify-content-between align-items-start flex-wrap'>
                  <div className='d-flex flex-column'>
                    <div className='d-flex align-items-center mb-2'>
                      <p className='text-gray-800 text-hover-primary fs-2 fw-bolder  me-1 default-cursor mb-0'>
                        {userData?.first_name} {userData?.last_name}
                      </p>
                    </div>
                    <div className='d-flex flex-wrap fw-bold fs-6 mb-7'>
                      <p className='d-flex align-items-center text-gray-400 text-hover-primary  me-5 mb-2 default-cursor'>
                        <KTIcon iconName='profile-circle' className='fs-4 me-1' />
                        {userData?.designation_name}
                      </p>
                      <p className='d-flex align-items-center text-gray-400 text-hover-primary  me-5 mb-2 default-cursor'>
                        <KTIcon iconName='geolocation' className='fs-4 me-1' />
                        {userData?.location_name}
                      </p>
                      <p className='d-flex align-items-center text-gray-400 text-hover-primary mb-2 default-cursor'>
                        <KTIcon iconName='sms' className='fs-4 me-1' />
                        {userData?.email}
                      </p>
                    </div>
                  </div>
                </div>
                <div className='d-flex flex-wrap flex-stack'>
                  <div className='d-flex flex-column flex-grow-1 pe-8'>
                    <div className='d-flex flex-wrap'>
                      <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                        <div className='d-flex align-items-center'>
                          <div className='fw-bolder fs-6'>{DateFormatter(userData?.hire_date)}</div>
                        </div>
                        <div className='fw-bold text-gray-400 fs-6'>Joining Date</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className='m-auto d-flex flex-column align-items-center'>
                {isLoading ? (
                  <div className='spinner-border spinner-primary mr-15'></div>
                ) : (
                  <h3>No Data</h3>
                )}
              </div>
            )}
          </div>

          <div className='d-flex overflow-auto h-55px'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
              <li className='nav-item nav-item-links'>
                <Link
                  className={
                    `nav-link text-active-primary text-grey me-6 ` +
                    (location.pathname === '/payroll-management/paypage-bar/my-payslips' &&
                      'active')
                  }
                  to='/payroll-management/paypage-bar/my-payslips'
                >
                  Salary Structure
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary text-grey me-6 ` +
                    (location.pathname ===
                      ('/payroll-management/paypage-bar/payroll-batch' ||
                        '/payroll-management/paypage-bar/my-payslip') && 'active')
                  }
                  to='/payroll-management/paypage-bar/payroll-batch'
                >
                  Payslips
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary text-grey me-6 ` +
                    (location.pathname === '/payroll-management/paypage-bar/payroll-components' &&
                      'active')
                  }
                  to='/payroll-management/paypage-bar/payroll-components'
                >
                  Payroll Components
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default PayNavigationBar
