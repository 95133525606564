import {useLocation} from 'react-router-dom'
import PayNavigationBar from './PayNavigationBar'
import {PageTitle} from '../../../_metronic/layout/core'
import {useIntl} from 'react-intl'
import Payslips from './my_payslips/Payslips'
import SalaryStructure from './my_payslips/SalaryStructure'
import AnnualEarnings from './my_payslips/AnnualEarnings'
import MyPaySlips from './my_payslips/MyPaySlips'

const PayrollRoutes = () => {
  const payBreadCrumbs = [
    {
      title: 'Payroll Management',
      path: '/payroll-management/paypage-bar/my-payslips',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]
  const {pathname} = useLocation()
  const intl = useIntl()

  const switchMethod = () => {
    switch (pathname) {
      case '/payroll-management/paypage-bar/my-payslips':
        return (
          <>
            <PageTitle breadcrumbs={payBreadCrumbs}>
              {intl.formatMessage({id: 'MENU.PAYROLL_MANAGEMENT.MY_PAYSLIPS'})}
            </PageTitle>
            <SalaryStructure />
          </>
        )

      case '/payroll-management/paypage-bar/payroll-batch':
        return (
          <>
            <PageTitle breadcrumbs={payBreadCrumbs}>
              {intl.formatMessage({id: 'MENU.PAYROLL_MANAGEMENT.PAYROLL_BATCH'})}
            </PageTitle>
            <Payslips />
          </>
        )
      case '/payroll-management/paypage-bar/payroll-components':
        return (
          <>
            <PageTitle breadcrumbs={payBreadCrumbs}>
              {intl.formatMessage({id: 'MENU.PAYROLL_MANAGEMENT.PAYROLL_COMPONENTS'})}
            </PageTitle>
            <AnnualEarnings />
          </>
        )

      default:
        return (
          <>
            <PageTitle breadcrumbs={payBreadCrumbs}>
              {intl.formatMessage({id: 'MENU.PAYROLL_MANAGEMENT.MY_PAYSLIPS'})}
            </PageTitle>
            <MyPaySlips />
          </>
        )
    }
  }

  return (
    <>
      <PayNavigationBar />
      {switchMethod()}
    </>
  )
}

export default PayrollRoutes
