import {useState} from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import {DragDropContext, Droppable, Draggable, DraggableProvided} from 'react-beautiful-dnd'
import {useIntl} from 'react-intl'
import {FormikHelpers} from 'formik'
import {ErrorMessage, Field, Form, Formik} from 'formik'
import * as Yup from 'yup'
import Swal from 'sweetalert2'
import secureLocalStorage from 'react-secure-storage'
import {baseUrl} from '../../core'
import usePostApi from '../../custom_hooks/usePostApi'
import {useParams} from 'react-router-dom'
const AddChecklist = (props: any) => {
  const {refreshData} = props.props
  const intl = useIntl()
  const {execute} = usePostApi()
  const {id}: any | null = useParams()
  const userInfo: any | null = secureLocalStorage.getItem('user_info')
  const {organization_id} = userInfo || {}
  const [checklistItems, setChecklistItems] = useState<string[]>([])
  const handleSubmit = async (values: any, {resetForm}: FormikHelpers<any>) => {
    const url = `${baseUrl}/hrConfig/checklist`
    try {
      const mappedItems = checklistItems.map((item) => ({
        checklist_item_id: -1,
        item,
      }))
      const requestData = {
        organization_id: organization_id,
        checklist_id: -1,
        checklist_name: values.checklist_name,
        checklist_code: values.checklist_code,
        items: mappedItems,
        status: values.status ? 1 : 0,
      }
      await execute(url, 'POST', requestData, id)
      resetForm()
      refreshData()
      const sideDrawer = document.getElementById('side_activities_close')
      if (sideDrawer) {
        sideDrawer.click()
      }
      Swal.fire({
        title: intl.formatMessage({id: 'LABEL.ADDED_SUCCESSFULLY'}),
        text: intl.formatMessage({id: 'LABEL.CHANGES_SAVED'}),
        icon: 'success',
      })
    } catch (error) {
      console.error(error)
      Swal.fire({
        icon: 'error',
        title: intl.formatMessage({id: 'LABEL.OOPS'}),
        text: intl.formatMessage({id: 'LABEL.SOMETHING_WRONG'}),
      })
    }
  }
  const validationSchema = Yup.object().shape({
    checklist_name: Yup.string().required(intl.formatMessage({id: 'LABEL.NAME_REQUIRED'})),
    checklist_code: Yup.string().required(intl.formatMessage({id: 'LABEL.CODE_REQUIRED'})),
    item: Yup.string().required(intl.formatMessage({id: 'LABEL.CHECKLIST_ITEM_IS_REQUIRED'})),
    status: Yup.string().required(intl.formatMessage({id: 'LABEL.STATUS_REQUIRED'})),
  })

  const [inputValue, setInputValue] = useState('')
  const handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, values: any) => {
    if (event.type === 'click') {
      event.preventDefault()
      if (inputValue.trim() !== '') {
        const newItem = inputValue.trim()
        const updatedItemValue = values.item ? `${values.item},${newItem}` : newItem
        values.item = updatedItemValue
        setChecklistItems((prevItems) => [...prevItems, newItem])
        setInputValue('')
      }
    }
  }

  const handleDragEnd = (result: any) => {
    if (!result.destination) return
    const updatedTasks = Array.from(checklistItems)
    const [removed] = updatedTasks.splice(result.source.index, 1)
    updatedTasks.splice(result.destination.index, 0, removed)
    setChecklistItems(updatedTasks)
  }
  const handleRemoveTask = (index: any) => {
    const updatedTasks = [...checklistItems]
    updatedTasks.splice(index, 1)
    setChecklistItems(updatedTasks)
  }
  const handleCancel = (resetForm: any) => {
    resetForm()
  }
  return (
    <div>
      <div className='card shadow-none rounded-0'>
        <Formik
          initialValues={{
            checklist_name: '',
            checklist_code: '',
            item: '',
            status: true,
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({values, resetForm}) => {
            return (
              <Form>
                <div className='card-header' id='side_activities_header'>
                  <h3 className='card-title fw-bolder text-dark'>
                    {intl.formatMessage({id: 'LABEL.ADD_CHECKLIST_TYPE'})}
                  </h3>
                  <div className='card-toolbar '>
                    <button
                      type='button'
                      className='btn btn-sm btn-icon btn-active-light-primary me-n5'
                      id='side_activities_close'
                    >
                      <KTIcon iconName='cross' className='fs-1' />
                    </button>
                  </div>
                </div>
                <div className='card-body position-relative' id='side_activities_body'>
                  <div
                    id='side_activities_scroll'
                    className='position-relative scroll-y me-n5 pe-5 w-350px'
                    data-kt-scroll='true'
                    data-kt-scroll-height='auto'
                    data-kt-scroll-wrappers='#side_activities_body'
                    data-kt-scroll-dependencies='#side_activities_header, #side_activities_footer'
                    data-kt-scroll-offset='5px'
                  >
                    <div className='form-floating mb-4'>
                      <Field
                        type='text'
                        className='form-control'
                        id='floatingInput'
                        name='checklist_name'
                        placeholder='type...'
                      />
                      <label htmlFor='floatingInput'>
                        {intl.formatMessage({id: 'LABEL.NAME'})}
                      </label>
                      <ErrorMessage component='div' className='text-danger' name='checklist_name' />
                    </div>
                    <div className='form-floating mb-4'>
                      <Field
                        type='text'
                        className='form-control'
                        id='floatingInput'
                        placeholder='type...'
                        name='checklist_code'
                      />
                      <label htmlFor='floatingInput'>
                        {intl.formatMessage({id: 'LABEL.CODE'})}
                      </label>
                      <ErrorMessage component='div' className='text-danger' name='checklist_code' />
                    </div>

                    <div className='form-floating d-flex'>
                      <Field
                        type='text'
                        className='form-control'
                        id='item'
                        name='item'
                        onChange={(e: any) => setInputValue(e.target.value)}
                        value={inputValue}
                        onKeyDown={(e: any) => handleInputKeyDown(e, values)}
                        placeholder='Add task...'
                      />
                      <label htmlFor='item'>
                        {intl.formatMessage({id: 'LABEL.ADD_CHECKLIST_ITEMS'})}
                      </label>
                      <div
                        className='btn btn-icon btn-active-color-primary btn-sm ms-3 mt-3'
                        onClick={(e: any) => handleInputKeyDown(e, values)}
                      >
                        <KTIcon iconName='plus-circle' className='fs-2x text-success text-end' />
                      </div>
                    </div>
                    <ErrorMessage name='item' component='div' className='text-danger' />
                    <div className='form-floating mb-4 h-300px bg-shadow card overflow-auto '>
                      <div className='fw-bold fs-6 text-muted mt-4'>
                        {intl.formatMessage({id: 'MENU.HR_CONFIGURATION.CHECKLIST_ITEMS'})}:
                      </div>
                      <DragDropContext onDragEnd={handleDragEnd}>
                        <Droppable droppableId='kanbanBoard'>
                          {(provided) => (
                            <div {...provided.droppableProps} ref={provided.innerRef}>
                              {checklistItems.map((item, index) => (
                                <Draggable key={index} draggableId={index.toString()} index={index}>
                                  {(provided: DraggableProvided) => (
                                    <div
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      ref={provided.innerRef}
                                      className={`tag-item light-primary bg-secondary mb-2 card d-flex justify-content-between flex-row`}
                                    >
                                      <span className=' fs-6 p-2'>{item}</span>
                                      <div
                                        className='btn btn-icon btn-active-color-primary btn-sm'
                                        onClick={() => handleRemoveTask(index)}
                                      >
                                        <KTIcon
                                          iconName='cross-circle'
                                          className='fs-1 text-danger text-end'
                                        />
                                      </div>
                                    </div>
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </div>
                    <div className='form-floating flex-fill'>
                      <div className='form-check form-check-solid form-switch mb-0'>
                        <Field
                          className='form-check-input w-40px h-20px'
                          type='checkbox'
                          name='status'
                          id='toggle'
                        />
                        <span
                          className={`me-4 fw-bold ${
                            values.status ? 'text-success' : 'text-danger'
                          }`}
                        >
                          {values.status ? 'Active' : 'Inactive'}
                        </span>
                        <ErrorMessage component='div' className='text-danger' name='status' />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='card-footer text-end' id='side_activities_footer'>
                  <div
                    className='btn btn-sm btn-secondary m-2'
                    onClick={() => handleCancel(resetForm)}
                    id='side_activities_close'
                  >
                    {intl.formatMessage({id: 'LABEL.CANCEL'})}
                  </div>
                  <button className='btn btn-light-primary btn-sm' type='submit'>
                    <KTIcon iconName='check' className='fs-7' />
                    {intl.formatMessage({id: 'BTN.SAVE'})}
                  </button>
                </div>
              </Form>
            )
          }}
        </Formik>
      </div>
    </div>
  )
}

export {AddChecklist}
