import React, {useEffect, useState} from 'react'
import Select, {components} from 'react-select'
import {useThemeMode} from '../../../../../_metronic/partials'
import {useIntl} from 'react-intl'
const Option = (props) => {
  const {darkMode, ...restProps} = props
  return (
    <div style={{backgroundColor: darkMode ? '#1e1e2d' : 'white'}}>
      <components.Option {...restProps}>
        <input
          type='checkbox'
          id='standard_week_offs'
          name='standard_week_offs'
          checked={props.isSelected}
          onChange={() => null}
          style={{transform: 'scale(0.7)'}}
        />
        <label>{props.label}</label>
      </components.Option>
    </div>
  )
}

export default function Multiselection(props) {
  const intl = useIntl()
  const {isEditMode, onChange, id, name} = props

  const weekOptions = [
    {value: 'Sunday', label: 'Sun'},
    {value: 'Monday', label: 'Mon'},
    {value: 'Tuesday', label: 'Tue'},
    {value: 'Wednesday', label: 'Wed'},
    {value: 'Thursday', label: 'Thu'},
    {value: 'Friday', label: 'Fri'},
    {value: 'Saturday', label: 'Sat'},
  ]

  const handleSelectedChange = (selected) => {
    const selectedValues = selected ? selected.map((option) => option.value) : []
    onChange(selectedValues)
  }
  const [optionSelected, setOptionSelected] = useState(null)
  const [darkMode, setDarkMode] = useState('') // Add darkMode state
  const {mode} = useThemeMode()
  useEffect(() => {
    setDarkMode(mode !== 'light')
  }, [mode])

  const handleChange = (selected) => {
    setOptionSelected(selected)
    handleSelectedChange(selected) // Call the handleSelectedChange function to update the selected values
  }

  const toggleDarkMode = () => {
    setDarkMode(!darkMode)
  }

  const selectStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: darkMode ? '#1e1e2d' : '',
      color: '#1e1e2d',
      borderWidth: '0.5px', // Adjust the border size
      borderColor: darkMode ? '#323248' : 'lightgray', // Adjust the border color
      borderRadius: '4px', // Adjust the border radius if needed
      height: '50px',
    }),
    menuPortal: (base) => ({
      ...base,
      zIndex: 9999,
      height: '240px', // adjust the height as needed
      overflowY: 'scroll',
      backgroundColor: darkMode ? '#1e1e2d' : '', // Adjust background color for dark mode
      color: darkMode ? 'white' : 'black', // Adjust text color for dark mode
    }),
  }

  return (
    <span data-toggle='popover' data-trigger='focus'>
      <Select
        options={weekOptions}
        isMulti
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        components={{
          Option: (props) => <Option {...props} darkMode={darkMode} />,
        }}
        onChange={handleChange}
        allowSelectAll={!isEditMode}
        value={optionSelected}
        menuPortalTarget={document.body}
        id={id}
        name={name}
        menuPosition={'absolute'}
        styles={selectStyles}
        placeholder={intl.formatMessage({id: 'LABEL.ORGANIZATION_WEEK_OFF'})}
      />
    </span>
  )
}
