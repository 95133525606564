import {useEffect, useState} from 'react'
import useApiCall from '../custom_hooks/useApiCall'
import secureLocalStorage from 'react-secure-storage'
export function GetAllAssets() {
  const {
    data: allassetsData,
    isLoading: isassetLoading,
    fetchData,
  } = useApiCall(`/assetManagement/asset/all`)

  useEffect(() => {
    fetchData()
  }, [])

  return {allassetsData, isassetLoading}
}

export function GetMyAssets() {
  let emp_id
  let user_id
  const userInfo = secureLocalStorage.getItem('user_info')
  if (userInfo) {
    ;({emp_id, user_id} = userInfo)
  }
  const {
    data: myassetData,
    isLoading: ismyassetLoading,
    fetchData,
  } = useApiCall(`/assetManagement/assets/${emp_id}`)

  useEffect(() => {
    fetchData()
  }, [])

  return {myassetData, ismyassetLoading}
}
