import React, {useState, useEffect} from 'react'

import {useIntl} from 'react-intl'

import LeaveTypesTable from './LeaveTypesTable'
import secureLocalStorage from 'react-secure-storage'
import usePageTitle from '../../custom_hooks/usePageTitle'
import useApiCall from '../../custom_hooks/useApiCall'

function LeaveTypes() {
  const intl = useIntl()
  usePageTitle(intl.formatMessage({id: 'LABEL.LEAVE_TYPES'}))

  const {data: leavetypesDetails, isLoading, fetchData} = useApiCall(`/leave/types`)

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <>
      {isLoading ? (
        <div className='card h-250px'>
          <div className='m-auto d-flex flex-column align-items-center'>
            <div className='spinner-border spinner-primary mr-15'></div>
          </div>
        </div>
      ) : leavetypesDetails && leavetypesDetails.length > 0 ? (
        <LeaveTypesTable leavetypesData={leavetypesDetails} />
      ) : (
        <div className='card h-250px'>
          <div className='m-auto d-flex flex-column align-items-center default-cursor'>
            <h1 className='mb-5'>{intl.formatMessage({id: 'MSG.NO_LEAVES_FOUND'})}</h1>
          </div>
        </div>
      )}
    </>
  )
}

export default LeaveTypes
