import {FC, useState, useEffect} from 'react'
import {KTIcon, KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'

import {useIntl} from 'react-intl'

import React from 'react'

import useApiCall from '../../custom_hooks/useApiCall'
import PerformanceManager from './performancereviewflow/PerformanceManager'
import PerformanceHR from './performancereviewflow/PerformanceHR'
import {defaultProfileImage} from '../../core'

const TeamReviewsDrawer = (reviewdata: any) => {
  const {props}: any = reviewdata
  const intl = useIntl()
  const {data, fetchData, isLoading}: any | null = useApiCall(
    `/performance/kpi/${props?.employee_performance_id}`
  )
  let performanceData = data && data[0]

  const reviewerDetails = {
    reviewer_status: performanceData?.reviewer_status || '',
    reviewer_comments: performanceData?.reviewer_comments || '',
    employee_performance_id: performanceData?.employee_performance_id || '',
    reviewsData: props?.fetchData,

    fetchData: fetchData,
  }

  const hrDetails = {
    reviewer_status: performanceData?.reviewer_status || '',
    hr_review_comments: performanceData?.hr_review_comments || '',
    hr_reviewed_status: performanceData?.hr_reviewed_status || '',
    employee_performance_id: performanceData?.employee_performance_id || '',
    reviewsData: props?.fetchData,
    fetchData: fetchData,
  }

  return (
    <div className='card shadow-none rounded-0'>
      <div>
        <div className='card-header' id='side_activities_header'>
          <h3 className='card-title fw-bolder text-dark'>
            {intl.formatMessage({id: 'LABEL.TEAM_REVIEWS'})}
          </h3>
          <div className='card-toolbar '>
            <React.Fragment>
              <div className='btn btn-icon btn-sm btn-active-danger' id='side_activities_close'>
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </React.Fragment>
          </div>
        </div>

        <div className='card-body position-relative' id='side_activities_body'>
          <div>
            <div
              id='side_activities_scroll'
              className='position-relative scroll-y me-n5 pe-5 w-400px'
              data-kt-scroll='true'
              data-kt-scroll-height='auto'
              data-kt-scroll-wrappers='#side_activities_body'
              data-kt-scroll-dependencies='#side_activities_header, #side_activities_footer'
              data-kt-scroll-offset='5px'
            >
              {isLoading ? (
                <div className='card h-350px'>
                  <div className='m-auto d-flex flex-column align-items-center'>
                    <div className='spinner-border spinner-primary mr-15'></div>
                  </div>
                </div>
              ) : (
                <>
                  <div className='d-flex'>
                    <div className='d-flex'>
                      <img
                        src={
                          performanceData?.employee_profile_pic
                            ? performanceData?.employee_profile_pic
                            : defaultProfileImage
                        }
                        alt=''
                        className='activity-img rounded w-60px h-60px me-3'
                      />
                      <div className=''>
                        <a href='#' className='text-dark fw-bold text-hover-primary fs-5'>
                          {performanceData?.first_name &&
                          performanceData?.middle_name &&
                          performanceData?.last_name
                            ? `${performanceData?.first_name} ${performanceData?.middle_name} ${performanceData?.last_name}`
                            : performanceData?.first_name && performanceData?.last_name
                            ? `${performanceData?.first_name} ${performanceData?.last_name}`
                            : '-'}
                        </a>
                        <br />
                        <a href='#' className='text-muted fw-bold text-hover-primary fs-7'>
                          {performanceData?.employee_code ? performanceData?.employee_code : '-'}
                        </a>
                        <br />
                        <a href='#' className='text-muted fw-bold text-hover-primary fs-7'>
                          {performanceData?.job_role_name ? performanceData?.job_role_name : '-'}
                        </a>
                        <br />
                        {/* <a href='#' className='text-muted fw-bold text-hover-primary fs-7'>
                          {performanceData?.department}
                        </a> */}
                        <br />
                      </div>
                    </div>
                  </div>
                  <h5 className='mt-1 text-dark'>Basic Information</h5>
                  <hr className='text-dark mt-1' />
                  <div className='row mb-2'>
                    <label className='col-lg-4 fw-bold text-muted'>
                      {intl.formatMessage({id: 'LABEL.KPI_NAME'})}
                    </label>
                    <div className='col-lg-8'>
                      <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                        {performanceData?.kpi_name}
                      </span>
                    </div>
                  </div>
                  <div className='row mb-2'>
                    <label className='col-lg-4 fw-bold text-muted'>
                      {intl.formatMessage({id: 'LABEL.START_DATE'})}
                    </label>
                    <div className='col-lg-8'>
                      <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                        {performanceData?.emp_start_date}
                      </span>
                    </div>
                  </div>
                  <div className='row mb-2'>
                    <label className='col-lg-4 fw-bold text-muted'>
                      {intl.formatMessage({id: 'LABEL.END_DATE'})}
                    </label>
                    <div className='col-lg-8'>
                      <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                        {performanceData?.emp_end_date}
                      </span>
                    </div>
                  </div>
                  <h5 className='mt-4 text-dark'>{intl.formatMessage({id: 'LABEL.WORKFLOW'})}</h5>
                  <hr className='text-dark mt-2' />
                  <div className='card-body position-relative p-0' id='kt_activities_body'>
                    <div className='timeline'>
                      <PerformanceManager reviewerDetails={reviewerDetails} />
                      <PerformanceHR hrDetails={hrDetails} />
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TeamReviewsDrawer
