import React, {useState, useEffect} from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import * as MetronicI18n from '../../../../_metronic/i18n/Metronici18n'
import enLocale from '@fullcalendar/core/locales/en-gb'
import frLocale from '@fullcalendar/core/locales/fr'
import esLocale from '@fullcalendar/core/locales/es-us'
import arLocale from '@fullcalendar/core/locales/ar-sa'

const MyLeavesCalender = (data) => {
  const [events, setEvents] = useState([])
  const [calendarLocale, setCalendarLocale] = useState(getInitLanguage())

  function getGlobalLanguage() {
    const selectedLang = MetronicI18n.getLanguage()
    return selectedLang
  }

  function getInitLanguage() {
    const selectedLanguage = getGlobalLanguage()

    let calendarLocale = enLocale

    switch (selectedLanguage) {
      case 'en':
        calendarLocale = enLocale
        break
      case 'fr':
        calendarLocale = frLocale
        break
      case 'ar':
        calendarLocale = arLocale
        break
      case 'es':
        calendarLocale = esLocale
        break
      default:
        calendarLocale = enLocale
        break
    }

    return calendarLocale
  }

  function getEventStyling(leaveStatus) {
    switch (leaveStatus) {
      case 'Pending':
        return '#9FA6B2'
      case 'HR Pending':
        return '#9FA6B2'
      case 'Manager Pending':
        return '#9FA6B2'
      case 'Approved':
        return 'green'
      case 'Reviewer Approved':
        return 'green'
      case 'Rejected':
        return 'red'
      default:
        return ''
    }
  }

  const employeeLeave = data.data.map((leave) => ({
    title: leave.leave_type_name,
    start: leave.start_date.slice(0, 10),
    end: addOneDayToDate(leave.end_date),
    backgroundColor: getEventStyling(leave.status),
    borderColor: getEventStyling(leave.status),
  }))

  function addOneDayToDate(dateString) {
    const date = new Date(dateString)
    date.setDate(date.getDate() + 1)
    return date.toISOString().split('T')[0]
  }

  return (
    <>
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay',
        }}
        initialView='dayGridMonth'
        weekends={true}
        events={employeeLeave}
        locale={calendarLocale}
        height={590.786}
      />
    </>
  )
}

export default MyLeavesCalender
