import axios from 'axios'
import {AuthModel, UserModel} from './_models'
import secureLocalStorage from 'react-secure-storage'
import {baseUrl} from '../../core'
import {loginBaseUrl} from '../../core'

type UserInfo = {
  user_id: string
}

export const GET_USER_BY_ACCESSTOKEN_URL = `${loginBaseUrl}/token/verify`

export const LOGIN_URL = `${loginBaseUrl}/`

export const REGISTER_URL = `${loginBaseUrl}/register`

export const REQUEST_PASSWORD_URL = `${loginBaseUrl}/forgot_password`

// Server should return AuthModel

export function login(email: string, password: string) {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      i18n_language: 'en',
    },
  }
  const response = axios.post(
    LOGIN_URL,
    {
      email,
      password,
    },
    config
  )

  return response
}

// Server should return AuthModel

export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)

export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export async function getUserByToken(token: string) {
  const userInfo = secureLocalStorage.getItem('user_info')
  let user_id: string | undefined
  if (userInfo) {
    ;({user_id} = userInfo as UserInfo)
  }
  const response = await axios.get<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      i18n_language: 'en',
      user_id: `${user_id}`,
    },
  })

  return response.data.api_response_data
}
