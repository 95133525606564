/* eslint-disable jsx-a11y/anchor-is-valid */
import AllCandidates from './AllCandidates'

import {useIntl} from 'react-intl'
function AllCandidatesTable() {
  const intl = useIntl()
  return (
    <>
      <div className='d-flex'>
        <div className='d-flex flex-wrap flex-stack mb-6'>
          <h3 className='fw-bolder my-2'>
            {' '}
            {intl.formatMessage({id: 'MENU.RECRUITMENT.ALLCANDIDATES'})}
          </h3>
        </div>
      </div>

      <div className='card mb-5 mb-xl-10'>
        <AllCandidates />
      </div>
    </>
  )
}

export default AllCandidatesTable
