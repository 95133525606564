import AssignkpiTable from './AssignkpiTable.jsx'

function AssignKPI() {
  return (
    <>
      <div className='d-flex'>
        <div className='d-flex flex-wrap flex-stack mb-6'>
          <h3 className='fw-bolder my-2'>Assign KPI</h3>
        </div>
      </div>

      <div className='card mb-5 mb-xl-10'>
        <AssignkpiTable />
      </div>
    </>
  )
}

export default AssignKPI
