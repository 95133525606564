/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet, Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

const AuthLayout = () => {
  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  const appThemeMode = localStorage.getItem('kt_theme_mode_value')

  return (
    <div className='d-flex flex-column flex-lg-row flex-column-fluid h-100'>
      {/* begin::Body */}
      <div
        className='d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-1 bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
        // className='col-lg-6 min-h-100 d-flex flex-column justify-content-center align-items-center bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed overflow-auto linear-bg'
        // style={{
        //   backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/sketchy-1/14.png')})`,
        // }}
      >
        {/* begin::Form */}
        <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
          {/* begin::Wrapper */}
          {/* <div className='w-lg-500px p-10'> */}
          {appThemeMode === 'dark' ? (
            <img src={toAbsoluteUrl('/media/zylk-logo-black.svg')} className='h-30px' alt='Logo' />
          ) : (
            <img src={toAbsoluteUrl('/media/zylk-logo-black.svg')} className='h-30px' alt='Logo' />
          )}
          <div className='bg-body rounded shadow-sm p-10 p-lg-15 mx-auto mt-7'>
            <Outlet />
          </div>
          {/* end::Wrapper */}
        </div>
      </div>
      {/* end::Body */}

      {/* begin::Aside */}
      <div
        className='d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-2 order-lg-2'
        style={{backgroundImage: `url(${toAbsoluteUrl('/media/misc/auth-bg.png')})`}}
      >
        {/* begin::Content */}
        <div className='d-flex flex-column flex-center py-15 px-5 px-md-15 w-100'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/zylk-logo-white.svg')}
            className='h-45px mb-12'
          />

          <h1 className='text-white fs-2qx fw-bolder text-center mb-7'>
            Fast, Efficient and Productive
          </h1>
          <div
            id='kt_carousel_1_carousel'
            className='carousel carousel-custom slide text-white'
            data-bs-ride='carousel'
            data-bs-interval='6000'
          >
            <div className='d-flex align-items-center justify-content-between flex-wrap'>
              <span className='fs-4 fw-bolder pe-2'>Projects</span>
              <ol className='p-0 m-0 carousel-indicators carousel-indicators-dots'>
                <li
                  data-bs-target='#kt_carousel_1_carousel'
                  data-bs-slide-to='0'
                  className='ms-1 active'
                ></li>
                <li
                  data-bs-target='#kt_carousel_1_carousel'
                  data-bs-slide-to='1'
                  className='ms-1'
                ></li>
                <li
                  data-bs-target='#kt_carousel_1_carousel'
                  data-bs-slide-to='2'
                  className='ms-1'
                ></li>
              </ol>
            </div>

            <div className='carousel-inner pt-8'>
              <div className='carousel-item active'>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                Ipsum has been the industry's standard dummy text ever since the 1500s, when an
                unknown printer took a galley of type and scrambled it to make a type specimen book.
                It has survived not only five centuries, but also the leap into electronic
                typesetting, remaining essentially unchanged.
              </div>

              <div className='carousel-item'>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                Ipsum has been the industry's standard dummy text ever since the 1500s, when an
                unknown printer took a galley of type and scrambled it to make a type specimen book.
              </div>

              <div className='carousel-item'>
                Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when
                an unknown printer took a galley of type and scrambled it to make a type specimen
                book. It has survived not only five centuries, but also the leap into electronic
                typesetting, remaining essentially unchanged.
              </div>
            </div>
          </div>
        </div>
        {/* end::Content */}
      </div>
      {/* end::Aside */}
    </div>
  )
}

export {AuthLayout}
