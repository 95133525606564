import React from 'react'

const VacancyContext = React.createContext({
  VACANCYID: 0,
  CANDIDATEID: 0,
  INTERVIEWID: 0,
  updateINTERVIEWID: () => {},
  updateCANDIDATEID: () => {},
  updateVACANCYID: () => {},
})

export default VacancyContext
