import {FC, useState, useEffect} from 'react'
import {KTIcon, KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'

import {useIntl} from 'react-intl'

import DateFormatter from '../../DateFormatter'
import RequestHr from './RequestHr'

import useApiCall from '../../custom_hooks/useApiCall'
import React from 'react'
import {defaultProfileImage} from '../../core'

const TeamRequestsDrawer = (requestInfo: any) => {
  const intl = useIntl()
  const {props}: any = requestInfo
  const {data, fetchData, isLoading}: any | null = useApiCall(
    `/selfService/request/${props?.request_id}`
  )
  let RequestData = data && data[0]

  const hrDetails = {
    hr_review_comments: RequestData?.hr_review_comments || '',
    request_status: RequestData?.request_status || '',
    hr_reviewed_timestamp: RequestData?.hr_reviewed_timestamp || '',
    fetchData: fetchData,
    requestFetchData: props?.fetchData,
    request_id: RequestData?.request_id,
  }

  return (
    <div className='card shadow-none rounded-0'>
      <div>
        <div className='card-header' id='side_activities_header'>
          <h3 className='card-title fw-bolder text-dark'>
            {intl.formatMessage({id: 'EMP.TEAM_REQUESTS'})}
          </h3>
          <div className='card-toolbar '>
            <React.Fragment>
              <div className='btn btn-icon btn-sm btn-active-danger' id='side_activities_close'>
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </React.Fragment>
          </div>
        </div>

        <div className='card-body position-relative' id='side_activities_body'>
          <div>
            <div
              id='side_activities_scroll'
              className='position-relative scroll-y me-n5 pe-5 w-400px'
              data-kt-scroll='true'
              data-kt-scroll-height='auto'
              data-kt-scroll-wrappers='#side_activities_body'
              data-kt-scroll-dependencies='#side_activities_header, #side_activities_footer'
              data-kt-scroll-offset='5px'
            >
              {isLoading ? (
                <div className='card h-350px'>
                  <div className='m-auto d-flex flex-column align-items-center'>
                    <div className='spinner-border spinner-primary mr-15'></div>
                  </div>
                </div>
              ) : (
                <>
                  <div className='d-flex'>
                    <div className='d-flex'>
                      <img
                        src={
                          RequestData?.employee_profile_pic
                            ? RequestData?.employee_profile_pic
                            : defaultProfileImage
                        }
                        alt='img'
                        className='activity-img rounded w-60px h-60px me-3'
                      />
                      <div className=''>
                        <a href='#' className='text-dark fw-bold text-hover-primary fs-5'>
                          {RequestData?.first_name &&
                          RequestData?.middle_name &&
                          RequestData?.last_name
                            ? `${RequestData?.first_name} ${RequestData?.middle_name} ${RequestData?.last_name}`
                            : RequestData?.first_name && RequestData?.last_name
                            ? `${RequestData?.first_name} ${RequestData?.last_name}`
                            : '-'}
                        </a>
                        <br />
                        <a href='#' className='text-muted fw-normal text-hover-primary fs-7'>
                          {RequestData?.employee_code ? RequestData?.employee_code : '-'}
                        </a>
                        <br />
                        <a href='#' className='text-muted fw-normal text-hover-primary fs-7'>
                          {RequestData?.job_title ? RequestData?.job_title : '-'}
                        </a>
                        <br />
                        {/* <a href='#' className='text-muted fw-normal text-hover-primary fs-7'>
                          {RequestData?.department}
                        </a> */}
                        <br />
                      </div>
                    </div>
                  </div>

                  <h5 className='mt-1 text-dark'>Basic Information</h5>
                  <hr className='text-dark mt-1' />
                  <div className='row mb-2'>
                    <label className='col-lg-4 fw-bold text-muted'>
                      {intl.formatMessage({id: 'LABEL.REQUEST_TYPE'})}
                    </label>
                    <div className='col-lg-8'>
                      <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                        {RequestData?.request_type}
                      </span>
                    </div>
                  </div>

                  <div className='row mb-2'>
                    <label className='col-lg-4 fw-bold text-muted'>
                      {intl.formatMessage({id: 'LABEL.REQUEST_DETAILS'})}
                    </label>
                    <div className='col-lg-8'>
                      <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                        {RequestData?.request_details}
                      </span>
                    </div>
                  </div>

                  <div className='row mb-2'>
                    <label className='col-lg-4 fw-bold text-muted'>
                      {intl.formatMessage({id: 'EMP.REQUESTED_DATE'})}
                    </label>
                    <div className='col-lg-8'>
                      <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                        {RequestData?.created_timestamp.slice(0, 10)}
                      </span>
                    </div>
                  </div>

                  <h5 className='mt-4 text-dark'>{intl.formatMessage({id: 'LABEL.WORKFLOW'})}</h5>
                  <hr className='text-dark mt-2' />
                  <div className='card-body position-relative p-0' id='kt_activities_body'>
                    <div className='timeline'>
                      <RequestHr hrDetails={hrDetails} />
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TeamRequestsDrawer
