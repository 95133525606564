/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {getUserByToken, login} from '../core/_requests'
import {useAuth} from '../core/Auth'
import secureLocalStorage from 'react-secure-storage'
import {KTIcon} from '../../../../_metronic/helpers'
import '../index.css'

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

const loginSchema = Yup.object().shape({
  email: Yup.string().matches(emailRegex, 'Invalid email format').required('Email is required'),
  password: Yup.string()
    .min(8, 'Password must be 8 characters')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login(): JSX.Element {
  const [loading, setLoading] = useState(false)
  const [loginFailed, setLoginFailed] = useState(false)
  const [loginError, setLoginError] = useState(null)
  const {saveAuth, setCurrentUser} = useAuth()
  const [passwordVisible, setPasswordVisible] = useState(false)
  const scrambleX = (str: any, numTimes = 5) => {
    let encoded = str
    for (let i = 0; i < numTimes; i++) {
      encoded = btoa(encoded)
    }
    return encoded
  }
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const {data: auth} = await login(values.email, scrambleX(values.password))
        setLoginFailed(auth.api_response_flag === false && true)
        setLoginError(auth.api_response_message)
        secureLocalStorage.setItem('user_id', auth.api_response_data[0].user_id)
        secureLocalStorage.setItem('emp_id', auth.api_response_data[0].employee_id)
        secureLocalStorage.setItem('auth_token', auth.api_response_data[0].api_token)
        secureLocalStorage.setItem('organization_id', auth.api_response_data[0].organization_id)
        secureLocalStorage.setItem('role', auth.api_response_data[0].role)

        const preferences = JSON.parse(auth.api_response_data[0].preferences)
        const dirValue = preferences.dir
        const langValue = preferences.lang
        const modeValue = preferences.theme_mode
        const userInfo = {
          user_id: auth.api_response_data[0].user_id,
          emp_id: auth.api_response_data[0].employee_id,
          role: auth.api_response_data[0].role,
          auth_token: auth.api_response_data[0].api_token,
          organization_id: auth.api_response_data[0].organization_id,
          job_role_id: auth.api_response_data[0].job_role_id,
          is_admin: auth.api_response_data[0].is_admin,
          is_finance: auth.api_response_data[0].is_finance,
          is_hr: auth.api_response_data[0].is_hr,
          is_itadmin: auth.api_response_data[0].is_itadmin,
          is_manager: auth.api_response_data[0].is_manager,
        }
        // console.log(userInfo)

        secureLocalStorage.setItem('user_info', userInfo)
        localStorage.setItem(
          'i18nConfig',
          JSON.stringify({selectedLang: langValue, direction: dirValue})
        )
        localStorage.setItem('kt_theme_mode_menu', modeValue)
        localStorage.setItem('kt_theme_mode_value', modeValue)
        saveAuth(auth)
        setCurrentUser(auth.api_response_data[0])
        window.location.reload()
      } catch (error: any) {
        if (error.response) {
          setLoginFailed(true)
          setLoginError(error.response.data.api_response_message)
        }
        console.error(error)
        saveAuth(undefined)
        setStatus('The login details are incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  const showPassword = () => {
    setPasswordVisible(!passwordVisible)
  }

  return (
    <form
      className='form min-w-350px'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>Sign In</h1>
      </div>

      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
        <input
          placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx('form-control bg-transparent')}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
        <div style={{position: 'relative'}} className='form-control-wrapper'>
          <input
            type={passwordVisible ? 'text' : 'password'}
            placeholder='Password'
            autoComplete='off'
            {...formik.getFieldProps('password')}
            className={clsx('form-control bg-transparent')}
          />
          <span onClick={showPassword} className='eye-icon'>
            <KTIcon iconName={passwordVisible ? 'eye-slash' : 'eye'} className='fs-2 icon' />
          </span>
        </div>

        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>

      {/* end::Form group */}

      {/* begin::Wrapper */}
      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
        <div />

        {/* begin::Link */}
        {/* <Link to='/auth/forgot-password' className='link-primary'>
          Forgot Password ?
        </Link> */}
        {/* end::Link */}
      </div>
      {/* end::Wrapper */}

      {/* begin::Action */}
      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Login</span>}

          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        {loginFailed && <label className='text-danger'>{loginError}</label>}
      </div>

      {/* end::Action */}

      {/* <div className='text-gray-500 text-center fw-semibold fs-6'>
        Not a Member yet?{' '}
        <Link to='/auth/registration' className='link-primary'>
          Sign up
        </Link>
      </div> */}
    </form>
  )
}
