/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {KTIcon} from '../../../../../_metronic/helpers'
import Multiselection from './Multiselection'
import {useIntl} from 'react-intl'
import * as Yup from 'yup'
import {ErrorMessage, Field, Form, Formik} from 'formik'
import Swal from 'sweetalert2'
import {baseUrl} from '../../../core'
import secureLocalStorage from 'react-secure-storage'
import {useParams} from 'react-router-dom'
import usePostApi from '../../../custom_hooks/usePostApi'
import {GetWorkPreferences} from '../../OrganizationAPI'
const WorkPreferences = () => {
  const intl = useIntl()
  const {execute} = usePostApi()
  const {id} = useParams()
  const userInfo = secureLocalStorage.getItem('user_info')
  const {is_admin, organization_id} = userInfo || {}
  const {workPrefereceData, isLoading} = GetWorkPreferences()
  const [isEditMode, setIsEditMode] = useState(false)
  const handleEditClick = () => {
    setIsEditMode(true)
  }
  const handleCancelClick = (resetForm) => {
    setIsEditMode(false)
    resetForm()
  }
  const validationSchema = Yup.object().shape({
    week_start_date: Yup.string().required(intl.formatMessage({id: 'LABEL.WEEK_START_REQUIRED'})),
    official_year_start_month: Yup.string().required(
      intl.formatMessage({id: 'LABEL.START_MONTH_REQUIRED'})
    ),
    standard_week_offs: Yup.array()
      .transform((value) => (value.length === 0 ? null : value))
      .required(intl.formatMessage({id: 'LABEL.WEEK_OF_REQUIRED'})),
    max_overtime_allowed: Yup.string().required(
      intl.formatMessage({id: 'LABEL.OVER_TIME_ALLOWED_REQUIRED'})
    ),
    regularization_allowed: Yup.string().required(
      intl.formatMessage({id: 'LABEL.REGULARIZATION_REQUIRED'})
    ),
    max_regularization_allowed: Yup.string().required(
      intl.formatMessage({id: 'LABEL.MAX_REGULARIZATION_REQUIRED'})
    ),
  })

  const handleEditSubmit = async (values) => {
    const url = `${baseUrl}/pref/work/1`
    setIsEditMode(false)
    try {
      const result = await Swal.fire({
        title: intl.formatMessage({id: 'LABEL.UPDATING_WORK_PREFERENCES'}),
        text: intl.formatMessage({id: 'LABEL.CONFIRM_UPDATE_WORK_PREFERENCES'}),
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: intl.formatMessage({id: 'BTN.CONFIRM'}),
        cancelButtonText: intl.formatMessage({id: 'LABEL.CANCEL'}),
        customClass: {
          confirmButton: 'btn btn-light-primary btn-sm',
          cancelButton: 'btn btn-sm btn-secondary',
        },
      })

      if (result.isConfirmed) {
        const requestData = {
          organization_id: organization_id,
          week_start_date: values.week_start_date,
          official_year_start_month: values.official_year_start_month,
          standard_week_offs: values.standard_week_offs.join(','),
          max_overtime_allowed: values.max_overtime_allowed,
          regularization_allowed: values.regularization_allowed,
          max_regularization_allowed: values.max_regularization_allowed,
        }
        await execute(url, 'PATCH', requestData, id)
        Swal.fire({
          title: intl.formatMessage({id: 'LABEL.WORK_PREFERENCES_EDITED'}),
          text: intl.formatMessage({id: 'LABEL.CHANGES_SAVED'}),
          icon: 'success',
          showConfirmButton: true,
          confirmButtonText: 'OK',
          customClass: {
            confirmButton: 'btn btn-light-primary btn-sm',
          },
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload()
          }
        })
      }
    } catch (error) {
      console.error(error)
      Swal.fire({
        icon: 'error',
        title: intl.formatMessage({id: 'LABEL.OOPS'}),
        text: intl.formatMessage({id: 'LABEL.SOMETHING_WRONG'}),
      })
    }
  }

  return (
    <div className={`card card-xl-stretch`}>
      {isLoading ? (
        <div className='card card-xl-stretch h-250px'>
          <div className='m-auto d-flex flex-column align-items-center'>
            <div className='spinner-border spinner-primary mr-15'></div>
          </div>
        </div>
      ) : (
        <Formik
          initialValues={{
            week_start_date: workPrefereceData?.week_start_date || '',
            official_year_start_month: workPrefereceData?.official_year_start_month || '',
            standard_week_offs: [workPrefereceData?.standard_week_offs] || [],
            max_overtime_allowed: workPrefereceData?.max_overtime_allowed || '',
            regularization_allowed: workPrefereceData?.regularization_allowed || '',
            max_regularization_allowed: workPrefereceData?.max_regularization_allowed || '',
          }}
          validationSchema={validationSchema}
          enableReinitialize={true}
          onSubmit={handleEditSubmit}
        >
          {({values, handleChange, setFieldValue, resetForm}) => {
            return (
              <Form>
                <div className='card-header'>
                  <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-5 mb-1'>
                      {intl.formatMessage({id: 'LABEL.ORGANIZATION_WORK_PREFERENCES'})}
                    </span>
                    <span className='text-muted fw-bold fs-7'>
                      <span className='text-muted fw-bold fs-7'>
                        {intl.formatMessage({id: 'LABEL.CONFIGURE_THE_WORK_PREFERENCES'})}
                      </span>
                    </span>
                  </h3>
                  {is_admin === 1 && (
                    <div className='card-toolbar'>
                      {!isEditMode && (
                        <button
                          type='button'
                          className='btn btn-sm btn-light-primary btn-ripple'
                          onClick={handleEditClick}
                        >
                          <KTIcon iconName='pencil' className='fs-7' />
                          {intl.formatMessage({id: 'BTN.EDIT'})}
                        </button>
                      )}
                      {isEditMode && (
                        <React.Fragment>
                          <button className='btn btn-light-primary btn-sm' type='submit'>
                            <KTIcon iconName='check' className='fs-7' />
                            {intl.formatMessage({id: 'BTN.SAVE'})}
                          </button>

                          <div
                            className='btn btn-icon btn-sm btn-active-light-danger ms-2'
                            onClick={() => handleCancelClick(resetForm)}
                          >
                            <KTIcon iconName='cross' className='fs-2' />
                          </div>
                        </React.Fragment>
                      )}
                    </div>
                  )}
                </div>
                <div className='card-body'>
                  <div className='row'>
                    <div className='col-md-6 col-sm-12 mb-2'>
                      <div className='row'>
                        <div className='col-3 d-flex'>
                          <div className='symbol symbol-50px me-2'>
                            <span className='symbol-label'>
                              <KTIcon iconName='user-edit' className='fs-2x' />
                            </span>
                          </div>
                        </div>
                        <div className='col-9 d-flex'>
                          {isEditMode ? (
                            <div className='form-floating flex-fill'>
                              <Field
                                as='select'
                                id='week_start_date'
                                name='week_start_date'
                                className='form-select'
                                value={values.week_start_date}
                                onChange={handleChange}
                              >
                                <option value='Sunday'>Sunday</option>
                                <option value='Monday'>Monday</option>
                                <option value='Tuesday'>Tuesday</option>
                                <option value='Wednesday'>Wednesday</option>
                                <option value='Thursday'>Thursday</option>
                                <option value='Friday'>Friday</option>
                                <option value='Saturday'>Saturday</option>
                              </Field>
                              <label htmlFor='week_start_date'>
                                {intl.formatMessage({id: 'LABEL.ORGANIZATION_WEEK_START_DATE'})}
                              </label>
                              <ErrorMessage
                                component='div'
                                className='text-danger'
                                name='week_start_date'
                              />
                            </div>
                          ) : (
                            <div className='form-floating'>
                              <p className='text-dark fw-bold text-hover-primary my-1 fs-6'>
                                {workPrefereceData?.week_start_date}
                              </p>
                              <span className='text-muted fw-bold d-block fs-7'>
                                {intl.formatMessage({id: 'LABEL.ORGANIZATION_WEEK_START_DATE'})}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className='col-md-6 col-sm-12 mb-2'>
                      <div className='row'>
                        <div className='col-3 d-flex'>
                          <div className='symbol symbol-50px me-2'>
                            <span className='symbol-label'>
                              <KTIcon iconName='user-edit' className='fs-2x' />
                            </span>
                          </div>
                        </div>
                        <div className='col-9 d-flex'>
                          {isEditMode ? (
                            <div className='form-floating flex-fill'>
                              <Field
                                as='select'
                                id='official_year_start_month'
                                name='official_year_start_month'
                                className='form-select'
                                value={values.official_year_start_month}
                                onChange={handleChange}
                              >
                                <option value='January'>January</option>
                                <option value='February'>February</option>
                                <option value='March'>March</option>
                                <option value='April'>April</option>
                                <option value='May'>May</option>
                                <option value='June'>June</option>
                                <option value='July'>July</option>
                                <option value='August'>August</option>
                                <option value='September'>September</option>
                                <option value='October'>October</option>
                                <option value='November'>November</option>
                                <option value='December'>December</option>
                              </Field>
                              <label htmlFor='official_year_start_month'>
                                {intl.formatMessage({id: 'LABEL.ORGANIZATION_START_MONTH'})}
                              </label>
                              <ErrorMessage
                                component='div'
                                className='text-danger'
                                name='official_year_start_month'
                              />
                            </div>
                          ) : (
                            <>
                              <div className='form-floating'>
                                <p className='text-dark fw-bold text-hover-primary my-1 fs-6'>
                                  {workPrefereceData?.official_year_start_month}
                                </p>
                                <span className='text-muted fw-bold d-block fs-7'>
                                  {intl.formatMessage({id: 'LABEL.ORGANIZATION_START_MONTH'})}
                                </span>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className='col-md-6 col-sm-12 my-2'>
                      <div className='row'>
                        <div className='col-3 d-flex'>
                          <div className='symbol symbol-50px me-2'>
                            <span className='symbol-label'>
                              <KTIcon iconName='user-edit' className='fs-2x' />
                            </span>
                          </div>
                        </div>
                        <div className='col-9 d-flex'>
                          {isEditMode ? (
                            <div className='flex-fill'>
                              <Multiselection
                                id='standard_week_offs'
                                name='standard_week_offs'
                                isEditMode={isEditMode}
                                value={values.standard_week_offs}
                                onChange={(selectedOptions) =>
                                  setFieldValue('standard_week_offs', selectedOptions)
                                }
                              />
                              <ErrorMessage
                                component='div'
                                className='text-danger'
                                name='standard_week_offs'
                              />
                            </div>
                          ) : (
                            <div className='form-floating'>
                              <p className='text-dark fw-bold text-hover-primary my-1 fs-6'>
                                {workPrefereceData?.standard_week_offs}
                              </p>
                              <span className='text-muted fw-bold d-block fs-7'>
                                {intl.formatMessage({id: 'LABEL.ORGANIZATION_WEEK_OFF'})}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className='col-md-6 col-sm-12 my-2'>
                      <div className='row'>
                        <div className='col-3 d-flex'>
                          <div className='symbol symbol-50px me-2'>
                            <span className='symbol-label'>
                              <KTIcon iconName='user-edit' className='fs-2x' />
                            </span>
                          </div>
                        </div>
                        <div className='col-9 d-flex'>
                          {isEditMode ? (
                            <div className='form-floating flex-fill'>
                              <Field
                                type='number'
                                className='form-control'
                                id='max_overtime_allowed'
                                name='max_overtime_allowed'
                                step='1'
                                value={
                                  !isEditMode
                                    ? workPrefereceData?.max_overtime_allowed
                                    : values.max_overtime_allowed
                                }
                                onChange={handleChange}
                              />
                              <label htmlFor='max_overtime_allowed'>
                                {intl.formatMessage({
                                  id: 'LABEL.ORGANIZATION_MAX_OVERTIME_ALLOWED',
                                })}
                              </label>
                              <ErrorMessage
                                component='div'
                                className='text-danger'
                                name='max_overtime_allowed'
                              />
                            </div>
                          ) : (
                            <>
                              <div className='form-floating'>
                                <p className='text-dark fw-bold text-hover-primary my-1 fs-6'>
                                  {workPrefereceData?.max_overtime_allowed}
                                  {workPrefereceData?.max_overtime_allowed > 1
                                    ? intl.formatMessage({
                                        id: 'LABEL.HOURS',
                                      })
                                    : intl.formatMessage({
                                        id: 'LABEL.HOUR',
                                      })}
                                </p>
                                <span className='text-muted fw-bold d-block fs-7'>
                                  {intl.formatMessage({
                                    id: 'LABEL.ORGANIZATION_MAX_OVERTIME_ALLOWED',
                                  })}
                                </span>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className='col-md-6 col-sm-12 my-2'>
                      <div className='row'>
                        <div className='col-3 d-flex'>
                          <div className='symbol symbol-50px me-2'>
                            <span className='symbol-label'>
                              <KTIcon iconName='user-edit' className='fs-2x' />
                            </span>
                          </div>
                        </div>
                        <div className='col-9 d-flex'>
                          {isEditMode ? (
                            <div className='form-floating flex-fill'>
                              <Field
                                type='number'
                                className='form-control'
                                id='regularization_allowed'
                                placeholder='type...'
                                name='regularization_allowed'
                              />
                              <label htmlFor='regularization_allowed'>
                                {intl.formatMessage({id: 'LABEL.ORGANIZATION_REG_ALLOWED'})}
                              </label>
                              <ErrorMessage
                                component='div'
                                className='text-danger'
                                name='regularization_allowed'
                              />
                            </div>
                          ) : (
                            <>
                              <div className='form-floating'>
                                <p className='text-dark fw-bold text-hover-primary my-1 fs-6'>
                                  {workPrefereceData?.regularization_allowed}
                                  {workPrefereceData?.regularization_allowed > 1
                                    ? intl.formatMessage({
                                        id: 'LABEL.DAYS',
                                      })
                                    : intl.formatMessage({
                                        id: 'LABEL.DAY',
                                      })}
                                </p>
                                <span className='text-muted fw-bold d-block fs-7'>
                                  {intl.formatMessage({id: 'LABEL.ORGANIZATION_REG_ALLOWED'})}
                                </span>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className='col-md-6 col-sm-12 my-2'>
                      <div className='row'>
                        <div className='col-3 d-flex'>
                          <div className='symbol symbol-50px me-2'>
                            <span className='symbol-label'>
                              <KTIcon iconName='user-edit' className='fs-2x' />
                            </span>
                          </div>
                        </div>
                        <div className='col-9 d-flex'>
                          {isEditMode ? (
                            <div className='form-floating flex-fill'>
                              <Field
                                type='number'
                                className='form-control'
                                id='max_regularization_allowed'
                                name='max_regularization_allowed'
                                value={values.max_regularization_allowed}
                              />
                              <label htmlFor='max_regularization_allowed'>
                                {intl.formatMessage({id: 'LABEL.ORGANIZATION_MAX_REG_TIME'})}
                              </label>
                              <ErrorMessage
                                component='div'
                                className='text-danger'
                                name='max_regularization_allowed'
                              />
                            </div>
                          ) : (
                            <>
                              <div className='form-floating'>
                                <p className='text-dark fw-bold text-hover-primary my-1 fs-6'>
                                  {workPrefereceData?.max_regularization_allowed}{' '}
                                  {workPrefereceData?.max_regularization_allowed > 1
                                    ? intl.formatMessage({
                                        id: 'LABEL.HOURS',
                                      })
                                    : intl.formatMessage({
                                        id: 'LABEL.HOUR',
                                      })}
                                </p>
                                <span className='text-muted fw-bold d-block fs-7'>
                                  {intl.formatMessage({id: 'LABEL.ORGANIZATION_MAX_REG_TIME'})}
                                </span>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )
          }}
        </Formik>
      )}
    </div>
  )
}

export {WorkPreferences}
